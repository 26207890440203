// import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  Drawer,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import CloseIcon from "@mui/icons-material/Close";
import { Card, Grid, TextField, Typography } from "@mui/material";
import styles from "./talent-profile.module.css";

// import docImg from "../../../assets/images/image54.png";
// import Sidebar from "../../../components/common/sidebar";
// import PanelNavbar from "../navbar/TalentNavbar";
// import TelantPhoto from "../../../assets/icons/dummayphoto.png";
// import LevelPhoto from "../../../assets/icons/status.svg";
import LevelPosition from "../../../assets/icons/levelposition.svg";
import LevelPosition1 from "../../../assets/icons/levelposition1.svg";
import LevelPosition2 from "../../../assets/icons/levelposition2.svg";
import CreateIcon from "@mui/icons-material/Create";
import btnStyles from "../../../assets/button.module.css";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailIcon from "@mui/icons-material/Email";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import ChatGPTIcon from "../../../assets/icons/ai-1.png";
// import TumeIcon from "../../../assets/icons/ai-2.png";

// import FileIcon from "../../../assets/icons/file-filled.svg";
import CrossIcon from "../../../assets/icons/crossIcon.svg";
import ProfileDefaultImg from "../../../assets/icons/defaultState.png";
import { useEffect, useMemo, useRef, useState } from "react";
import WelcomeModal from "../../../components/talent-component/modal/WelcomeModal";
// import ChipSelect from "../../../components/common/form/chipSelect";
import countryList from "react-select-country-list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  talentDeleteEducationAPI,
  talentDeleteExperienceAPI,
  talentEditEducationAPI,
  talentEditExperienceAPI,
  talentEducationAPI,
  talentExperienceAPI,
  talentProfileAPI,
  talentProfileUpdateAPI,
  talentsAiToolsAPI,
  talentsSkillsAPI,
} from "../../../reducers/talentProfileSlice";
import Loader from "../../../components/common/Loader";
import CalenderIcon from "../../../assets/icons/calenderIcon.svg";
import ExperienceListItem from "./components/ExprienceListItem";
import EducationListItem from "./components/EducationListItem";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={`${styles.tabScrollBox}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className={`${styles.noScroll}`}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentProfile() {
  const [welcomeModal, setWelcome] = useState(false);
  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [drawerTitle, setDrawerTitle] = useState("");
  const isMounted = useRef(false);
  const [openDocPreview, setOpenDocPreview] = useState(false);
  const [startSelected, setStartSelected] = useState();
  const [editedProfileData, setEditedProfileData] = useState({
    name: "",
    countryCode: "",
    phone: "",
    about: "",
    // address: "",
    // dob: null,
    profileImg: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [experienceDetails, setExperienceDetails] = useState({
    title: "",
    employmentType: "",
    companyName: "",
    location: "",
    startDate: null,
    endDate: null,
    currentWorking: false,
  });
  const [experienceDetailsError, setExperienceDetailsError] = useState({});
  const [educationDetails, setEducationDetails] = useState({
    school: "",
    degree: "",
    fieldOfStudy: "",
    graduationDate: null,
  });
  const [educationDetailsError, setEducationDetailsError] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedAiTools, setSelectedAiTools] = useState([]);
  const {
    loading,
    talentProfileLoading,
    talentProfile,
    talentRating,
    talentProjectCount,
    talentExperience,
    talentEducation,
    talentSkills,
    talentAiTools,
    skillList,
    aiList,
  } = useSelector((state) => state.talentProfile);

  // const dd = useSelector((state) => state.talentProfile);
  // console.log("ratingData", skillList);
  // const prfileData = useSelector((state) => state.talentProfile);
  // console.log("state.talentProfile", prfileData);
  const dispatch = useDispatch();
  // const today = new Date();
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");
  useEffect(() => {
    if (!isMounted.current) {
      dispatch(talentProfileAPI());

      isMounted.current = true;
    }
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpenDocPreview = () => {
    setOpenDocPreview(true);
  };

  const handleCloseDocPreview = () => {
    setOpenDocPreview(false);
  };

  const handleDrawerOpen = (type, title, detail) => {
    if (talentProfile && type === "personalInfo") {
      setEditedProfileData({
        name: talentProfile.name,
        countryCode: talentProfile.countryCode,
        phone: talentProfile.phone,
        about: talentProfile.about,
        // address: talentProfile.address,
        // dob: talentProfile.dob ? new Date(talentProfile.dob) : null,
        profileImg: talentProfile.profilePicture,
      });
    }
    if (talentSkills && type === "skills") {
      const arrayOfStrings = talentSkills.map((obj) => obj.name);
      setSelectedSkills(arrayOfStrings);
    }
    if (talentAiTools && type === "ai") {
      const AITools = talentAiTools.map((obj) => obj.name);
      setSelectedAiTools(AITools);
    }
    if (type === "experience" && title === "Edit Experience" && detail) {
      setExperienceDetails({
        experinceId: detail._id,
        title: detail.title,
        employmentType: detail.employmentType,
        companyName: detail.companyName,
        location: detail.location,
        startDate: new Date(detail.startDate),
        endDate: detail.endDate ? new Date(detail.endDate) : null,
        currentWorking: detail.currentWorking || false,
      });
    }
    if (type === "education" && title === "Edit Education" && detail) {
      setEducationDetails({
        educationId: detail._id,
        school: detail.school,
        degree: detail.degree,
        fieldOfStudy: detail.fieldOfStudy,
        graduationDate: new Date(detail.graduationDate),
      });
    }
    setDrawerType(type);
    setDrawerTitle(title);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setPreviewImage("");
    setEditedProfileData({
      name: "",
      countryCode: "",
      phone: "",
      about: "",
      // address: "",
      // dob: null,
      profileImg: null,
    });
    setFormErrors({});
    setExperienceDetails({
      title: "",
      employmentType: "",
      companyName: "",
      location: "",
      startDate: null,
      endDate: null,
      currentWorking: false,
    });
    setExperienceDetailsError({});
    setEducationDetails({ school: "", degree: "", fieldOfStudy: "", graduationDate: null });
    setEducationDetailsError({});
    setSelectedSkills([]);
    setSelectedAiTools([]);
  };

  useEffect(() => {
    const openWelcome = localStorage.getItem("welcome");
    if (openWelcome === "true") {
      setWelcome(true);
    }
    if (talentProfile.about === "") {
      setWelcome(true);
    }
  }, [talentProfile.about]);

  // const handleClickOpen = () => {
  //   setWelcome(true);
  // };

  const handleClose = () => {
    localStorage.removeItem("welcome");
    setWelcome(false);
  };

  // Profile update functionality

  useEffect(() => {
    if (talentProfile) {
      setEditedProfileData({
        name: talentProfile.name,
        countryCode: talentProfile.countryCode,
        phone: talentProfile.phone,
        about: talentProfile.about,
        // address: talentProfile.address,
        // dob: talentProfile.dob ? new Date(talentProfile.dob) : null,
        profileImg: talentProfile.profilePicture,
      });

      // setPreviewImage(talentProfile.profilePicture);
    }
  }, [talentProfile]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditedProfileData((prevData) => ({
        ...prevData,
        profileImg: file,
      }));
    }
  };
  // const [textareaRows, setTextareaRows] = useState(1);
  const handleProfileInputChange = (event) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setEditedProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    })); ///new Date(date)
  };

  useEffect(() => {
    const textarea = document.getElementById("dynamic-textarea");
    if (textarea) {
      textarea.style.height = "auto"; // Reset the height to auto before recalculating
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
    }
  }, [editedProfileData.about]);
  // const handleProfileDateChange = (date) => {
  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     dob: undefined,
  //   }));

  //   if (date === null) {
  //     setEditedProfileData((prevData) => ({
  //       ...prevData,
  //       dob: null,
  //     }));
  //   } else {
  //     setEditedProfileData((prev) => ({ ...prev, dob: new Date(date) }));
  //   }
  // };

  const options = useMemo(() => countryList().getData(), []);

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    const errors = {};

    // Validation logic for each field
    if (!editedProfileData.name || !editedProfileData.name.trim()) {
      errors.name = "Name is required";
    }

    // if (!editedProfileData.countryCode) {
    //   errors.countryCode = "Country code is required";
    // }

    if (!editedProfileData.phone) {
      errors.phone = "Phone is required";
    }
    //  else if (!/^\d{10}$/.test(editedProfileData.phone)) {
    //   errors.phone = "Phone must be 10 digits";
    // }

    // if (!editedProfileData.about || !editedProfileData.about.trim()) {
    //   errors.about = "About is required";
    // }

    // if (!editedProfileData.dob) {
    //   errors.dob = "Member Since is required";
    // }

    // if (!editedProfileData.address || !editedProfileData.address.trim()) {
    //   errors.address = "Address is required";
    // }

    // If there are errors, set them in the state
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // If no errors, you can proceed with form submission
      // Add your form submission logic here
      dispatch(talentProfileUpdateAPI(editedProfileData, handleDrawerClose));
    }
  };

  //Experience update functionality
  // const [experienceDetails, setExperienceDetails] = useState({
  //   title: "",
  //   employmentType: "",
  //   companyName: "",
  //   location: "",
  //   startDate: null,
  //   endDate: null,
  //   currentWorking: false,
  // });
  // const [experienceDetailsError, setExperienceDetailsError] = useState({});

  const handleExperienceInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "currentWorking") {
      setExperienceDetails((prevData) => ({
        ...prevData,
        endDate: null,
        currentWorking: event.target.checked,
      }));
    } else {
      setExperienceDetailsError((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));

      setExperienceDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleExperienceDateChange = (date, name) => {
    setExperienceDetailsError((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    const selectedDate = date ? new Date(date) : null;
    if (name === "startDate") {
      setStartSelected(selectedDate);
    }

    if (name === "endDate") {
      setExperienceDetails((prevData) => ({
        ...prevData,
        currentWorking: false,
        [name]: selectedDate,
      }));
    } else {
      setExperienceDetails((prevData) => ({
        ...prevData,
        [name]: selectedDate,
      }));
    }
  };

  const handleExperienceUpdate = (e, type) => {
    e.preventDefault();
    const errors = {};

    // Validation logic for each field
    if (!experienceDetails.title || !experienceDetails.title.trim()) {
      errors.title = "Title is required";
    }

    if (!experienceDetails.employmentType || !experienceDetails.employmentType.trim()) {
      errors.employmentType = "Employment type is required";
    }

    if (!experienceDetails.companyName || !experienceDetails.companyName.trim()) {
      errors.companyName = "Company name is required";
    }

    if (!experienceDetails.location || !experienceDetails.location.trim()) {
      errors.location = "Location is required";
    }

    if (!experienceDetails.startDate) {
      errors.startDate = "Start date is required";
    }

    if (!experienceDetails.currentWorking && !experienceDetails.endDate) {
      errors.endDate = "End date is required";
    }

    // if (!experienceDetails.currentWorking) {
    //   errors.address = "Address is required";
    // }

    // If there are errors, set them in the state
    if (Object.keys(errors).length > 0) {
      setExperienceDetailsError(errors);
    } else {
      // If no errors, you can proceed with form submission
      // Add your form submission logic here
      let request = {
        experinceId: experienceDetails.experinceId,
        title: experienceDetails.title,
        employmentType: experienceDetails.employmentType,
        companyName: experienceDetails.companyName,
        location: experienceDetails.location,
        startDate: experienceDetails.startDate,
      };

      if (!experienceDetails.currentWorking) {
        request.endDate = experienceDetails.endDate;
      } else {
        request.currentWorking = "true";
      }

      if (type === "Add") {
        dispatch(talentExperienceAPI(request, handleDrawerClose));
      } else {
        dispatch(talentEditExperienceAPI(request, handleDrawerClose));
      }
    }
  };

  // Education updation functionality

  const handleEducationInputChange = (event) => {
    const { name, value } = event.target;

    setEducationDetailsError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setEducationDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEducationDateChange = (date, name) => {
    setEducationDetailsError((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    const selectedDate = date ? new Date(date) : null;

    setEducationDetails((prevData) => ({
      ...prevData,
      [name]: selectedDate,
    }));
  };

  const handleEducationUpdate = (e, type) => {
    e.preventDefault();
    const errors = {};

    // Validation logic for each field
    if (!educationDetails.school || !educationDetails.school.trim()) {
      errors.school = "School is required";
    }

    if (!educationDetails.degree || !educationDetails.degree.trim()) {
      errors.degree = "Degree  is required";
    }

    if (!educationDetails.fieldOfStudy || !educationDetails.fieldOfStudy.trim()) {
      errors.fieldOfStudy = "Field of study is required";
    }

    // if (!educationDetails.grade || !educationDetails.grade.trim()) {
    //   errors.grade = "Grade is required";
    // }

    if (!educationDetails.graduationDate) {
      errors.graduationDate = "Graduation date is required";
    }

    // if (!educationDetails.endDate) {
    //   errors.endDate = "End date is required";
    // }

    // If there are errors, set them in the state
    if (Object.keys(errors).length > 0) {
      setEducationDetailsError(errors);
    } else {
      if (type === "Add") {
        dispatch(talentEducationAPI(educationDetails, handleDrawerClose));
      } else {
        dispatch(talentEditEducationAPI(educationDetails, handleDrawerClose));
      }
    }
  };

  // Skills updation functionality
  const handleSkillsChange = (event) => {
    setSelectedSkills(event.target.value);
  };

  const getIdArrayByName = (selectedSkills, skillList) => {
    return selectedSkills.map((name) => {
      const foundObject = skillList.find((obj) => obj.name === name);
      return foundObject ? foundObject._id : null;
    });
  };
  const handleSkillsUpdate = (e) => {
    e.preventDefault();
    const request = getIdArrayByName(selectedSkills, skillList);
    const apiRequest = {
      skillId: request,
    };
    dispatch(talentsSkillsAPI(apiRequest, handleDrawerClose, setSelectedSkills));
  };

  // AI updation functionality

  const handleAiToolsChange = (event) => {
    setSelectedAiTools(event.target.value);
  };

  const getIdArrayByNameAI = (selectedAiTools, aiList) => {
    return selectedAiTools.map((name) => {
      const foundObject = aiList.find((obj) => obj.name === name);
      return foundObject ? foundObject._id : null;
    });
  };

  const handleAiToolsUpdate = (e) => {
    e.preventDefault();
    const request = getIdArrayByNameAI(selectedAiTools, aiList);
    const apiRequest = {
      AiId: request,
    };
    //console.log("first,", request);
    dispatch(talentsAiToolsAPI(apiRequest, handleDrawerClose, setSelectedAiTools));
  };

  const aboutRef = useRef(null);
  const toolsRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const educationRef = useRef(null);
  const innerScrollRef = useRef(null);

  const handleScrollToSection = (e, sectionRef) => {
    e.preventDefault();
    const innerContainer = innerScrollRef.current;
    const section = sectionRef.current;

    if (innerContainer && section) {
      const scrollTop = section.offsetTop - innerContainer.offsetTop;

      innerContainer.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleEducationDelete = (id) => {
    const request = {
      educationId: id,
    };
    dispatch(talentDeleteEducationAPI(request));
  };

  const handleExperienceDelete = (id) => {
    const request = {
      experienceId: id,
    };
    dispatch(talentDeleteExperienceAPI(request));
  };

  let imageSource;

  if (talentProfile.proficiency === "Professional") {
    imageSource = LevelPosition1;
  } else if (talentProfile.proficiency === "Expert") {
    imageSource = LevelPosition;
  } else {
    imageSource = LevelPosition2;
  }

  const starArray = Array.from({ length: 5 }, (_, index) => index + 1);

  // You Tube //

  const videoUrl = talentProfile?.videoUrl;

  // Extract video ID from the YouTube URL
  const videoId = videoUrl && new URL(videoUrl).searchParams.get("v");

  const opts = {
    height: "350px",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 0,
      modestbranding: 1,
      showinfo: 0,
    },
  };

  return (
    <>
      <Box class={`${styles.noScroll}`} style={{ position: "relative", height: "80vh", width: "100%" }}>
        <CssBaseline />
        {/* <Sidebar /> */}
        <Box>
          {/* <PanelNavbar /> */}

          {loading ? (
            <Loader />
          ) : (
            <>
              <Card
                className={`panelCard panelCardFullHeight ${
                  talentProfile?.proficiency === "Professional"
                    ? "professtionView"
                    : talentProfile?.proficiency === "Expert"
                    ? "expertView"
                    : "geniusView"
                }`}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card className="panelCard p-0 innerCardFullHeight professtionInnerView">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box className={`${styles.talentContentCardLeft}`}>
                            <Box className={`${styles.videoBox}`}>
                              <div className={`${styles.videoBoxBg}`}>
                                <img src={talentProfile.profilePicture || ProfileDefaultImg} alt=""></img>
                              </div>
                              <div className={`${styles.videoPlayIcon}`} onClick={handleClickOpenDocPreview}>
                                <PlayArrowIcon />
                              </div>
                            </Box>
                            <Box className={`${styles.telentPhotoContainer} p-20`}>
                              <Box className={`${styles.flexCard} gap-md `}>
                                <div className={`${styles.telentPhotoBox}`}>
                                  <Box className={`${styles.telentPhotoCard}`}>
                                    <Box className={`${styles.telentEditPhoto}`}>
                                      <CameraAltIcon
                                        className={`${styles.telentEditPhotoIcon}`}
                                        onClick={() => handleDrawerOpen("personalInfo", "Add Personal Info")}
                                      />
                                    </Box>
                                    <img src={talentProfile.profilePicture || ProfileDefaultImg} alt=""></img>
                                  </Box>
                                  <div
                                    className={`${
                                      talentProfile?.proficiency === "Professional"
                                        ? "professtionBgColor"
                                        : talentProfile?.proficiency === "Expert"
                                        ? "expertBgColor"
                                        : "geniusBgColor"
                                    } ${styles.telentNameBox}`}
                                  >
                                    <Typography variant="body">{talentProfile?.proficiency}</Typography>
                                  </div>
                                </div>
                                <div className={``}>
                                  <Typography variant="h4" className={`${styles.telentName}`}>
                                    {talentProfile?.name}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    className={`${styles.paragraphText}  ${styles.darkText}`}
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {talentProfile?.countryCode}
                                  </Typography>
                                </div>
                              </Box>
                              {/* <Box className={`${styles.telentPhotoBox} p-20`}>
                              <Box className={`${styles.telentPhotoCard}`}>
                                <Box className={`${styles.telentEditPhoto}`}>
                                  <CameraAltIcon
                                    className={`${styles.telentEditPhotoIcon}`}
                                    onClick={() => handleDrawerOpen("personalInfo", "Add Personal Info")}
                                  />
                                </Box>
                                <img src={talentProfile.profilePicture || ProfileDefaultImg} alt=""></img>
                              </Box>
                              <div className={`${styles.telentNameBox}`}>
                                <Typography variant="body">Proffessional</Typography>
                                <Typography variant="h4">{talentProfile?.name}</Typography>
                                <Typography variant="h4" className={`${styles.paragraphText}  ${styles.darkText}`} style={{ wordBreak: "break-all" }}>
                                  {talentProfile.countryCode ?? ""}
                                </Typography>
                              </div> */}
                              <div className={`flexCard justified-between ${styles.ratingBox} mt-4`}>
                                <div className={`${styles.ratingStar} flexCard`}>
                                  {starArray.map((index) =>
                                    talentRating?.[0]?.rating >= index ? (
                                      <StarIcon key={index} className={`cursor-pointer ${styles.rated}`} />
                                    ) : (
                                      <StarBorderIcon key={index} className={`cursor-pointer`} />
                                    ),
                                  )}
                                </div>
                                <Typography variant="h4">
                                  {talentRating?.[0]?.rating !== undefined && talentRating?.[0]?.rating !== null
                                    ? parseFloat(talentRating[0]?.rating).toFixed(1) // Adjust the number in toFixed to your preferred decimal places
                                    : "0.00"}
                                </Typography>
                              </div>
                            </Box>
                            {/* <Box className={`${styles.LinkedBtnBox} p-20`}>
                              <div className={`${styles.cetnerAlignedBtn} fullWidth `}>
                                <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinLinkedinBtn} ${btnStyles.yinRound}  `}>
                                  Import from LinkedIn
                                </Button>
                              </div>
                            </Box> */}

                            <Box className={`${styles.levelBox} p-20`}>
                              <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                Proficiency Level
                              </Typography>
                              {/* <Box className={`mt-3 ${styles.levelPhotoCard}`}>
                                <img src={LevelPhoto} alt=""></img>
                              </Box> */}
                              <Box className={`mt-15 ${styles.levelPositionCard}`}>
                                <img src={imageSource} alt=""></img>
                                <Link
                                  to="mailto:admin@yingo.work?subject=Request for upgrade Proficiency Level"
                                  className={`mt-3 ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} ${styles.upgrade}  `}
                                >
                                  <ForwardToInboxIcon style={{ marginRight: "10px" }} />
                                  Level Upgrade
                                </Link>
                              </Box>
                            </Box>
                            <Box className={`${styles.PersonalInfoCard} p-20`}>
                              <div className="flexCard justified-between">
                                <Typography variant="h4" className={`${styles.pageTalentTitle} `}>
                                  Personal Info
                                </Typography>
                                <CreateIcon
                                  className={` ${
                                    talentProfile?.proficiency === "Professional"
                                      ? "professtionColor"
                                      : talentProfile?.proficiency === "Expert"
                                      ? "expertColor"
                                      : "geniusColor"
                                  } cursor_pointer`}
                                  onClick={() => handleDrawerOpen("personalInfo", "Add Personal Info")}
                                />
                              </div>
                              <div className={`flexCard gap-md mt-15`}>
                                <EmailIcon style={{ color: "#9A9A9A" }} />
                                <Typography
                                  variant="h4"
                                  className={`${styles.paragraphText} ${styles.darkText} ${styles.pRight}`}
                                  style={{ wordBreak: "break-all" }}
                                >
                                  {talentProfile?.email}
                                </Typography>
                              </div>
                              <div className={`flexCard gap-md mt-15`}>
                                <CalendarTodayIcon style={{ color: "#9A9A9A" }} />
                                <Typography variant="h4" className={`${styles.paragraphText} ${styles.darkText}`}>
                                  Member Since {talentProfile.created_at && formatDate(talentProfile.created_at)}
                                </Typography>
                              </div>
                            </Box>
                            <Box className={`${styles.LinkedBtnBox} p-20`} style={{ border: "0" }}>
                              {/* <div className={`${styles.cetnerAlignedBtn} fullWidth mt-3`}>
                            <Button
                              className={`${btnStyles.yinBtnSm} ${btnStyles.yinSecondaryBtnOutline} ${btnStyles.yinRound}  `}
                              style={{ margin: "0 auto " }}
                            >
                              Update Profile
                            </Button>
                          </div> */}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9}>
                          <Box className={`${styles.talentContentCard}`}>
                            <Grid container spacing={2} className="p-20">
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxInProgress}`}>
                                  <Typography>
                                    In-Progress <br />
                                    Projects
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.inprogress}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxCompleted}`}>
                                  <Typography>
                                    Completed <br />
                                    Projects
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.completed}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxOverDue}`}>
                                  <Typography>
                                    Request <br />
                                    Pending
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.requests}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box sx={{ width: "100%" }}>
                              <Box
                                class={`${
                                  talentProfile?.proficiency === "Professional"
                                    ? "proffessionTab"
                                    : talentProfile?.proficiency === "Expert"
                                    ? "expertTab"
                                    : "geniusTab"
                                } profileTab`}
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                {/* <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example"> */}
                                <Tabs
                                  id={`${styles.profileTabList}`}
                                  value={value}
                                  onChange={handleChange}
                                  variant={isLargeScreen ? "scrollable" : "fullWidth"}
                                  scrollButtons="auto"
                                  aria-label="basic tabs example"
                                >
                                  <Tab label="About" {...a11yProps(0)} onClick={(e) => handleScrollToSection(e, aboutRef)} />
                                  <Tab label="Ai Tools" {...a11yProps(1)} onClick={(e) => handleScrollToSection(e, toolsRef)} />
                                  <Tab label="Experience" {...a11yProps(2)} onClick={(e) => handleScrollToSection(e, experienceRef)} />
                                  <Tab label="Skills" {...a11yProps(3)} onClick={(e) => handleScrollToSection(e, skillsRef)} />
                                  <Tab label="Education" {...a11yProps(4)} onClick={(e) => handleScrollToSection(e, educationRef)} />
                                  {/* <Tab label="Cerifications" {...a11yProps(5)} /> */}
                                </Tabs>
                              </Box>
                              <CustomTabPanel>
                                <div className={`${styles.scrollableContainer}`} ref={innerScrollRef}>
                                  {/* About */}
                                  <div className={`${styles.sectionDivider}`} ref={aboutRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        About
                                      </Typography>
                                      <Box className="flexCard gap-xs">
                                        <CreateIcon
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.smIcon} cursor_pointer`}
                                          style={{ marginLeft: "15px" }}
                                        />

                                        <Typography
                                          variant="body"
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.font15} cursor_pointer`}
                                          onClick={() => handleDrawerOpen("personalInfo", "Add Personal Info")}
                                        >
                                          Add{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Typography variant="body" className={`${styles.commonText}`}>
                                      {talentProfile.about ?? <div className="text-center text-muted">No Data Added</div>}
                                    </Typography>
                                  </div>
                                  {/*  AI Tools */}
                                  <div className={`${styles.sectionDivider}`} ref={toolsRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        AI Tools
                                      </Typography>
                                      <Box className="flexCard gap-xs ">
                                        <CreateIcon
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.smIcon} cursor_pointer`}
                                          style={{ marginLeft: "15px" }}
                                        />
                                        <Typography
                                          variant="body"
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.font15} cursor_pointer`}
                                          onClick={() => handleDrawerOpen("ai", "Add Ai Tools")}
                                        >
                                          Add{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {talentAiTools && talentAiTools.length > 0 ? (
                                          talentAiTools.map((aiTool) => {
                                            const { name, icon } = aiTool;
                                            return (
                                              <ListItem disablePadding key={aiTool.id}>
                                                <ListItemIcon>
                                                  <img src={icon} alt="" />
                                                </ListItemIcon>
                                                <ListItemText className={`${styles.listaiToolTitle}`} primary={name} />
                                              </ListItem>
                                            );
                                          })
                                        ) : (
                                          <div className="text-center text-muted">No Data Added</div>
                                        )}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Experince */}
                                  <div className={`${styles.sectionDivider}`} ref={experienceRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Experience
                                      </Typography>
                                      <Box className="flexCard gap-xs ">
                                        <CreateIcon
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.smIcon} cursor_pointer`}
                                          style={{ marginLeft: "15px" }}
                                        />
                                        <Typography
                                          variant="body"
                                          className={` ${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.font15} cursor_pointer`}
                                          onClick={() => handleDrawerOpen("experience", "Add Experience")}
                                        >
                                          Add{" "}
                                        </Typography>{" "}
                                      </Box>
                                    </Box>
                                    <Box className={` ${styles.listExperience}`}>
                                      <List>
                                        {talentExperience && talentExperience.length > 0 ? (
                                          talentExperience.map((experience, index) => {
                                            return (
                                              <ExperienceListItem
                                                experience={experience}
                                                key={index}
                                                onUpdate={handleDrawerOpen}
                                                onDelete={handleExperienceDelete}
                                              />
                                            );
                                          })
                                        ) : (
                                          <div className="text-center text-muted">No Data Added</div>
                                        )}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Skills*/}
                                  <div className={`${styles.sectionDivider}`} ref={skillsRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Skills
                                      </Typography>
                                      <Box className="flexCard gap-xs ">
                                        <CreateIcon
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.smIcon} cursor_pointer`}
                                          style={{ marginLeft: "15px" }}
                                        />
                                        <Typography
                                          variant="body"
                                          className={` ${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.font15} cursor_pointer`}
                                          onClick={() => handleDrawerOpen("skills", "Add Skills")}
                                        >
                                          Add{" "}
                                        </Typography>{" "}
                                      </Box>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {talentSkills && talentSkills.length > 0 ? (
                                          talentSkills.map((skills, index) => {
                                            return (
                                              <ListItem disablePadding key={index}>
                                                <ListItemText className={`${styles.listaiToolTitle}`} primary={skills.name} />
                                              </ListItem>
                                            );
                                          })
                                        ) : (
                                          <div className="text-center text-muted">No Data Added</div>
                                        )}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Education*/}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Education
                                      </Typography>
                                      <Box className="flexCard gap-xs ">
                                        <CreateIcon
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.smIcon} cursor_pointer`}
                                          style={{ marginLeft: "15px" }}
                                        />

                                        <Typography
                                          variant="body"
                                          className={`${
                                            talentProfile?.proficiency === "Professional"
                                              ? "professtionColor"
                                              : talentProfile?.proficiency === "Expert"
                                              ? "expertColor"
                                              : "geniusColor"
                                          } ${styles.font15} cursor_pointer`}
                                          onClick={() => handleDrawerOpen("education", "Add Education")}
                                        >
                                          Add{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className={`${styles.listExperience}`}>
                                      <List>
                                        {talentEducation && talentEducation.length > 0 ? (
                                          talentEducation.map((education, index) => {
                                            // const { degree, fieldOfStudy, school, graduationDate } = education;
                                            // const graduationYear = new Date(graduationDate).getFullYear();
                                            return (
                                              <EducationListItem
                                                education={education}
                                                key={index}
                                                onUpdate={handleDrawerOpen}
                                                onDelete={handleEducationDelete}
                                              />
                                              // <ListItem className="flexCard aignItemCenter justified-between" disablePadding key={experience.id}>
                                              //   <div className="flexCard aignItemCenter">
                                              //     <ListItemIcon className={`${styles.listCardIcon}`}>
                                              //       <img src={FileIcon} alt="" />
                                              //     </ListItemIcon>
                                              //     <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                              //       <div className="fullWidth flexCard  justified-between">
                                              //         <ListItemText className={`${styles.listaiToolTitle}`} primary={school} />
                                              //       </div>
                                              //       <div className={`${styles.listaiToolSubtitle} flexCard`} style={{ marginTop: "-5px" }}>
                                              //         <ListItemText className={`${styles.listaiToolSubtitle}`}>
                                              //           {degree} in {fieldOfStudy} in {graduationYear}{" "}
                                              //         </ListItemText>
                                              //       </div>
                                              //     </Box>
                                              //   </div>
                                              //   <div className="flexCard cardActionIcon">
                                              //     <IconButton>
                                              //       <CreateIcon className={`${styles.innerActionIcon}`} />
                                              //     </IconButton>
                                              //     <IconButton>
                                              //       <DeleteOutlineIcon className={`${styles.innerActionIcon}`} />
                                              //     </IconButton>
                                              //   </div>
                                              // </ListItem>
                                            );
                                          })
                                        ) : (
                                          <div className="text-center text-muted">No Data Added</div>
                                        )}
                                      </List>
                                      <div ref={educationRef} style={{ height: "150px" }}></div>
                                    </Box>
                                  </div>
                                </div>
                              </CustomTabPanel>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                {/* Personal Info Drawer */}
                <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose} hideBackdrop>
                  {/* Drawer content goes here */}
                  <Box className={`${styles.drawerWidth} `}>
                    <div className={`${styles.drawerHeader} flexCard justified-between`}>
                      <Typography variant="h4">{drawerTitle}</Typography>
                      <IconButton onClick={handleDrawerClose} disabled={talentProfileLoading}>
                        {" "}
                        <img src={CrossIcon} alt="" style={{ width: "24px" }} />
                      </IconButton>
                    </div>
                    <Box component="form" className={`${styles.drawerInputBox} mt-3`}>
                      {drawerType === "personalInfo" && (
                        <>
                          <Box className={`${styles.formDrawerBox}`}>
                            <InputLabel className={`${styles.inputLabel} `}>Profile Photo</InputLabel>
                            <Box className={`${styles.userProfilePhoto}`}>
                              <div className={`${styles.userPhoto}`}>
                                <img src={previewImage || editedProfileData.profileImg || ProfileDefaultImg} alt="Profile Preview" />
                              </div>
                              <Box className={`${styles.uploadPhotoBtn} cursor_pointer`}>
                                Upload Photo
                                <input type="file" id="uploadimage" onChange={handleImageUpload} className={`${styles.uploadPhotoInput}`} />
                              </Box>
                            </Box>
                            <div className={`${styles.inputGroup} mb-2 mt-3`}>
                              <InputLabel className={`${styles.inputLabel} `}>Name</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  // placeholder="Enter Name"
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleProfileInputChange}
                                  value={editedProfileData.name}
                                  name="name"
                                />
                              </div>
                              {formErrors.name && <div className="error">{formErrors.name}</div>}
                            </div>
                            {/* <div className={`${styles.inputGroup} mb-2`}>
                            <InputLabel className={`${styles.inputLabel} `}>Email Address</InputLabel>
                            <div className={`${styles.inputBox}`}>
                              <TextField required placeholder="" className={`${styles.inputBoxInput}`} />
                            </div>
                          </div> */}
                            {/* <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Member Since</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <DatePicker
                                  className={`${styles.datePicker}`}
                                  // value={editedProfileData.dob && showFormatedDate(editedProfileData.dob)}
                                  selected={editedProfileData.dob}
                                  onChange={(date) => handleProfileDateChange(date)}
                                  dateFormat="yyyy/MM/dd"
                                  name="dob"
                                  maxDate={today}
                                  // placeholderText="yyyy/mm/dd"
                                  // readOnly={projectDetails?.initStatus === "completed"}
                                />
                                <div className={`${styles.addonIcon}`}>
                                  <img src={CalenderIcon} alt=""></img>
                                </div>
                              </div>
                              {formErrors.dob && <div className="error">{formErrors.dob}</div>}
                            </div> */}
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Phone Number</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  // placeholder="Enter Phone number"
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleProfileInputChange}
                                  value={editedProfileData.phone}
                                  name="phone"
                                  type="number"
                                />
                              </div>
                              {formErrors.phone && <div className="error">{formErrors.phone}</div>}
                            </div>
                            {/* <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Address</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  // placeholder="Enter Address"
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleProfileInputChange}
                                  value={editedProfileData.address}
                                  name="address"
                                />
                              </div>
                              {formErrors.address && <div className="error">{formErrors.address}</div>}
                            </div> */}
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Country</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <Select
                                  name="countryCode"
                                  className={`formInputSelect ${styles.inputBoxSelect}`}
                                  // className={`formInputSelect ${styles.formInput} `}
                                  value={editedProfileData.countryCode}
                                  onChange={handleProfileInputChange}
                                >
                                  <MenuItem value="">Select a country</MenuItem>
                                  {options.map((option) => (
                                    <MenuItem key={option.value} value={option.label}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>
                              {formErrors.countryCode && <div className="error">{formErrors.countryCode}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>About</InputLabel>
                              <div className={`${styles.TextAreaInputBox}`}>
                                <TextField
                                  id="dynamic-textarea"
                                  multiline
                                  // rows={2}
                                  required
                                  placeholder=""
                                  className={`${styles.TextAreaInput}`}
                                  onChange={handleProfileInputChange}
                                  value={editedProfileData.about}
                                  name="about"
                                  rows={1}
                                  style={{ resize: "none", overflowY: "hidden" }} // Disable manual resizing and hide vertical scrollbar
                                />
                              </div>
                              {formErrors.about && <div className="error">{formErrors.about}</div>}
                            </div>
                          </Box>

                          <div className={`${styles.buttonBox} fullWidth`}>
                            <Button
                              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                              onClick={handleProfileUpdate}
                            >
                              Submit {talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                            </Button>
                          </div>
                        </>
                      )}
                      {drawerType === "ai" && (
                        <>
                          <div className={`${styles.inputGroup} mb-2 customSelction`}>
                            <InputLabel className={`${styles.inputLabel} `}>Choose Ai Tools</InputLabel>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip2"
                              multiple
                              value={selectedAiTools}
                              onChange={handleAiToolsChange}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  {selected.length === 0
                                    ? " Add/Edit AI Tools"
                                    : selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={value}
                                          icon={value.icon ? <img src={value.icon} alt="" style={{ width: "20px" }} /> : null}
                                        />
                                      ))}
                                </Box>
                              )}
                            >
                              <MenuItem disabled value="">
                                Add/Edit AI Tools
                              </MenuItem>
                              {aiList.map((ai) => (
                                <MenuItem key={ai.name} value={ai.name}>
                                  {ai?.icon && <img src={ai.icon} alt="" style={{ width: "20px", marginRight: "5px" }} />}
                                  {ai.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className={`${styles.buttonBox} fullWidth`}>
                            <Button
                              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                              onClick={handleAiToolsUpdate}
                              disabled={selectedAiTools.length === 0}
                            >
                              Submit{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                            </Button>
                          </div>
                        </>
                      )}
                      {drawerType === "skills" && (
                        <>
                          <div className={`${styles.inputGroup} mb-2  customSelction `}>
                            <InputLabel className={`${styles.inputLabel} `}>Choose Skills</InputLabel>

                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip1"
                              multiple
                              value={selectedSkills}
                              onChange={handleSkillsChange}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  {selected.length === 0 ? "Add/Edit Skills" : selected.map((value) => <Chip key={value} label={value} />)}
                                </Box>
                              )}
                            >
                              <MenuItem disabled value="">
                                Add/Edit Skills
                              </MenuItem>
                              {skillList.map((skill) => (
                                <MenuItem key={skill.name} value={skill.name}>
                                  {skill.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className={`${styles.buttonBox} fullWidth`}>
                            <Button
                              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                              onClick={handleSkillsUpdate}
                              disabled={selectedSkills.length === 0}
                            >
                              Submit{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                            </Button>
                          </div>
                        </>
                      )}
                      {drawerType === "education" && (
                        <>
                          <Box className={`${styles.formDrawerBox}`}>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>School</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleEducationInputChange}
                                  value={educationDetails.school}
                                  name="school"
                                />
                              </div>
                              {educationDetailsError.school && <div className="error">{educationDetailsError.school}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Degree</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleEducationInputChange}
                                  value={educationDetails.degree}
                                  name="degree"
                                />
                              </div>
                              {educationDetailsError.degree && <div className="error">{educationDetailsError.degree}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Field of Study</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleEducationInputChange}
                                  value={educationDetails.fieldOfStudy}
                                  name="fieldOfStudy"
                                />
                              </div>
                              {educationDetailsError.fieldOfStudy && <div className="error">{educationDetailsError.fieldOfStudy}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Graduation Date</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <DatePicker
                                  className={`${styles.datePicker}`}
                                  selected={educationDetails.graduationDate}
                                  onChange={(date) => handleEducationDateChange(date, "graduationDate")}
                                  dateFormat="yyyy/MM/dd"
                                  name="graduationDate"
                                  maxDate={new Date()}
                                />
                                <div className={`${styles.addonIcon}`}>
                                  <img src={CalenderIcon} alt=""></img>
                                </div>
                              </div>
                              {educationDetailsError.graduationDate && <div className="error">{educationDetailsError.graduationDate}</div>}
                            </div>
                            {/* <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Grade</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleEducationInputChange}
                                  value={experienceDetails.grade}
                                  name="grade"
                                />
                              </div>
                              {educationDetailsError.grade && <div className="error">{educationDetailsError.grade}</div>}
                            </div> */}

                            {/* <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>End Date</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <DatePicker
                                  className={`${styles.datePicker}`}
                                  selected={educationDetails.endDate}
                                  onChange={(date) => handleEducationDateChange(date, "endDate")}
                                  dateFormat="yyyy/MM/dd"
                                  name="endDate"
                                />
                              </div>
                              {educationDetailsError.endDate && <div className="error">{educationDetailsError.endDate}</div>}
                            </div> */}
                          </Box>
                          {drawerTitle === "Edit Education" ? (
                            <div className={`${styles.buttonBox} fullWidth`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                                onClick={(e) => handleEducationUpdate(e, "Edit")}
                              >
                                Update{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                              </Button>
                            </div>
                          ) : (
                            <div className={`${styles.buttonBox} fullWidth`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                                onClick={(e) => handleEducationUpdate(e, "Add")}
                              >
                                Submit{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      {drawerType === "experience" && (
                        <>
                          <Box className={`${styles.formDrawerBox}`}>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Title</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleExperienceInputChange}
                                  value={experienceDetails.title}
                                  name="title"
                                />
                              </div>
                              {experienceDetailsError.title && <div className="error">{experienceDetailsError.title}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Employment Type</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <Select
                                  className={`${styles.inputSelectBox}`}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={handleExperienceInputChange}
                                  name="employmentType"
                                  value={experienceDetails.employmentType}
                                >
                                  <MenuItem value={"Full-time"}>Full Time</MenuItem>
                                  <MenuItem value={"Part-time"}>Part Time</MenuItem>
                                </Select>
                                {/* <TextField required placeholder="" className={`${styles.inputBoxInput}`} /> */}
                              </div>
                              {experienceDetailsError.employmentType && <div className="error">{experienceDetailsError.employmentType}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Company Name</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleExperienceInputChange}
                                  value={experienceDetails.companyName}
                                  name="companyName"
                                />
                              </div>
                              {experienceDetailsError.companyName && <div className="error">{experienceDetailsError.companyName}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Location</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <TextField
                                  required
                                  placeholder=""
                                  className={`${styles.inputBoxInput}`}
                                  onChange={handleExperienceInputChange}
                                  value={experienceDetails.location}
                                  name="location"
                                />
                              </div>
                              {experienceDetailsError.location && <div className="error">{experienceDetailsError.location}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>Start Date</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <DatePicker
                                  className={`${styles.datePicker}`}
                                  selected={experienceDetails.startDate}
                                  onChange={(date) => handleExperienceDateChange(date, "startDate")}
                                  dateFormat="yyyy/MM/dd"
                                  name="startDate"
                                  maxDate={new Date()}
                                />
                                <div className={`${styles.addonIcon}`}>
                                  <img src={CalenderIcon} alt=""></img>
                                </div>
                              </div>
                              {experienceDetailsError.startDate && <div className="error">{experienceDetailsError.startDate}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <InputLabel className={`${styles.inputLabel} `}>End Date</InputLabel>
                              <div className={`${styles.inputBox}`}>
                                <DatePicker
                                  className={`${styles.datePicker}`}
                                  selected={experienceDetails.endDate}
                                  onChange={(date) => handleExperienceDateChange(date, "endDate")}
                                  dateFormat="yyyy/MM/dd"
                                  name="endDate"
                                  disabled={experienceDetails.currentWorking}
                                  maxDate={new Date()}
                                  minDate={new Date(startSelected)}
                                />
                                <div className={`${styles.addonIcon}`}>
                                  <img src={CalenderIcon} alt=""></img>
                                </div>
                              </div>
                              {experienceDetailsError.endDate && <div className="error">{experienceDetailsError.endDate}</div>}
                            </div>
                            <div className={`${styles.inputGroup} mb-2`}>
                              <FormControlLabel
                                control={<Checkbox checked={experienceDetails.currentWorking} onChange={handleExperienceInputChange} />}
                                label="Currently Working"
                                name="currentWorking"
                                className={`${styles.inputCheckBoxLabel} `}
                              />
                            </div>
                          </Box>
                          {drawerTitle === "Edit Experience" ? (
                            <div className={`${styles.buttonBox} fullWidth`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                                onClick={(e) => handleExperienceUpdate(e, "Edit")}
                              >
                                Update{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                              </Button>
                            </div>
                          ) : (
                            <div className={`${styles.buttonBox} fullWidth`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
                                onClick={(e) => handleExperienceUpdate(e, "Add")}
                              >
                                Submit{talentProfileLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                </Drawer>
              </Card>

              {welcomeModal ? <WelcomeModal open={welcomeModal} handleClose={handleClose} /> : null}
            </>
          )}
        </Box>
      </Box>
      <Dialog className={`videoPreviewModal`} open={openDocPreview} onClose={handleCloseDocPreview} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCloseDocPreview} />
          </IconButton>
          <Box className={`docImgBox`}>
            <Box className={`docImgBox`}>{videoId ? <YouTube videoId={videoId} opts={opts} /> : "No Video Uploaded Yet!"}</Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
