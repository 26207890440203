import { createSlice } from "@reduxjs/toolkit";

import { openSnackbar } from "./snackbarSlice";
import { getAdminTalentsListAPI, getTalentsListAPI, postAssignTalentAPI } from "../services/api/talents-api";
import { projectListByStatusAPI } from "./intialQuerySlice";

const initialState = {
  loading: false,
  talentsList: [],
};

const talentsSlice = createSlice({
  name: "talents",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    talentsSuccess(state, action) {
      state.talentsList = action.payload;
    },
  },
});

export const { isLoading, finishLoading, talentsSuccess } = talentsSlice.actions;

export const talentsListAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getTalentsListAPI(request);
    if (response.status === 200) {
      dispatch(talentsSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishLoading());
};

export const talentsAssignAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postAssignTalentAPI(request);
    if (response.status === 201) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      const apiBody = {
        status: "pending",
        pageNo: "1",
        // search: "",
      };
      dispatch(projectListByStatusAPI(apiBody));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

/*********GD API ***** */
export const adminTalentsListAPI = (request) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await getAdminTalentsListAPI(request);
    if (response.status === 200) {
      dispatch(talentsSuccess(response.data?.data));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishLoading());
};

export default talentsSlice.reducer;
