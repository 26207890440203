/* eslint-disable react/prop-types */
import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileIcon from "../../../../assets/icons/file-filled.svg";
import styles from "../talent-profile.module.css";

function ExprienceListItem({ experience, onDelete, onUpdate }) {
  const [isHovered, setIsHovered] = useState(false);

  const { id, title, startDate, endDate, companyName, employmentType, location, currentWorking } = experience;
  const startyear = new Date(startDate).getFullYear();
  const endyear = currentWorking ? "Present" : new Date(endDate).getFullYear();

  return (
    <ListItem
      className="flexCard aignItemCenter"
      disablePadding
      key={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ListItemIcon className={`${styles.listCardIcon}`}>
        <img src={FileIcon} alt="" />
      </ListItemIcon>
      <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
        <div className="fullWidth flexCard  justified-between">
          <ListItemText className={`${styles.listaiToolTitle}`} primary={title} />

          {isHovered && (
            <div className="cardActionIcon">
              <div className="flexCard gap10">
                <IconButton className={`${styles.editIcon}`} onClick={() => onUpdate("experience", "Edit Experience", experience)}>
                  <CreateIcon className={`${styles.innerActionIcon}`} />
                </IconButton>
                <IconButton className={`${styles.delIcon}`} onClick={() => onDelete(experience._id)}>
                  <DeleteOutlineIcon className={`${styles.innerActionIcon}`} />
                </IconButton>
              </div>
            </div>
          )}
        </div>
        <div className="flexCard " style={{ marginTop: "-5px" }}>
          <ListItemText className={`${styles.listaiToolSubtitle}`} primary={companyName} />({" "}
          <div className="flexCard">
            <ListItemText className={`${styles.listaiToolSubtitle}`} primary={startyear} />-
            <ListItemText className={`${styles.listaiToolSubtitle}`} primary={endyear} />
          </div>
          )
        </div>
        <div className="flexCard " style={{ marginTop: "-5px" }}>
          <ListItemText className={`${styles.listaiToolSubtitle}`} primary={employmentType} />
          <div className={`${styles.spacerSm}`}>|</div>
          <ListItemText className={`${styles.listaiToolSubtitle}`} primary={location} />
        </div>
      </Box>
    </ListItem>
  );
}

export default ExprienceListItem;
