/* eslint-disable react/prop-types */
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import styles from "./projectCard.module.css";
import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
// import NoTalent from "../../../assets/icons/notalent.svg";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { capitalizeFirstLetter, formatDate } from "../../../utils/helper";
import ProfileDefaultImg from "../../../assets/icons/defaultState.png";
import { useTranslation } from "react-i18next";

function TalentProjectCard({ projectsList, currentTab }) {
  const { t } = useTranslation();
  const { projects } = projectsList;
  return (
    <>
      {projects && projects.length > 0 ? (
        projects.map((project, index) => {
          const { status, projectDetails, inProgressDeliverable, completedDeliverable } = project;
          const { _id, name, longDescription, endDate, userData } = projectDetails[0];
          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card
                className={`${
                  capitalizeFirstLetter(status) === "Not-submitted"
                    ? styles.notSubmitCard
                    : capitalizeFirstLetter(status) === "Pending"
                    ? styles.pendingCard
                    : capitalizeFirstLetter(status) === "In-progress"
                    ? styles.progressCard
                    : capitalizeFirstLetter(status) === "Overdue"
                    ? styles.overdueCard
                    : capitalizeFirstLetter(status) === "Completed"
                    ? styles.completedCard
                    : ""
                } ${styles.projectCard}`}
              >
                <CardContent className={`${styles.projectContent}`}>
                  <Box className={`${styles.projectCardHead} ${styles.flexBox}`}>
                    <div className={`${styles.flexBox} `}>
                      <span
                        className={`${styles.badge}    ${
                          capitalizeFirstLetter(status) === "In-progress"
                            ? styles.badgeInProgress
                            : capitalizeFirstLetter(status) === "Overdue"
                            ? styles.badgeOverdue
                            : styles.badgeCompleted
                        }`}
                      >
                        {capitalizeFirstLetter(status)}
                      </span>
                      {/* <p className={`${styles.lastStatusTime}`}>Updated 3hrs ago</p> */}
                    </div>
                  </Box>
                  <Box className={`${styles.projectCardBody}`}>
                    <Link to={`/talent-project-detail/${_id}`}>
                      <Box className={`${styles.projectInfo} ${styles.flexBox} ${styles.flexStart}`}>
                        <CardMedia className={`${styles.projectThumb}`}>
                          {/* <img src={ProjectImg} alt="" /> */}
                          <img src={DummyImg} alt="" />
                        </CardMedia>
                        <CardContent className={`${styles.projectDesc}`}>
                          <Typography variant="h4" className={`${styles.projectName}`}>
                            {name}
                          </Typography>
                          <Typography variant="p" className={`${styles.projectDetail}`}>
                            {longDescription}
                          </Typography>
                        </CardContent>
                      </Box>
                      <Box className={`${styles.tasksCard}`}>
                        <div className={`${styles.flexBox} ${styles.tasksBox}`}>
                          <span>Deliverables</span>
                          <span className={`${styles.flexBox}`} style={{ gap: "0" }}>
                            <ListIcon />
                            {completedDeliverable}/{inProgressDeliverable}
                          </span>
                        </div>
                      </Box>
                    </Link>
                    <Box>
                      <div className={`${styles.flexBox} ${styles.userBox}`}>
                        <div className={`${styles.flexBox}`}>
                          <CardMedia className={`${styles.userBoxImg}`}>
                            {userData.profilePicture ? (
                              <img src={userData.profilePicture || ProfileDefaultImg} alt="" />
                            ) : (
                              <span>{userData.name.charAt(0)}</span>
                            )}
                          </CardMedia>
                        </div>
                        <div className={`${styles.flexBox} ${styles.dateOfProject} ${styles.alignCenter}`} style={{ gap: "2px" }}>
                          <CalendarMonthIcon />
                          {formatDate(endDate)}
                        </div>
                      </div>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      ) : (
        <Grid container spacing={2}>
          <Grid item sm={12} md={12} className={`mt-2`}>
            <Box className={`emptyStateBox`}>
              <Box className="emptyStateContainer">
                <DataNotFound icon={"record"} title={""} subtitle={t("textProjectNotFound")} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TalentProjectCard;
