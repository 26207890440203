import Box from "@mui/material/Box";
import { useEffect, useLayoutEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Typography } from "@mui/material";
import styles from "./terms.module.css";
import LineOverlay from "../../../assets/icons/linelayer.png";
import Navbar from "../../../components/common/navbar";
// import Footer from "../../../components/dashboard/footer";
import { ROLES } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";

export default function Terms(props) {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (role === ROLES.Talent) {
      navigate("/talent-profile", { replace: true });
    }
  }, [navigate, role]);

  useEffect(() => {
    document.body.classList.add("darkbg");

    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);
  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className="custombg">
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <Navbar />
        <Grid container className={`container  ${styles.textWhite} ${styles.paddingTop152}`} style={{ zIndex: 0 }}>
          <Grid item xs={12} className={`${styles.textWhite}`}>
            <Typography variant="h3">Terms of Use</Typography>
            <Typography>
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point
              of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content making it
              look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
              search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </Typography>
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </Box>
    </>
  );
}
