export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Format the date as "dd Month, yyyy"
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = dateObject.toLocaleDateString(undefined, options);
  return formattedDate;
};

export const showFormatedDate = (date) => {
  const originalDate = new Date(date);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const showYearOnly = (date) => {
  const dateString = date;

  const dateObject = new Date(dateString);

  const year = dateObject.getFullYear();

  return year;
};
