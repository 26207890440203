import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "./../../../../../assets/yingoIcons";
import styles from "./uploadFIle.module.css";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15px 10px",
  borderWidth: 1,
  borderRadius: "25px",
  borderColor: "#6A6A6A",
  borderStyle: "dashed",
  color: "#B3B5B6",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#6A6A6A",
};

const acceptStyle = {
  borderColor: "#6A6A6A",
};

const rejectStyle = {
  borderColor: "#6A6A6A",
};

const UploadTalentDocument = (props) => {
  const { onDropChange } = props || {};
  const { maxFiles } = props || null;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive } = useDropzone({
    onDrop: onDropChange,
    maxFiles,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className={`${styles.uploadMainContainer}`}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <div className="uploadPopIcon">
              <UploadIcon />
            </div>
            <p>Drop the files here ...</p>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div className="uploadPopIcon">
              <UploadIcon />
            </div>
            <p className={`${styles.uploadBoxInnerTitle}`}>
              <span>Upload Document</span>
              <small>Supported file(jpeg, jpg, png, pdf, txt, doc, docx, xls, ppt, zip)</small>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadTalentDocument;
