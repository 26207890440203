import { Box, Grid, Link, List, ListItem, Typography } from "@mui/material";
import styles from "./footer.module.css";
import footLogo from "../../../assets/icons/logo-light.png";
import Fb from "../../../assets/icons/fb.png";
import Insta from "../../../assets/icons/insta.png";
import Tweet from "../../../assets/icons/tweet.png";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import UsFlag from "../../../assets/icons/us.png";
import SpFlag from "../../../assets/icons/spain.png";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = [Tweet, Fb, Insta];
  const links = [t("footerAbout"), t("footerHowItWorks"), t("footerCareers"), t("footerTerms&Conditions"), t("footerTrust&Safety")];
  const explorelinks = [
    t("footerExploreMore"),
    t("footerFreelancersinLATAM"),
    t("footerFreelancersinCanada"),
    t("footerJobsinUSA"),
    t("footerFindJobs"),
  ];
  const currentLanguage = localStorage.getItem("currentLang");
  const quickLinks = [t("footerTermsandConditions"), t("footerPrivacyPolicy"), t("footerFrequentQuestions")];
  localStorage.setItem("currentLang", "en");
  const changeLanguageFunc = () => {
    if (currentLanguage === "en") {
      navigate(0);
      localStorage.setItem("currentLang", "es");
    } else {
      navigate(0);
      localStorage.setItem("currentLang", "en");
    }
  };
  return (
    <div className={`${styles.contianerBg} `}>
      <Grid container className={`container  ${styles.textWhite}`}>
        <Grid item sm={12} md={6}>
          <img src={footLogo} className={`${styles.footLogo}`} alt="" />
          <Typography className={`${styles.textP}`}> {t("footerDescription")} </Typography>

          <List className={`${styles.flexCard}`}>
            {data.map((item, index) => (
              <ListItem key={index} className={`${styles.yinListItem}`}>
                <Link href="#">
                  <img src={item} alt="" />
                </Link>
              </ListItem>
            ))}
          </List>

          <div className={`${styles.footerContact}`}>
            <Link href="mailto:hello@yingo.work">
              <EmailIcon /> <span> {t("supportEmail")} </span>
            </Link>
            <Link href="">
              <WhatsAppIcon /> <span> {t("supportPhone")} </span>
            </Link>
          </div>
        </Grid>

        <Grid item sm={12} md={6} className={`${styles.rightCol}`}>
          <Grid container>
            <Grid item sm={12} md={6}>
              <Typography variant="h4" className={`${styles.footerheading}`}>
                {t("company")}
              </Typography>
              <div className={`${styles.footerLinks}`}>
                {links.map((links, index) => (
                  <Link key={index} href="">
                    <span>
                      <ArrowForwardIcon /> {links}
                    </span>
                  </Link>
                ))}
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant="h4" className={`${styles.footerheading}`}>
                {t("footerExploreMoreTitle")}
              </Typography>
              <div className={`${styles.footerLinks}`}>
                {explorelinks.map((explorelinks, index) => (
                  <Link key={index} href="">
                    <span>
                      {" "}
                      <ArrowForwardIcon /> {explorelinks}
                    </span>
                  </Link>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={`${styles.footerBottom}`}>
        <Grid container className={`container ${styles.textWhite}`}>
          <Grid item sm={12}>
            <Grid container>
              <Grid item sm={12} md={6}>
                <div className={`${styles.footerQuickLinks}`}>
                  <Box className={`${styles.languageContainer}`} onClick={changeLanguageFunc}>
                    {currentLanguage === "en" ? (
                      <Box className={`${styles.languageBox}`}>
                        <img src={SpFlag} alt="" />
                      </Box>
                    ) : (
                      <Box className={`${styles.languageBox}`}>
                        {" "}
                        <img src={UsFlag} alt="" />{" "}
                      </Box>
                    )}
                    <span>{currentLanguage === "en" ? "Spanish" : "English"}</span>
                  </Box>
                  {quickLinks.map((quickLinks, index) => (
                    <Link key={index} href="">
                      <span>{quickLinks}</span>
                    </Link>
                  ))}
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <span className={`${styles.textRight} ${styles.dummy} ${styles.blockBox}`}> {t("footerCopyRights")} </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
