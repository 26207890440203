import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card, CircularProgress, Grid, TextField } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import LoginBanner from "../../assets/images/loginBanner.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userRegistrationAPI } from "../../reducers/userSlice";
import Logo from "../../assets/icons/yingoLogo.png";
import { useTranslation } from "react-i18next";
export default function AuthRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [isValidName, setIsValidName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  // navigate("/otp", { state: { nameInput, emailInput, fromPath: "register" } });

  const dispatch = useDispatch();
  const { loading, registrationStatus, userNotFoundStatus } = useSelector((state) => state.user);

  useEffect(() => {
    if (userNotFoundStatus) {
      const userLocalEmail = localStorage.getItem("newUserEmail");
      setEmailInput(userLocalEmail);
    }
  }, [userNotFoundStatus]);

  useEffect(() => {
    if (registrationStatus) {
      navigate("/otp");
    }
  }, [registrationStatus, navigate]);

  const handleNameChange = (event) => {
    setNameInput(event.target.value);
    setIsValidName(true);
  };
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmailInput(email);
    setIsValidEmail(validateEmail(email));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleButtonClick = async () => {
    const data = {
      name: nameInput,
      email: emailInput,
      fromPath: "register",
    };

    if (!nameInput.trim()) {
      setIsValidName(false);
      return;
    }
    if (!validateEmail(emailInput)) {
      setIsValidEmail(false);
      return;
    }
    dispatch(userRegistrationAPI(data));
  };

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Link to="/">
            <div className={`${styles.logoCard}`}>
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div className={`${styles.contentBox} ${styles.textWhite}`}>
            <Box>
              <Typography variant="h1">{t("regsiterWelcomeText")}</Typography>
              <Typography>{t("regsiterSubTitleText")}</Typography>
            </Box>
            <Box component="form" noValidate autoComplete="off" className={`transparentInput`}>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  id="outlined-required-name"
                  placeholder="Enter your full Name"
                  className={`${styles.inputBoxInput}`}
                  value={nameInput}
                  onChange={handleNameChange}
                  error={!isValidName}
                />
                {!isValidName && <span className={`${styles.errorMessage}`}> {t("errorName")}</span>}
              </div>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  id="outlined-required-email"
                  placeholder="Enter your Email Address"
                  className={`${styles.inputBoxInput}`}
                  value={emailInput}
                  onChange={handleEmailChange}
                  error={!isValidEmail}
                />
                {!isValidEmail && <span className={`${styles.errorMessage}`}>{t("errorEmail")}</span>}
              </div>
              <div className={`${styles.yinDivider}`}></div>
              <Button
                className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinFullWidth}`}
                onClick={handleButtonClick}
              >
                <span className={`${btnStyles.btnText}`}>
                  <span className={`${btnStyles.btnTitle}`}> {t("regsiterTextContinue")}</span>
                </span>
                {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
            </Box>
            <Typography className={`${styles.routeLinkBox}`} style={{ marginBottom: 0 }}>
              {t("textAlreadyAccount")}
              <Link to="/" className={`${styles.routeLink}`}>
                {t("textLoginNow")}
              </Link>
            </Typography>
            <Typography align="center" style={{ marginBottom: 0 }}>
              {t("textOR")}
            </Typography>
            <Typography align="center">
              <Link to="/" className={`${styles.routeLink}`}>
                {t("becomeTalentText")}
              </Link>
            </Typography>

            <Typography className={`${styles.policyLink}`}>
              {t("becomeTalentText")}{" "}
              <Link to="/" className={`${styles.routeLink}`}>
                {t("textTerms")}{" "}
              </Link>{" "}
              {t("textAnd")}{" "}
              <Link to="/" className={`${styles.routeLink}`}>
                {t("textPolicy")}
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Card className={`${styles.authCard}`}>
            <img src={LoginBanner} alt="" />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
