/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgress, CssBaseline, Grid, InputLabel } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/common/navbar";
import LineOverlay from "../../assets/icons/linelayer.png";
import { resendOtpAPI, talentVerifyOtpAPI } from "../../reducers/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

export default function TalentOTPVerify() {
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const localEmail = localStorage.getItem("email");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    document.body.classList.add("darkbg");
    inputRefs.current[0].focus();
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, "");
    const singleDigitValue = sanitizedValue.slice(0, 1);

    setInputValues((prevInputValues) => {
      const updatedValues = [...prevInputValues];
      updatedValues[index] = singleDigitValue;
      return updatedValues;
    });

    if (singleDigitValue !== "") {
      const nextInputIndex = (index + 1) % inputRefs.current.length;
      inputRefs.current[nextInputIndex].focus();
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    if (pastedText.length === 4) {
      for (let i = 0; i < 4; i++) {
        if (inputRefs.current[i]) {
          inputRefs.current[i].value = pastedText[i];
          handleInputChange(i, { target: { value: pastedText[i] } });
        }
      }
    }
  };
  const handleInputKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      const prevInputIndex = index - 1;
      inputRefs.current[prevInputIndex].focus();
    }
  };

  const handleButtonClick = async () => {
    if (inputValues.some((value) => value === "")) {
      setError("Please enter vaild otp.");
      return;
    }
    setError("");
    const otp = inputValues.join("");
    const usrEmail = localStorage.getItem("email");
    const data = {
      email: usrEmail,
      otp: otp,
    };
    dispatch(talentVerifyOtpAPI(data, navigate));
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    const usrEmail = localStorage.getItem("email");
    const data = {
      email: usrEmail,
    };
    try {
      await dispatch(resendOtpAPI(data));
    } finally {
      setIsLoading(false);
    }
  };

  // **** Modal ***

  return (
    <>
      <Navbar />
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className={`${styles.authRegisterPage} custombg `}>
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <img src={LineOverlay} alt="" className={`${styles.lineLayerRight}`} />
        <Grid container>
          <Grid item sm={12}>
            <Box className={`${styles.talentAuthContainer}`}>
              <div>
                <Box className={`${styles.talentHeadingBox} ${styles.textWhite}`}>
                  <Typography variant="h2">{t("talentOTPTitle")} </Typography>
                  <Typography variant="h4">
                    {" "}
                    {t("talentOtpSubTitleText")} <b>{localEmail} </b> {t("talentOtpSubTitleText2")}
                  </Typography>
                </Box>
                <Box className={`${styles.talentRegsiterBox} ${styles.textWhite} mt-5`}>
                  <InputLabel className={`${styles.inputLabel} `}>
                    {t("textAccessCode")} <span>*</span>
                  </InputLabel>
                  <Box className={`transparentInput ${styles.otpBox}`} onPaste={handlePaste}>
                    {inputValues.map((value, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <div className={`${styles.inputBox}`} key={index}>
                        <input
                          key={index}
                          type="number"
                          value={value}
                          className={`${styles.inputBoxInput}`}
                          onChange={(event) => handleInputChange(index, event)}
                          ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                          onKeyDown={(event) => handleInputKeyDown(index, event)}
                        />
                      </div>
                    ))}
                  </Box>
                  {error && <div className={`${styles.errorMessage}`}>{error}</div>}
                  <div className={`${styles.cetnerAlignedBtn} fullWidth mt-5`}>
                    <Button className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} `} onClick={handleButtonClick}>
                      <span className={`${btnStyles.btnText}`}>
                        <span className={`${btnStyles.btnTitle}`}> {t("textContinue")}</span>
                      </span>
                      {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                    </Button>
                  </div>
                </Box>
                <Typography className={`${styles.routeLinkBox}`}>
                  <Link to="" className={`${styles.routeLink} ${styles.resendBtn}`} onClick={handleResendOtp}>
                    {t("textResendOTP")} {isLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                  </Link>
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* *** Welcome Modal*** */}
    </>
  );
}
