import { callAPI } from "../config/api-core";
import {
  registerAPIMethods,
  verifyOTPAPIMethods,
  resendOTPAPIMethods,
  customerLoginAPIMethods,
  getProfileAPIMethods,
  updateProfileAPIMethods,
  talentLoginAPIMethods,
  talentRegisterAPIMethods,
  getTalentPreviewAPIMethods,
  registerAdminTalentAPIMethods,
  getRatingTalentAPIMethods,
  talentSubmitVideoAPIMethods,
} from "../methods";

// const loginAPI = async (request) => {
//   const response = await callAPI(loginAPIMethods, false, request, {}, false, false);
//   return response;
// };

const registerAPI = async (request) => {
  const response = await callAPI(registerAPIMethods, false, request, {}, false, false);
  return response;
};

const registerAdminTalentAPI = async (request) => {
  const response = await callAPI(registerAdminTalentAPIMethods, false, request, {}, false, false);
  return response;
};

const customerLoginAPI = async (request) => {
  const response = await callAPI(customerLoginAPIMethods, false, request, {}, false, false);
  return response;
};

const verifyOTPAPI = async (request) => {
  const response = await callAPI(verifyOTPAPIMethods, false, request, {}, false, false);
  return response;
};

const resendOTPAPI = async (request) => {
  const response = await callAPI(resendOTPAPIMethods, false, request, {}, false, false);
  return response;
};

const profileApi = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(getProfileAPIMethods, true, "", header, false, false);
  return response;
};

const updateProfileApi = async (request) => {
  const { name, email, phone, country, companyName, profileImg } = request;

  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  if (profileImg) {
    formData.append("file", profileImg);
  }

  formData.append("contactPerson", name);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("country", country);
  formData.append("companyName", companyName);

  const response = await callAPI(updateProfileAPIMethods, false, formData, header, false, false);
  return response;
};

const talentLoginAPI = async (request) => {
  const response = await callAPI(talentLoginAPIMethods, false, request, {}, false, false);
  return response;
};

const talentRegisterAPI = async (request) => {
  const response = await callAPI(talentRegisterAPIMethods, false, request, {}, false, false);
  return response;
};

//// TALENT PREVIEW API GD//
const getTalentPreviewAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = request ? `?talentId=${request}` : "";

  const response = await callAPI(getTalentPreviewAPIMethods + params, true, "", header, false, false);
  return response;
};

const getRatingTalentAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(getRatingTalentAPIMethods, false, request, header, false, false);
  return response;
};

const talentSubmitVideoAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };

  const response = await callAPI(talentSubmitVideoAPIMethods, false, request, header, false, false);
  return response;
};

// export const userLogin = (request) => loginAPI(request);
export const userRegister = (request) => registerAPI(request);
export const customerLogin = (request) => customerLoginAPI(request);
export const userVerifyOtp = (request) => verifyOTPAPI(request);
export const userResendOtp = (request) => resendOTPAPI(request);
export const getProfileAPI = (request) => profileApi(request);
export const postProfileAPI = (request) => updateProfileApi(request);
export const talentLogin = (request) => talentLoginAPI(request);
export const talentRegister = (request) => talentRegisterAPI(request);
export const getTalentPreview = (request) => getTalentPreviewAPI(request);
export const adminRegisterTalent = (request) => registerAdminTalentAPI(request);
export const getTalentRatingAPI = (request) => getRatingTalentAPI(request);
export const talentVideoSubmit = (request) => talentSubmitVideoAPI(request);

export default {
  // loginAPI,
  userRegister,
  customerLogin,
  userVerifyOtp,
  userResendOtp,
  getProfileAPI,
  postProfileAPI,
  talentLogin,
  talentRegister,
};
