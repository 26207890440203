import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./logoSlider.module.css";
import { Grid, Typography } from "@mui/material";
import Logo1 from "../../../assets/icons/ic1.png";
import Logo2 from "../../../assets/icons/ic2.png";
import Logo3 from "../../../assets/icons/ic3.png";
import Logo4 from "../../../assets/icons/ic4.png";
import Logo5 from "../../../assets/icons/ic5.png";
import Logo6 from "../../../assets/icons/ic6.png";
import ArrowIcon from "../../../assets/icons/arrowIcon.svg";
import { useTranslation } from "react-i18next";
export default function LogoSlider() {
  const { t } = useTranslation();
  const data = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6]; // Fix the duplicate Logo4
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className={`${styles.borderBottom}`}>
      <Grid container className={`container ${styles.textWhite} ${styles.py5} ${styles.borderBottom}`}>
        <Grid item xs={12} md={3}>
          <Typography variant="h4" className={`${styles.headingH4}`}>
            {t("logoSliderSubTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} className={`${styles.customArrows}`}>
          <div className={`${styles.customArrowBox}`}>
            <img src={ArrowIcon} className={`${styles.leftArrow}`} alt={ArrowIcon} />
            <img src={ArrowIcon} className={`${styles.rightArrow}`} alt={ArrowIcon} />
          </div>

          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index}>
                <div className="logoBox">
                  <img src={item} alt={`Logo ${index}`} />
                </div>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
}
