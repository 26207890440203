// import { styled } from "@mui/material/styles";
import { Box, CssBaseline, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Card, Grid, Typography } from "@mui/material";
import styles from "./talent-profile.module.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import LevelPosition from "../../../assets/icons/levelposition.svg";
// import CreateIcon from "@mui/icons-material/Create";
// import btnStyles from "../../../assets/button.module.css";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailIcon from "@mui/icons-material/Email";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatGPTIcon from "../../../assets/icons/ai-1.png";
import TumeIcon from "../../../assets/icons/ai-2.png";

import FileIcon from "../../../assets/icons/file-filled.svg";
// import CrossIcon from "../../../assets/icons/crossIcon.svg";
import ProfileDefaultImg from "../../../assets/icons/defaultState.png";
import { useState } from "react";
// import WelcomeModal from "../../../components/talent-component/modal/WelcomeModal";
// import ChipSelect from "../../../components/common/form/chipSelect";
// import countryList from "react-select-country-list";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../utils/helper";
import { useSelector } from "react-redux";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import Loader from "../../../components/common/Loader";
// import CalenderIcon from "../../../assets/icons/calenderIcon.svg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={`${styles.tabScrollBox}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentProfile() {
  const [value, setValue] = useState(0);
  const { loading, talentProfile, talentProjectCount } = useSelector((state) => state.talentProfile);

  // const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const aiTools = [
    {
      id: 1,
      title: "ChatGPT",
      icon: ChatGPTIcon,
    },
    {
      id: 2,
      title: "Tume",
      icon: TumeIcon,
    },
  ];

  const skills = [
    {
      id: 1,
      title: "Figma",
    },
    {
      id: 2,
      title: "React",
    },
  ];

  const experience = [
    {
      id: 1,
      title: "Sr. Designer",
      empType: "Full-time",
      company: "Worksdelight",
      startDate: "2022",
      endDate: "2023",
      location: "Mohali",
    },
    {
      id: 2,
      title: "Team Lead",
      empType: "Full-time",
      company: "Bright",
      startDate: "2022",
      endDate: "2023",
      location: "Mohali",
    },
  ];

  const education = [
    {
      id: 1,
      icon: FileIcon,
      degree: "B.Sc IT",
      school: "Punjab University",
      fieldOfStudy: "Computer Application",
      year: "2022",
    },
    {
      id: 2,
      icon: FileIcon,
      degree: "Masters",
      school: "Punjab University",
      fieldOfStudy: "Computer Application",
      year: "2018",
    },
  ];

  return (
    <>
      <Box style={{ position: "relative", height: "80vh", width: "100%" }}>
        <CssBaseline />
        {/* <Sidebar /> */}
        <Box>
          {/* <PanelNavbar /> */}

          {loading ? (
            <Loader />
          ) : (
            <>
              <Card
                className={`panelCard panelCardFullHeight ${
                  talentProfile?.proficiency === "Professional"
                    ? "professtionView"
                    : talentProfile?.proficiency === "Expert"
                    ? "expertView"
                    : "geniusView"
                }`}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card className="panelCard p-0 innerCardFullHeight professtionInnerView">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box className={`${styles.talentContentCardLeft}`}>
                            <Box className={`${styles.videoBox}`}>
                              <div className={`${styles.videoPlayIcon}`}>
                                <PlayArrowIcon />
                              </div>
                            </Box>
                            <Box className={`${styles.telentPhotoContainer} p-20`}>
                              <Box className={`${styles.flexCard} gap-md `}>
                                <div className={`${styles.telentPhotoBox}`}>
                                  <Box className={`${styles.telentPhotoCard}`}>
                                    <img src={talentProfile.profilePicture || ProfileDefaultImg} alt=""></img>
                                  </Box>
                                  <div
                                    className={`${
                                      talentProfile?.proficiency === "Professional"
                                        ? "professtionBgColor"
                                        : talentProfile?.proficiency === "Expert"
                                        ? "expertBgColor"
                                        : "geniusBgColor"
                                    } ${styles.telentNameBox}`}
                                  >
                                    <Typography variant="body">Genius</Typography>
                                  </div>
                                </div>
                                <div className={``}>
                                  <Typography variant="h4" className={`${styles.telentName}`}>
                                    Gurdeep{" "}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    className={`${styles.paragraphText}  ${styles.darkText}`}
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    India
                                  </Typography>
                                </div>
                              </Box>

                              {/* <Box className={`${styles.telentPhotoCard}`}>
                                <img src={talentProfile.profilePicture || ProfileDefaultImg} alt=""></img>
                              </Box>
                              <div className={`${styles.telentNameBox}`}>
                                <Typography variant="body">Proffessional</Typography>
                                <Typography variant="h4">Gurdeep </Typography>
                                <Typography variant="h4" className={`${styles.paragraphText}  ${styles.darkText}`} style={{ wordBreak: "break-all" }}>
                                  India
                                </Typography>
                              </div> */}
                              <div className={`flexCard justified-between ${styles.ratingBox} mt-4`}>
                                <div className={`${styles.ratingStar} flexCard`}>
                                  <StarBorderIcon />
                                  <StarBorderIcon />
                                  <StarBorderIcon />
                                  <StarBorderIcon />
                                  <StarBorderIcon />
                                </div>
                                <Typography variant="h4">4.0</Typography>
                              </div>
                            </Box>
                            <Box className={`${styles.levelBox} p-20`}>
                              <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                Proficiency Level
                              </Typography>
                              <div className={`flexCard gap-md mt-15`}>
                                <BusinessCenterIcon style={{ color: "#9A9A9A" }} />

                                <Typography
                                  variant="h4"
                                  className={`${styles.paragraphText} ${styles.darkText} ${styles.pRight}`}
                                  style={{ wordBreak: "break-all" }}
                                >
                                  Professional
                                </Typography>
                              </div>
                            </Box>
                            <Box className={`${styles.PersonalInfoCard} p-20`}>
                              <div className="flexCard justified-between">
                                <Typography variant="h4" className={`${styles.pageTalentTitle} `}>
                                  Personal Info
                                </Typography>
                                {/* <CreateIcon className="professtionColor cursor_pointer" /> */}
                              </div>
                              <div className={`flexCard gap-md mt-15`}>
                                <EmailIcon style={{ color: "#9A9A9A" }} />
                                <Typography
                                  variant="h4"
                                  className={`${styles.paragraphText} ${styles.darkText} ${styles.pRight}`}
                                  style={{ wordBreak: "break-all" }}
                                >
                                  {talentProfile?.email}
                                </Typography>
                              </div>
                              <div className={`flexCard gap-md mt-15`}>
                                <CalendarTodayIcon style={{ color: "#9A9A9A" }} />
                                <Typography variant="h4" className={`${styles.paragraphText} ${styles.darkText}`}>
                                  Member Since {talentProfile.created_at && formatDate(talentProfile.created_at)}
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9}>
                          <Box className={`${styles.talentContentCard}`}>
                            <Grid container spacing={2} className="p-20">
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxInProgress}`}>
                                  <Typography>
                                    In-Progress <br />
                                    Projects
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.inprogress}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxCompleted}`}>
                                  <Typography>
                                    Completed <br />
                                    Projects
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.completed}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className={`${styles.statBox} ${styles.statBoxOverDue}`}>
                                  <Typography>
                                    Request <br />
                                    Pending
                                  </Typography>
                                  <Typography variant="h4">{talentProjectCount?.commited}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box sx={{ width: "100%" }}>
                              <Box
                                class={`${
                                  talentProfile?.proficiency === "Professional"
                                    ? "proffessionTab"
                                    : talentProfile?.proficiency === "Expert"
                                    ? "expertTab"
                                    : "geniusTab"
                                } profileTab`}
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                {/* <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example"> */}
                                <Tabs
                                  id={`${styles.profileTabList}`}
                                  value={value}
                                  onChange={handleChange}
                                  variant={isLargeScreen ? "scrollable" : "fullWidth"}
                                  scrollButtons="auto"
                                  aria-label="basic tabs example"
                                >
                                  <Tab label="About" {...a11yProps(0)} />
                                  <Tab label="Ai Tools" {...a11yProps(1)} />
                                  <Tab label="Experience" {...a11yProps(2)} />
                                  <Tab label="Skills" {...a11yProps(3)} />
                                  <Tab label="Education" {...a11yProps(4)} />
                                  {/* <Tab label="Cerifications" {...a11yProps(5)} /> */}
                                </Tabs>
                              </Box>
                              <CustomTabPanel>
                                <div className={`${styles.scrollableContainer}`}>
                                  {/* About */}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        About
                                      </Typography>
                                    </Box>
                                    <Typography variant="body" className={`${styles.commonText}`}>
                                      About Data
                                    </Typography>
                                  </div>
                                  {/*  AI Tools */}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        AI Tools
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {aiTools.map((aiTools, index) => (
                                          <ListItem key={index} disablePadding>
                                            <ListItemIcon>
                                              <img src={aiTools.icon} alt="" />
                                            </ListItemIcon>
                                            <ListItemText className={`${styles.listaiToolTitle}`} primary={aiTools.title} />
                                          </ListItem>
                                        ))}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Experince */}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Experience
                                      </Typography>
                                    </Box>
                                    <Box className={` ${styles.listExperience}`}>
                                      <List>
                                        {experience.map((experience, index) => (
                                          <ListItem key={index} className="flexCard aignItemCenter" disablePadding>
                                            <ListItemIcon className={`${styles.listCardIcon}`}>
                                              <img src={FileIcon} alt="" />
                                            </ListItemIcon>
                                            <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                              <div className="fullWidth flexCard  justified-between">
                                                <ListItemText className={`${styles.listaiToolTitle}`} primary={experience.title} />
                                                <div className="flexCard">
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.startDate} /> -
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.endDate} />
                                                </div>
                                              </div>
                                              <div className="flexCard " style={{ marginTop: "-5px" }}>
                                                <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.company} />
                                              </div>
                                              <div className="flexCard " style={{ marginTop: "-5px" }}>
                                                <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.empType} />
                                                <div className={`${styles.spacerSm}`}>|</div>
                                                <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.location} />
                                              </div>
                                            </Box>
                                          </ListItem>
                                        ))}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Skills*/}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Skills
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {skills.map((skill, index) => (
                                          <ListItem key={index} disablePadding>
                                            <ListItemText className={`${styles.listaiToolTitle}`} primary={skill.title} />
                                          </ListItem>
                                        ))}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Education*/}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Education
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listExperience}`}>
                                      <List>
                                        {education.map((education, index) => (
                                          <ListItem className="flexCard aignItemCenter justified-between" disablePadding key={index}>
                                            <div className="flexCard aignItemCenter">
                                              <ListItemIcon className={`${styles.listCardIcon}`}>
                                                <img src={FileIcon} alt="" />
                                              </ListItemIcon>
                                              <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                                <div className="fullWidth flexCard  justified-between">
                                                  <ListItemText className={`${styles.listaiToolTitle}`} primary={education.school} />
                                                </div>
                                                <div className={`${styles.listaiToolSubtitle} flexCard`} style={{ marginTop: "-5px" }}>
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`}>
                                                    {education.degree} in {education.fieldOfStudy} in {education.year}
                                                  </ListItemText>
                                                </div>
                                              </Box>
                                            </div>
                                          </ListItem>
                                        ))}
                                      </List>
                                      <div style={{ height: "150px" }}></div>
                                    </Box>
                                  </div>
                                </div>
                              </CustomTabPanel>
                              {/* <CustomTabPanel value={value} index={1}>
                                Ai Tools
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={2}>
                                Experince
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={3}>
                                Skills
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={4}>
                                Education
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={5}>
                                Education
                              </CustomTabPanel> */}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
