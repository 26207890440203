import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { Card, Grid, TextField, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import styles from "./invoices.module.css";
import Sidebar from "../../../components/common/sidebar";
import PanelNavbar from "../../../components/common/navbar/panelNavbar";
import SearchIcon from "@mui/icons-material/Search";

export default function Invoices() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "transparent",
      borderBottom: "0 !important",
      opacity: ".6",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "0 !important",
      fontWeight: 500,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function createData(transId, projectName, planName, amount, date, status) {
    return { transId, projectName, planName, amount, date, status };
  }

  const rows = [
    createData("yiN344R54", "Restaurant App", "Proffessional", "$99", "12 Aug, 2023", <span className={`${styles.completed}`}>Completed</span>),
    createData("yiN344R54", "Restaurant App", "Proffessional", "$99", "12 Aug, 2023", <span className={`${styles.completed}`}>Completed</span>),
    createData("yiN344R54", "Restaurant App", "Proffessional", "$99", "12 Aug, 2023", <span className={`${styles.completed}`}>Completed</span>),
    createData("yiN344R54", "Restaurant App", "Proffessional", "$99", "12 Aug, 2023", <span className={`${styles.completed}`}>Completed</span>),
  ];
  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        <Sidebar />
        <Box>
          <PanelNavbar />
          {/* <Box
            className={`contentArea`}
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          > */}
          <Grid container style={{ alignItems: "center" }}>
            <Grid item sm={12} md={8}>
              <Typography variant="h4" className={`panelHeading`}>
                Invoices
              </Typography>
            </Grid>
            <Grid item sm={12} md={4} className={`${styles.buttonAlignment}`}>
              <Box className={`${styles.searchBar}`}>
                <TextField id="outlined-basic" variant="outlined" placeholder="Search" autoComplete="off" className={`${styles.searchInput}`} />
                <SearchIcon className={`${styles.inputIcon}`} />
              </Box>
            </Grid>
          </Grid>
          <Card className="panelCard mt-3">
            <Grid container>
              <Grid item sm={12} md={12}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Traction Id</StyledTableCell>
                      <StyledTableCell>Project Name</StyledTableCell>
                      <StyledTableCell>Plan Name</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Date & Time </StyledTableCell>
                      <StyledTableCell>Status </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <StyledTableRow key={`${index}_${row.name}`}>
                        <StyledTableCell>{row.transId}</StyledTableCell>
                        <StyledTableCell>{row.projectName}</StyledTableCell>
                        <StyledTableCell>{row.planName}</StyledTableCell>
                        <StyledTableCell>{row.amount}</StyledTableCell>
                        <StyledTableCell>{row.date}</StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Card>
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
}
