/* eslint-disable react/prop-types */
import { useState } from "react";
import { Box, Dialog, DialogContent, CircularProgress, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import styles from "./deliverable.module.css";
import btnStyles from "../../../assets/button.module.css";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import { FileDarkIcon } from "../../../assets/yingoIcons";

import FileIcon from "../../../assets/icons/darkfile.svg";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckIcon from "@mui/icons-material/Check";
import TabsListing from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { deliverableCommentListAPI, deliverableDocumentListAPI, deliverablesInfoAPI } from "../../../reducers/deliverablesSlice";
import { customerDeliverableForReviewAPI } from "../../../reducers/talentProjectSlice";

import { Button } from "@mui/base";
import { useTranslation } from "react-i18next";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import DataNotFound from "../PageNotFound/dataNotFound";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function DeliverablesList() {
  const { t } = useTranslation();
  // const { deliverables } = props;
  const [expanded, setExpanded] = useState(null);
  const [infoLoading, setInfoLoading] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("Option1");

  const { deliverables } = useSelector((state) => state.deliverables);
  const [selectedDeleverableId, setSelectedDeleverableId] = useState(null);
  const toUserDetail = useSelector((state) => state.talentProject);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const handlePopupOpen = (id) => {
    setSelectedDeleverableId(id);
    setOpen(true);
  };
  const handlePopupClose = () => {
    setOpen(false);
  };

  const handleCompletedPopupOpen = () => {
    setOpenCompleted(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompleted(false);
  };
  const handleProgressPopupOpen = () => {
    setOpenProgress(true);
  };
  const handleProgressPopupClose = () => {
    setOpenProgress(false);
  };

  const handleChange = (panel) => async (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    // if (newExpanded) {
    //   dispatch(deliverablesInfoAPI(panel));
    //   // dispatch(deliverableCommentAPI(panel));
    // }

    if (newExpanded) {
      try {
        setInfoLoading(true);
        await Promise.all([
          dispatch(deliverablesInfoAPI(panel)),
          dispatch(deliverableCommentListAPI(panel)),
          dispatch(deliverableDocumentListAPI(panel)),
        ]);
        // setInfoLoading(false);
      } catch (error) {
        console.error("Error:", error);
        // setInfoLoading(false);
      } finally {
        setInfoLoading(false);
      }
    }
  };

  // const handleDeliverableBadge = (endDates) => {
  //   const currentDate = new Date();

  //   const endDate = new Date(endDates);
  //   if (currentDate > endDate) {
  //     return "Overdue";
  //   } else {
  //     return "In-progress";
  //   }
  // };
  let userId = localStorage.getItem("userId");
  const [loadingNotApprove, setLoadingNotApprove] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);

  const handleDeliverableReviewNotSubmit = async () => {
    setLoadingNotApprove(true);

    const request = {
      status: "in-progress",
      deliverableId: selectedDeleverableId,
      toUser: userId,
    };
    dispatch(customerDeliverableForReviewAPI(request, handlePopupClose));
    setTimeout(async () => {
      try {
        setInfoLoading(true);
        await Promise.all([dispatch(deliverableCommentListAPI(selectedDeleverableId))]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setInfoLoading(false);
      }
      setLoadingNotApprove(false);
    }, 1000);
  };

  const handleDeliverableReviewSubmit = async () => {
    setLoadingApprove(true);

    const request = {
      status: "completed",
      deliverableId: selectedDeleverableId,
      toUser: userId,
    };
    dispatch(customerDeliverableForReviewAPI(request, handlePopupClose));

    setTimeout(async () => {
      try {
        setInfoLoading(true);
        await Promise.all([dispatch(deliverableCommentListAPI(selectedDeleverableId))]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setInfoLoading(false);
      }
      setLoadingApprove(false);
    }, 1000);
  };

  return (
    <>
      {Object.keys(toUserDetail.deliverableCurrentStatus && toUserDetail.deliverableCurrentStatus).length > 0 ? (
        Object.values(toUserDetail.deliverableCurrentStatus)
          .reverse()
          .map((deliverable, index) => {
            const { _id, title, status } = deliverable;
            const panel = _id;

            return (
              <div className={`deliverableMuiAccordian ${styles.deliverableAccordians}`} key={index}>
                <Accordion className={`${styles.deliverableAccordianList}`} expanded={expanded === panel} onChange={handleChange(panel)}>
                  <div className={`${styles.deliverableAccordianHeader}`}>
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                      className={`${styles.deliverableAccordianTitleBox}`}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div className={`${styles.flexCard} ${styles.deliverableHeadingContainer}`}>
                        <div className={`${styles.headerIcon}`}>
                          <img src={FileIcon} alt=""></img>
                        </div>
                        <Typography className={`${styles.deliverableAccordianTitle}`}>{title}</Typography>
                      </div>
                    </AccordionSummary>
                    <div
                      className={`${styles.btnPostion}`}
                      onClick={() => {
                        if (status === "in-review") {
                          handlePopupOpen(panel);
                        }
                        if (status === "in-progress" || status === "overdue") {
                          handleProgressPopupOpen(panel);
                        }
                        if (status === "completed") {
                          handleCompletedPopupOpen(panel);
                        }
                      }}
                    >
                      <span
                        style={{ textTransform: "capitalize" }}
                        className={`${styles.badge} ${
                          status === "in-progress"
                            ? styles.InProgress
                            : status === "completed"
                            ? styles.Completed
                            : status === "in-review"
                            ? styles.InReview
                            : status === "overdue"
                            ? styles.Overdue
                            : ""
                        }`}
                      >
                        <span>{status}</span>
                      </span>
                    </div>
                  </div>
                  <AccordionDetails className={`${styles.deliverableAccordianBody}`}>
                    <Box className={`${styles.AccordianBodyInnerCard}`}>
                      <TabsListing deliverableDetail={deliverable} infoLoading={infoLoading} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
      ) : (
        <>
          {deliverables[0]?.deliverable?.map((item) => {
            const { _id, title } = item;
            const panel = _id;

            return (
              <div className={`deliverableMuiAccordian ${styles.deliverableAccordians}`} key={_id}>
                <Accordion className={`${styles.deliverableAccordianList}`} expanded={expanded === panel} onChange={handleChange(panel)}>
                  <div className={`${styles.deliverableAccordianHeader}`}>
                    <AccordionSummary
                      aria-controls={`${panel}-content`}
                      id={`${panel}-header`}
                      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                      className={`${styles.deliverableAccordianTitleBox}`}
                    >
                      <div className={`${styles.flexCard} ${styles.deliverableHeadingContainer}`}>
                        <div className={`${styles.headerIcon}`}>
                          <img src={FileIcon} alt=""></img>
                        </div>
                        <Typography className={`${styles.deliverableAccordianTitle}`}>{title}</Typography>
                      </div>
                    </AccordionSummary>
                    <div
                      className={`${styles.btnPostion}  `}
                      onClick={() => {
                        if (item.status === "in-review") {
                          handlePopupOpen(panel);
                        }
                        if (item.status === "in-progress" || item.status === "overdue") {
                          handleProgressPopupOpen(panel);
                        }
                        if (item.status === "completed") {
                          handleCompletedPopupOpen(panel);
                        }
                      }}
                    >
                      <span
                        style={{ textTransform: "capitalize" }}
                        className={`${styles.badge} ${
                          item.status === "in-progress"
                            ? styles.InProgress
                            : item.status === "completed"
                            ? styles.Completed
                            : item.status === "in-review"
                            ? styles.InReview
                            : item.status === "overdue"
                            ? styles.Overdue
                            : ""
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  </div>
                  <AccordionDetails className={`${styles.deliverableAccordianBody}`}>
                    <Box className={`${styles.AccordianBodyInnerCard}`}>
                      <TabsListing deliverableDetail={item} infoLoading={infoLoading} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </>
      )}

      {/* Dialog for Approve for Deliverable */}
      <Dialog className="DelcineWarningModal" open={open} onClose={handlePopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handlePopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">Are your Sure to Approve this Deliverable?</Typography>
            <Typography className="popupSubTitle">Please cross check this develierable before approve.</Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleDeliverableReviewNotSubmit}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                style={{ marginRight: "10px" }}
              >
                Not Approve {loadingNotApprove && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
              <Button
                onClick={handleDeliverableReviewSubmit}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                Approve {loadingApprove && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openCompleted} onClose={handleCompletedPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon successCircle">
            <CheckIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCompletedPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">{t("popupTextComepletedTitle")}</Typography>
            <Typography className="popupSubTitle">{t("popupTextComepletedSubTitle")}</Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleCompletedPopupClose}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                {t("textOkay")}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openProgress} onClose={handleProgressPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon progressCircle">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleProgressPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle"> {t("popupTextWorkTitle")}</Typography>
            <Typography className="popupSubTitle"> {t("popupTextWorkSubTitle")}</Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleProgressPopupClose}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                {t("textOkay")}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
