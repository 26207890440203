import { Box, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import styles from "./projectDetails.module.css";

import FileIcon from "../../../assets/icons/darkfile.svg";

// import teamImg from "../../../assets/images/team1.png";

// import messageIcon from "../../../assets/images/messageIcon.svg";
import { FileDarkIcon } from "../../../assets/yingoIcons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import { projectDetailAPI } from "../../../reducers/intialQuerySlice";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { formatDate } from "../../../utils/helper";
import Loader from "../../../components/common/Loader";

function ProjectDetails(props) {
  const { projectDetail, loading } = useSelector((state) => state.intialQuery);
  const isMounted = useRef(false);

  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMounted.current) {
      const data = {
        projectID: param.id,
      };
      // dispatch(setTemporaryDocEmpty([]));
      dispatch(projectDetailAPI(data));
      isMounted.current = true;
    }
  }, [dispatch, param.id]);

  const projectDetails = useMemo(() => {
    if (projectDetail && projectDetail.length > 0) {
      const project = projectDetail[0];
      // const deliverables = project.deliverables ? JSON.parse(project.deliverables) : {};
      const skillsRequired = project.skillsRequired ? JSON.parse(project.skillsRequired) : {};
      const tags = project.tags ? JSON.parse(project.tags) : {};

      return {
        initProjectDescription: project.longDescription || "",
        initProjectDeliverable: project.deliverable,
        initProjectSkills: skillsRequired,
        initProjectAi: tags,
        initPlan: project.plan,
        initProjectDoc: project.projectDocuments,
        initStatus: project.status,
        initTitle: project.name,
        initStartDate: project.startDate,
        initTalents: project.talents,
        initCustomer: project.userData,
        initTentative: project.tentative,
      };
    }
    return {};
  }, [projectDetail]);

  return (
    <>
      {Object.keys(projectDetails).length > 0 ? (
        <Box>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Grid container className={`${styles.justifySpace}`} style={{ marginBottom: "25px", alignItems: "center" }}>
                <Grid item sm={12} md={8}>
                  <Box className={`${styles.projectEditableInputBox}`}>
                    <label>
                      <b>Project Title :</b>
                    </label>
                    <div className={styles.projectTitleContainer}>
                      <span className={styles.projectTitle}>{projectDetails?.initTitle}</span>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.scrollableContent}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Project Description
                      </Typography>
                      <Typography variant="p" className={`panelCardP`}>
                        {projectDetails?.initProjectDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listDeliverables} ${styles.scrollableContent}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Deliverables
                      </Typography>
                      <List className={`${styles.adminProjectDelilist}`}>
                        {projectDetails?.initProjectDeliverable?.map((item, index) => {
                          return (
                            <ListItem disablePadding key={index}>
                              <ListItemIcon>
                                <img src={FileIcon} alt="" />
                              </ListItemIcon>
                              <ListItemText className={`${styles.listDeliverableTitle}`} primary={item.title} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listAiTools}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        AI Tools
                      </Typography>
                      <List>
                        {projectDetails?.initProjectAi.map((aiTool, index) => (
                          <ListItem disablePadding key={index}>
                            <ListItemIcon>
                              <img src={aiTool.icon} alt="" />
                            </ListItemIcon>
                            <ListItemText className={`${styles.listaiToolTitle}`} primary={aiTool.title} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6} className="fullWidth">
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.skills}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Skills
                      </Typography>
                      <List>
                        {Object.keys(projectDetails?.initProjectSkills).map((key) => (
                          <ListItem disablePadding key={key}>
                            <ListItemText className={`${styles.listSkillTitle}`} primary={projectDetails.initProjectSkills[key]} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listAiTools}`}>
                      <Typography variant="p" className={`panelCardH4`}>
                        Start Date
                      </Typography>
                      <div className={`${styles.datePickerBox}`}>{formatDate(projectDetails?.initStartDate)}</div>
                      <div className={`mt-3`}>
                        <label className={`formLabel`}>Selected Plan</label>
                        <Card className={`${styles.priceCard}`}>
                          <div
                            className={`${styles.badge} ${
                              projectDetails?.initPlan?.type === "Professionals"
                                ? styles.professional
                                : projectDetails?.initPlan?.type === "Genius"
                                ? styles.genius
                                : projectDetails?.initPlan?.type === "Expert"
                                ? styles.expert
                                : ""
                            }`}
                          >
                            {projectDetails?.initPlan?.type}
                          </div>
                          <CardContent className={`${styles.cardContent}`}>
                            <div>
                              <Typography variant="h4" className={`${styles.planName}`}>
                                {projectDetails?.initPlan?.experience} Years Experience
                              </Typography>
                              <p>10+ expert folks in this plan.</p>
                              <p className={`${styles.devs}`}>Payment Status: {projectDetails?.initPlan?.paymentStatus}</p>
                            </div>
                            <div className={`${styles.price}`}>${projectDetails?.initPlan?.price}</div>
                          </CardContent>
                        </Card>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listAiTools}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Documents
                      </Typography>
                      {projectDetails?.initProjectDoc?.length > 0 ? (
                        projectDetails.initProjectDoc.map((doc, index) => {
                          return (
                            <div className={`${styles.doclistitem}`} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className={`${styles.docImgs}`}>
                                  <FileDarkIcon />
                                </div>
                                <span className={`${styles.docTitle}`}>{doc.filename}</span>
                              </div>
                              {/* <TimesIcon /> */}
                            </div>
                          );
                        })
                      ) : (
                        <Grid item sm={12}>
                          <Box className="emptyStateContainer">
                            <DataNotFound icon={"record"} title={""} subtitle={"No Document Added!"} />
                          </Box>
                        </Grid>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${styles.xsFull}`} style={{ paddingBottom: "50px" }}>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.projectTeam} ${styles.listAiTools}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Talent
                      </Typography>

                      <Grid container spacing={2}>
                        {projectDetails.initTalents?.length > 0 ? (
                          projectDetails.initTalents.map((team, index) => {
                            const { userInfo } = team;

                            return (
                              <Grid key={index} item sm={12} md={12} lg={12} className="fullWidth">
                                <Card
                                  className={`panelCard mt-3 ${styles.overflowVisible} ${
                                    projectDetails.initTentative === "true"
                                      ? styles.tentativeBorder
                                      : userInfo[0]?.proficiency === "Professional"
                                      ? styles.professionalBorder
                                      : userInfo[0]?.proficiency === "Genius"
                                      ? styles.geniusBorder
                                      : userInfo[0]?.proficiency === "Expert"
                                      ? styles.expertBorder
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`${styles.badge} ${styles[team.badge]} ${
                                      userInfo[0]?.proficiency === "Professional"
                                        ? styles.professional
                                        : userInfo[0]?.proficiency === "Genius"
                                        ? styles.genius
                                        : userInfo[0]?.proficiency === "Expert"
                                        ? styles.expert
                                        : ""
                                    }`}
                                  >
                                    {userInfo[0]?.proficiency}
                                  </div>
                                  {projectDetails.initTentative === "true" ? (
                                    <div className={`${styles.badge} ${styles.tentative}`}>Tentative</div>
                                  ) : (
                                    ""
                                  )}

                                  <div className={`${styles.flexCard} } ${styles.justifyBW}`}>
                                    <Box className={`${styles.teamCard}`}>
                                      <CardMedia className={`${styles.teamMemberImg}`}>
                                        {userInfo[0]?.profilePicture ? (
                                          <img src={userInfo[0]?.profilePicture} alt="TeamMember" />
                                        ) : (
                                          <span>{userInfo[0]?.name.charAt(0)}</span>
                                        )}
                                      </CardMedia>
                                      <CardContent className={`${styles.teamMemberDetail}`}>
                                        <Link to={`/talents/${userInfo[0]?._id}`} className={`${styles.textPrimary}`}>
                                          <Typography variant="h4">{userInfo[0]?.name}</Typography>
                                        </Link>
                                        <Typography variant="body">
                                          Project(s): <b>{userInfo[0]?.totalProjects.length}</b>
                                        </Typography>
                                      </CardContent>
                                    </Box>
                                    {/* <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                                      <img src={messageIcon} alt="" />
                                    </Link> */}
                                  </div>
                                </Card>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item sm={12}>
                            <DataNotFound icon={"team"} title={""} subtitle={"Not Talent Assigned Yet!"} />
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* //Customer Info */}
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.projectTeam} ${styles.listAiTools}`}>
                      <Typography variant="h4" className={`panelCardH4`}>
                        Customer
                      </Typography>
                      <Grid container spacing={2}>
                        {projectDetails.initCustomer?.length > 0 ? (
                          projectDetails.initCustomer.map((customer, index) => {
                            return (
                              <Grid key={index} item sm={12} md={12} lg={12} className="fullWidth">
                                <Card className={`panelCard mt-3 ${styles.overflowVisible} ${styles.border}`}>
                                  <div className={`${styles.badge} ${styles.professional}`}>Customer</div>
                                  <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                                    <Box className={`${styles.teamCard}`}>
                                      <CardMedia className={`${styles.teamMemberImg}`}>
                                        {customer?.profilePicture ? (
                                          <img src={customer?.profilePicture} alt="TeamMember" />
                                        ) : (
                                          <span>{customer?.name.charAt(0)}</span>
                                        )}
                                      </CardMedia>
                                      <CardContent className={`${styles.teamMemberDetail}`}>
                                        <Typography variant="h4">
                                          <b>{customer?.name}</b>
                                        </Typography>
                                        <Typography variant="body">
                                          Email: <b> {customer?.email}</b>
                                        </Typography>
                                      </CardContent>
                                    </Box>
                                  </div>
                                </Card>

                                {/* <Card className={`panelCard mt-3 ${styles.overflowVisible}`} style={{ border: "1px solid #cacaca" }}>
                                  <div className={`${styles.flexCard} ${styles.noTopMargin} ${styles.justifyBW} `}>
                                    <Box className={`${styles.teamCard}`}>
                                      <CardMedia className={`${styles.teamMemberImg}`}>
                                        <img src={teamImg} alt="TeamMember" />
                                      </CardMedia>
                                      <CardContent className={`${styles.teamMemberDetail}`}>
                                        <Typography variant="h4">{customer?.name}</Typography>
                                        <Typography variant="body">
                                          Email: <b>{customer?.email}</b>
                                        </Typography>
                                        <Typography variant="body">
                                          Phone number: <b>{customer?.phone}</b>
                                        </Typography>
                                      </CardContent>
                                    </Box>
                                    <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                                      <img src={messageIcon} alt="" />
                                    </Link>
                                  </div>
                                </Card> */}
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item sm={12}>
                            <DataNotFound icon={"team"} title={""} subtitle={"No Record Found !"} />
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ) : (
        <Box className="emptyStateBox">
          <DataNotFound icon={"record"} title={"Data Not Found!"} subtitle={"We have looked real hard but do not seem to find this Page."} />
        </Box>
      )}
    </>
  );
}

export default ProjectDetails;
