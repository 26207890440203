/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./reject-request-dialog.module.css";
import btnStyles from "../../../../assets/button.module.css";
// import WarningIcon from "../../../../assets/icons/warningIcon.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { talentRejectRequestAPI } from "../../../../reducers/talentProjectSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RejectRequestDialog({ open, handleClose, selectedProjectId }) {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState("");
  const [description, setDescription] = useState("");

  const { requestLoading } = useSelector((state) => state.talentProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const isFormValid = selectedReason !== "" && description !== "";

  const handleRejectSubmit = () => {
    const request = {
      status: "rejected",
      declineReason: selectedReason,
      projectId: selectedProjectId,
      declineDescription: description,
    };
    dispatch(talentRejectRequestAPI(request, handleClose, navigate));
  };

  return (
    <Dialog
      className="DelcineWarningModal"
      fullScreen={fullScreen}
      open={open}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      hideBackdrop
    >
      <DialogTitle className={`${styles.dialogTitleBox}`}>
        {/* <Box className={`${styles.flexBox}`}>
          <div className={`${styles.flexBox}`}>
            <div className={`${styles.headThumb}`}>
              <img src={WarningIcon} alt=""></img>
            </div>
            <div className={`${styles.headContent}`}>
              <Typography variant="h4" className={`${styles.headTitle}`}>
                {" "}
                Decline Request
              </Typography>
              <Typography variant="body" className={`${styles.headTagline}`}>
                Please select reason why you are decline this request.
              </Typography>
            </div>
          </div>
          <IconButton>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </Box> */}
        <div className="text-center">
          <Box className="popupIcon">
            <WarningAmberIcon />
          </Box>
          <Typography variant="h4" className={`popupTitle mt-2`}>
            {" "}
            {t("popupTextDeclineTitle")}
          </Typography>
        </div>
        <IconButton className={`popupCrossBtn`}>
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${styles.dialogContentBox}`}>
        <Box component="form">
          <InputLabel className={`${styles.inputLabel} mt-2 `}>{t("popupTextDeclineReasonLabel")}</InputLabel>
          <Box>
            <FormControl fullWidth>
              <Select
                className={`${styles.selectInput} ${styles.selectInputMd}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedReason}
                onChange={handleReasonChange}
              >
                {/* <MenuItem value="">Select a reason</MenuItem> */}
                <MenuItem value="Not-Available">Not Available</MenuItem>
                <MenuItem value="Not-Interested">Not Interested</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <InputLabel className={`${styles.inputLabel} mt-3  `}>{t("textDescription")}</InputLabel>
          <Box>
            <TextField
              className={`${styles.TextAreaInput} `}
              id="outlined-multiline-static"
              multiline
              rows={2}
              defaultValue=""
              value={description}
              onChange={handleDescriptionChange}
            />
          </Box>
          <div className="flexCard mt-3 justified-center fullwidth">
            <Button
              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn}  ${btnStyles.yinRound}`}
              disabled={!isFormValid}
              onClick={handleRejectSubmit}
            >
              {t("textSubmit")} {requestLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RejectRequestDialog;
