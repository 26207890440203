import { useState, useRef, useEffect } from "react";
import { Button, Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import styles from "./previewRespond.module.css";
import btnStyles from "../../../assets/button.module.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import FileIcon from "../../../assets/icons/file.svg";
import ChatGPTIcon from "../../../assets/icons/ai-1.png";
import TumeIcon from "../../../assets/icons/ai-2.png";
import CodeSIcon from "../../../assets/icons/ai-3.png";
import ChatBotIcon from "../../../assets/icons/ai-4.png";
import { functions, instructions } from "../../../utils/instructions";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-empty-pattern
export default function PreviewRespond({}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [assistantMsgParam, setAssistantMsgParam] = useState("");
  const [skillResponseList, setSkillResponseList] = useState([]);
  const [deliverableResponseList, setDeliverableResponseList] = useState([]);
  const [getTitle, setGetTitle] = useState("");
  const pageTypeParam = location.state?.pageType || "";

  const fetchData = async (assistantMsgParam, pageTypeParam) => {
    setLoading(true);

    try {
      const [skillsResponse, deliverablesResponse, titleResponse] = await Promise.all([
        callAPI(assistantMsgParam, pageTypeParam, "get_skills"),
        callAPI(assistantMsgParam, pageTypeParam, "get_deliverables"),
        callAPI(assistantMsgParam, pageTypeParam, "get_title"),
      ]);

      const deliverable = Object.values(deliverablesResponse);

      setSkillResponseList(skillsResponse);
      setDeliverableResponseList(deliverable);

      // const functionArguments = JSON.parse(titleResponse.choices[0].message.function_call.arguments);
      // const projectTitle = functionArguments.projectTitle;

      setGetTitle(titleResponse?.projectTitle);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching API:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted.current === false && pageTypeParam) {
      const assistantMsgParam = location.state?.lastAssistantMessage || "";

      setAssistantMsgParam(assistantMsgParam);

      fetchData(assistantMsgParam, pageTypeParam);
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Rest of your code

  const callAPI = async (assistantMsgParam, type, functionName) => {
    const url = "https://api.openai.com/v1/chat/completions";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_KEY}`,
    };
    try {
      const userMessage = {
        role: "user",
        content: assistantMsgParam,
      };
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      let systemContent = "";

      if (functionName === "get_skills") {
        systemContent = instructions["preview"]?.skills;
      } else if (functionName === "get_deliverables") {
        systemContent = instructions["preview"]?.deliverable;
      } else if (functionName === "get_title") {
        systemContent = instructions["preview"]?.title;
      }

      const messages = [
        {
          role: "system",
          content: systemContent,
        },
        ...chatHistory.map((message) => ({
          role: message.role,
          content: message.content,
        })),
        { role: "user", content: assistantMsgParam },
      ];

      const data = {
        model: "gpt-3.5-turbo",
        messages,
        functions: functions,
        function_call: { name: functionName },
      };

      const response = await axios.post(url, data, { headers });

      return JSON.parse(response.data.choices[0].message.function_call.arguments);
    } catch (error) {
      console.error("Error fetching API:", error);
      throw error;
    }
  };

  const aiTools = [
    {
      id: 1,
      title: "ChatGPT",
      icon: ChatGPTIcon,
    },
    {
      id: 2,
      title: "Tume",
      icon: TumeIcon,
    },
    {
      id: 3,
      title: "Code Squire",
      icon: CodeSIcon,
    },
    {
      id: 4,
      title: "ChatBot",
      icon: ChatBotIcon,
    },
  ];

  const handleAcceptButton = async () => {
    localStorage.setItem("getTitle", getTitle);
    localStorage.setItem("assistantMsgParam", assistantMsgParam);
    localStorage.setItem("skillResponseList", JSON.stringify(skillResponseList));
    localStorage.setItem("deliverableResponseList", JSON.stringify(deliverableResponseList));
    localStorage.setItem("aiTools", JSON.stringify(aiTools));
    // navigate("/customerLogin");
    // navigate("/login");

    const lastAssistantMessage = location.state?.lastAssistantMessage || "";
    navigate("/pricing", {
      state: { lastAssistantMessage, pageType: "preview" },
    });
  };

  const handleRefineButton = async () => {
    const inputData = assistantMsgParam;

    navigate("/result", { state: { inputData, pageType: "home" } });
  };

  return (
    <>
      {loading ? (
        <div className={`${styles.loadingView}`}>
          <div className={`container ${styles.previewContaner} `}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className={`${styles.assistanTpingLoader}`}>
                  {t("previewPageLoadText")}
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <Grid container className={`container ${styles.pagePagination} ${styles.textWhite} ${styles.borderBottom} ${styles.spaceY} `}>
            <Grid item sm={6} md={6}>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtnOutline}  ${btnStyles.yinSqCornerSm} `} onClick={handleRefineButton}>
                {/* <ArrowBackIcon /> */}
                {t("textRefineMore")}
              </Button>
            </Grid>
            <Grid item sm={6} md={6} style={{ textAlign: "right" }}>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn}  ${btnStyles.yinSqCornerRev} `} onClick={handleAcceptButton}>
                {t("textAcceptContinue")}
              </Button>
            </Grid>
          </Grid>
          <div className={`container ${styles.previewContainer} `}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Card className={`${styles.previewSectionTitleCard}`}>
                  <Typography className={`${styles.sectionlabel}`}> {t("textProjectTitle")}</Typography>
                  <Typography className={`${styles.sectionCardProjectTitle}`}>{getTitle}</Typography>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} className={`${styles.columnRightSpace} `}>
                <Card className={`${styles.previewSectionCard}`}>
                  <Typography className={`${styles.sectionCardTitle}`}>{t("textProjectDescription")} </Typography>
                  <CardContent>
                    <pre className={`${styles.noWhiteSpace}`}>{assistantMsgParam}</pre>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} className={`${styles.columnLeftSpace}`}>
                <Card className={`${styles.previewSectionCard}`}>
                  <Typography className={`${styles.sectionCardTitle}`}>{t("textDeliverables")}</Typography>
                  <CardContent className={`${styles.listDeliverables}`}>
                    <List className={`${styles.dablList}`}>
                      {Object.entries(deliverableResponseList).map(([key, value], index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon className={`${styles.deliverableIcon}`}>
                              <img src={FileIcon} alt="" />
                            </ListItemIcon>
                            <div></div>
                            <ListItemText className={`${styles.listDeliverableTitle}`} primary={value} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} className={`${styles.columnRightSpace}`}>
                <Card className={`${styles.previewSectionCard}`}>
                  <Typography className={`${styles.sectionCardTitle}`}> {t("textAITools")} </Typography>
                  <CardContent className={`${styles.listDeliverables} ${styles.listAiTools}`}>
                    <List>
                      {aiTools.map((aiTool) => (
                        <ListItem disablePadding key={aiTool.id}>
                          <ListItemIcon>
                            <img src={aiTool.icon} alt="" />
                          </ListItemIcon>
                          <ListItemText className={`${styles.listaiToolTitle}`} primary={aiTool.title} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} className={`${styles.columnLeftSpace}`}>
                <Card className={`${styles.previewSectionCard}`}>
                  <Typography className={`${styles.sectionCardTitle}`}>{t("textSkills")}</Typography>
                  <CardContent className={`${styles.skills}`}>
                    <List>
                      {Object.entries(skillResponseList).map(([key, value], index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText className={`${styles.listSkillTitle}`} primary={value} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <Grid container className="container">
            <Grid item sm={12} className={`${styles.flexCard}`}></Grid>
          </Grid>
        </>
      )}
    </>
  );
}
