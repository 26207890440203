/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import styles from "./talents.module.css";
import btnStyles from "../../../assets/button.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminTalentsListAPI } from "../../../reducers/talentSlice";
import NewTalentDrawer from "./newTalentDrawer";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { formatDate } from "../../../utils/helper";
import Eye from "../../../assets/icons/eyetab.svg";
import Loader from "../../../components/common/Loader";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    borderBottom: "0 !important",
    opacity: ".6",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "0 !important",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Talents() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const adminTalents = useSelector((state) => state.talents);
  const { loading } = useSelector((state) => state.talents);
  const dispatch = useDispatch();

  useEffect(() => {
    const request = {
      role: "talent",
    };
    dispatch(adminTalentsListAPI(request));
    return () => {};
  }, []);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTalents = adminTalents.talentsList.slice(startIndex, endIndex);
  console.log("loading", loading);
  return (
    <>
      <Card className="panelCard mt-3 innerCardFullHeight">
        {loading ? (
          <>
            <Box className="emptyStateContainer">
              <Loader />
            </Box>
          </>
        ) : (
          <>
            <CardContent className={`${styles.projectTeam}`}>
              <Grid container className="py-3" style={{ alignItems: "center" }}>
                <Grid item xs={12} sm={5} md={8}>
                  <Typography variant="h4" className={`panelHeading`}>
                    Talents
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={4} className={`${styles.btnAligns}`}>
                  <Button onClick={handleDrawerOpen} className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                    New Talent
                  </Button>
                </Grid>
              </Grid>
              <Grid container className="py-3" style={{ alignItems: "center" }}>
                <Grid item sm={12} md={12}>
                  <TableContainer>
                    <Table aria-label="customized table" id="customTable">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell> Image</StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>Name</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Phone</StyledTableCell>
                          <StyledTableCell>Proficiency</StyledTableCell>
                          <StyledTableCell>Created At</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentTalents.length > 0 ? (
                          currentTalents.map((talent, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <CardMedia className={`${styles.teamMemberImg}`}>
                                  {talent.profilePicture ? (
                                    <img src={talent.profilePicture} alt="TeamMember" />
                                  ) : (
                                    <span style={{ textTransform: "uppercase" }}>{talent.name.charAt(0)}</span>
                                  )}
                                </CardMedia>
                              </StyledTableCell>
                              <StyledTableCell>{talent.name} </StyledTableCell>
                              <StyledTableCell>{talent.email}</StyledTableCell>
                              <StyledTableCell>{talent.phone}</StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  variant="body"
                                  className={`${styles.talentBadge} ${
                                    talent.proficiency === "Professional"
                                      ? styles.professional
                                      : talent.proficiency === "Genius"
                                      ? styles.genius
                                      : talent.proficiency === "Expert"
                                      ? styles.expert
                                      : ""
                                  }`}
                                >
                                  {talent.proficiency}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell> {formatDate(talent.created_at)} </StyledTableCell>
                              <StyledTableCell>
                                <StyledTableCell>
                                  <div className={`${styles.buttonGroup}`}>
                                    <Link to={`/talents/${talent._id}`}>
                                      <Button variant="contained" size="small" className={`${styles.eyeIconbtn} ${styles.tableAction}`}>
                                        <img src={Eye} alt={"eyeIcon"} />
                                      </Button>
                                    </Link>
                                  </div>
                                </StyledTableCell>{" "}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5} align="center">
                              No talents found.
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(adminTalents.talentsList.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                renderItem={(item) => <PaginationItem component={Link} to={`#${item.page}`} {...item} />}
                className={`paignationList`}
              />
            </CardContent>
          </>
        )}
      </Card>
      <NewTalentDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
    </>
  );
}
