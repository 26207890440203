import { callAPI } from "../config/api-core";
import {
  talentAcceptRequestAPIMethods,
  talentDeliverableChatAPIMethods,
  talentDeliverableChatListAPIMethods,
  talentDeliverableDocAPIMethods,
  talentDeliverableDocUploadAPIMethods,
  talentDeliverableForReviewAPIMethods,
  talentNewRequestListAPIMethods,
  talentProjectDetailAPIMethods,
  talentProjectListAPIMethods,
  talentProjectListByStatusAPIMethods,
  talentRejectRequestAPIMethods,
  talentRequestDetailAPIMethods,
  customerDeliverableForReviewAPIMethods,
} from "../methods";

const talentNewRequestListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(talentNewRequestListAPIMethods, true, "", header, false, false);
  return response;
};

const talentAcceptRequestAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAcceptRequestAPIMethods, false, request, header, false, false);
  return response;
};

const talentRejectRequestAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentRejectRequestAPIMethods, false, request, header, false, false);
  return response;
};

const talentRequestDetailAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  //   let token = JSON.parse(userToken);

  let header = {
    Authorization: userToken && userToken,
  };
  const query = `?requestId=${request.projectID}`;
  const response = await callAPI(talentRequestDetailAPIMethods + query, true, request, header, false, false);
  return response;
};

const talentProjectListByStatusAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentProjectListByStatusAPIMethods, false, request, header, false, false);
  return response;
};

const talentProjectDetailAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const query = `?projectId=${request.projectId}`;
  const response = await callAPI(talentProjectDetailAPIMethods + query, true, request, header, false, false);
  console.log("MAIN", response);
  return response;
};

const talentProjectListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(talentProjectListAPIMethods, true, "", header, false, false);
  return response;
};

const talentDeliverableChatListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const query = `?deliverableId=${request}`;
  // const param = `?search=${request}`;
  const response = await callAPI(talentDeliverableChatListAPIMethods + query, true, "", header, false, false);
  return response;
};

const talentDeliverableChatAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentDeliverableChatAPIMethods, false, request, header, false, false);
  return response;
};

const talentDeliverableDocUploadAPI = async (request) => {
  const { doc, deliverableId, title } = request;

  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  if (doc && doc instanceof File) {
    formData.append("file", doc);
  }
  formData.append("deliverableId", deliverableId);
  formData.append("title", title);

  const response = await callAPI(talentDeliverableDocUploadAPIMethods, false, formData, header, false, false);
  return response;
};

const talentDeliverableDocumentListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const query = `?deliverableId=${request}`;
  // const param = `?search=${request}`;
  const response = await callAPI(talentDeliverableDocAPIMethods + query, true, "", header, false, false);
  return response;
};

const talentDeliverableForReviewAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentDeliverableForReviewAPIMethods, false, request, header, false, false);
  return response;
};

/*** Customer for Review API GD**/

const customerDeliverableForReviewAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(customerDeliverableForReviewAPIMethods, false, request, header, false, false);
  return response;
};

export const getTalentNewRequestList = (request) => talentNewRequestListAPI(request);
export const postAcceptRequestAPI = (request) => talentAcceptRequestAPI(request);
export const postRejectRequestAPI = (request) => talentRejectRequestAPI(request);
export const getRequestDetailAPI = (request) => talentRequestDetailAPI(request);
export const postTalentProjectListByStatusAPI = (request) => talentProjectListByStatusAPI(request);
export const getProjectDetailAPI = (request) => talentProjectDetailAPI(request);
export const getTalentProjectListAPI = (request) => talentProjectListAPI(request);
export const getTalentDeliverableChatListAPI = (request) => talentDeliverableChatListAPI(request);
export const postTalentDeliverableChatAPI = (request) => talentDeliverableChatAPI(request);
export const postTalentDeliverableDocUploadAPI = (request) => talentDeliverableDocUploadAPI(request);
export const getTalentDeliverableDocumentListAPI = (request) => talentDeliverableDocumentListAPI(request);
export const postTalentDeliverableForReviewAPI = (request) => talentDeliverableForReviewAPI(request);
export const postCustomerDeliverableForReviewAPI = (request) => customerDeliverableForReviewAPI(request);

// export const postTalentProfileAPI = (request) => talentUpdateProfileAPI(request);

export default {
  getTalentNewRequestList,
  postAcceptRequestAPI,
  postRejectRequestAPI,
  getRequestDetailAPI,
  postTalentProjectListByStatusAPI,
  getProjectDetailAPI,
  getTalentProjectListAPI,
  postTalentDeliverableChatAPI,
  getTalentDeliverableChatListAPI,
  postTalentDeliverableDocUploadAPI,
  getTalentDeliverableDocumentListAPI,
  postTalentDeliverableForReviewAPI,
};
