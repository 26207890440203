const professional = {
  price: "99",
  type: "Professionals",
  experience: "1-3",
  paymentStatus: "pending",
};

const expert = {
  price: "199",
  type: "Expert",
  experience: "3-6",
  paymentStatus: "pending",
};

const genius = {
  price: "249",
  type: "Genius",
  experience: "10+",
  paymentStatus: "pending",
};

export { professional, expert, genius };

export function getPlanDetails(type) {
  switch (type) {
    case "Professionals":
      return professional;
    case "Expert":
      return expert;
    case "Genius":
      return genius;
    default:
      return null;
  }
}
