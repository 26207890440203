import { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./preview.module.css";
import LineOverlay from "../../../assets/icons/linelayer.png";
import PreviewRespond from "../../../components/dashboard/previewRespond";
import Navbar from "../../../components/common/navbar";

function Preview(props) {
  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  return (
    <>
      <Navbar />
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className={`${styles.resultPage}`}>
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <img src={LineOverlay} alt="" className={`${styles.lineLayerRight}`} />
      </Box>
      <PreviewRespond />
    </>
  );
}

export default Preview;
