import Box from "@mui/material/Box";
import styles from "./deliverable.module.css";
import btnStyles from "../../../assets/button.module.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import DeliverablesList from "../../../components/common/deliverableCard";

import { Button } from "@mui/base";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deliverableProjectListAPI, deliverablesListAPI } from "../../../reducers/deliverablesSlice";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import Loader from "../../../components/common/Loader";

export default function DeliverablesPage() {
  // const [value, setValue] = useState("Select");
  const [open, setOpen] = useState(false);
  const [selectProject, setSelectProject] = useState("Select");
  const [error, setError] = useState("");
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const { deliverables, loading, deliverableProjectListing } = useSelector((state) => state.deliverables);

  useEffect(() => {
    if (!isMounted.current) {
      const storedValue = localStorage.getItem("selectedProjectId");
      // const projectID = "64f96cacbd5f3e2df0e00bab";
      // dispatch(deliverablesListAPI(projectID));
      dispatch(deliverablesListAPI(storedValue));
      isMounted.current = true;
    }
  }, [dispatch]);

  const handlePopupOpen = () => {
    setError("");
    dispatch(deliverableProjectListAPI());
    setOpen(true);
  };
  const handlePopupClose = () => {
    setError("");
    setOpen(false);
  };

  const changeProjectHandle = (e) => {
    if (e.target.value === "Select") {
      setError("Please select project");
    } else {
      setError("");
      setSelectProject(e.target.value);
    }
  };

  const handleChangeProject = () => {
    if (selectProject === "" || selectProject === "Select") {
      console.log("first");
      setError("Please select project");
    } else {
      localStorage.setItem("selectedProjectId", selectProject);
      if (selectProject !== "Select") {
        dispatch(deliverablesListAPI(selectProject));
      }
      setOpen(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {deliverables?.length > 0 ? (
            <>
              <Card className={`${styles.headCard}`}>
                <Grid container style={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={8} md={8}>
                    <Card className={`${styles.innerCardHead}`}>
                      <CardContent className={`${styles.innerCardBody} ${styles.innerBtnGap}`}>
                        <span className={`${styles.projectDetailSubTitle}`}>Project Name</span>
                        <Typography variant="h2" className={`${styles.projectDetailTitle}`}>
                          {deliverables[0]?.name}
                        </Typography>

                        <Dialog className="DelcineWarningModal" open={open} onClose={handlePopupClose} aria-labelledby="responsive-dialog-title">
                          <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
                            <IconButton className={`popupCrossBtn`}>
                              <CloseIcon onClick={handlePopupClose} />
                            </IconButton>
                            {"Change Project"}
                            <Typography className="mb-3">Please select the project for which you want to display the deliverables.</Typography>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              <Select className={`formInputSelect ${styles.formInput} `} value={selectProject} onChange={changeProjectHandle}>
                                <MenuItem value="Select">Select</MenuItem>
                                {deliverableProjectListing?.map((project) => {
                                  const { _id, name } = project;
                                  return (
                                    <MenuItem key={_id} value={_id}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {error && <div className={`${styles.errorMessage}`}>{error}</div>}
                            </DialogContentText>
                          </DialogContent>
                          <Box className="popupBtn mt-3">
                            <Button
                              onClick={handleChangeProject}
                              autoFocus
                              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className={`${styles.buttonAlignment}`} style={{ justifyContent: "end", display: "flex" }}>
                    <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                      <span onClick={handlePopupOpen} className={`${styles.subTitle}`}>
                        Change Project
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </Card>
              <Card className="panelCard mt-3">
                <Grid container spacing={2}>
                  <Grid item sm={12} md={12}>
                    <Typography variant="h4" className={`panelHeading  mb-3`}>
                      Deliverables
                    </Typography>
                    <DeliverablesList deliverables={deliverables} />
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <>
              <Box className="emptyStateBox">
                <DataNotFound
                  createProject={"createProject"}
                  icon={"record"}
                  title={" No Project Created Yet!"}
                  subtitle={"Click on New Project to create new project"}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
