import { createSlice } from "@reduxjs/toolkit";

import { openSnackbar } from "./snackbarSlice";

import {
  getAiListAPI,
  getSkillListAPI,
  getTalentProfileAPI,
  postTalentAiToolsAPI,
  postTalentDeleteEducationAPI,
  postTalentDeleteExperienceAPI,
  postTalentEditEducationAPI,
  postTalentEditExperienceAPI,
  postTalentEducationAPI,
  postTalentExperienceAPI,
  postTalentProfileAPI,
  postTalentSkillsAPI,
} from "../services/api/talent-profile-api";

const initialState = {
  loading: false,
  talentProfile: {},
  talentProjectCount: {},
  talentEducation: [],
  talentSkills: [],
  talentAiTools: [],
  talentExperience: [],
  // talentCertifications: [],
  talentProfileLoading: false,
  skillList: [],
  aiList: [],
  talentRating: [],
};

const talentsSlice = createSlice({
  name: "talentProfile",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    isTalentProfileLoading(state) {
      state.talentProfileLoading = true;
    },
    finishTalentProfileLoading(state) {
      state.talentProfileLoading = false;
    },
    talentsProfileSuccess(state, action) {
      state.talentProfile = action.payload;
    },
    talentsProjectCountSuccess(state, action) {
      state.talentProjectCount = action.payload;
    },
    talentsEducationSuccess(state, action) {
      // state.talentEducation = [...state.talentEducation, ...action.payload];
      state.talentEducation = action.payload;
    },
    talentsSkillsSuccess(state, action) {
      state.talentSkills = action.payload;
    },
    talentsAiToolsSuccess(state, action) {
      state.talentAiTools = action.payload;
    },
    talentsExperienceSuccess(state, action) {
      // state.talentExperience = [...state.talentExperience, ...action.payload];
      state.talentExperience = action.payload;
    },
    // talentsCertificationsSuccess(state, action) {
    //   state.talentCertifications = action.payload;
    // },
    skillListSuccess(state, action) {
      state.skillList = action.payload;
    },
    aiListSuccess(state, action) {
      state.aiList = action.payload;
    },
    talentsRatingSuccess(state, action) {
      state.talentRating = action.payload;
    },
  },
});

export const {
  isLoading,
  finishLoading,
  isTalentProfileLoading,
  talentsProfileSuccess,
  talentsProjectCountSuccess,
  finishTalentProfileLoading,
  talentsEducationSuccess,
  talentsSkillsSuccess,
  talentsAiToolsSuccess,
  talentsExperienceSuccess,
  // talentsCertificationsSuccess,
  skillListSuccess,
  aiListSuccess,
  talentsRatingSuccess,
} = talentsSlice.actions;

export const talentProfileAPI = (request) => async (dispatch, getState) => {
  dispatch(isLoading());

  // try {
  //   const response = await getTalentProfileAPI(request);

  //   if (response.status === 200) {

  //     const { name, about, countryCode, email, phone, profilePicture, dob, address, expData, educationData } = response.data.data[0];
  //     const profileDetail = {
  //       name,
  //       about,
  //       countryCode,
  //       email,
  //       phone,
  //       profilePicture,
  //       dob,
  //       address,
  //     };

  //     dispatch(talentsProfileSuccess(profileDetail));
  //     dispatch(talentsExperienceSuccess(expData));
  //     dispatch(talentsEducationSuccess(educationData));
  //   }
  // }
  try {
    const [profileResponse, skillListResponse, aiListResponse] = await Promise.all([
      getTalentProfileAPI(request),
      getSkillListAPI(), // Assuming getSkillListAPI and getAiListAPI do not require request parameters.
      getAiListAPI(),
    ]);

    //console.log("profileResponse", profileResponse);

    if (profileResponse.status === 200) {
      const {
        name,
        about,
        countryCode,
        email,
        phone,
        profilePicture,
        // dob,
        created_at,
        // address,
        expData,
        educationData,
        skillData,
        aiData,
        ratingData,
        proficiency,
        completed,
        inprogress,
        videoUrl,
        requests,
      } = profileResponse.data.data[0];
      const profileDetail = {
        name,
        about,
        videoUrl,
        countryCode,
        email,
        phone,
        profilePicture,
        // dob,
        created_at,
        // address,
        proficiency,
      };

      const projectCount = {
        requests,
        completed,
        inprogress,
      };

      dispatch(talentsProfileSuccess(profileDetail));
      dispatch(talentsExperienceSuccess(expData));
      dispatch(talentsEducationSuccess(educationData));
      dispatch(talentsSkillsSuccess(skillData));
      dispatch(talentsAiToolsSuccess(aiData));
      dispatch(talentsProjectCountSuccess(projectCount));
      dispatch(talentsRatingSuccess(ratingData));
    }

    if (skillListResponse.status === 201) {
      // Dispatch an action for skill list success here.
      dispatch(skillListSuccess(skillListResponse.data.data));
    }

    if (aiListResponse.status === 201) {
      // Dispatch an action for AI list success here.
      dispatch(aiListSuccess(aiListResponse.data.data));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishLoading());
};

export const talentProfileUpdateAPI = (request, handleDrawerClose) => async (dispatch) => {
  dispatch(isTalentProfileLoading());

  try {
    const response = await postTalentProfileAPI(request);

    if (response.status === 200) {
      const { name, about, countryCode, email, proficiency, phone, profilePicture, created_at } = response.data.data;
      const profileDetail = {
        name,
        about,
        countryCode,
        email,
        phone,
        profilePicture,
        // dob,
        proficiency,
        created_at,
        // address,
      };
      dispatch(talentsProfileSuccess(profileDetail)); //response.data.data
      localStorage.setItem("profile_picture", profilePicture);
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentEducationAPI = (request, handleDrawerClose) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentEducation = getState().talentProfile.talentEducation;
    const response = await postTalentEducationAPI(request);
    if (response.status === 201) {
      const { _id, degree, endDate, fieldOfStudy, graduationDate, school } = response.data.data;
      const result = {
        degree,
        endDate,
        fieldOfStudy,
        graduationDate,
        school,
        _id,
      };
      dispatch(talentsEducationSuccess([...talentEducation, result]));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));

      // setEducationDetails({ school: "", degree: "", fieldOfStudy: "", graduationDate: null });
      // setEducationDetailsError({});
      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentEditEducationAPI = (request, handleDrawerClose) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentEducation = getState().talentProfile.talentEducation;
    const response = await postTalentEditEducationAPI(request);
    if (response.status === 201) {
      const { _id, degree, endDate, fieldOfStudy, graduationDate, school } = response.data.data;
      const updatedTalentEducation = talentEducation.filter((item) => item._id !== _id);

      const result = {
        degree,
        endDate,
        fieldOfStudy,
        graduationDate,
        school,
        _id,
      };
      dispatch(talentsEducationSuccess([...updatedTalentEducation, result]));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));

      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentDeleteEducationAPI = (request) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentEducation = getState().talentProfile.talentEducation;
    const response = await postTalentDeleteEducationAPI(request);
    if (response.status === 201) {
      const updatedTalentEducation = talentEducation.filter((item) => item._id !== request.educationId);

      dispatch(talentsEducationSuccess([...updatedTalentEducation]));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentsSkillsAPI = (request, handleDrawerClose, setSelectedSkills) => async (dispatch) => {
  dispatch(isTalentProfileLoading());

  try {
    const response = await postTalentSkillsAPI(request);
    if (response.status === 201) {
      dispatch(talentsSkillsSuccess(response.data.data));
      handleDrawerClose();
      // setSelectedSkills([]);
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentsAiToolsAPI = (request, handleDrawerClose, setSelectedAiTools) => async (dispatch) => {
  dispatch(isTalentProfileLoading());

  try {
    const response = await postTalentAiToolsAPI(request);
    if (response.status === 201) {
      dispatch(talentsAiToolsSuccess(response.data.data));
      handleDrawerClose();
      // setSelectedAiTools([]);
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentExperienceAPI = (request, handleDrawerClose) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentExperience = getState().talentProfile.talentExperience;
    const response = await postTalentExperienceAPI(request);
    if (response.status === 201) {
      const { _id, companyName, employmentType, location, startDate, title, endDate } = response.data.data;

      const result = {
        companyName,
        employmentType,
        location,
        startDate,
        title,
        _id,
      };
      // if (!response.data.data.currentWorking) {
      //   request.endDate = response.data.data.endDate;
      // } else {
      //   request.currentWorking = true;
      // }
      if (endDate) {
        result.endDate = endDate;
      } else {
        result.currentWorking = true;
      }

      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(talentsExperienceSuccess([...talentExperience, result]));
      // setExperienceDetails({ title: "", employmentType: "", companyName: "",
      // location: "", startDate: null, endDate: null, currentWorking: false });
      // setExperienceDetailsError({});
      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentEditExperienceAPI = (request, handleDrawerClose) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentExperience = getState().talentProfile.talentExperience;
    const response = await postTalentEditExperienceAPI(request);
    if (response.status === 201) {
      const { _id, companyName, employmentType, location, startDate, title, endDate } = response.data.data;
      const updatedTalentExperience = talentExperience.filter((item) => item._id !== _id);
      const result = {
        companyName,
        employmentType,
        location,
        startDate,
        title,
        _id,
      };

      if (endDate) {
        result.endDate = endDate;
      } else {
        result.currentWorking = true;
      }

      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(talentsExperienceSuccess([...updatedTalentExperience, result]));

      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

export const talentDeleteExperienceAPI = (request) => async (dispatch, getState) => {
  dispatch(isTalentProfileLoading());

  try {
    const talentExperience = getState().talentProfile.talentExperience;
    const response = await postTalentDeleteExperienceAPI(request);
    if (response.status === 201) {
      const updatedTalentExperience = talentExperience.filter((item) => item._id !== request.experienceId);

      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(talentsExperienceSuccess([...updatedTalentExperience]));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishTalentProfileLoading());
};

// export const talentCertificationAPI = (request) => async (dispatch) => {
//   dispatch(isLoading());

//   try {
//     const response = await getTalentsListAPI(request);
//     if (response.status === 200) {
//       dispatch(talentsCertificationsSuccess(response.data?.result));
//     }
//   } catch (error) {

//   }

//   dispatch(finishLoading());
// };

export default talentsSlice.reducer;
