import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./sidebar.module.css";
import ryaIcon from "../../../assets/icons/rya.png";
import { ProjectIcon, DeliverableIcon, TeamsIcon, FileIcon } from "../../../assets/yingoIcons";
import { Link, NavLink } from "react-router-dom";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/icons/yingoLogo.png";
import { navbarProjectTitle, projectCreditFunc } from "../../../reducers/talentProjectSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { instructions } from "../../../utils/ryaInstructions";

const drawerMargin = "55px";
const drawerWidth = 250;

export default function Sidebar(props) {
  const baseUrl = process.env.REACT_APP_BASE_URL_LOCAL || process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { window, role, mobileOpen, handleDrawerToggle } = props;
  const isLGScreen = useMediaQuery("(max-width: 1024px)");
  const isXLScreen = useMediaQuery("(max-width: 1200px)");
  const contentWidth = isLGScreen ? "200px" : isXLScreen ? "220px" : "260px";

  const UserMenuItems = [
    { text: "Projects", icon: <ProjectIcon />, link: "/projects" },
    { text: "Deliverables", icon: <DeliverableIcon />, link: "/deliverables" },
    { text: "Team", icon: <TeamsIcon />, link: "/teams" },
    // { text: "Invoices", icon: <PaymentIcon />, link: "/invoices" },
    { text: "Support", icon: <FileIcon />, link: "/support" },
  ];

  const AdminMenuItems = [
    { text: "Projects", icon: <ProjectIcon />, link: "/admin-dahbaord" },
    { text: "New Requests", icon: <DeliverableIcon />, link: "/requests" },
    { text: "Talents", icon: <TeamsIcon />, link: "/talents" },
    { text: "Support", icon: <FileIcon />, link: "/admin-support" },
  ];

  const TalentMenuItems = [
    { text: t("talentSideMenuItem1"), icon: <ProjectIcon />, link: "/talent-projects" },
    { text: t("talentSideMenuItem2"), icon: <DeliverableIcon />, link: "/talent-requests" },
    // { text: t("talentSideMenuItem3"), icon: <FileIcon />, link: "/talent-invoices" },
    { text: t("talentSideMenuItem4"), icon: <TeamsIcon />, link: "/talent-support" },
  ];

  const menuItems = role === "admin" ? AdminMenuItems : role === "user" ? UserMenuItems : TalentMenuItems;

  const url = `${baseUrl}/talent/getProjectListForTalent`;

  let userToken = localStorage.getItem("token");

  let headers = {
    Authorization: userToken && userToken,
  };

  const handleRyaBot = async () => {
    localStorage.setItem("isRyaClick", "true");
    localStorage.setItem("ryaSelectProjectId", "6583f21b4df18353ecb46d3d");
    localStorage.setItem("ryaSelectProjectName", "Welcome Project");
    localStorage.setItem("currentActionId", instructions.defaultCategories[0]?.actionId);
    localStorage.setItem("currentCatName", instructions.defaultCategories[0]?.name);
    dispatch(navbarProjectTitle("Welcome Project"));

    const Newresponse = await axios.get(url, { headers }); // Corrected placement of headers
    if (Newresponse.status === 200) {
      // console.log("Newresponse.data.result", Newresponse.data.result[0].projectCredits);
      const activeCredits = Newresponse.data?.result[0]?.projectCredits;
      localStorage.setItem("ryaSelectProjectCredits", activeCredits);
      dispatch(projectCreditFunc(activeCredits));
    } else {
      console.log("Cancelled API Processed");
    }
  };

  const currentUserRole = localStorage.getItem("role");

  const drawer = (
    <div className={`${styles.sidebarContainer}`} onClick={handleDrawerToggle}>
      <Box className={`${styles.sibebarLogoBox}`}>
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </Box>
      <List className={`${styles.topSpace10} ${styles.eventPointer}`}>
        {menuItems.map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <NavLink to={item.link} className={`${styles.menuItemLink} menuSideItem`}>
              <ListItemButton className={`${styles.sidebarItemBox} `}>
                <ListItemIcon className={`${styles.sidebarIconBox}`}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} className={`${styles.sidebarText}`} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
      {currentUserRole === "talent" ? (
        <Box className={`${styles.ryabotContainer}`}>
          <Link to="/rya-dashboard" onClick={handleRyaBot}>
            <Box className={`${styles.ryabotActionBtn}`}>
              <img src={ryaIcon} alt="" />
              <Typography variant="body">{t("askRya")}</Typography>
            </Box>
          </Link>
        </Box>
      ) : null}
    </div>
  );

  return (
    <>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={window}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className={`${styles.sideDrawerBox}`}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: contentWidth,
              marginTop: drawerMargin,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

Sidebar.propTypes = {
  window: PropTypes.object,
  role: PropTypes.string.isRequired,
  mobileOpen: PropTypes.object,
  handleDrawerToggle: PropTypes.string.isRequired,
};
