import { Box, CssBaseline, Dialog, DialogContent, IconButton, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Card, Grid, Typography } from "@mui/material";
import styles from "./talent-profile.module.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "../../../assets/icons/file-filled.svg";
import ProfileDefaultImg from "../../../assets/icons/defaultState.png";
import { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import { useParams } from "react-router-dom";
import { talentPreviewAPI } from "../../../reducers/userSlice";
import YouTube from "react-youtube";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={`${styles.tabScrollBox}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentProfile() {
  const [value, setValue] = useState(0);
  const loading = useSelector((state) => state.user?.loading);
  const talentProfile = useSelector((state) => state?.user?.profileData);

  const [openDocPreview, setOpenDocPreview] = useState(false);
  console.log("CCS", talentProfile?.ratingData?.[0]?.rating);
  const isMounted = useRef(false);

  const param = useParams();

  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    if (!isMounted.current) {
      const data = {
        talentId: param,
      };
      dispatch(talentPreviewAPI(data));
      isMounted.current = true;
    }
  }, [dispatch, param]);
  const talentDetails = useMemo(() => {
    if (talentProfile) {
      const talent = talentProfile;

      const aiData = talent.aiData;
      const expData = talent.expData;
      const skillData = talent.skillData;
      const educationData = talent.educationData;
      return {
        initTalentAi: aiData,
        initTalentExperience: expData,
        initTalentSkills: skillData,
        initTalentEducation: educationData,
      };
    }
    return {};
  }, [talentProfile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const aboutRef = useRef(null);
  const toolsRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const educationRef = useRef(null);
  const innerScrollRef = useRef(null);

  const handleScrollToSection = (e, sectionRef) => {
    e.preventDefault();
    const innerContainer = innerScrollRef.current;
    const section = sectionRef.current;

    if (innerContainer && section) {
      const scrollTop = section.offsetTop - innerContainer.offsetTop;

      innerContainer.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };
  const starArray = Array.from({ length: 5 }, (_, index) => index + 1);
  const handleClickOpenDocPreview = () => {
    setOpenDocPreview(true);
  };

  const handleCloseDocPreview = () => {
    setOpenDocPreview(false);
  };
  const videoUrl = talentProfile?.videoUrl;

  // Extract video ID from the YouTube URL
  const videoId = videoUrl && new URL(videoUrl).searchParams.get("v");

  const opts = {
    height: "350px",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 0,
      modestbranding: 1,
      showinfo: 0,
    },
  };

  return (
    <>
      <Box style={{ position: "relative", height: "80vh", width: "100%" }}>
        <CssBaseline />
        <Box>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Card
                className={`panelCard panelCardFullHeight ${
                  talentProfile?.proficiency === "Professional"
                    ? "professtionView"
                    : talentProfile?.proficiency === "Expert"
                    ? "expertView"
                    : "geniusView"
                }`}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card className="panelCard p-0 innerCardFullHeight professtionInnerView">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box className={`${styles.talentContentCardLeft}`}>
                            <Box className={`${styles.videoBox}`}>
                              <div className={`${styles.videoPlayIcon}`} onClick={handleClickOpenDocPreview}>
                                <PlayArrowIcon />
                              </div>
                            </Box>
                            <Box className={`${styles.telentPhotoContainer} p-20`}>
                              <Box className={`${styles.flexdCard} gap-md `}>
                                <div className={`${styles.telentPhotoBox}`}>
                                  <Box className={`${styles.telentPhotoCard}`}>
                                    <img src={talentProfile?.profilePicture || ProfileDefaultImg} alt=""></img>
                                  </Box>
                                  {/* <div className={`${styles.telentNameBox}`}>
                                    <Typography variant="body">Proffessional</Typography>
                                  </div> */}
                                  <div
                                    className={`${
                                      talentProfile?.proficiency === "Professional"
                                        ? "professtionBgColor"
                                        : talentProfile?.proficiency === "Expert"
                                        ? "expertBgColor"
                                        : "geniusBgColor"
                                    } ${styles.telentNameBox}`}
                                  >
                                    <Typography variant="body">{talentProfile?.proficiency}</Typography>
                                  </div>
                                </div>
                                <div className={`${styles.telentData}`}>
                                  <Typography variant="h4" className={`${styles.telentName}`}>
                                    {talentProfile?.name}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    className={`${styles.paragraphText}  ${styles.darkText}`}
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {talentProfile?.countryCode}
                                  </Typography>
                                </div>
                              </Box>

                              <div className={`flexCard justified-between ${styles.ratingBox} mt-4`}>
                                <div className={`${styles.ratingStar} flexCard`}>
                                  {starArray.map((index) =>
                                    talentProfile?.ratingData?.[0]?.rating >= index ? (
                                      <StarIcon key={index} className={`cursor-pointer ${styles.rated}`} />
                                    ) : (
                                      <StarBorderIcon key={index} className={`cursor-pointer`} />
                                    ),
                                  )}
                                </div>
                                <Typography variant="h4">
                                  {talentProfile?.ratingData?.[0]?.rating !== undefined && talentProfile?.ratingData?.[0]?.rating !== null
                                    ? parseFloat(talentProfile?.ratingData?.[0]?.rating).toFixed(1)
                                    : "0.00"}
                                </Typography>
                              </div>
                            </Box>
                            <Box className={`${styles.levelBox} p-20`}>
                              <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                Proficiency Level
                              </Typography>
                              <div className={`flexCard gap-md mt-15`}>
                                <BusinessCenterIcon style={{ color: "#9A9A9A" }} />

                                <Typography variant="h4" className={`${styles.paragraphText} ${styles.darkText}`} style={{ wordBreak: "break-all" }}>
                                  {talentProfile?.proficiency}
                                </Typography>
                              </div>
                            </Box>
                            <Box className={`${styles.PersonalInfoCard} p-20`}>
                              {/* <div className="flexCard justified-between">
                                <Typography variant="h4" className={`${styles.pageTalentTitle} `}>
                                  Personal Info
                                </Typography>
                              </div>
                              <div className={`flexCard gap-md mt-15`}>
                                <EmailIcon style={{ color: "#9A9A9A" }} />
                                <Typography variant="h4" className={`${styles.paragraphText} ${styles.darkText}`} style={{ wordBreak: "break-all" }}>
                                  {talentProfile?.email}
                                </Typography>
                              </div> */}
                              <div className={`flexCard gap-md mt-10`}>
                                <CalendarTodayIcon style={{ color: "#9A9A9A" }} />
                                <Typography variant="h4" className={`${styles.paragraphText} ${styles.darkText}`}>
                                  Member Since {talentProfile?.created_at && formatDate(talentProfile?.created_at)}
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9}>
                          <Box className={`${styles.talentContentCard}`}>
                            <Box sx={{ width: "100%" }}>
                              <Box
                                class={`${
                                  talentProfile?.proficiency === "Professional"
                                    ? "proffessionTab"
                                    : talentProfile?.proficiency === "Expert"
                                    ? "expertTab"
                                    : "geniusTab"
                                } profileTab`}
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  id={`${styles.profileTabList}`}
                                  value={value}
                                  onChange={handleChange}
                                  variant={isLargeScreen ? "scrollable" : "fullWidth"}
                                  scrollButtons="auto"
                                  aria-label="basic tabs example"
                                >
                                  <Tab label="About" {...a11yProps(0)} onClick={(e) => handleScrollToSection(e, aboutRef)} />
                                  <Tab label="Ai Tools" {...a11yProps(1)} onClick={(e) => handleScrollToSection(e, toolsRef)} />
                                  <Tab label="Experience" {...a11yProps(2)} onClick={(e) => handleScrollToSection(e, experienceRef)} />
                                  <Tab label="Skills" {...a11yProps(3)} onClick={(e) => handleScrollToSection(e, skillsRef)} />
                                  <Tab label="Education" {...a11yProps(4)} onClick={(e) => handleScrollToSection(e, educationRef)} />
                                </Tabs>
                              </Box>
                              <CustomTabPanel>
                                <div className={`${styles.scrollableContainer}`} ref={innerScrollRef}>
                                  {/* About */}
                                  <div className={`${styles.sectionDivider}`} ref={aboutRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        About
                                      </Typography>
                                    </Box>
                                    <Typography variant="body" className={`${styles.commonText}`}>
                                      {talentProfile?.about}
                                    </Typography>
                                  </div>
                                  {/*  AI Tools */}
                                  <div className={`${styles.sectionDivider}`} ref={toolsRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        AI Tools
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {talentDetails?.initTalentAi &&
                                          talentDetails.initTalentAi.map((aiData, index) => (
                                            <ListItem disablePadding key={index}>
                                              <ListItemIcon>
                                                <img src={aiData.icon} alt="" />
                                              </ListItemIcon>
                                              <ListItemText className={`${styles.listaiToolTitle}`} primary={aiData.name} />
                                            </ListItem>
                                          ))}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Experince */}
                                  <div className={`${styles.sectionDivider}`} ref={experienceRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Experience
                                      </Typography>
                                    </Box>
                                    <Box className={` ${styles.listExperience}`}>
                                      <List>
                                        {talentDetails?.initTalentExperience &&
                                          talentDetails.initTalentExperience.map((expData, index) => (
                                            <ListItem key={index} className="flexCard aignItemCenter" disablePadding>
                                              <ListItemIcon className={`${styles.listCardIcon}`}>
                                                <img src={FileIcon} alt="" />
                                              </ListItemIcon>
                                              <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                                <div className="fullWidth flexCard  justified-between">
                                                  <ListItemText className={`${styles.listaiToolTitle}`} primary={expData.title} />
                                                  <div className="flexCard">
                                                    <ListItemText
                                                      className={`${styles.listaiToolSubtitle}`}
                                                      primary={new Date(expData.startDate).getFullYear()}
                                                    />
                                                    -
                                                    <ListItemText
                                                      className={`${styles.listaiToolSubtitle}`}
                                                      primary={expData.currentWorking ? "Present" : new Date(expData.endDate).getFullYear()}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="flexCard " style={{ marginTop: "-5px" }}>
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`} primary={expData.companyName} />
                                                </div>
                                                <div className="flexCard " style={{ marginTop: "-5px" }}>
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`} primary={expData.employmentType} />
                                                  <div className={`${styles.spacerSm}`}>|</div>
                                                  <ListItemText className={`${styles.listaiToolSubtitle}`} primary={expData.location} />
                                                </div>
                                              </Box>
                                            </ListItem>
                                          ))}
                                      </List>

                                      {/* <List>
                                      {experience.map((experience, index) => (
                                        <ListItem key={index} className="flexCard aignItemCenter" disablePadding>
                                          <ListItemIcon className={`${styles.listCardIcon}`}>
                                            <img src={FileIcon} alt="" />
                                          </ListItemIcon>
                                          <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                            <div className="fullWidth flexCard  justified-between">
                                              <ListItemText className={`${styles.listaiToolTitle}`} primary={experience.title} />
                                              <div className="flexCard">
                                                <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.startDate} /> -
                                                <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.endDate} />
                                              </div>
                                            </div>
                                            <div className="flexCard " style={{ marginTop: "-5px" }}>
                                              <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.company} />
                                            </div>
                                            <div className="flexCard " style={{ marginTop: "-5px" }}>
                                              <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.empType} />
                                              <div className={`${styles.spacerSm}`}>|</div>
                                              <ListItemText className={`${styles.listaiToolSubtitle}`} primary={experience.location} />
                                            </div>
                                          </Box>
                                        </ListItem>
                                      ))}
                                    </List> */}
                                    </Box>
                                  </div>
                                  {/*  Skills*/}
                                  <div className={`${styles.sectionDivider}`} ref={skillsRef}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Skills
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listAiTools}`}>
                                      <List>
                                        {talentDetails?.initTalentSkills &&
                                          talentDetails.initTalentSkills.map((skillData, index) => (
                                            <ListItem key={index} disablePadding>
                                              <ListItemText className={`${styles.listaiToolTitle}`} primary={skillData.name} />
                                            </ListItem>
                                          ))}
                                      </List>
                                    </Box>
                                  </div>
                                  {/*  Education*/}
                                  <div className={`${styles.sectionDivider}`}>
                                    <Box className="flexCard justified-between mb-3">
                                      <Typography variant="h4" className={`${styles.pageTalentTitle}`}>
                                        Education
                                      </Typography>
                                    </Box>
                                    <Box className={`${styles.listExperience}`}>
                                      <List>
                                        {talentDetails?.initTalentEducation &&
                                          talentDetails.initTalentEducation.map((education, index) => (
                                            <ListItem className="flexCard aignItemCenter justified-between" disablePadding key={index}>
                                              <div className="flexCard aignItemCenter">
                                                <ListItemIcon className={`${styles.listCardIcon}`}>
                                                  <img src={FileIcon} alt="" />
                                                </ListItemIcon>
                                                <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
                                                  <div className="fullWidth flexCard  justified-between">
                                                    <ListItemText className={`${styles.listaiToolTitle}`} primary={education.school} />
                                                  </div>
                                                  <div className={`${styles.listaiToolSubtitle} flexCard`} style={{ marginTop: "-5px" }}>
                                                    <ListItemText className={`${styles.listaiToolSubtitle}`}>
                                                      {education.degree} in {education.fieldOfStudy} in{" "}
                                                      {new Date(education.graduationDate).getFullYear()}
                                                    </ListItemText>
                                                  </div>
                                                </Box>
                                              </div>
                                            </ListItem>
                                          ))}
                                      </List>
                                      <div ref={educationRef} style={{ height: "150px" }}></div>
                                    </Box>
                                  </div>
                                </div>
                              </CustomTabPanel>
                              {/* <CustomTabPanel value={value} index={1}>
                                Ai Tools
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={2}>
                                Experince
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={3}>
                                Skills
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={4}>
                                Education
                              </CustomTabPanel>
                              <CustomTabPanel value={value} index={5}>
                                Education
                              </CustomTabPanel> */}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </>
          )}
        </Box>
      </Box>
      <Dialog className={`videoPreviewModal`} open={openDocPreview} onClose={handleCloseDocPreview} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCloseDocPreview} />
          </IconButton>
          <Box className={`docImgBox`}>
            <Box className={`docImgBox`}>{videoId ? <YouTube videoId={videoId} opts={opts} /> : "No Video Uploaded Yet!"}</Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
