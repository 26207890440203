import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectListByStatusAPI } from "../../../reducers/intialQuerySlice";
import { formatDate } from "../../../utils/helper";
import { styled } from "@mui/material/styles";

import {
  Card,
  Grid,
  TableContainer,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Box,
} from "@mui/material";

import styles from "./new-request.module.css";
// import btnStyles from "../../../assets/button.module.css";
import Users from "../../../assets/icons/usertab.svg";
import Eye from "../../../assets/icons/eyetab.svg";
// import team from "../../../assets/images/team1.png";
import Loader from "../../../components/common/Loader";
import { useNavigate } from "react-router-dom";
import { AssignPopUp, TeamListPopUp } from "../../../components/adminPanel/talentsPopUp";
import { talentsListAPI } from "../../../reducers/talentSlice";

import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    borderBottom: "0 !important",
    opacity: ".6",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "0 !important",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function NewRequests() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openTalent, setOpenTalent] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const defaultSelectOption = { _id: "", name: "Select" };
  const [selectedTeam, setSelectedTeam] = useState(defaultSelectOption);

  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedPriority1Talent, setSelectedPriority1Talent] = useState(null);
  const [selectedPriority2Talent, setSelectedPriority2Talent] = useState(null);

  const isMounted = useRef(false);
  const navigate = useNavigate();

  const { projectsListingByStatus, loading } = useSelector((state) => state.intialQuery);
  // const { talentsList } = useSelector((state) => state.talents);

  useEffect(() => {
    if (!isMounted.current) {
      const apiBody = {
        status: "pending",
        pageNo: "1",
        // search: "",
      };
      dispatch(projectListByStatusAPI(apiBody));
      isMounted.current = true;
    }
  }, [dispatch]);

  // useEffect(() => {

  // }, [selectedTeam, selectedPriority, selectedPriority1Talent, selectedPriority2Talent]);

  const handleClickOpen = (id) => {
    setSelectedProjectId(id);
    dispatch(talentsListAPI());
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedTeam(defaultSelectOption);
    setSelectedPriority(null);
    setSelectedPriority1Talent(null);
    setSelectedPriority2Talent(null);
    setOpen(false);
    localStorage.setItem("isHidden", "false");
    setDeleteAssignedTalent("true");
  };

  const handleTalentOpen = (priority) => {
    setSelectedPriority(priority);
    setIsHidden(isHidden);
    setOpenTalent(true);
  };

  const handleTalentClose = () => {
    // setSelectedPriority1Talent(null);
    // setSelectedPriority2Talent(null);
    setSelectedTeam(defaultSelectOption);
    setDeleteAssignedTalent("true");
    setSelectedPriority(null);
    setOpenTalent(false);
    localStorage.setItem("isHidden", false);
  };

  const handleChange = (event, newValue) => {
    if (newValue && newValue._id !== "") {
      // const selectedTalentId = newValue._id;
      setSelectedTeam(newValue);
    }
  };

  const handlePreview = (id) => {
    if (id) {
      navigate(`/preview/${id}`);
    }
  };

  const handleAssignTalent = () => {
    localStorage.setItem("isHidden", "true");
    setDeleteAssignedTalent("true");
    if (selectedPriority === "priority-1") {
      setSelectedPriority1Talent(selectedTeam);
    } else {
      setSelectedPriority2Talent(selectedTeam);
    }
    // const talent = talentsList.find((talent) => talent.name === name);
    setOpenTalent(false);
  };
  const [deleteAssignedTalent, setDeleteAssignedTalent] = useState("true");
  const isHiddenValue = localStorage.getItem("isHidden");

  const removeAssignedTalent = () => {
    setDeleteAssignedTalent("false");
    setSelectedTeam(defaultSelectOption);
    setSelectedPriority1Talent(null);
  };

  return (
    <>
      {/* <Box
        className={`contentArea ${styles.noMargin}`}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      > */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Card className="panelCard mt-3">
            {projectsListingByStatus?.projects && projectsListingByStatus?.projects.length > 0 ? (
              <>
                {" "}
                <Grid container>
                  <Grid container className="panelCard py-3" style={{ alignItems: "center" }}>
                    <Grid item xs={12} sm={5} md={8}>
                      <Typography variant="h4" className={`panelHeading`}>
                        Requests
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={4} className={`${styles.buttonAlignment}`}>
                      {/* <Box className={`${styles.searchBar}`}>
                 <TextField id="outlined-basic" variant="outlined" placeholder="Search" autoComplete="off" className={`${styles.searchInput}`} />
                 <SearchIcon className={`${styles.inputIcon}`} />
               </Box> */}
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <TableContainer>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>Customer Name</StyledTableCell>
                            <StyledTableCell>Start Date</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell> Pricing Plan </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>Created on </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projectsListingByStatus?.projects?.map((info) => {
                            const { _id, name, created_at, startDate, plan, status, userInfo } = info;

                            return (
                              <StyledTableRow key={_id}>
                                <StyledTableCell>{name}</StyledTableCell>
                                <StyledTableCell>{userInfo[0]?.name}</StyledTableCell>
                                <StyledTableCell>{formatDate(startDate)}</StyledTableCell>
                                <StyledTableCell>
                                  <span className={`${styles.badge} ${styles.newBadge}`}>{status === "pending" ? "New Request" : ""}</span>
                                </StyledTableCell>
                                <StyledTableCell>{plan.type ? `${plan.type} $${plan.price}.00` : ""}</StyledTableCell>
                                <StyledTableCell>{formatDate(created_at)}</StyledTableCell>
                                <StyledTableCell>
                                  <div className={`${styles.buttonGroup}`}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className={`${styles.eyeIconbtn} ${styles.tableAction}`}
                                      onClick={() => handlePreview(_id)}
                                    >
                                      <img src={Eye} alt={"eyeIcon"} />
                                    </Button>
                                    <Button
                                      onClick={() => handleClickOpen(_id)}
                                      variant="contained"
                                      size="small"
                                      className={`${styles.userIconbtn} ${styles.tableAction}`}
                                    >
                                      <img src={Users} alt={"userIcon"} />
                                    </Button>
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                          {/* <StyledTableRow>
                       <StyledTableCell>name</StyledTableCell>
                       <StyledTableCell>Sumit</StyledTableCell>
                       <StyledTableCell>startDate</StyledTableCell>
                       <StyledTableCell>
                         <span className={`${styles.badge} ${styles.newBadge}`}>New Request</span>
                       </StyledTableCell>
                       <StyledTableCell>Expert $199.00</StyledTableCell>
                       <StyledTableCell>created_at</StyledTableCell>
                       <StyledTableCell>
                         <div className={`${styles.buttonGroup}`}>
                           <Button variant="contained" size="small" className={`${styles.eyeIconbtn} ${styles.tableAction}`}>
                             <img src={Eye} alt={"eyeIcon"} />
                           </Button>
                           <Button
                             onClick={handleClickOpen}
                             variant="contained"
                             size="small"
                             className={`${styles.userIconbtn} ${styles.tableAction}`}
                           >
                             <img src={Users} alt={"userIcon"} />
                           </Button>
                         </div>
                       </StyledTableCell>
                     </StyledTableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {open ? (
                    <AssignPopUp
                      open={open}
                      handleClose={handleClose}
                      handleTalentOpen={handleTalentOpen}
                      selectedPriority1Talent={selectedPriority1Talent}
                      selectedPriority2Talent={selectedPriority2Talent}
                      selectedProjectId={selectedProjectId}
                      deleteAssignedTalent={deleteAssignedTalent}
                      isHiddenValue={isHiddenValue}
                      removeAssignedTalent={removeAssignedTalent}
                    />
                  ) : null}
                  {openTalent ? (
                    <TeamListPopUp
                      handleTalentClose={handleTalentClose}
                      openTalent={openTalent}
                      handleChange={handleChange}
                      selectedTeam={selectedTeam}
                      handleAssignTalent={handleAssignTalent}
                      selectedPriority={selectedPriority}
                      selectedPriority1Talent={selectedPriority1Talent}
                      selectedPriority2Talent={selectedPriority2Talent}
                      defaultSelectOption={defaultSelectOption}
                    />
                  ) : null}
                </Grid>
              </>
            ) : (
              <>
                <Box className="emptyStateBox">
                  <DataNotFound icon={"record"} title={"No New Request Found!"} subtitle={""} />
                </Box>
              </>
            )}
          </Card>
        </>
      )}
      {/* </Box> */}
    </>
  );
}
