// reducers/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  customerProjectRemoveAPI,
  deleteProjectAdminAPI,
  getProjectDetailAPI,
  postInitialQueryAPI,
  postProjectDetailAPI,
  postProjectListByStatusAPI,
  updateProjectDocAPI,
  updateProjectStatusAPI,
  updateProjectTitleAPI,
} from "../services/api/initial-query-api";
import { openSnackbar } from "./snackbarSlice";
// import { useSelector } from "react-redux";

const initialState = {
  loading: false,
  initProjectTitle: [],
  initProjectDescription: "",
  initProjectDeliverable: {},
  initProjectSkills: {},
  initProjectAi: [],
  projectDetail: {},
  projectsListing: [],
  projectsListingByStatus: {},
  createdProjectId: "",
  projectDetailsToViewAdmin: {},
  temporaryApiDoc: [],
  docLoading: false,
};

const intialQuerySlice = createSlice({
  name: "intialQuery",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    setInitialQuery: (state, action) => {
      const { assistantMsgParam, skillResponseList, deliverableResponseList, aiTools } = action.payload;
      return {
        ...state,
        initProjectDescription: assistantMsgParam,
        initProjectDeliverable: deliverableResponseList,
        initProjectSkills: skillResponseList,
        initProjectAi: aiTools,
      };
    },
    projectDetailSuccess: (state, action) => {
      state.projectDetail = action.payload;
    },
    projectsListingSuccess: (state, action) => {
      state.projectsListing = action.payload;
    },
    projectsListingByStatusSuccess: (state, action) => {
      state.projectsListingByStatus = action.payload;
    },
    setProjectDetailToPreview: (state, action) => {
      //for admin
      state.projectDetailsToViewAdmin = action.payload;
    },
    setTemporaryDoc: (state, action) => {
      // state.temporaryApiDoc = action.payload;
      state.temporaryApiDoc = [...state.temporaryApiDoc, ...action.payload];
    },
    setTemporaryDocEmpty: (state, action) => {
      state.temporaryApiDoc = action.payload;
    },
    isDocLoading(state) {
      state.docLoading = true;
    },
    finishDocLoading(state) {
      state.docLoading = false;
    },
  },
});

export const {
  setInitialQuery,
  isLoading,
  finishLoading,
  projectDetailSuccess,
  projectsListingSuccess,
  projectsListingByStatusSuccess,
  setProjectDetailToPreview,
  setTemporaryDoc,
  setTemporaryDocEmpty,
  isDocLoading,
  finishDocLoading,
} = intialQuerySlice.actions;

export const initialQueryAPI = (request) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await postInitialQueryAPI(request);

    if (response.status === 201) {
      localStorage.removeItem("assistantMsgParam");
      localStorage.removeItem("skillResponseList");
      localStorage.removeItem("deliverableResponseList");
      localStorage.removeItem("aiTools");
      localStorage.removeItem("plan");
      // localStorage.setItem("projectId", response.data.result._id);
      dispatch(finishLoading());
      const projectId = response.data.result[0]._id;
      return projectId;

      // return response.data.result._id;
    }
  } catch (error) {
    console.log(error);
    dispatch(finishLoading());
    throw error;
  }
};

export const projectDetailAPI = (request) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await postProjectDetailAPI(request);

    if (response.status === 200) {
      dispatch(projectDetailSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishLoading());
};

export const projectListAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getProjectDetailAPI(request);
    if (response.status === 200) {
      dispatch(projectsListingSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishLoading());
};

export const projectListByStatusAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postProjectListByStatusAPI(request);

    if (response.status === 200) {
      dispatch(projectsListingByStatusSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

export const projectTitleAPI = (request) => async (dispatch) => {
  // dispatch(isLoading());
  try {
    const response = await updateProjectTitleAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(projectDetailSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const projectDocAPI = (request) => async (dispatch) => {
  dispatch(isDocLoading());
  try {
    const response = await updateProjectDocAPI(request);
    if (response.status === 201) {
      dispatch(setTemporaryDoc(response.data.result));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    dispatch(finishDocLoading());
  }
};

export const projectStatusAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await updateProjectStatusAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(projectDetailSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    dispatch(finishLoading());
  }
};

export const projectDeleteAdminAPI = (request, currentTab) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await deleteProjectAdminAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      const apiBody = {
        status: currentTab,
        pageNo: "1",
        // search: "",
      };
      await dispatch(projectListByStatusAPI(apiBody));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

/********* DELETE NOT SUBMITTED PROJECT GD ********** */

export const customerProjectRemove = (request, setOpenCompleted, navigate) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await customerProjectRemoveAPI(request);
    if (response.status === 201) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      setOpenCompleted(false);
      navigate("/projects");
    }
  } catch (error) {
    console.log(error);
  } finally {
    //dispatch(finishLoading());
  }
};

export default intialQuerySlice.reducer;
