/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CssBaseline, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import styles from "./adminDashboard.module.css";
// import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminProjectCard from "../../../components/adminPanel/projectCard";
import { useDispatch, useSelector } from "react-redux";
import { projectListByStatusAPI } from "../../../reducers/intialQuerySlice";
import Loader from "../../../components/common/Loader";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminDashboard() {
  const isLGScreen = useMediaQuery("(max-width: 767px)");
  const [value, setValue] = useState(0);
  // const isMounted = useRef(false);
  const dispatch = useDispatch();
  const tabLabels = ["all", "in-progress", "overdue", "completed"];

  // useEffect(() => {

  // }, [value]);

  const { projectsListingByStatus, loading } = useSelector((state) => state.intialQuery);

  useEffect(() => {
    const apiBody = {
      status: tabLabels[value],
      pageNo: "1",
      // search: "",
    };
    dispatch(projectListByStatusAPI(apiBody));
  }, [value, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // const tabLabels = ["all", "in-progress", "overdue", "completed"];

    // setValue(tabLabels[newValue]);
  };

  return (
    <>
      <CssBaseline />
      <Grid container style={{ marginBottom: "35px" }}>
        <Grid item sm={12} md={6}>
          <Box className={`${styles.flexTitleInput}`}>
            <Typography variant="h4" className={`panelHeading`}>
              Welcome!
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={6} className={`${styles.btnAligns}`}></Grid>
      </Grid>
      <Card className={`panelCard mt-3`}>
        <Grid container style={{ marginBottom: "35px", alignItems: " center" }}>
          <Grid item sm={12} md={9} className="fullWidth">
            <Box className={`${styles.flexTitleInput}`}>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    variant={isLGScreen ? "fullWidth" : "scrollable"}
                    scrollButtons={isLGScreen ? "" : "auto"}
                    className="adminTabs"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="In Progress" {...a11yProps(1)} />
                    <Tab label="Overdue" {...a11yProps(2)} />
                    <Tab label="Completed" {...a11yProps(3)} />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={3} className={`${styles.btnAligns}`}>
            {/* <Box className={`${styles.searchBar}`}>
              <TextField id="outlined-basic" variant="outlined" placeholder="Search" autoComplete="off" className={`${styles.searchInput}`} />
              <SearchIcon className={`${styles.inputIcon}`} />
            </Box> */}
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: "35px", alignItems: " center" }}>
          <Grid item sm={12} md={12}>
            <CustomTabPanel value={value} index={0} className={`tablisting ${styles.tablist}`}>
              {/* <Typography variant="h4" className={`panelHeading mb-3`}>
                All Projects
              </Typography> */}
              {loading ? <Loader /> : <AdminProjectCard projectsList={projectsListingByStatus} currentTab={tabLabels[value]} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} className={`tablisting ${styles.tablist}`}>
              {/* <Typography variant="h4" className={`panelHeading mb-3`}>
                In Progress
              </Typography> */}
              {loading ? <Loader /> : <AdminProjectCard projectsList={projectsListingByStatus} currentTab={tabLabels[value]} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} className={`tablisting ${styles.tablist}`}>
              {/* <Typography variant="h4" className={`panelHeading mb-3`}>
                Overdue Projects
              </Typography> */}
              {loading ? <Loader /> : <AdminProjectCard projectsList={projectsListingByStatus} currentTab={tabLabels[value]} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3} className={`tablisting ${styles.tablist}`}>
              {/* <Typography variant="h4" className={`panelHeading mb-3`}>
                Completed Projects
              </Typography> */}
              {loading ? <Loader /> : <AdminProjectCard projectsList={projectsListingByStatus} currentTab={tabLabels[value]} />}
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
