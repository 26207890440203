/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, useMediaQuery } from "@mui/material";

import AdminPanelNavbar from "../components/common/navbar/adminPanelNavbar";
import PanelNavbar from "../components/common/navbar/panelNavbar";
import TalentNavbar from "../pages/talent/navbar/TalentNavbar";
import Sidebar from "../components/common/sidebar";
import { useDispatch } from "react-redux";
import { currentDeliverableStatus, talentNewRequestAPI } from "../reducers/talentProjectSlice";
import { deliverableStatusProjectAPI } from "../reducers/deliverablesSlice";
import RyaNavbar from "../pages/talent/ryabot/ryanavbar";

function ProtectedRoute({ allowedRoles }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const isLGScreen = useMediaQuery("(max-width: 1024px)");
  const isXLScreen = useMediaQuery("(max-width: 1200px)");
  const contentWidth = isLGScreen ? "calc(100% - 200px)" : isXLScreen ? "calc(100% - 220px)" : "calc(100% - 260px)";

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role === "talent") {
      dispatch(talentNewRequestAPI());
    }
    if (role === "user") {
      dispatch(deliverableStatusProjectAPI());
    }
  }, [dispatch, location, role]);
  dispatch(currentDeliverableStatus(""));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const NavbarComponent = role === "admin" ? AdminPanelNavbar : role === "user" ? PanelNavbar : TalentNavbar;
  const isRya = localStorage.getItem("isRyaClick");

  return (
    <>
      {allowedRoles.includes(role) ? (
        <Box
          className={`${isRya === "true" ? "ryaContentArea" : "contentArea"}`}
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: contentWidth,
          }}
          style={{ position: "relative" }}
        >
          <CssBaseline />
          {isRya === "true" ? (
            <RyaNavbar />
          ) : (
            <>
              <NavbarComponent handleDrawerToggle={handleDrawerToggle} />
              <Sidebar role={role} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            </>
          )}

          <Box>
            <Outlet />
          </Box>
        </Box>
      ) : (
        <Navigate to="/unauthorised" replace />
      )}
    </>
  );
}

export default ProtectedRoute;
