/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DocPreviewModal({ handleCloseDocPreviewModal, selectedDocPathPreview, openDocPreviewModal }) {
  return (
    <Dialog className={`docPreviewModal`} open={openDocPreviewModal} onClose={handleCloseDocPreviewModal} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <IconButton className={`popupCrossBtn`}>
          <CloseIcon onClick={handleCloseDocPreviewModal} />
        </IconButton>
        <Box className={`docImgBox`} style={{ height: "100vh", width: "100%" }}>
          <iframe src={selectedDocPathPreview} alt="" style={{ height: "100vh", width: "100%" }} />
          {/* <img src={selectedDocPathPreview} alt=""></img> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DocPreviewModal;
