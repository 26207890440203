/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CssBaseline, Grid, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import styles from "./projects.module.css";
// import styles1 from "./projectCard.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { useDispatch } from "react-redux";
// import { projectListByStatusAPI } from "../../../reducers/intialQuerySlice";
import Sidebar from "../../../components/common/sidebar";
// import PanelNavbar from "../navbar/panelNavbarTalent";
// import ListIcon from "@mui/icons-material/List";

// import NoTalent from "../../../assets/icons/notalent.svg";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { talentProjectListByStatusAPI } from "../../../reducers/talentProjectSlice";
import TalentProjectCard from "./TalentProjectCard";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentProjects() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const { loading, talentProjectListByStatus } = useSelector((state) => state.talentProject);

  const dispatch = useDispatch();

  const isLGScreen = useMediaQuery("(max-width: 1024px)");
  const tabLabels = ["all", "in-progress", "overdue", "completed"];

  useEffect(() => {
    const apiBody = {
      status: tabLabels[value],
      // pageNo: "1",
      // search: "",
    };
    dispatch(talentProjectListByStatusAPI(apiBody));
  }, [value, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        <Sidebar />
        <Box>
          <Card className={`panelCard panelCardFullHeight`}>
            <Grid container style={{ marginBottom: "15px", alignItems: " center" }}>
              <Grid item sm={12} md={9} className="fullWidth">
                <Box className={`${styles.flexTitleInput}`}>
                  <Box sx={{ width: "100%" }}>
                    <Box>
                      <Tabs
                        variant={isLGScreen ? "fullWidth" : "scrollable"}
                        scrollButtons={isLGScreen ? "" : "auto"}
                        className="adminTabs"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label={t("textAll")} {...a11yProps(0)} />
                        <Tab label={t("textInProgress")} {...a11yProps(1)} />
                        <Tab label={t("textOverdue")} {...a11yProps(2)} />
                        <Tab label={t("textCompleted")} {...a11yProps(3)} />
                      </Tabs>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md={3} className={`${styles.btnAligns}`}>
                <Box className={`${styles.searchBar}`}>
                  <TextField id="outlined-basic" variant="outlined" placeholder="Search" autoComplete="off" className={`${styles.searchInput}`} />
                  <SearchIcon className={`${styles.inputIcon}`} />
                </Box>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: "35px", alignItems: " center" }}>
              <Grid item sm={12} md={12}>
                <CustomTabPanel value={value} index={0} className={`tablisting ${styles.tablist}`}>
                  {/* <Typography variant="h4" className={`${styles.tabHeading} mb-3`}>
                    All Projects
                  </Typography>{" "} */}
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} lg={4}>
                      <Link to="/talent-projectDetail">
                        <Card className={`${styles1.pendingCard} ${styles1.projectCard}`}>
                          <CardContent className={`${styles1.projectContent}`}>
                            <Box className={`${styles1.projectCardHead} ${styles1.flexBox}`}>
                              <div className={`${styles1.flexBox} `}>
                                <span className={`${styles1.badge} ${styles1.Pending}`}>Pending</span>
                              </div>
                            </Box>
                            <Box className={`${styles1.projectCardBody}`}>
                              <Box className={`${styles1.projectInfo} ${styles1.flexBox} ${styles1.flexStart}`}>
                                <CardMedia className={`${styles1.projectThumb}`}>
                                  <img src={DummyImg} alt="" />
                                </CardMedia>
                                <CardContent className={`${styles1.projectDesc}`}>
                                  <Typography variant="h4" className={`${styles1.projectName}`}>
                                    Build a website for Pharmacy
                                  </Typography>
                                  <Typography variant="p" className={`${styles1.projectDetail}`}>
                                    It will be as simple as occidental in fact, it will be Occidental.
                                  </Typography>
                                </CardContent>
                              </Box>
                              <Box className={`${styles1.tasksCard}`}>
                                <div className={`${styles1.flexBox} ${styles1.tasksBox}`}>
                                  <span>Deliverables</span>
                                  <span className={`${styles1.flexBox}`} style={{ gap: "0" }}>
                                    <ListIcon />
                                    {`0/5`}
                                  </span>
                                </div>
                              </Box>
                              <Box>
                                <div className={`${styles1.flexBox} ${styles1.userBox}`}>
                                  <div className={`${styles1.flexBox}`}>
                                    <CardMedia className={`${styles1.userBoxImg}`}>
                                      <img src={NoTalent} alt="" />
                                    </CardMedia>
                                  </div>
                                  <div className={`${styles1.flexBox} ${styles1.dateOfProject}`} style={{ gap: "0" }}>
                                    <CalendarMonthIcon />1 Nov, 2023
                                  </div>
                                </div>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid> */}
                    {loading ? <Loader /> : <TalentProjectCard projectsList={talentProjectListByStatus} currentTab={tabLabels[value]} />}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} className={`tablisting ${styles.tablist}`}>
                  {/* <Typography variant="h4" className={`${styles.tabHeading} mb-3`}>
                    In Progress
                  </Typography>{" "} */}
                  <Grid container spacing={2}>
                    {loading ? <Loader /> : <TalentProjectCard projectsList={talentProjectListByStatus} currentTab={tabLabels[value]} />}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} className={`tablisting ${styles.tablist}`}>
                  {/* <Typography variant="h4" className={`${styles.tabHeading} mb-3`}>
                    Overdue Projects
                  </Typography> */}
                  <Grid container spacing={2}>
                    {loading ? <Loader /> : <TalentProjectCard projectsList={talentProjectListByStatus} currentTab={tabLabels[value]} />}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3} className={`tablisting ${styles.tablist}`}>
                  {/* <Typography variant="h4" className={`${styles.tabHeading} mb-3`}>
                    Completed Projects
                  </Typography> */}
                  <Grid container spacing={2}>
                    {loading ? <Loader /> : <TalentProjectCard projectsList={talentProjectListByStatus} currentTab={tabLabels[value]} />}
                  </Grid>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
    </>
  );
}
