import { useSelector, useDispatch } from "react-redux";
import { closeSnackbar } from "../../../reducers/snackbarSlice";
import SnackbarComponent from "./index";

const GlobalSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarState = useSelector((state) => state.snackbar);

  const handleCloseSnackbar = () => {
    dispatch(closeSnackbar());
  };

  return (
    <SnackbarComponent
      open={snackbarState.open}
      message={snackbarState.message}
      severity={snackbarState.severity}
      handleClose={handleCloseSnackbar}
    />
  );
};

export default GlobalSnackbar;
