import { useState, useRef, useEffect } from "react";
import { Box, Button, Card, CardContent, CardMedia, Grid, TextField } from "@mui/material";
import styles from "./chatroom.module.css";
import btnStyles from "../../../assets/button.module.css";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { instructions } from "../../../utils/instructions";
import { useTranslation } from "react-i18next";

export default function ChatRoom() {
  const { t } = useTranslation();
  const [inputData, setInputData] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const buttonRef = useRef(null);
  const [lastAssistantMessage, setLastAssistantMessage] = useState("");

  const isMounted = useRef(false);
  const [apiResponded, setApiResponded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const inputDataParam = location.state?.inputData || "";
    const pageTypeParam = location.state?.pageType || "";

    const tagSystemContentParam = location.state?.tagInputSystemContent || "";

    if (inputDataParam && !isMounted.current) {
      handleButtonClick(inputDataParam, pageTypeParam, tagSystemContentParam);

      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = "https://api.openai.com/v1/chat/completions";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_AUTH_KEY}`,
  };

  const chatHistoryRef = useRef(null);

  const handleInputChange = (event) => {
    setInputData(event.target.value);
  };

  const handleButtonClick = async (paramData, type, tagContentParam) => {
    try {
      const userMessage = {
        role: "user",
        //content: paramData,
        // Check if tagContentParam is not empty. If it's not, use it; otherwise, use paramData.
        content: tagContentParam ? tagContentParam : paramData,
      };
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      setInputData("");
      setLoading(true);

      let systemContent = "";
      if (type === "home") {
        systemContent = instructions["home"]?.default;
      } else if (type === "tag") {
        systemContent = instructions["home"]?.default; //tagContentParam; // This doesnt get used anymore but making it consistent
      } else {
        systemContent = instructions["result"]?.default;
      }

      const messages = [
        {
          role: "system",
          content: systemContent,
        },
        ...chatHistory.map((message) => ({
          role: message.role,
          content: message.content,
        })),
        { role: "user", content: paramData },
      ];

      const data = {
        model: "gpt-3.5-turbo",
        messages,
      };
      const response = await axios.post(url, data, { headers });
      const botResponse = response.data.choices[0].message;
      setLastAssistantMessage(botResponse.content);

      setChatHistory((prevChatHistory) => [...prevChatHistory, { role: "assistant", content: botResponse.content }]);
      setLoading(false);
      setApiResponded(true);
    } catch (error) {
      console.error("Error fetching API:", error);
      setLoading(false);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!loading && inputData.trim() !== "") {
      handleButtonClick(inputData, "");
    }
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleGreatButtonClick = () => {
    navigate("/preview", {
      state: { lastAssistantMessage, pageType: "preview" },
    });
  };
  const handleBacktoHomeClick = () => {
    navigate("/");
  };
  return (
    <>
      <Grid container className={`container ${styles.pagePagination} ${styles.textWhite} ${styles.borderBottom} ${styles.spaceY} `}>
        <Grid item xs={6} md={6}>
          <Button
            className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm} conditionalClass`}
            disabled={loading || !apiResponded}
            ref={buttonRef}
            onClick={handleBacktoHomeClick}
            // disabled={loading || !apiResponded}
          >
            <ArrowBackIcon />
            <span>{t("textBack")}</span>
          </Button>
        </Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
          <Button
            className={`${btnStyles.yinBtnSm} ${btnStyles.yinSecondaryBtn} ${btnStyles.yinSqCornerRev} conditionalClass`}
            disabled={loading || !apiResponded} // Disable the button if the API hasn't responded
            ref={buttonRef}
            onClick={handleGreatButtonClick}
          >
            <span className={`${btnStyles.btnText}`}>
              <span className={`${btnStyles.btnTitle}`}> {t("textDone")}</span>
            </span>
            <ArrowBackIcon style={{ transform: "rotate(-180deg)" }} />
          </Button>
        </Grid>
      </Grid>
      <Grid container className={`container ${styles.meesageBody}`} ref={chatHistoryRef}>
        {chatHistory.map((message, index) => (
          <Grid item key={index} sm={12} className={message.role === "user" ? `${styles.queryBox}` : `${styles.botBox}`}>
            <CardMedia className={message.role === "user" ? `${styles.queryIcon}` : `${styles.botIcon}`}>
              {message.role === "user" ? "U" : "A"}
            </CardMedia>
            <Card className={message.role === "user" ? `${styles.queryMessage}` : `${styles.botMessage}`}>
              <CardContent>
                <pre className={`${styles.noWhiteSpace}`}>{message.content}</pre>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className={`${styles.contianerBg} `}>
        <Grid container className={`container ${styles.textWhite} `} style={{ alignItems: "center" }}>
          <Grid item sm={12} md={12} className={`${styles.fullWidth}`}>
            {loading && (
              <div className={`${styles.assistanTpingLoader}`}>
                {t("textTyping")}
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            )}

            <div className={`${styles.flexCard} ${styles.bottomBarDiv}`}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                className={` transparentInput ${styles.searchQueryForm} ${loading ? styles.disabledBox : ""}`}
                onSubmit={handleFormSubmit} // Add onSubmit event handler to the form
              >
                <TextField
                  id="outlined-required"
                  placeholder="Type your query.."
                  className={`${styles.searchQueryInput}`}
                  value={inputData}
                  onChange={handleInputChange}
                />
                <Button
                  className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm} conditionalClass`}
                  onClick={() => handleButtonClick(inputData, "")}
                  disabled={loading || inputData.trim() === ""}
                  ref={buttonRef}
                >
                  <span className={`${btnStyles.btnText}`}>
                    <span className={`${btnStyles.btnTitle}`}>{t("textSend")} </span>
                    <span className={`${btnStyles.btnIcon}`}>
                      <SendIcon />
                    </span>
                  </span>
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
