/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./messages.module.css";
import btnStyles from "../../../assets/button.module.css";
import { Link } from "react-router-dom";
// import docImg from "../../../assets/images/image53.png";
import downloadImg from "../../../assets/icons/downloadCircle.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useRef, useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  supportListAPI,
  supportTicketCommentAPI,
  supportTicketCommentListAPI,
  supportTicketInfoAPI,
  supportTicketStatusUpdateAPI,
} from "../../../reducers/supportSlice";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { useTranslation } from "react-i18next";
// import Loader from "../../../components/common/loader";
// import Loader from "../../../components/common/loader";

import CloseIcon from "@mui/icons-material/Close";
export default function Messages(props) {
  const { t } = useTranslation();
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  // const [setInfoLoading] = useState(false);
  const [attachedDoc, setAttachedDoc] = useState([]);
  const [attachedDocPreview, setAttachedDocPreview] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [selectedTickedId, setSelectedTicketId] = useState("");
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [open, setOpen] = useState(false);
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { supportList, supportTicketInfo, supportTicketCommentList } = useSelector((state) => state.support);
  const { loading } = useSelector((state) => state.support);

  const loggedUserName = localStorage.getItem("name");
  const loggedUserEmail = localStorage.getItem("email");
  const loggedUserPhoto = localStorage.getItem("profile_picture");

  const userRoleFromLocal = localStorage.getItem("role");
  const loggedInUserId = localStorage.getItem("userId");

  let loggedTitle;
  if (loggedUserPhoto === "null" || loggedUserPhoto === "undefined") {
    if (loggedUserName === "null" || loggedUserName === "undefined") {
      loggedTitle = <span>{loggedUserEmail.charAt(0)}</span>;
    } else {
      loggedTitle = <span>{loggedUserName.charAt(0)}</span>;
    }
  } else {
    loggedTitle = <img src={loggedUserPhoto} alt={loggedUserPhoto} />;
  }
  console.log(loggedTitle);
  useEffect(() => {
    if (isMounted.current === false) {
      // const request = {
      //   pageNo: "1",
      // };
      dispatch(supportListAPI("1", userRoleFromLocal));
      isMounted.current = true;
    }
  }, [dispatch]);

  const fetchData = async () => {
    const selectedTicket = supportList.result[activeMessageIndex];

    const { _id } = selectedTicket;

    try {
      // setInfoLoading(true);
      const request = { pageNo: "1", ticketId: _id };
      await Promise.all([dispatch(supportTicketInfoAPI(_id, userRoleFromLocal)), dispatch(supportTicketCommentListAPI(request, userRoleFromLocal))]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setInfoLoading(false);
      setEditorContent("");
      setAttachedDoc([]);
      setAttachedDocPreview([]);
      setSelectedTicketId(_id);
    }
  };

  useEffect(() => {
    if (supportList && supportList.result && activeMessageIndex >= 0 && activeMessageIndex < supportList.result.length) {
      fetchData();
    }
  }, [activeMessageIndex, supportList]);

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const previewImages = [];
      const images = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onloadend = () => {
          // Push the reader result (image data URL) to the previewImages array
          previewImages.push(reader.result);
          images.push(file);

          // If we have processed all files, update the state with the array of preview images
          if (previewImages.length === files.length) {
            setAttachedDocPreview([...attachedDocPreview, ...previewImages]);
            setAttachedDoc([...attachedDoc, ...images]);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleImageDelete = (index) => {
    const newAttachedDoc = [...attachedDoc];
    const newAttachedDocPreview = [...attachedDocPreview];

    newAttachedDoc.splice(index, 1);
    newAttachedDocPreview.splice(index, 1);

    setAttachedDoc(newAttachedDoc);
    setAttachedDocPreview(newAttachedDocPreview);
  };

  const handleDiscard = () => {
    setEditorContent("");
    setAttachedDoc([]);
    setAttachedDocPreview([]);
  };

  const handleCommentSubmit = () => {
    const request = {
      file: attachedDoc,
      ticketId: selectedTickedId,
      comment: editorContent,
    };
    dispatch(supportTicketCommentAPI(request, handleDiscard, userRoleFromLocal));
  };

  const handleSeeMore = () => {
    setIsSeeMore(!isSeeMore);
  };

  const handleTicketStatus = async () => {
    const request = {
      status: "closed",
      ticketId: selectedTickedId,
    };
    await dispatch(supportTicketStatusUpdateAPI(request));
    setOpen(false);
  };

  const renderConfirmDailog = () => {
    return (
      // <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
      //   <DialogTitle className={`${styles.dialogTitle} `} id="responsive-dialog-title">
      //     Close Support Ticket
      //     <Typography className="mb-3">Are you sure you want to close the ticket?</Typography>
      //   </DialogTitle>{" "}
      //   <DialogActions>
      //     <Button autoFocus onClick={() => setOpen(false)} className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}>
      //       {t("textCancel")}
      //     </Button>
      //     <Button onClick={handleTicketStatus} autoFocus className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
      //       {t("textDone")}
      //     </Button>
      //   </DialogActions>
      // </Dialog>

      <Dialog className="DelcineWarningModal" open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <QuestionMarkIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={() => setOpen(false)} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">Are you sure to close the ticket!</Typography>
            <Typography className="popupSubTitle">Are you sure you want to close the ticket. </Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={() => setOpen(false)}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button onClick={handleTicketStatus} autoFocus className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                Confirm {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {supportList && supportList.result?.length > 0 ? (
        <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={8} md={6} lg={4}>
              <Box className={`${styles.flexTitleInput}`}>
                <Typography variant="h4" className={`panelHeading`}>
                  {t("textSupport")}
                </Typography>
              </Box>
            </Grid>
            {userRoleFromLocal === "user" ? (
              // Content for "user" role
              <Grid item xs={4} md={6} lg={8} className={`${styles.btnAligns}`}>
                <Link to="/create-ticket">
                  <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}> {t("textSupport")} </Button>
                </Link>
              </Grid>
            ) : userRoleFromLocal === "talent" ? (
              // Content for "talent" role
              <Grid item xs={4} md={6} lg={8} className={`${styles.btnAligns}`}>
                <Link to="/talent-ticket">
                  <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}> {t("textSupport")} </Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
          {/* {loading ? (
          <Loader />
        ) : ( */}
          <Grid container spacing={2}>
            <Grid item sm={12} md={5} lg={4}>
              <Card className={`panelCard mt-3`}>
                <List>
                  {supportList && supportList.result?.length > 0 ? (
                    supportList.result?.map((ticket, index) => {
                      const { _id, subject, message, updatedAt } = ticket;
                      const updateDate = new Date(updatedAt);

                      const options = {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      };

                      const formattedDate = updateDate.toLocaleDateString("en-US", options);
                      return (
                        <ListItem
                          key={_id}
                          className={`${styles.messageItem} ${activeMessageIndex === index ? styles.activeMsg : ""}`}
                          onClick={() => setActiveMessageIndex(index)}
                        >
                          {/* <ListItemIcon className={`${styles.discussIcon}`}>
                          <DiscussionIcon />
                        </ListItemIcon> */}
                          <ListItemText className={`${styles.discussionBody}`}>
                            <Typography variant="h4" className={`${styles.messageSubject}`}>
                              {subject}
                            </Typography>
                            <Typography variant="body" className={`${styles.messageSummary}`} dangerouslySetInnerHTML={{ __html: message }} />
                            {/* <Typography variant="body" className={`${styles.messageSummary}`}>
                            {message}
                          </Typography> */}

                            <Typography variant="h5" className={`${styles.messageDate}`}>
                              {formattedDate}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      );
                    })
                  ) : (
                    <p>{t("textDataNotFound")}</p>
                  )}
                </List>
              </Card>
            </Grid>
            {/* {infoLoading ? (
              <Loader />
            ) : ( */}
            <Grid item sm={12} md={7} lg={8}>
              <Card className={`panelCard mt-3 ${styles.noXsPadding}`}>
                <CardContent>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h4" className={`${styles.messageSubject} ${styles.messageDetailSubject}`}>
                      {supportTicketInfo[0]?.subject}
                    </Typography>
                    {supportTicketInfo[0]?.status && supportTicketInfo[0].status === "closed" ? (
                      <span className={`${styles.badge}  ${styles.Completed} }`}>Closed</span>
                    ) : null}
                  </div>
                  <Typography variant="body" className={`${styles.messageProject}`}>
                    <b>Project</b>: {supportTicketInfo[0]?.project[0]?.name}
                  </Typography>

                  {supportTicketInfo[0]?.deliverable[0]?.title ? (
                    <Typography variant="body" className={`${styles.messageProject}`}>
                      <b>Deliverable</b>: {supportTicketInfo[0].deliverable[0].title}
                    </Typography>
                  ) : null}

                  <Box className={`${styles.messageSummaryContainer}`}>
                    <Box className={`${styles.supportTicketInfo} ${isSeeMore ? styles.seeMore : ""}`}>
                      <Typography
                        variant="h5"
                        className={`${styles.messageSummary} ${isSeeMore ? styles.seeMore : ""}`}
                        dangerouslySetInnerHTML={{ __html: supportTicketInfo[0]?.message }}
                      ></Typography>
                      <List className={`${styles.supportTicketInfoDoc}`}>
                        <ListItem className={`${styles.attachmentBox}`}>
                          {supportTicketInfo[0]?.ticketdocuments && supportTicketInfo[0]?.ticketdocuments.length > 0
                            ? supportTicketInfo[0]?.ticketdocuments.map((filename, index) => {
                                const { path } = filename;
                                return (
                                  <a href={path} download key={index} target="_blank" rel="noreferrer">
                                    <Box className={`${styles.attachmentImgs}`}>
                                      <img src={path} alt="Document" />
                                      <Box className={`${styles.downloadIcon}`}>
                                        <img src={downloadImg} alt="Document" />
                                      </Box>
                                    </Box>
                                  </a>
                                );
                              })
                            : null}
                        </ListItem>
                      </List>
                    </Box>
                    <span className={`${styles.messageSummarySeeMore}`} onClick={handleSeeMore}>
                      {!isSeeMore ? t("textSeeMore") : t("textHide")}
                    </span>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box>
                    {supportTicketCommentList && supportTicketCommentList.result?.length > 0 ? (
                      supportTicketCommentList.result.map((msg, index) => {
                        const { userId, comment, updatedAt, ticketCommentdocuments } = msg;
                        const updateDate = new Date(updatedAt);
                        console.log("check USer", userId);
                        const options = {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        };

                        const formattedDate = updateDate.toLocaleDateString("en-US", options);
                        if (userId === loggedInUserId) {
                          return (
                            <Card className={`${styles.userDiscussion}`} key={index}>
                              <CardMedia>
                                {/* <Box className={`${styles.userDiscussionIcon}`}>{loggedTitle}</Box> */}
                                <Box className={userRoleFromLocal === "admin" ? `${styles.botDiscussionIcon}` : styles.userDiscussionIcon}>
                                  {userRoleFromLocal === "user" || userRoleFromLocal === "talent" ? loggedTitle : "Y"}
                                </Box>
                              </CardMedia>
                              <CardContent className={`${styles.userDiscussionBody}`}>
                                <Box className={`${styles.userDiscussionContent}`}>
                                  <Typography className={`${styles.userDiscussionMsg}`} dangerouslySetInnerHTML={{ __html: comment }} />

                                  <List>
                                    <ListItem className={`${styles.attachmentBox}`}>
                                      {ticketCommentdocuments && ticketCommentdocuments.length > 0
                                        ? ticketCommentdocuments.map((img, index) => {
                                            const { path } = img;
                                            return (
                                              <a href={path} download key={index} target="_blank" rel="noreferrer">
                                                <Box className={`${styles.attachmentImgs}`}>
                                                  <img src={path} alt="Document" />
                                                  <Box className={`${styles.downloadIcon}`}>
                                                    <img src={downloadImg} alt="Document" />
                                                  </Box>
                                                </Box>
                                              </a>
                                            );
                                          })
                                        : null}
                                    </ListItem>
                                  </List>
                                </Box>
                                <Box className={`${styles.userDiscussionFooter}`}>
                                  <Typography className={`${styles.userDiscussionDate}`}>{formattedDate}</Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          );
                        } else {
                          return (
                            <Card className={`${styles.botDiscussion}`} key={index}>
                              <CardMedia>
                                <Box className={userRoleFromLocal === "admin" ? `${styles.userDiscussionIcon}` : styles.botDiscussionIcon}>
                                  {userRoleFromLocal === "user" || userRoleFromLocal === "talent" ? (
                                    "Y"
                                  ) : supportTicketInfo[0]?.user[0]?.profilePicture ? (
                                    <img src={supportTicketInfo[0]?.user[0]?.profilePicture} alt={loggedUserPhoto} />
                                  ) : (
                                    <span>{supportTicketInfo[0]?.user[0]?.name?.charAt(0)}</span>
                                  )}
                                </Box>
                              </CardMedia>

                              <CardContent className={`${styles.botDiscussionBody}`}>
                                <Box className={`${styles.botDiscussionContent}`}>
                                  <Typography className={`${styles.botDiscussionMsg}`} dangerouslySetInnerHTML={{ __html: comment }} />
                                  <List>
                                    <ListItem className={`${styles.attachmentBox}`}>
                                      {ticketCommentdocuments && ticketCommentdocuments.length > 0
                                        ? ticketCommentdocuments.map((img, index) => {
                                            const { path } = img;
                                            return (
                                              <a href={path} download key={index} target="_blank" rel="noreferrer">
                                                <Box className={`${styles.attachmentImgs}`}>
                                                  <img src={path} alt="Document" />
                                                  <Box className={`${styles.downloadIcon}`}>
                                                    <img src={downloadImg} alt="Document" />
                                                  </Box>
                                                </Box>
                                              </a>
                                            );
                                          })
                                        : null}
                                    </ListItem>
                                  </List>
                                </Box>
                                <Box className={`${styles.botDiscussionFooter}`}>
                                  <Typography className={`${styles.botDiscussionDate}`}>{formattedDate}</Typography>
                                  {/* <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinTransparentBtn}`}>Reply</Button> */}
                                </Box>
                              </CardContent>
                            </Card>
                          );
                        }
                      })
                    ) : (
                      <Box className={`emptyStateBox  ${styles.noCommentBOx}`}>
                        <DataNotFound createProject={""} icon={"chat"} title={""} subtitle={t("textComentNotFound")} />
                      </Box>
                    )}
                    {supportTicketInfo[0]?.status && supportTicketInfo[0].status === "closed" ? null : (
                      <Card className={`${styles.editorCard} `}>
                        <CardContent className={`${styles.noXsPadding}`}>
                          <div className={`ckeditorContainer ${styles.editorBox}`}>
                            {/* <CKEditor editor={ClassicEditor} data="<p>Hello from CKEditor&nbsp;5!</p>" onReady={() => {}} /> */}
                            <CKEditor
                              style={{ minHeight: "300px" }}
                              editor={ClassicEditor}
                              onReady={() => {}}
                              data={editorContent}
                              onChange={(event, editor) => {
                                const content = editor.getData();
                                setEditorContent(content);
                              }}
                            />
                          </div>
                          {attachedDocPreview && attachedDocPreview.length > 0 && (
                            <List>
                              <ListItem className={`${styles.attachmentBox}`}>
                                {attachedDocPreview.map((image, index) => (
                                  <Box key={index} className={`${styles.attachmentImgs}`}>
                                    <img src={image} alt={`Document ${index + 1}`} />
                                    <Box className={`${styles.RemoveAttachIcon}`} onClick={() => handleImageDelete(index)}>
                                      <DeleteOutlineIcon />
                                    </Box>
                                  </Box>
                                ))}
                              </ListItem>
                            </List>
                          )}

                          {/* {previewImage && (
                        <List>
                          <ListItem className={`${styles.attachmentBox}`}>
                            <Box className={`${styles.attachmentImgs}`}>
                              <img src={previewImage} alt="Document" />
                              <Box className={`${styles.RemoveAttachIcon}`}>
                                <DeleteOutlineIcon />
                              </Box>
                            </Box>
                          </ListItem>
                        </List>
                      )} */}
                          <div className={`${styles.flexCard} ${styles.justifiedBetween}`}>
                            <div className={`${styles.flexCard}`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                                onClick={handleCommentSubmit}
                                disabled={!editorContent}
                              >
                                {t("textSubmit")} {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                              </Button>
                              <Tooltip title="Attchments">
                                <IconButton className={`${styles.attachmentBtnBox}`}>
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    multiple
                                    id="uploadimage"
                                    onChange={handleImageUpload}
                                    className={`${styles.attachmentInput}`}
                                    accept="image/*"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>

                            <div className={`${styles.flexCard}`}>
                              <Button
                                className={`${btnStyles.yinBtnSm} ${btnStyles.yinSecondaryBtnOutline} ${btnStyles.yinRound}`}
                                // onClick={handleTicketStatus}
                                onClick={() => setOpen(true)}
                              >
                                {t("textCloseTicket")}
                              </Button>
                              {/* <Tooltip title="Discard">
                            <IconButton onClick={handleDiscard}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Tooltip> */}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {/* )} */}
          </Grid>
          {/* )} */}
        </Box>
      ) : (
        <>
          <Box className="emptyStateBox">
            <DataNotFound
              createProject={"createTicket"}
              userRoleFromLocal={userRoleFromLocal}
              icon={"chat"}
              title={""}
              subtitle={t("textTicketNotFound")}
            />
          </Box>
        </>
      )}
      {renderConfirmDailog()}
    </>
  );
}

// const messageList = [
//   {
//     subject: "Recruitment & Talent Acquisition",
//     messageDate: "17July,2023 at 10:26AM",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
//   {
//     subject: "Want to Create a Logo",
//     messageDate: "13July,2023 at 11:26AM",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
//   {
//     subject: "Recruitment & Talent Acquisition",
//     messageDate: "11July,2023 at 07:26PM",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
// ];
