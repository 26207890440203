import { createSlice } from "@reduxjs/toolkit";
import {
  userRegister,
  userResendOtp,
  userVerifyOtp,
  customerLogin,
  getProfileAPI,
  postProfileAPI,
  talentLogin,
  talentRegister,
  getTalentPreview,
  adminRegisterTalent,
  getTalentRatingAPI,
  talentVideoSubmit,
} from "../services/api/register-api";
import { openSnackbar } from "./snackbarSlice";
import { initialQueryAPI } from "./intialQuerySlice";

const initialState = {
  loading: false,
  loginStatus: false,
  registrationStatus: false,
  userNotFoundStatus: false,
  profileData: {},
  ratingData: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    loginSuccess(state, action) {
      state.loginStatus = true;
    },
    logoutSuccess(state, action) {
      state.loginStatus = false;
    },
    newUserFound(state) {
      state.userNotFoundStatus = true;
    },
    registerSuccess(state, action) {
      state.registrationStatus = true;
      state.loading = false;
      localStorage.setItem("email", action.payload);
    },
    registerFailed(state) {
      state.error = "Error Occured";
      state.loading = false;
    },
    getProfileSuccess: (state, action) => {
      state.profileData = action.payload;
    },
    getRatingSuccess: (state, action) => {
      state.ratingData = action.payload;
    },
  },
});

export const {
  isLoading,
  getRatingSuccess,
  registerSuccess,
  registerFailed,
  finishLoading,
  newUserFound,
  loginSuccess,
  getProfileSuccess,
  logoutSuccess,
} = userSlice.actions;

export const userLoginAPI = (request, navigate) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await userRegister(request);
    if (response.status === 200) {
      navigate("/otp");
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      localStorage.setItem("email", email);
    }
  } catch (error) {
    console.log(error);

    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
    if (error.response.status === 404) {
      localStorage.setItem("newUserEmail", email);
      dispatch(newUserFound());
    }
  }
  dispatch(finishLoading());
};

export const customerLoginAPI = (request, navigate) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await customerLogin(request);
    if (response.status === 200) {
      navigate("/otp");
      localStorage.setItem("email", email);
    }
  } catch (error) {
    console.log(error);

    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
    if (error.response.status === 404) {
      localStorage.setItem("newUserEmail", email);
      dispatch(newUserFound());
    }
  }
  dispatch(finishLoading());
};

export const userRegistrationAPI = (request) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await userRegister(request);
    // dispatch(openSnackbar({ message: "Registration success!", severity: "success" }));
    if (response.data.result) {
      dispatch(registerSuccess(email));
    }
  } catch (error) {
    console.log(error);
    dispatch(finishLoading());
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));

    // dispatch(registerFailed(error));
  }
};

export const adminRegisterTalentAPI = (request, handleDrawerClose) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await adminRegisterTalent(request);

    console.log("first response", response);
    if (response.status === 201) {
      // deliverableSendCommentSuccess(response.data.result);
      dispatch(openSnackbar({ message: "Registration success!", severity: "success" }));
      dispatch(registerSuccess(email));
      handleDrawerClose();
    }
  } catch (error) {
    console.log(error);
    dispatch(finishLoading());
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));

    // dispatch(registerFailed(error));
  }
};

export const verifyOtpAPI = (request, navigate) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await userVerifyOtp(request);

    if (response.status === 200) {
      const role = response.data?.result?.data?.role;
      const userId = response.data?.result?.data?._id;
      const name = response.data?.result?.data?.name;
      const profile_picture = response.data?.result?.data?.profilePicture;
      localStorage.setItem("token", response.data.result.token);
      localStorage.setItem("role", role);
      localStorage.setItem("userId", userId);
      localStorage.setItem("name", name);
      localStorage.setItem("profile_picture", profile_picture);
      dispatch(loginSuccess());
      const queryPresent = localStorage.getItem("assistantMsgParam");

      if (queryPresent && role !== "admin") {
        const assistantMsgParam = localStorage.getItem("assistantMsgParam");
        const localSkillResponseList = localStorage.getItem("skillResponseList");
        const localDeliverableResponseList = localStorage.getItem("deliverableResponseList");
        const localAiTools = localStorage.getItem("aiTools");
        const projectTitle = localStorage.getItem("getTitle");
        const loaclPlan = localStorage.getItem("plan");
        const plan = JSON.parse(loaclPlan);

        const parsedLocalDeliverableResponseList = JSON.parse(localDeliverableResponseList);
        // const selectedPlan = localStorage.getItem("plan");
        const data = {
          name: projectTitle,
          smallDescription: "projectTitle",
          longDescription: assistantMsgParam,
          deliverable: parsedLocalDeliverableResponseList,
          skillsRequired: localSkillResponseList,
          status: "not-submitted",
          tags: [localAiTools],
          plan: plan,
        };
        // const id = dispatch(initialQueryAPI(data));

        // navigate(`/project/${id}`);
        const projectIdPromise = dispatch(initialQueryAPI(data));

        // Handling the Promise
        projectIdPromise
          .then((projectId) => {
            navigate(`/projects/${projectId}`);
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      } else {
        if (response.data.result.data.role === "admin") {
          navigate("/requests");
        } else {
          navigate("/projects");
        }
      }

      // dispatch(openSnackbar({ message: response.data?.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);

    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
    // dispatch(registerFailed(error));
  }
  dispatch(finishLoading());
};

export const resendOtpAPI = (request) => async (dispatch) => {
  try {
    const response = await userResendOtp(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data?.message, severity: "success" }));
    }
    // if (response.data.status === "OtpSent") {
    //   dispatch(openSnackbar({ message: "OTP Send to your email!", severity: "success" }));
    // }
  } catch (error) {
    console.log(error);
    dispatch(registerFailed(error));
  }
};

export const profileApi = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getProfileAPI(request);
    if (response.status === 200) {
      dispatch(getProfileSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(finishLoading());
};

export const updateProfileApi = (request, setEdit) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postProfileAPI(request);

    if (response.status === 200) {
      dispatch(getProfileSuccess(response.data?.data));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      const profile_picture = response.data?.data.profilePicture;

      localStorage.setItem("profile_picture", profile_picture);
      setEdit(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    console.log(error.response);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

// Talent

export const talentLoginAPI = (request, navigate) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await talentLogin(request);
    if (response.status === 200) {
      navigate("/talent-otp");
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      localStorage.setItem("email", email);
    }
  } catch (error) {
    console.log(error);

    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
    if (error.response.status === 404) {
      localStorage.setItem("newUserEmail", email);
      // dispatch(newUserFound());
      navigate("/talent-name");
    }
  }
  dispatch(finishLoading());
};

export const talentRegistrationAPI = (request, navigate) => async (dispatch) => {
  const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await talentRegister(request);
    //
    if (response.status === 201) {
      dispatch(registerSuccess(email));
      localStorage.removeItem("newUserEmail");
      localStorage.setItem("welcome", "true");
      navigate("/talent-otp");
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    dispatch(finishLoading());
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));

    // dispatch(registerFailed(error));
  }
};

export const talentVerifyOtpAPI = (request, navigate) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await userVerifyOtp(request);

    if (response.status === 200) {
      const role = response.data?.result?.data?.role;
      const userId = response.data?.result?.data?._id;
      const name = response.data?.result?.data?.name;
      const profile_picture = response.data?.result?.data?.profilePicture;
      localStorage.setItem("token", response.data.result.token);
      localStorage.setItem("role", role);
      localStorage.setItem("userId", userId);
      localStorage.setItem("name", name);
      localStorage.setItem("profile_picture", profile_picture);
      dispatch(loginSuccess());
      navigate("/talent-profile", { replace: true });

      // dispatch(openSnackbar({ message: response.data?.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);

    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
    // dispatch(registerFailed(error));
  }
  dispatch(finishLoading());
};

/********* Talent Ptreview API GD ********* */
export const talentPreviewAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getTalentPreview(request.talentId?.id);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      const responseData = response?.data;
      dispatch(getProfileSuccess(responseData.data[0]));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

export const talentRatingAPI = (request) => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getTalentRatingAPI(request);

    if (response.status === 201) {
      console.log("rating NEW");
      console.log("rating", response.data?.data.rating);
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      //const responseData = response?.data;
      dispatch(getRatingSuccess(response.data?.data.rating));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  // dispatch(finishLoading());
};

export const talentVideoSubmitAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await talentVideoSubmit(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      console.log("Video API", response);
      //const responseData = response?.data;
      //dispatch(getProfileSuccess(responseData.data[0]));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

export default userSlice.reducer;
