import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./projectDetails.module.css";
import btnStyles from "../../../assets/button.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileIcon from "../../../assets/icons/darkfile.svg";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadDocument from "../../../components/common/uploadDocument";
import { FileDarkIcon } from "../../../assets/yingoIcons";
import { Link, useNavigate, useParams } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import {
  customerProjectRemove,
  projectDetailAPI,
  projectDocAPI,
  projectStatusAPI,
  projectTitleAPI,
  setTemporaryDocEmpty,
} from "../../../reducers/intialQuerySlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import MessageIcon from "@mui/icons-material/Message";

function ProjectDetails(props) {
  // const dfc = useSelector((state) => state.intialQuery);

  const { projectDetail, loading, temporaryApiDoc, docLoading } = useSelector((state) => state.intialQuery);

  const [editMode, setEditMode] = useState(false);
  const [projectTitle, setProjectTitle] = useState("");
  const [startDate, setStartDate] = useState();
  // const [startDate] = useState(new Date());
  const [prevProjectTitle, setPrevProjectTitle] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);
  const navigate = useNavigate();
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  // // const [names, setNames] = useState([]);

  const isMounted = useRef(false);

  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMounted.current) {
      const data = {
        projectID: param.id,
      };
      dispatch(setTemporaryDocEmpty([]));
      dispatch(projectDetailAPI(data));
      isMounted.current = true;
    }
  }, [dispatch, param.id]);

  const projectDetails = useMemo(() => {
    if (projectDetail && projectDetail.length > 0) {
      const project = projectDetail[0];
      // const deliverables = project.deliverables ? JSON.parse(project.deliverables) : {};
      const skillsRequired = project.skillsRequired ? JSON.parse(project.skillsRequired) : {};
      const tags = project.tags ? JSON.parse(project.tags) : {};

      return {
        initProjectDescription: project.longDescription || "",
        initProjectDeliverable: project.deliverable,
        initProjectSkills: skillsRequired,
        initProjectAi: tags,
        initPlan: project.plan,
        initProjectDoc: project.projectDocuments,
        initStatus: project.status,
      };
    }
    return {};
  }, [projectDetail]);

  useEffect(() => {
    if (projectDetail && projectDetail.length > 0) {
      const project = projectDetail[0];
      setProjectTitle(project?.name);
      setPrevProjectTitle(project?.name);
      const startDateString = project?.startDate;
      const startDateObject = new Date(startDateString);
      setStartDate(startDateObject);
      // setStartDate();
    }
  }, [projectDetail]);

  const handleDateSelect = (date) => {
    const originalDate = new Date(date);
    // const year = originalDate.getFullYear();
    // const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    // const day = originalDate.getDate().toString().padStart(2, "0");

    // const formattedDate = `${year}-${month}-${day}`;
    const request = {
      name: projectTitle,
      startDate: originalDate,
      projectID: param.id,
    };
    dispatch(projectTitleAPI(request));
    // setStartDate(date);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    setError("");
    setProjectTitle(event.target.value);
  };

  const handleInputBlur = () => {
    setEditMode(false);
    if (projectTitle && projectTitle.length > 0) {
      if (projectTitle !== prevProjectTitle) {
        const request = {
          name: projectTitle,
          projectID: param.id,
          startDate: startDate,
        };
        dispatch(projectTitleAPI(request));
        setPrevProjectTitle(projectTitle);
      }
    } else {
      setError("** Project Title is empty");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInputBlur();
    }
  };

  const handleDocumentUpload = useCallback(
    (files) => {
      if (files.length > 0) {
        const file = files[0];
        const request = {
          file: file,
          projectID: param.id,
        };
        dispatch(projectDocAPI(request));
      }
    },
    [dispatch, param.id],
  );

  const handleProjectStatus = async (status) => {
    const request = {
      status: status,
      projectID: param.id,
    };
    await dispatch(projectStatusAPI(request));
    setOpen(false);
  };

  const handleProjectRemove = async () => {
    const request = {
      projectId: param.id,
    };

    await dispatch(customerProjectRemove(request, setOpenCompleted, navigate));
  };
  const handleCompletedPopupOpen = () => {
    setOpenCompleted(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompleted(false);
  };
  const renderConfirmDailog = () => {
    return (
      <>
        {/* Dialog for Compelted for Deliverable */}
        <Dialog className="DelcineWarningModal" open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
          <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
            <box className="popupIcon">
              <WarningAmberIcon />
            </box>
            <IconButton className={`popupCrossBtn`}>
              <CloseIcon onClick={() => setOpen(false)} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography className="popupTitle">Are you Sure to Complete!</Typography>
              <Typography className="popupSubTitle">Do you want to compelted this project</Typography>
              <Box className="popupBtn mt-3">
                <Button
                  onClick={() => setOpen(false)}
                  autoFocus
                  className={` ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleProjectStatus("completed")}
                  autoFocus
                  className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                >
                  Confirm {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {Object.keys(projectDetails).length > 0 ? (
        <Box
        // className={`contentArea`}
        // component="main"
        // sx={{
        //   flexGrow: 1,
        //   p: 3,
        //   width: { sm: `calc(100% - ${drawerWidth}px)` },
        // }}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              {" "}
              <Grid container className={`${styles.justifySpace}`} style={{ marginBottom: "25px", alignItems: "center" }}>
                <Grid item sm={12} md={6} lg={6}>
                  <Box className={`${styles.projectEditableInputBox}`}>
                    <label>
                      <b>Project Title :</b>
                    </label>
                    {editMode ? (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={projectTitle}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      <div className={styles.projectTitleContainer}>
                        <span className={styles.projectTitle}>{projectTitle}</span>
                        {projectDetails?.initStatus === "completed" ? null : (
                          <span className={styles.editTitle} onClick={handleEditClick}>
                            <EditIcon />
                          </span>
                        )}
                      </div>
                    )}
                  </Box>
                  {error && <Typography style={{ color: "red" }}>{error}</Typography>}
                </Grid>
                <Grid item sm={12} md={6} lg={6} className={`${styles.btnAligns}`}>
                  {projectDetails?.initStatus === "not-submitted" ? (
                    <>
                      <Button
                        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                        style={{ marginRight: "15px" }}
                        onClick={() => handleProjectStatus("pending")}
                      >
                        Submit
                      </Button>
                      <Button
                        className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                        style={{ marginRight: "15px" }}
                        onClick={() => handleCompletedPopupOpen()}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <>
                      {projectDetails?.initStatus !== "completed" &&
                      projectDetails?.initStatus !== "pending" &&
                      projectDetails?.initStatus !== "not-submitted" ? (
                        <Button
                          className={`${btnStyles.yinBtnSm} ${btnStyles.yinSecondaryBtnOutline} ${btnStyles.yinRound}`}
                          style={{ marginRight: "15px" }}
                          onClick={() => setOpen(true)}
                        >
                          <span className={`${styles.dNoneMd}`}> Mark as complete</span>{" "}
                          <span className={`${styles.dNoneLg}`}>
                            <CheckIcon />
                          </span>
                        </Button>
                      ) : null}
                      <Link to="/create-ticket">
                        <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} style={{ marginRight: "15px" }}>
                          <span className={`${styles.dNoneMd}`}>Support</span>{" "}
                          <span className={`${styles.dNoneLg}`}>
                            <MessageIcon />
                          </span>
                        </Button>
                      </Link>
                      <Link to="/projects">
                        <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}>
                          <span className={`${styles.dNoneMd}`}>Back</span>{" "}
                          <span className={`${styles.dNoneLg}`}>
                            <ArrowBackIcon />
                          </span>
                        </Button>
                      </Link>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.scrollableContent}`}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        Project Description
                      </Typography>
                      <Typography variant="p" className={`${styles.panelCardP}`}>
                        {projectDetails?.initProjectDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listDeliverables} ${styles.scrollableContent}`}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        Deliverables
                      </Typography>
                      <List className={`${styles.dablList}`}>
                        {/* {Object.keys(projectDetails?.initProjectDeliverable).map((key) => (
                          <ListItem disablePadding key={key}>
                            <ListItemIcon>
                              <img src={FileIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText className={`${styles.listDeliverableTitle}`} primary={projectDetails.initProjectDeliverable[key]} />
                          </ListItem>
                        ))} */}
                        {projectDetails?.initProjectDeliverable
                          ?.slice()
                          .reverse()
                          .map((item, index) => {
                            return (
                              <ListItem disablePadding key={index}>
                                <ListItemIcon>
                                  <img src={FileIcon} alt="" />
                                </ListItemIcon>
                                <ListItemText className={`${styles.listDeliverableTitle}`} primary={item.title} />
                              </ListItem>
                            );
                          })}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.listAiTools}`}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        AI Tools
                      </Typography>
                      <List>
                        {projectDetails?.initProjectAi.map((aiTool, index) => (
                          <ListItem disablePadding key={index}>
                            <ListItemIcon>
                              <img src={aiTool.icon} alt="" />
                            </ListItemIcon>
                            <ListItemText className={`${styles.listaiToolTitle}`} primary={aiTool.title} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent className={`${styles.skills}`}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        Skills
                      </Typography>
                      <List>
                        {Object.keys(projectDetails?.initProjectSkills).map((key) => (
                          <ListItem disablePadding key={key}>
                            <ListItemText className={`${styles.listSkillTitle}`} primary={projectDetails.initProjectSkills[key]} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull ${styles.overflowVisible}`}>
                    <CardContent className={`${styles.listAiTools}`}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        Start Date
                      </Typography>
                      <div className={`${styles.datePickerBox}`}>
                        <DatePicker
                          minDate={new Date()}
                          className={`${styles.datePicker}`}
                          selected={startDate}
                          onChange={handleDateSelect}
                          dateFormat="yyyy/MM/dd"
                          readOnly={projectDetails?.initStatus === "completed"}
                          popperPlacement="top"
                        />
                        {/* <DatePicker className={`${styles.datePicker}`} selected={startDate} readOnly /> */}
                      </div>
                      <div className={`mt-3`}>
                        <label className={`${styles.panelCardH4}`}>Selected Plan</label>
                        <Card className={`panelCard mt-3 ${styles.overflowVisible} ${styles.border}`}>
                          <div className={`${styles.teambadge} ${styles.professional}`}>{projectDetails?.initPlan?.type}</div>
                          <div className={`${styles.flexCardBox} ${styles.justifyBW}`}>
                            <Box className={`${styles.teamCard} ${styles.fullWidth}`}>
                              <CardContent className={`${styles.teamMemberDetail} ${styles.fullWidth} ${styles.flexCardBox} ${styles.justifyBW} `}>
                                <div style={{ marginTop: "5px" }}>
                                  <Typography variant="h4"> {projectDetails?.initPlan?.experience} Years Experience</Typography>
                                  <Typography>10+ {projectDetails?.initPlan?.type} in this plan.</Typography>
                                  <Typography>
                                    Payment Status: <b className={`${styles.captalize}`}>{projectDetails?.initPlan?.paymentStatus}</b>
                                  </Typography>
                                </div>
                                <div className={`${styles.price}`}>${projectDetails?.initPlan?.price}</div>
                              </CardContent>
                            </Box>
                          </div>
                        </Card>

                        {/* <Card className={`${styles.priceCard}`}>
                          <div className={`${styles.badge}`}>{projectDetails?.initPlan?.type}</div>
                          <CardContent className={`${styles.cardContent}`}>
                            <div>
                              <Typography variant="h4" className={`${styles.planName}`}>
                                {projectDetails?.initPlan?.experience} Years Experience
                              </Typography>
                              <p>10+ {projectDetails?.initPlan?.type} in this plan.</p>
                              <p className={`${styles.devs}`}>Payment Status: {projectDetails?.initPlan?.paymentStatus}</p>
                            </div>
                            <div className={`${styles.price}`}>${projectDetails?.initPlan?.price}</div>
                          </CardContent>
                        </Card> */}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Card className={`panelCard heightFull`}>
                    <CardContent style={{ position: "relative" }}>
                      <Typography variant="h4" className={`${styles.panelCardH4}`}>
                        Documents
                      </Typography>
                      {projectDetails?.initStatus === "completed" ? null : (
                        <div className={`${styles.uploadBox}`}>
                          {docLoading ? <CircularProgress color="inherit" className={`${styles.docLoaderIcon}`} /> : null}
                          <UploadDocument onDropChange={handleDocumentUpload} />
                        </div>
                      )}

                      <div className={`${styles.uploadedList}`}>
                        {/* <CircularProgress color="inherit" className={`${styles.loaderIcon}`} /> */}
                        {projectDetails?.initProjectDoc?.map((doc, index) => {
                          return (
                            <div className={`${styles.doclistitem}`} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className={`${styles.docImgs}`}>
                                  <FileDarkIcon />
                                </div>
                                <span className={`${styles.docTitle}`}> {doc.filename}</span>
                              </div>
                              {/* <TimesIcon /> */}
                            </div>
                          );
                        })}
                        {temporaryApiDoc?.map((doc, index) => {
                          console.log("fff", doc);
                          return (
                            <div className={`${styles.doclistitem}`} key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className={`${styles.docImgs}`}>
                                  {" "}
                                  <FileDarkIcon />
                                </div>
                                <span className={`${styles.docTitle}`}>New File {index + 1}</span>
                              </div>
                              {/* <TimesIcon /> */}
                            </div>
                          );
                        })}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ) : null}
      {renderConfirmDailog()}

      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openCompleted} onClose={handleCompletedPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCompletedPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">Are you Sure to Delete!</Typography>
            <Typography className="popupSubTitle">Do you want to delete this project? If deleted, this project cannot be recovered.</Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleCompletedPopupClose}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button onClick={handleProjectRemove} autoFocus className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                Confirm {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProjectDetails;

// useEffect(() => {
//   if (projectDetail && Object.keys(projectDetail).length > 0) {
//     const deliverables = projectDetail[0].deliverables ? JSON.parse(projectDetail[0].deliverables) : {};
//     const skillsRequired = projectDetail[0].skillsRequired ? JSON.parse(projectDetail[0].skillsRequired) : {};
//     const tags = projectDetail[0].tags ? JSON.parse(projectDetail[0].tags) : [];

//     const initProjectQuery = {
//       initProjectDescription: projectDetail[0].longDescription || "",
//       initProjectDeliverable: deliverables,
//       initProjectSkills: skillsRequired,
//       initProjectAi: tags,
//     };
//     setProjectDetails(initProjectQuery);
//     setProjectTitle(projectDetail[0]?.name);
//   }
// }, [projectDetail]);
