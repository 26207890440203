/* eslint-disable react/prop-types */
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import styles from "./chatbox.module.css";
import btnStyles from "../../../assets/button.module.css";
import { useEffect, useRef, useState } from "react";
import { deliverableCommentAPI } from "../../../reducers/deliverablesSlice";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../PageNotFound/dataNotFound";
export default function ChatBox() {
  const [inputValue, setInputValue] = useState("");
  const { deliverableInfo, deliverableComment } = useSelector((state) => state.deliverables);
  // const { comments, deliverableId, sendComment } = props;

  const dispatch = useDispatch();
  const loggedUserName = localStorage.getItem("name");
  const loggedUserEmail = localStorage.getItem("email");
  const loggedUserPhoto = localStorage.getItem("profile_picture");
  let loggedTitle;
  if (loggedUserPhoto === "null" || loggedUserPhoto === "undefined" || loggedUserPhoto === "") {
    if (loggedUserName === "null" || loggedUserName === "undefined") {
      loggedTitle = <span>{loggedUserEmail.charAt(0)}</span>;
    } else {
      loggedTitle = <span>{loggedUserName.charAt(0)}</span>;
    }
  } else {
    loggedTitle = <img src={loggedUserPhoto} alt={loggedUserPhoto} />;
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleComment = () => {
    if (inputValue) {
      const userId = localStorage.getItem("userId");
      const request = {
        toUser: userId,
        deliverableId: deliverableInfo[0]?._id,
        // deliverableId: deliverableId,
        message: inputValue,
      };
      dispatch(deliverableCommentAPI(request, setInputValue));
    }
  };
  const loggedInUserId = localStorage.getItem("userId");

  const chatContainerRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom when the talentDeliverableCommentList changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [deliverableComment]);

  return (
    <>
      <Card className={`${styles.accordianChatBox} p-0`}>
        <Box className={`${styles.accordianChatMsgs}`} ref={chatContainerRef}>
          {deliverableComment && deliverableComment.result?.length > 0 ? (
            deliverableComment.result.map((comment, index) => {
              const { byUser, message, byUserInfo } = comment;
              if (byUser === loggedInUserId) {
                return (
                  <div key={index} className={`${styles.userMsgsBox} ${styles.alignRight}`}>
                    <Typography variant="body" className={`${styles.userText}`}>
                      {message}
                    </Typography>
                    <div className={`${styles.userImg}`}>{loggedTitle}</div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className={`${styles.devsMsgsBox}`}>
                    {/* <div className={`${styles.devsImg}`}>Y</div> */}
                    {byUserInfo[0]?.profilePicture === "null" || byUserInfo[0]?.profilePicture === "" ? (
                      <div className={`${styles.devsImg}`}>{byUserInfo[0]?.name.charAt(0)}</div>
                    ) : (
                      <div className={`${styles.devsImg}`}>
                        <img src={byUserInfo[0]?.profilePicture} alt="" />
                      </div>
                    )}
                    <Typography variant="body" className={`${styles.devsText}`}>
                      {message}
                    </Typography>
                  </div>
                );
              }
            })
          ) : (
            <Box className={`${styles.emptyStateBox}`}>
              <Box className="emptyStateContainer">
                <DataNotFound icon={"chat"} title={""} subtitle={"Send a message to start the conversation."} />
              </Box>
            </Box>
          )}
          {/* comments?.map((comment, index) => {
            const { byUser, message } = comment;
            if (byUser === loggedInUserId) {
              return (
                <div key={index} className={`${styles.userMsgsBox} ${styles.alignRight}`}>
                  <Typography variant="body" className={`${styles.userText}`}>
                    {message}
                  </Typography>
                  <div className={`${styles.userImg}`}>U</div>
                </div>
              );
            } else {
              return (
                <div key={index} className={`${styles.devsMsgsBox}`}>
                  <div className={`${styles.devsImg}`}>D</div>
                  <Typography variant="body" className={`${styles.devsText}`}>
                    {message}
                  </Typography>
                </div>
              );
            }
          })} */}
          {/* {sendComment?.map((comment, index) => {
            const { message } = comment;
            return (
              <div key={index} className={`${styles.userMsgsBox} ${styles.alignRight}`}>
                <Typography variant="body" className={`${styles.userText}`}>
                  {message}
                </Typography>
                <div className={`${styles.userImg}`}>U</div>
              </div>
            );
          })} */}
        </Box>
        <Box className={`${styles.accordianChatInput} chatBottomInput`}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            autoComplete="off"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevents form submission
                handleComment();
              }
            }}
          />
          <Button
            className={`${btnStyles.yinBtnSm} ${inputValue ? btnStyles.yinPrimaryBtn : btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
            disabled={!inputValue}
            onClick={handleComment}
          >
            Send
          </Button>
        </Box>
      </Card>
    </>
  );
}
