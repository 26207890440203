import Box from "@mui/material/Box";
import styles from "./projects.module.css";
import btnStyles from "../../../assets/button.module.css";

import { Button, Grid, Typography } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import ProjectCard from "../../../components/common/projectCard";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { projectListAPI } from "../../../reducers/intialQuerySlice";
import { capitalizeFirstLetter } from "../../../utils/helper";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import Loader from "../../../components/common/Loader";

function Projects(props) {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { projectsListing, loading } = useSelector((state) => state.intialQuery);

  useEffect(() => {
    if (!isMounted.current) {
      dispatch(projectListAPI());
      isMounted.current = true;
    }
  }, [dispatch]);

  const getStatusOrder = (status) => {
    // <-- Added this function to reorder projects displayed
    const order = {
      "not-submitted": 1,
      pending: 2,
      "in-progress": 3,
      overdue: 4,
      completed: 5,
    };
    return order[status] || 999;
  };

  // Group projects by status
  // const maxProjectsPerStatus = 3; // Maximum number of projects to show for each status
  const groupedProjects = projectsListing.reduce((groups, project) => {
    const status = project.status;

    if (!groups[status]) {
      groups[status] = [];
    }

    // Check if the number of projects for this status is less than the maximum
    // if (groups[status].length < maxProjectsPerStatus) {
    groups[status].push(project);
    // }

    return groups;
  }, {});

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box container style={{ paddingBottom: "0px" }}>
            {Object.entries(groupedProjects).length > 0 ? (
              <>
                <Grid container style={{ marginBottom: "5px", alignItems: "center" }}>
                  <Grid item sm={12} md={6}>
                    <Box className={`${styles.flexTitleInput}`}>
                      <Typography variant="h4" className={`panelHeading`}>
                        Projects
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item sm={12} md={6} className={`${styles.btnAligns}`}>
                    <Link to="/">
                      <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                        <div className={`${styles.flexCard}`}>
                          <AddIcon /> <span>New Project</span>
                        </div>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                {Object.entries(groupedProjects)
                  .sort((a, b) => getStatusOrder(a[0]) - getStatusOrder(b[0]))
                  .map(([status, projects]) => {
                    return (
                      <Box key={status} className={`${styles.overDues}`} style={{ marginTop: "15px" }}>
                        <Grid container className={`${styles.justifySpace}`} style={{ marginBottom: "15px" }}>
                          <Grid item sm={6} md={6}>
                            <Box className={`${styles.flexTitleInput}`}>
                              <Typography
                                variant="h5"
                                className={`${styles.panelHeadingH5} ${
                                  capitalizeFirstLetter(status) === "Not-submitted"
                                    ? styles.notSubmitCardTitle
                                    : capitalizeFirstLetter(status) === "Pending"
                                    ? styles.pendingCardTitle
                                    : capitalizeFirstLetter(status) === "In-progress"
                                    ? styles.progressCardTitle
                                    : capitalizeFirstLetter(status) === "Overdue"
                                    ? styles.overdueCardTitle
                                    : capitalizeFirstLetter(status) === "Completed"
                                    ? styles.completedCardTitle
                                    : ""
                                }`}
                              >
                                {capitalizeFirstLetter(status)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <div className={`tablisting ${styles.tablist}`}>
                          <Grid container spacing={2}>
                            {projects.map((project) => {
                              return (
                                <Grid item key={project.id} sm={12} md={6} lg={4}>
                                  <ProjectCard projectInfo={project} status={capitalizeFirstLetter(status)} />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </Box>
                    );
                  })}
              </>
            ) : (
              <>
                <Box className="emptyStateBox">
                  <DataNotFound
                    createProject={"createProject"}
                    icon={"record"}
                    title={" No Project Created Yet!"}
                    subtitle={"Click on New Project to create new project"}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default Projects;
