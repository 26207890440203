import { callAPI } from "../config/api-core";
import {
  supportListAPIMethods,
  supportListAdminAPIMethods,
  supportTicketCommentAPIMethods,
  supportTicketCommentAdminAPIMethods,
  supportTicketCommentListAPIMethods,
  supportTicketCommentListAdminAPIMethods,
  supportTicketComposeAPIMethods,
  supportTicketInfoAPIMethods,
  supportTicketInfoAdminAPIMethods,
  supportTicketStatusAPIMethods,
} from "../methods";

// const supportListAPI = async (request, userRoleFromLocal) => {
//   let userToken = localStorage.getItem("token");
//   let header = {
//     Authorization: userToken && userToken,
//   };
//   let params = request ? `?pageNo=${request}` : "";
//   let response;
//   if (userRoleFromLocal === "user") {
//     response = await callAPI(supportListAPIMethods + params, true, "", header, false, false);
//   } else {
//     response = await callAPI(supportListAdminAPIMethods + params, true, "", header, false, false);
//   }

//   return response;
// };

const supportListAPI = async (request, userRoleFromLocal) => {
  const userToken = localStorage.getItem("token");
  const header = {
    Authorization: userToken || "",
  };

  const params = request ? `?pageNo=${request}` : "";
  //const apiUrl = userRoleFromLocal === "user" ? supportListAPIMethods : supportListAdminAPIMethods;
  const apiUrl =
    userRoleFromLocal === "user" ? supportListAPIMethods : userRoleFromLocal === "talent" ? supportListAPIMethods : supportListAdminAPIMethods;
  try {
    const response = await callAPI(apiUrl + params, true, "", header, false, false);

    return response;
  } catch (error) {
    console.error("Error in supportListAPI:", error);
    // Handle the error here or re-throw it if needed.
    throw error;
  }
};

// const supportTicketInfoAPI = async (request, userRoleFromLocal) => {
//   let userToken = localStorage.getItem("token");
//   let header = {
//     Authorization: userToken && userToken,
//   };
//   let params = request ? `?ticketId=${request}` : "";

//   const response = await callAPI(supportTicketInfoAPIMethods + params, true, "", header, false, false);
//   return response;
// };

const supportTicketInfoAPI = async (request, userRoleFromLocal) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = request ? `?ticketId=${request}` : "";
  const apiUrl =
    userRoleFromLocal === "user"
      ? supportTicketInfoAPIMethods
      : userRoleFromLocal === "talent"
      ? supportTicketInfoAPIMethods
      : supportTicketInfoAdminAPIMethods;

  const response = await callAPI(apiUrl + params, true, "", header, false, false);
  return response;
};

const supportTicketComposeAPI = async (request) => {
  const { file, subject, projectId, deliverableId, message } = request;

  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  if (file && file.length > 0) {
    // formData.append("files", file);
    file.forEach((file) => {
      formData.append("files", file);
    });
  }
  formData.append("subject", subject);
  formData.append("projectId", projectId);
  formData.append("message", message);
  if (deliverableId) {
    formData.append("deliverableId", deliverableId);
  }

  const response = await callAPI(supportTicketComposeAPIMethods, false, formData, header, false, false);
  return response;
};

// const supportTicketCommentListAPI = async (request) => {
//   const { pageNo, ticketId } = request;
//   let userToken = localStorage.getItem("token");
//   let header = {
//     Authorization: userToken && userToken,
//   };

//   let params = request ? `?ticketId=${ticketId}&pageNo=${pageNo}` : "";

//   const response = await callAPI(supportTicketCommentListAPIMethods + params, true, "", header, false, false);
//   return response;
// };

const supportTicketCommentListAPI = async (request, userRoleFromLocal) => {
  const { pageNo, ticketId } = request;
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };

  let params = request ? `?ticketId=${ticketId}&pageNo=${pageNo}` : "";
  const apiUrl =
    userRoleFromLocal === "user"
      ? supportTicketCommentListAPIMethods
      : userRoleFromLocal === "talent"
      ? supportTicketCommentListAPIMethods
      : supportTicketCommentListAdminAPIMethods;

  const response = await callAPI(apiUrl + params, true, "", header, false, false);
  return response;
};

// const supportTicketCommentAPI = async (request) => {
//   const { file, ticketId, comment } = request;
//   let userToken = localStorage.getItem("token");
//   let header = {
//     "Content-Type": "multipart/form-data",
//     Authorization: userToken && userToken,
//   };

//   const formData = new FormData();
//   if (file && file.length > 0) {
//     file.forEach((file) => {
//       formData.append("files", file);
//     });
//   }
//   formData.append("ticketId", ticketId);
//   formData.append("comment", comment);

//   const response = await callAPI(supportTicketCommentAPIMethods, false, formData, header, false, false);
//   return response;
// };

const supportTicketCommentAPI = async (request, userRoleFromLocal) => {
  const { file, ticketId, comment } = request;

  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  if (file && file.length > 0) {
    file.forEach((file) => {
      formData.append("files", file);
    });
  }
  formData.append("ticketId", ticketId);
  formData.append("comment", comment);

  const apiUrl =
    userRoleFromLocal === "user"
      ? supportTicketCommentAPIMethods
      : userRoleFromLocal === "talent"
      ? supportTicketCommentAPIMethods
      : supportTicketCommentAdminAPIMethods;

  const response = await callAPI(apiUrl, false, formData, header, false, false);
  return response;
};

const supportTicketStatusAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };

  const response = await callAPI(supportTicketStatusAPIMethods, false, request, header, false, false);
  return response;
};

export const getSupportListAPI = (request, userRoleFromLocal) => supportListAPI(request, userRoleFromLocal);
export const getSupportTicketInfoAPI = (request, userRoleFromLocal) => supportTicketInfoAPI(request, userRoleFromLocal);
export const getSupportTicketCommentListAPI = (request, userRoleFromLocal) => supportTicketCommentListAPI(request, userRoleFromLocal);
export const postSupportTicketComposeAPI = (request) => supportTicketComposeAPI(request);
export const postSupportTicketCommentAPI = (request, userRoleFromLocal) => supportTicketCommentAPI(request, userRoleFromLocal);
export const updateSupportTicketStatusAPI = (request) => supportTicketStatusAPI(request);

export default {
  getSupportListAPI,
  getSupportTicketInfoAPI,
  postSupportTicketComposeAPI,
  getSupportTicketCommentListAPI,
  postSupportTicketCommentAPI,
  updateSupportTicketStatusAPI,
};
