// import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import styles from "./navbar.module.css";
import Logo from "../../../assets/icons/yingoLogo.png";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Typography } from "@mui/material";
// import { LogoutIcon, ProfileIcon } from "../../../assets/yingoIcons";
import { Link, NavLink, useNavigate } from "react-router-dom";
import WalletIcon from "../../../assets/icons/walletIcon.svg";
import BellIcon from "../../../assets/icons/bellIcon.svg";
import { useEffect, useState } from "react";
import { LogoutIcon, ProfileIcon } from "../../../assets/yingoIcons";
// import ProfileDefaultImg from "../../../assets/icons/defaultState.png";
import { useSelector } from "react-redux";
import NewRequestDrawer from "./NewRequestDrawer";
// import DialogBox from "../../../components/common/dialog";
import AcceptRequest from "./AcceptRequest";
import RejectRequestDialog from "./dialogs/RejectRequestDialog";
import UsFlag from "../../../assets/icons/us.png";
import SpFlag from "../../../assets/icons/spain.png";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
// import { changeLanguage } from "i18next";
function TalentNavbar(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [acceptRequestDialog, setAcceptRequestDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  // const [langValue, setLangValue] = useState(null);
  const { handleDrawerToggle } = props;
  const { talentNewRequest } = useSelector((state) => state.talentProject);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const body = document.body;
    body.classList.add("scrollBarLine");
  };

  const handleClose = () => {
    setAnchorEl(null);
    const body = document.body;
    body.classList.remove("scrollBarLine");
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const currentLanguage = localStorage.getItem("currentLang");
  const menuItems = [
    { text: t("textProfile"), icon: <ProfileIcon />, link: "/talent-profile", onClick: handleClose },
    {
      text: currentLanguage === "en" ? "Spanish" : "English",
      icon:
        currentLanguage === "en" ? (
          <Box className={`${styles.languageBox}`}>
            <img src={SpFlag} alt="" />
          </Box>
        ) : (
          <Box className={`${styles.languageBox}`}>
            {" "}
            <img src={UsFlag} alt="" />{" "}
          </Box>
        ),
      onClick: () => {
        changeLanguageFunc();
      },
    },
    {
      text: t("textLogOut"),
      icon: <LogoutIcon />,
      link: "/",
      onClick: () => {
        localStorage.clear();
        localStorage.setItem("isProjectSelect", "false");
      },
    },
  ];

  const { talentProfile } = useSelector((state) => state.talentProfile);

  const localProfileImg = localStorage.getItem("profile_picture");
  const loggedUserName = localStorage.getItem("name");
  const profileImgSrc = talentProfile.profilePicture || localProfileImg;

  const profileImg = profileImgSrc ? <img src={profileImgSrc} alt="..." /> : <span>{loggedUserName.charAt(0)}</span>;

  const handleDrawerOpen = (type, title) => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleClickOpen = (projectId) => {
    setSelectedProjectId(projectId);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAcceptRequestDialog = (projectId) => {
    setSelectedProjectId(projectId);
    setAcceptRequestDialog(true);
  };

  const handleAcceptRequestDialogClose = () => {
    setAcceptRequestDialog(false);
  };
  useEffect(() => {
    localStorage.setItem("isRyaClick", "false");
  }, []);

  const changeLanguageFunc = () => {
    if (currentLanguage === "en") {
      handleClose(false);
      navigate(0);
      localStorage.setItem("currentLang", "es");
    } else {
      handleClose(false);
      navigate(0);
      localStorage.setItem("currentLang", "en");
    }
  };
  // const handleLangClick = (event) => {
  //   setLangValue(event.currentTarget);
  // };
  // const handleLangClose = (event) => {
  //   setLangValue(null);
  // };
  // let activeLang;
  // let langName;
  // const currentLang = localStorage.getItem("currentLang");
  // if (currentLang === "en") {
  //   activeLang = <img src={UsFlag} alt="" />;
  //   langName = "English";
  // } else {
  //   activeLang = <img src={SpFlag} alt="" />;
  //   langName = "Spanish";
  // }

  // const openlangValue = Boolean(langValue);
  // const idlangValue = openlangValue ? "simple-popover" : undefined;

  // const handleEsLangChange = (e) => {
  //   localStorage.setItem("currentLang", "es");
  //   handleLangClose();
  //   navigate(0);
  // };

  // const handleEnLangChange = (e) => {
  //   localStorage.setItem("currentLang", "en");
  //   handleLangClose();
  //   navigate(0);
  // };

  return (
    <>
      <AppBar position="fixed" className={`${styles.AppBar} appbar`}>
        <Toolbar className={`${styles.panelNavBox}`}>
          <Box className={`${styles.panelLogoBox}`}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={`${styles.navHamBurgerIcon}`}
              sx={{ padding: "0", display: { sm: "none" } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Box className={`${styles.sibebarLogoBox}`}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Box>
          <Box className={`globalSeachBox ${styles.globalSeachContainer}`}>
            <Box className={`${styles.searchBar}`}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search Projects"
                autoComplete="off"
                className={`${styles.searchInput}`}
              />
              <SearchIcon className={`${styles.inputIcon}`} />
            </Box>
            <div className={`${styles.flexCard} ${styles.flexAlignCenter}`}>
              {/* <IconButton className={`${styles.menuItems} ${styles.languageContainer}`} onClick={handleLangClick}>
                <Box className={`${styles.languageBox}`}>
                  {activeLang}
                  {langName}
                </Box>
              </IconButton>
              <Popover
                id={idlangValue}
                open={openlangValue}
                anchorEl={langValue}
                onClose={handleLangClose}
                style={{ marginTop: "40px", marginLeft: "-15px" }}
              >
                <Card className="panelCard p-0">
                  <List className={`${styles.popoverLinking}`}>
                    <ListItem>
                      <Box className={`${styles.flexCard} ${styles.gap10} cursor-pointer`} onClick={handleEnLangChange}>
                        <img src={UsFlag} alt="" style={{ width: "20px" }} />
                        <Typography>English</Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box className={`${styles.flexCard} ${styles.gap10} cursor-pointer`} onClick={handleEsLangChange}>
                        <img src={SpFlag} alt="" style={{ width: "20px" }} />
                        <Typography> Spanish</Typography>
                      </Box>
                    </ListItem>
                  </List>
                </Card>
              </Popover> */}
              <div className={`${styles.walletListItem}`}>
                <img src={WalletIcon} alt=""></img>
                <div className={`${styles.walletBox}`}>
                  <Typography variant="body" sx={{ display: { xs: "none", md: "block" } }}>
                    {t("myEarning")}
                  </Typography>
                  <Typography variant="h4">$0.00</Typography>
                </div>
              </div>
              <div className="requestListItem">
                <div
                  className={`${styles.requestBox} ${talentNewRequest.length > 0 ? styles.requestBoxBubble : ""}`}
                  onClick={() => handleDrawerOpen()}
                >
                  <img src={BellIcon} alt=""></img>
                </div>
              </div>
              <div onClick={handleClick} className={`${styles.navProfileBox}`}>
                {profileImg}
              </div>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className={`navPopOver`}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem key={item.text} disablePadding>
                    <NavLink to={item.link} className={`${styles.menuItemLink} menuSideItem`} onClick={item.onClick}>
                      <ListItemButton className={`${styles.sidebarItemBox} `}>
                        <ListItemIcon className={`${styles.sidebarIconBox}`}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} className={`${styles.sidebarText}`} />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <NewRequestDrawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        talentNewRequest={talentNewRequest}
        handleClickOpen={handleClickOpen}
        handleAcceptRequestDialog={handleAcceptRequestDialog}
      />
      {/* <DialogBox open={openDialog} handleClose={handleDialogClose} /> */}
      <RejectRequestDialog open={openDialog} handleClose={handleDialogClose} selectedProjectId={selectedProjectId} />
      <AcceptRequest open={acceptRequestDialog} handleClose={handleAcceptRequestDialogClose} selectedProjectId={selectedProjectId} />
      {/* {isDrawerOpen && <NewRequestDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />} */}
    </>
  );
}

TalentNavbar.propTypes = {
  window: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
};
export default TalentNavbar;
