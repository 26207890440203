/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, CardMedia, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import styles from "./requests.module.css";
import styles1 from "./requestCard.module.css";

// import btnStyles from "../../../assets/button.module.css";
import SearchIcon from "@mui/icons-material/Search";
// import NoTalent from "../../../assets/icons/notalent.svg";
import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
// import DialogBox from "../../../components/common/dialog";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/helper";
import Loader from "../../../components/common/Loader";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
// import RejectRequestDialog from "../navbar/dialogs/RejectRequestDialog";
// import AcceptRequest from "../navbar/AcceptRequest";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TalentRequests() {
  const { t } = useTranslation();
  // const [openDialog, setOpenDialog] = useState(false);
  // const [acceptRequestDialog, setAcceptRequestDialog] = useState(false);
  // const [selectedProjectId, setSelectedProjectId] = useState("");

  const { loading, talentNewRequest } = useSelector((state) => state.talentProject);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("adminFontFamily");
    return () => {
      document.body.classList.remove("adminFontFamily");
    };
  }, []);

  // const handleClickOpenDetail = () => {
  //   window.location.href = "/talent-requestDetail";
  // };

  // const handleClickOpen = (projectId) => {
  //   setSelectedProjectId(projectId);
  //   setOpenDialog(true);
  // };

  // const handleDialogClose = () => {
  //   setOpenDialog(false);
  // };

  // const handleAcceptRequestDialog = (projectId) => {
  //   setSelectedProjectId(projectId);
  //   setAcceptRequestDialog(true);
  // };

  // const handleAcceptRequestDialogClose = () => {
  //   setAcceptRequestDialog(false);
  // };

  const handleRequestDetail = (requestId) => {
    navigate(`/talent-request-detail/${requestId}`);
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />

        <Box>
          {loading ? (
            <Loader />
          ) : (
            <Card className={`panelCard panelCardFullHeight`}>
              <Grid container style={{ marginBottom: "15px", alignItems: " center" }}>
                <Grid item sm={12} md={9} className="fullWidth">
                  <Typography variant="h4" className={`panelHeading`}>
                    {t("textNewRequests")}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={3} className={`${styles.btnAligns}`}>
                  <Box className={`${styles.searchBar}`}>
                    <TextField id="outlined-basic" variant="outlined" placeholder="Search" autoComplete="off" className={`${styles.searchInput}`} />
                    <SearchIcon className={`${styles.inputIcon}`} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: "35px", alignItems: " center" }}>
                <Grid item sm={12} md={12}>
                  <Grid container spacing={2}>
                    {talentNewRequest && talentNewRequest.length > 0 ? (
                      talentNewRequest.map((request, index) => {
                        const { projectId, _id } = request;
                        return (
                          <Grid item xs={12} lg={4} key={index}>
                            <Card className={` ${styles1.projectCard} cursor_pointer`} onClick={() => handleRequestDetail(_id)}>
                              <CardContent className={`${styles1.projectContent}`}>
                                <Box className={`${styles1.projectCardBody}`}>
                                  <Box className={`${styles1.projectInfo} ${styles1.flexBox} ${styles1.flexStart}`}>
                                    <CardMedia className={`${styles1.projectThumb}`}>
                                      {/* <img src={ProjectImg} alt="" /> */}
                                      <img src={DummyImg} alt="" />
                                    </CardMedia>
                                    <CardContent className={`${styles1.projectDesc}`}>
                                      <Typography variant="h4" className={`${styles1.projectName}`}>
                                        {projectId[0].name}
                                      </Typography>
                                      <div className={`${styles.flexBox} ${styles1.dateOfProject} justified-start mb-1`} style={{ gap: "0" }}>
                                        Start Date: {projectId[0].startDate ? formatDate(projectId[0].startDate) : "N/A"}
                                      </div>
                                      <div className={`${styles.flexBox} ${styles1.dateOfProject} justified-start `} style={{ gap: "0" }}>
                                        Deadline:{projectId[0].endDate ? formatDate(projectId[0].endDate) : "N/A"}
                                      </div>
                                    </CardContent>
                                  </Box>

                                  <Box className={`${styles1.tasksCard}`}>
                                    <Typography variant="p" className={`${styles1.projectDetail}`}>
                                      {projectId[0]?.longDescription}
                                    </Typography>
                                  </Box>
                                  {/* <Box>
                                    <div className={`${styles1.flexBox} ${styles1.userBox}`}>
                                      <div className={`${styles1.flexBox}`}>
                                        <CardMedia className={`${styles1.userBoxImg}`}>
                                          <img src={NoTalent} alt="" />
                                        </CardMedia>
                                        Amit Dhiman
                                      </div>
                                      <div className={`${styles1.flexBox} ${styles1.dateOfProject}`} style={{ gap: "0" }}>
                                        <div className={`${styles.flexCard} mt-1`}>
                                          <Button
                                            className={`${btnStyles.yinBtnXs} ${btnStyles.yinSuccessBtn}`}
                                            onClick={() => handleAcceptRequestDialog(projectId[0]._id)}
                                          >
                                            Accept
                                          </Button>
                                          <Button
                                            className={`${btnStyles.yinBtnXs} ${btnStyles.yinDangerBtn}`}
                                            onClick={() => handleClickOpen(projectId[0]._id)}
                                          >
                                            Decline
                                          </Button>
                                          <Button className={`${btnStyles.yinBtnXs} ${btnStyles.yinCylinBtn}`} onClick={handleClickOpenDetail}>
                                            View Detail
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Box> */}
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12}>
                        <DataNotFound icon="record" subtitle={t("textRequestNotFound")} dClass="centeredText" />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
        </Box>
      </Box>
      {/* <DialogBox open={openDialog} handleClose={handleDialogClose} /> */}
      {/* <RejectRequestDialog open={openDialog} handleClose={handleDialogClose} selectedProjectId={selectedProjectId} />
      <AcceptRequest open={acceptRequestDialog} handleClose={handleAcceptRequestDialogClose} selectedProjectId={selectedProjectId} /> */}
    </>
  );
}
