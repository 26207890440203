import Box from "@mui/material/Box";
import { Button, Card, CircularProgress, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import styles from "./profile.module.css";
import btnStyles from "../../assets/button.module.css";
import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileApi, updateProfileApi } from "../../../src/reducers/userSlice";

import ProfileDefaultImg from "../../assets/icons/defaultState.png";
import countryList from "react-select-country-list";
import Loader from "../../components/common/Loader";
export default function Profile(props) {
  const isMounted = useRef(false);
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const { profileData, loading } = useSelector((state) => state.user);

  const [previewImage, setPreviewImage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [apiProfileImage, setApiProfileImage] = useState("");
  const [editedProfileData, setEditedProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    companyName: "",
    profileImg: null,
  });

  // useEffect(() => {
  // }, [editedProfileData]);

  useEffect(() => {
    if (profileData) {
      setApiProfileImage(profileData.profilePicture || "");
      setEditedProfileData({
        name: profileData.name || "",
        email: profileData.email || "",
        phone: profileData.phone || "",
        country: profileData.countryCode || "",
        companyName: profileData.companyName || "",
        profileImg: profileData.profilePicture || null,
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (!isMounted.current) {
      dispatch(profileApi());
      isMounted.current = true;
    }
  }, [dispatch]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditedProfileData((prevData) => ({
        ...prevData,
        profileImg: file,
      }));
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBackClick = () => {
    setIsEditing(false);
    setPreviewImage("");
  };

  const handleUpdateProfile = () => {
    if (typeof editedProfileData.profileImg === "string") {
      const request = {
        name: editedProfileData.name,
        email: editedProfileData.email,
        phone: editedProfileData.phone,
        country: editedProfileData.country,
        companyName: editedProfileData.companyName,
      };
      dispatch(updateProfileApi(request, setIsEditing));
    } else {
      dispatch(updateProfileApi(editedProfileData, setIsEditing));
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <Grid container style={{ alignItems: "center" }} className={`${styles.xsJustify}`}>
          <Grid item sm={6} md={8}>
            <Typography variant="h4" className={`panelHeading`}>
              Profile
            </Typography>
          </Grid>
          <Grid item sm={6} md={4} className={`${styles.buttonAlignment}`}>
            {isEditing ? (
              <>
                <Button className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleUpdateProfile}>
                  {" "}
                  Update {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                </Button>
                <Button className={`  ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`} onClick={handleBackClick}>
                  Back
                </Button>
              </>
            ) : (
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleEditClick}>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Card className={`panelCard mt-2 innerCardFullHeight`}>
              <Grid container spacing={2}>
                {loading ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box className="emptyStateContainer">
                        <Loader />
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid item xs={12} md={12}>
                      <Typography variant="h5" className={`${styles.headingH5}`}>
                        Personal Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Profile Photo
                      </InputLabel>
                      <Box className={`${styles.userProfilePhoto}`}>
                        {isEditing ? (
                          <>
                            <div className={`${styles.userPhoto}`}>
                              <img src={previewImage || apiProfileImage || ProfileDefaultImg} alt="Profile Preview" />
                            </div>
                            <Box className={`${styles.uploadPhotoBtn}`}>
                              Add Logo
                              <input type="file" id="uploadimage" onChange={handleImageUpload} className={`${styles.uploadPhotoInput}`} />
                            </Box>
                          </>
                        ) : (
                          <div className={`${styles.userPhoto}`}>
                            <img src={apiProfileImage || ProfileDefaultImg} alt="Profile Preview" />
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Company Name
                      </InputLabel>
                      {isEditing ? (
                        <TextField
                          name="companyName"
                          className={`${styles.formInput}`}
                          value={editedProfileData.companyName}
                          onChange={handleChange}
                          id="outlined-basic"
                          variant="outlined"
                          autoComplete="off"
                        />
                      ) : (
                        <Typography>{editedProfileData.companyName || "N/A"}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Contact Person
                      </InputLabel>
                      {isEditing ? (
                        <TextField
                          name="name"
                          value={editedProfileData.name}
                          className={`${styles.formInput}`}
                          onChange={handleChange}
                          id="outlined-basic"
                          variant="outlined"
                          autoComplete="off"
                        />
                      ) : (
                        <Typography>{editedProfileData.name || "N/A"}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Email Address
                      </InputLabel>
                      {isEditing ? (
                        <TextField
                          name="email"
                          value={editedProfileData.email}
                          className={`${styles.formInput} `}
                          onChange={handleChange}
                          id="outlined-basic"
                          variant="outlined"
                          autoComplete="off"
                        />
                      ) : (
                        <Typography>{editedProfileData.email || "N/A"}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Phone Number
                      </InputLabel>
                      {isEditing ? (
                        <TextField
                          name="phone"
                          onChange={handleChange}
                          value={editedProfileData.phone}
                          className={`${styles.formInput} `}
                          id="outlined-basic"
                          variant="outlined"
                          autoComplete="off"
                          type="number"
                        />
                      ) : (
                        <Typography>{editedProfileData.phone || "N/A"}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <InputLabel id="label" className={`${styles.formLabel}`}>
                        Country
                      </InputLabel>
                      {isEditing ? (
                        <Select
                          name="country"
                          className={`formInputSelect ${styles.formInput} `}
                          value={editedProfileData.country}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select a country</MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography>{editedProfileData.country || "N/A"}</Typography>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
