import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en.json";
import translationES from "../locales/es.json";
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};
const currentLang = localStorage.getItem("currentLang");
i18n
  .use(initReactI18next) // Pass in initReactI18next here
  .init({
    resources,
    lng: currentLang || "en",
    interpolation: {
      escapeValue: false,
    },
  });
console.log("Initializing i18n:", resources);

export default i18n;
