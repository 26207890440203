import Box from "@mui/material/Box";
import styles from "./projectDetails.module.css";
import btnStyles from "../../../assets/button.module.css";
// import docImg from "../../../assets/images/image54.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import FileIcon from "./../../../assets/icons/file-filled.svg";

import DefaultImg from "../../../assets/icons/defaultState.png";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { talentProjectDetailAPI, talentProjectListAPI } from "../../../reducers/talentProjectSlice";
import TalentDeliverables from "../deliverables";
import DocPreviewModal from "./components/DocPreviewModal";
import Loader from "../../../components/common/Loader";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentProjectDetail() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const param = useParams();
  const [open, setOpen] = useState(false);
  const [selectProject, setSelectProject] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestDetailLoading, talentProjectDetail, talentProjectList } = useSelector((state) => state.talentProject);
  const [openDocPreview, setOpenDocPreview] = useState(false);
  const [selectedDocPathPreview, setSelectedDocPathPreview] = useState(null);
  const [error, setError] = useState("");
  const { name, longDescription, projectDocuments, userData, deliverable } = talentProjectDetail?.[0] || {};
  //console.log("talentProjectList TEST ", talentProjectList);
  useEffect(() => {
    // if (!isMounted.current) {
    const request = {
      projectId: param.id,
    };
    // dispatch(setTemporaryDocEmpty([]));
    dispatch(talentProjectDetailAPI(request));
    dispatch(talentProjectListAPI());
    // isMounted.current = true;
    // }
  }, [dispatch, param.id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePopupOpen = () => {
    setError("");
    setOpen(true);
  };
  const handlePopupClose = () => {
    setError("");
    setOpen(false);
  };

  const handleChangeProject = (e) => {
    if (e.target.value === "Select") {
      setError("Please select project");
    } else {
      setError("");
      setSelectProject(e.target.value);
    }
  };

  const handleChangeProjectSubmit = () => {
    // console.log("selectProject", selectProject);
    if (selectProject === "" || selectProject === "Select") {
      console.log("first");
      setError("Please select project");
    } else {
      if (selectProject !== "Select") {
        setError("");
        navigate(`/talent-project-detail/${selectProject}`);
        // dispatch(deliverablesListAPI(selectProject));
      }
      setOpen(false);
    }
  };

  // const handleClickOpenDocPreview = (path) => {
  //   setSelectedDocPathPreview(path);
  //   setOpenDocPreview(true);
  // };

  const handleCloseDocPreview = () => {
    setSelectedDocPathPreview(null);
    setOpenDocPreview(false);
  };

  // Group documents by created_at date
  const groupedDocuments = projectDocuments?.reduce((acc, doc) => {
    const dateKey = new Date(doc.created_at).toDateString();
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(doc);
    return acc;
  }, {});

  const handleDownload = (path) => {
    fetch(path)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", path); // Empty string for the attribute will use the default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        {requestDetailLoading ? (
          <Loader />
        ) : (
          <>
            <Card className={`${styles.headCard}`}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={12} sm={8} md={8}>
                  <Card className={`${styles.innerCardHead}`}>
                    <CardContent className={`${styles.innerCardBody} ${styles.innerBtnGap}`}>
                      <span className={`${styles.projectDetailSubTitle}`}>{t("textProjectTitle")}</span>
                      <Typography variant="h2" className={`${styles.projectDetailTitle}`}>
                        {name || ""}
                      </Typography>
                      <Dialog className="DelcineWarningModal" open={open} onClose={handlePopupClose} aria-labelledby="responsive-dialog-title">
                        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
                          <IconButton className={`popupCrossBtn`}>
                            <CloseIcon onClick={handlePopupClose} />
                          </IconButton>
                          {"Change Project"}
                          <Typography className="mb-3">Please select the project for which you want to display the deliverables.</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            <Select className={`formInputSelect ${styles.formInput} `} value={selectProject} onChange={handleChangeProject}>
                              <MenuItem value="Select">Select</MenuItem>
                              {talentProjectList?.map((project) => {
                                const { projectInfo } = project;
                                return (
                                  <>
                                    {projectInfo && projectInfo.length > 0 ? (
                                      <MenuItem key={projectInfo[0]?._id} value={projectInfo[0]?._id}>
                                        {projectInfo[0]?.name}
                                      </MenuItem>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </Select>
                            {error && <div className={`${styles.errorMessage}`}>{error}</div>}
                          </DialogContentText>
                        </DialogContent>
                        <Box className="popupBtn mt-3">
                          <Button
                            autoFocus
                            className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                            onClick={handleChangeProjectSubmit}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Dialog>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={`${styles.buttonAlignment}`} style={{ justifyContent: "end", display: "flex" }}>
                  <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                    <span onClick={handlePopupOpen} className={`${styles.subTitle}`}>
                      {t("textChangeProject")}
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <Card className="panelCard mt-3">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                  <Typography variant="h4" className={`panelHeading mb-3`}>
                    {t("textDeliverables")}
                  </Typography>
                  <TalentDeliverables deliverableList={deliverable} />
                </Grid>
              </Grid>
            </Card>
            <Card className="panelCard mt-3">
              <Grid container style={{ alignItems: "center" }} className="mb-2">
                <Grid item sm={12} md={12}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        className={`basicTabList ${styles.delvierableTabList}`}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab className={`basicTabListItem`} label={t("textProjectDescription")} {...a11yProps(0)} />
                        <Tab className={`basicTabListItem`} label={t("textCustomerInfo")} {...a11yProps(1)} />
                        <Tab className={`basicTabListItem`} label={t("textDocuments")} {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <Typography className={`${styles.tabParagraph}`}> {longDescription || ""}</Typography>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={5}>
                          <Card className={`panelCard mt-3 ${styles.overflowVisible} ${styles.border}`}>
                            <div className={`${styles.teambadge} ${styles.professional}`}>Customer</div>
                            <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                              <Box className={`${styles.teamCard}`}>
                                <CardMedia className={`${styles.teamMemberImg}`}>
                                  <img src={(userData && userData[0]?.profilePicture) || DefaultImg} alt="TeamMember" />
                                </CardMedia>
                                <CardContent className={`${styles.teamMemberDetail}`}>
                                  <Typography variant="h4">{(userData && userData[0]?.name) || ""}</Typography>
                                  <Typography variant="body">
                                    Email: <b> {(userData && userData[0]?.email) || ""}</b>
                                  </Typography>
                                </CardContent>
                              </Box>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      <Grid container spacing={2}>
                        {groupedDocuments &&
                          Object.entries(groupedDocuments).map(([date, documents]) => (
                            <React.Fragment key={date}>
                              <Grid item sm={12} md={12} className={`mt-2`}>
                                <Typography className={`${styles.docCardTitle}`}>{date}</Typography>
                              </Grid>
                              {documents.map((doc) => {
                                const { _id, path, filename } = doc;
                                return (
                                  <Grid key={_id} item sm={12} md={6}>
                                    <Box className={`${styles.docCardBox} `}>
                                      <Box className={`${styles.flexCard} justified-between `}>
                                        <Box className={`${styles.flexCard} ${styles.gap10}`}>
                                          <Box className={`${styles.docCardIcon}`}>
                                            <img src={FileIcon} alt=""></img>
                                          </Box>
                                          <Typography className={`${styles.docCardTitle}`}>{filename}</Typography>
                                        </Box>
                                        <Box className={`${styles.flexCard}`}>
                                          <IconButton onClick={() => handleDownload(path)}>
                                            <DownloadIcon className={`${styles.colorPrimary}`} />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                );
                              })}
                            </React.Fragment>
                          ))}
                        {!groupedDocuments ||
                          (Object.keys(groupedDocuments).length === 0 && (
                            <Grid item sm={12} md={12} className={``}>
                              <DataNotFound icon={"record"} title={""} subtitle={t("textDocumentNotFound")} />
                            </Grid>
                          ))}
                      </Grid>
                    </CustomTabPanel>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>
      {openDocPreview && (
        <DocPreviewModal
          openDocPreviewModal={openDocPreview}
          selectedDocPathPreview={selectedDocPathPreview}
          handleCloseDocPreviewModal={handleCloseDocPreview}
        />
      )}
    </>
  );
}
