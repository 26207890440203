import { Box, Button, Typography } from "@mui/material";
import styles from "./emptystate.module.css";
import { ChatIcon, FileDarkIcon, TeamsIcon } from "../../../assets/yingoIcons";
import btnStyles from "../../../assets/button.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
export default function DataNotFound(props) {
  const { t } = useTranslation();
  const { icon, title, subtitle, createProject, dClass, userRoleFromLocal } = props;

  return (
    <>
      <Box className={`${styles.emptyStateBox} ${dClass === "centeredText" ? styles.centeredText : ""}`}>
        <Box className={`${styles.emptyIcon}`}>
          {icon === "record" ? <FileDarkIcon /> : icon === "team" ? <TeamsIcon /> : icon === "chat" ? <ChatIcon /> : ""}
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body">{subtitle}</Typography>
        {createProject === "createProject" ? (
          <Box style={{ textAlign: "center" }}>
            <Link to="/">
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                <AddIcon /> {t("textNewProject")}
              </Button>
            </Link>
          </Box>
        ) : createProject === "createTicket" ? (
          <Box style={{ textAlign: "center" }}>
            <Link to={userRoleFromLocal === "user" ? "/create-ticket" : userRoleFromLocal === "talent" ? "/talent-ticket" : ""}>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}>
                <AddIcon /> {t("textNewTicket")}
              </Button>
            </Link>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}

DataNotFound.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  createProject: PropTypes.string.isRequired,
  dClass: PropTypes.string,
  userRoleFromLocal: PropTypes.string,
};
