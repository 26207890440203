/* eslint-disable react/prop-types */

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FileIcon from "./../../../assets/icons/darkfile.svg";
import btnStyles from "./../../../assets/button.module.css";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styles from "./deliverable.module.css";
import { Box, Button, Dialog, DialogContent, CircularProgress, DialogContentText, DialogTitle, IconButton } from "@mui/material";
// import DeliverableTabs from "./deliverableTabs";
import { styled } from "@mui/material/styles";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { talentDeliverableChatListAPI, talentDeliverableDocumentListAPI, talentDeliverableForReviewAPI } from "../../../reducers/talentProjectSlice";
import TalentDeliverableTabs from "./deliverableTabs";
import { useTranslation } from "react-i18next";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function TalentDeliverables({ deliverableList }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [selectedDeleverableId, setSelectedDeleverableId] = useState(null);
  const toUserDetail = useSelector((state) => state.talentProject);
  const { loading } = useSelector((state) => state.talentProject);
  //console.log("toUserDetail11", toUserDetail.deliverableCurrentStatus);
  //console.log("toUserDetail11 deliverableData ", toUserDetail.deliverableCurrentStatus?.deliverableData?.length);

  const toUserId = toUserDetail.talentProjectDetail[0]?.userData[0]?._id;
  const dispatch = useDispatch();
  const [openCompleted, setOpenCompleted] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const handlePopupOpen = (id) => {
    setSelectedDeleverableId(id);
    setOpen(true);
  };
  const handlePopupClose = () => {
    setOpen(false);
  };

  const handleCompletedPopupOpen = () => {
    setOpenCompleted(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompleted(false);
  };
  const handleReviewPopupOpen = () => {
    setOpenReview(true);
  };
  const handleReviewPopupClose = () => {
    setOpenReview(false);
  };
  const handleChange = (panel) => async (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    // if (newExpanded) {
    //   dispatch(deliverablesInfoAPI(panel));
    //   // dispatch(deliverableCommentAPI(panel));
    // }

    if (newExpanded) {
      try {
        setInfoLoading(true);
        await Promise.all([dispatch(talentDeliverableChatListAPI(panel)), dispatch(talentDeliverableDocumentListAPI(panel))]);
        // setInfoLoading(false);
      } catch (error) {
        console.error("Error:", error);
        // setInfoLoading(false);
      } finally {
        setInfoLoading(false);
      }
    }
  };

  const handleDeliverableReviewSubmit = () => {
    console.log("selectedDeleverableId", selectedDeleverableId);
    const request = {
      // status: "completed",
      deliverableId: selectedDeleverableId,
      toUser: toUserId,
    };
    dispatch(talentDeliverableForReviewAPI(request, handlePopupClose));
    setTimeout(async () => {
      try {
        setInfoLoading(true);
        await Promise.all([dispatch(talentDeliverableChatListAPI(selectedDeleverableId))]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setInfoLoading(false);
      }
    }, 1000);
  };

  // const handleChange1 = (panel) => async (event) => {
  //   console.log("first");
  //   try {
  //     setInfoLoading(true);
  //     await Promise.all([dispatch(talentDeliverableChatListAPI(panel))]);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setInfoLoading(false);
  //   }
  // };
  return (
    <>
      {Object.keys(toUserDetail?.deliverableCurrentStatus).length > 0 ? (
        Object.values(toUserDetail?.deliverableCurrentStatus)
          .reverse()
          .map((deliverable, index) => {
            const { _id, title, status, startDate, endDate } = deliverable;
            const panel = _id;
            const formatStartDate = new Date(startDate);
            const formatEndDate = new Date(endDate);
            return (
              <div className={`deliverableMuiAccordian ${styles.deliverableAccordians}`} key={index}>
                <Accordion className={`${styles.deliverableAccordianList}`} expanded={expanded === panel} onChange={handleChange(panel)}>
                  <div className={`${styles.deliverableAccordianHeader}`}>
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                      className={`${styles.deliverableAccordianTitleBox}`}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div className={`${styles.flexCard} ${styles.deliverableHeadingContainer}`}>
                        <div className={`${styles.headerIcon}`}>
                          <img src={FileIcon} alt=""></img>
                        </div>
                        <Typography className={`${styles.deliverableAccordianTitle}`}>{title}</Typography>
                      </div>
                    </AccordionSummary>
                    <div
                      className={`${styles.btnPostion}`}
                      onClick={() => {
                        if (status === "in-progress" || status === "overdue") {
                          handlePopupOpen(panel);
                        }
                        if (status === "completed") {
                          handleCompletedPopupOpen(panel);
                        }
                        if (status === "in-review") {
                          handleReviewPopupOpen(panel);
                        }
                      }}
                    >
                      <span
                        style={{ textTransform: "capitalize" }}
                        className={`${styles.badge} ${
                          status === "in-progress"
                            ? styles.InProgress
                            : status === "completed"
                            ? styles.Completed
                            : status === "in-review"
                            ? styles.InReview
                            : status === "overdue"
                            ? styles.Overdue
                            : ""
                        }`}
                      >
                        <span>{status}</span>
                      </span>
                    </div>
                  </div>
                  <AccordionDetails className={`${styles.deliverableAccordianBody}`}>
                    <Box className={`${styles.AccordianBodyInnerCard}`}>
                      <TalentDeliverableTabs
                        deliverabletitle={title}
                        startDate={formatStartDate}
                        endDate={formatEndDate}
                        deliverableId={_id}
                        infoLoading={infoLoading}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
      ) : (
        <>
          {deliverableList && deliverableList.length > 0
            ? deliverableList.map((deliverable, index) => {
                const { _id, title, status, startDate, endDate } = deliverable;
                const panel = _id;
                const formatStartDate = new Date(startDate);
                const formatEndDate = new Date(endDate);
                return (
                  <div className={`deliverableMuiAccordian ${styles.deliverableAccordians}`} key={index}>
                    <Accordion className={`${styles.deliverableAccordianList}`} expanded={expanded === panel} onChange={handleChange(panel)}>
                      <div className={`${styles.deliverableAccordianHeader}`}>
                        <AccordionSummary
                          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                          className={`${styles.deliverableAccordianTitleBox}`}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <div className={`${styles.flexCard} ${styles.deliverableHeadingContainer}`}>
                            <div className={`${styles.headerIcon}`}>
                              <img src={FileIcon} alt=""></img>
                            </div>
                            <Typography className={`${styles.deliverableAccordianTitle}`}>{title}</Typography>
                          </div>
                        </AccordionSummary>
                        <div
                          className={`${styles.btnPostion}`}
                          onClick={() => {
                            if (status === "in-progress" || status === "overdue") {
                              handlePopupOpen(panel);
                            }
                            if (status === "completed") {
                              handleCompletedPopupOpen(panel);
                            }
                            if (status === "in-review") {
                              handleReviewPopupOpen(panel);
                            }
                          }}
                        >
                          <span
                            style={{ textTransform: "capitalize" }}
                            className={`${styles.badge} ${
                              status === "in-progress"
                                ? styles.InProgress
                                : status === "completed"
                                ? styles.Completed
                                : status === "in-review"
                                ? styles.InReview
                                : status === "overdue"
                                ? styles.Overdue
                                : ""
                            }`}
                          >
                            <span>{status}</span>
                          </span>
                        </div>
                      </div>
                      <AccordionDetails className={`${styles.deliverableAccordianBody}`}>
                        <Box className={`${styles.AccordianBodyInnerCard}`}>
                          <TalentDeliverableTabs
                            deliverabletitle={title}
                            startDate={formatStartDate}
                            endDate={formatEndDate}
                            deliverableId={_id}
                            infoLoading={infoLoading}
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            : null}
        </>
      )}

      {/* Dialog for Submit for Review */}
      <Dialog className="DelcineWarningModal" open={open} onClose={handlePopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handlePopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">{t("popupTextSubmitReviewTitle")}</Typography>
            <Typography className="popupSubTitle">{t("popupTextSubmitReviewSubTitle")}</Typography>
            <Box className="popupBtn mt-3">
              <Button
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                onClick={handleDeliverableReviewSubmit}
              >
                {t("textConfirm")} {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openCompleted} onClose={handleCompletedPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon successCircle">
            <CheckIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCompletedPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">{t("popupTextComepletedTitle")}</Typography>
            <Typography className="popupSubTitle">{t("popupTextComepletedSubTitle")}</Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleCompletedPopupClose}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                {t("textOkay")}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openReview} onClose={handleReviewPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon progressCircle">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleReviewPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">{t("popupTextReviewTitle")} </Typography>
            <Typography className="popupSubTitle"> {t("popupTextReviewSubTitle")} </Typography>
            <Box className="popupBtn mt-3">
              <Button
                onClick={handleReviewPopupClose}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                {t("textOkay")}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
