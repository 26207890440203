import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import styles from "./categories.module.css";
import { instructions } from "../../../../utils/ryaInstructions";
import { useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PropTypes from "prop-types";
export default function RyaCategories({ inputValue, setListPopupOpen, setInputValue, handleSwitchCat }) {
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const onCategoryId = (currentCategoryId) => {
    setSelectedCategoryId(currentCategoryId);
  };

  const selectCategory = instructions.categories.find((category) => category.categoryId === selectedCategoryId);

  const subcategories = selectCategory?.subcategories || [];

  return (
    <>
      <Grid item md={4} lg={3}>
        <Box className={`${styles.flexCard} ${styles.listPopupHead} mb-1`}>
          <Typography className={`${styles.textLightGray} mb-0`}>Categories</Typography>
        </Box>
        <List className={`${styles.projectList}`}>
          {instructions.categories.map((category) => (
            <ListItem
              key={category.categoryId}
              className={`${styles.projectListItem}`}
              onClick={() => {
                onCategoryId(category.categoryId);
              }}
            >
              {category.name}
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item md={8} lg={9}>
        <Box className={`${styles.flexCard} ${styles.listPopupHead} pl-3`}>Sub Categories</Box>
        <Box className={`${styles.subCateBox}`}>
          <Grid container spacing={2} className={`${styles.innerDataList} ${styles.pt30px} pl-4`}>
            {subcategories?.map((subcategory) => (
              <Grid item md={6} key={subcategory} className={`pt-0 pl-1 mb-2`}>
                <ListItem
                  className={`${styles.flexCard} ${styles.innerDataListItem} cursor-pointer`}
                  onClick={() => handleSwitchCat(subcategory.actionId, subcategory.name)}
                >
                  <div>
                    <Typography variant="h4">{subcategory.name}</Typography>
                    <Typography>{subcategory.description}</Typography>
                  </div>
                  <Box className={`${styles.badgeBtn}`}>
                    <ArrowOutwardIcon />
                  </Box>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
RyaCategories.propTypes = {
  inputValue: PropTypes.string.isRequired,
  setListPopupOpen: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  handleSwitchCat: PropTypes.func.isRequired,
};
