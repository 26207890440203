import { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import styles from "./navbar.module.css";
import Logo from "../../../assets/icons/logo-light.png";
import { ListItemIcon, Popover, TextField } from "@mui/material";
import { LogoutIcon, ProfileIcon } from "../../../assets/yingoIcons";
import { Link, NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { logoutSuccess } from "../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";

import BellIcon from "../../../assets/icons/bellDark-bl.svg";
import NewRequestDrawer from "./NewReviewProjectDrawer";
function PanelNavbar(props) {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { deliverableStatusNotification } = useSelector((state) => state.deliverables);

  const { handleDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const loggedUserName = localStorage.getItem("name");
  const loggedUserEmail = localStorage.getItem("email");
  const loggedUserPhoto = localStorage.getItem("profile_picture");
  let loggedTitle;
  if (loggedUserPhoto === "null" || loggedUserPhoto === "undefined" || loggedUserPhoto === "") {
    if (loggedUserName === "null" || loggedUserName === "undefined") {
      loggedTitle = <span>{loggedUserEmail.charAt(0)}</span>;
    } else {
      loggedTitle = <span>{loggedUserName.charAt(0)}</span>;
    }
  } else {
    loggedTitle = <img src={loggedUserPhoto} alt={loggedUserPhoto} />;
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuItems = [
    { text: "Profile", icon: <ProfileIcon />, link: "/profile", onClick: handleClose },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      link: "/",
      onClick: () => {
        localStorage.clear();
        dispatch(logoutSuccess());
      },
    },
  ];
  const handleDrawerOpen = (type, title) => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" className={`${styles.AppBar} appbar`}>
        <Toolbar className={`${styles.panelNavBox}`}>
          <Box className={`${styles.panelLogoBox}`}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={`${styles.navHamBurgerIcon}`}
              sx={{ mr: 2, display: { sm: "none" } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Box className={`${styles.sibebarLogoBox}`}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Box>
          <Box className={`globalSeachBox ${styles.globalSeachContainer}`}>
            <Box className={`${styles.searchBar}`}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search Projects"
                autoComplete="off"
                className={`${styles.searchInput}`}
              />
              <SearchIcon className={`${styles.inputIcon}`} />
            </Box>
            <div className={`${styles.flexCard} ${styles.flexAlignCenter}`}>
              <div className="requestListItem">
                <div
                  onClick={() => handleDrawerOpen()}
                  className={`${styles.requestBox}  ${styles.lightRequestBox} ${
                    deliverableStatusNotification[0]?.totalInReviewCount > 0 ? styles.requestBoxBubble : ""
                  }`}
                >
                  <img src={BellIcon} alt=""></img>
                </div>
              </div>
              <div onClick={handleClick} className={`${styles.navProfileBox}`}>
                {loggedTitle}
              </div>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className={`navPopOver`}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem key={item.text} disablePadding>
                    <NavLink to={item.link} className={`${styles.menuItemLink} menuSideItem`} onClick={item.onClick}>
                      <ListItemButton className={`${styles.sidebarItemBox} `}>
                        <ListItemIcon className={`${styles.sidebarIconBox}`}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} className={`${styles.sidebarText}`} />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <NewRequestDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} projectsListing={deliverableStatusNotification} />
    </>
  );
}

PanelNavbar.propTypes = {
  window: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
};

export default PanelNavbar;
