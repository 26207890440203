/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { Box, Typography, Button, Card, Grid, TextField, CircularProgress } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import LoginBanner from "../../assets/images/loginBanner.jpg";
import { Link, useNavigate } from "react-router-dom";
import validateEmail from "../../utils/emailValidation";
import { customerLoginAPI } from "../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/icons/yingoLogo.png";
export default function CustomerLogin() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { loading } = useSelector((state) => state.user);

  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userNotFoundStatus } = useSelector((state) => state.user);

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  useEffect(() => {
    if (userNotFoundStatus) {
      navigate("/register");
    }
  }, [userNotFoundStatus, navigate]);

  const handleEmailChange = (event) => {
    setEmailError("");
    setEmail(event.target.value);
  };

  const handleContinueClick = () => {
    const username = email.split("@")[0];
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      const data = {
        email: email,
        name: username,
      };

      dispatch(customerLoginAPI(data, navigate));
      // Proceed with your logic, e.g., navigate to the next step
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Link to="/">
            <div className={`${styles.logoCard}`}>
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div className={`${styles.contentBox} ${styles.textWhite}`}>
            <Box>
              <Typography variant="h1">{t("loginWelcomeText")}</Typography>
              <Typography>{t("loginSubTitleText")}</Typography>
            </Box>
            {/* <Box component="form" noValidate autoComplete="off" className={`transparentInput`}>
              <div className={`${styles.inputBox}`}>
                <TextField required id="outlined-required" placeholder="Enter your Email Address" className={`${styles.inputBoxInput}`} />
              </div>
              <div className={`${styles.yinDivider}`}></div>
              <Button className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinFullWidth}`}>
                <span className={`${btnStyles.btnText}`}>
                  <span className={`${btnStyles.btnTitle}`}>Continue</span>
                </span>
              </Button>
            </Box> */}

            <Box component="form" noValidate autoComplete="off" className={`transparentInput`}>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  id="outlined-required"
                  placeholder="Enter your Email Address"
                  className={`${styles.inputBoxInput}`}
                  value={email}
                  onChange={handleEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                />
              </div>
              <div className={`${styles.yinDivider}`}></div>
              <Button
                className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinFullWidth}`}
                onClick={handleContinueClick}
              >
                <span className={`${btnStyles.btnText}`}>
                  <span className={`${btnStyles.btnTitle}`}>{t("textContinue")}</span>
                </span>
                {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
              </Button>
            </Box>
            <Typography className={`${styles.routeLinkBox}`}>
              {t("textDontAccount")}
              <Link to="/register" className={`${styles.routeLink}`}>
                {t("textRegisterNow")}
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card className={`${styles.authCard}`}>
            <img src={LoginBanner} alt="" />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
