import Box from "@mui/material/Box";
import { Card, CardContent, CardMedia, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import styles from "./teams.module.css";
// import teamImg from "../../../assets/images/team1.png";
import { Link } from "react-router-dom";
import messageIcon from "../../../assets/images/messageIcon.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectListAPI } from "../../../reducers/intialQuerySlice";
import Loader from "../../../components/common/Loader";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
export default function Teams(props) {
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { projectsListing, loading } = useSelector((state) => state.intialQuery);

  // useEffect(() => {

  // }, [activeMessageIndex]);

  useEffect(() => {
    if (!isMounted.current) {
      dispatch(projectListAPI());
      isMounted.current = true;
    }
  }, [dispatch]);

  const filteredProjects = projectsListing.filter((project) => project.status !== "not-submitted" && project.status !== "pending");

  const selectedProject = filteredProjects[activeMessageIndex];
  console.log("selectedProject", selectedProject);
  const starArray = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <>
      {filteredProjects && filteredProjects.length > 0 ? (
        <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
          {loading ? (
            <Box className="emptyStateBox">
              <Loader />{" "}
            </Box>
          ) : (
            <>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item sm={12} md={8}>
                  <Typography variant="h4" className={`panelHeading`}>
                    Team Members
                  </Typography>
                </Grid>
                <Grid item sm={12} md={4} className={`${styles.buttonAlignment}`}></Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={12} md={5} lg={4}>
                  <Card className={`panelCard mt-3`}>
                    <List>
                      {filteredProjects.map((project, index) => {
                        const { name, longDescription } = project;
                        return (
                          <ListItem
                            key={index}
                            className={`${styles.messageItem} ${activeMessageIndex === index ? styles.activeMsg : ""}`}
                            onClick={() => setActiveMessageIndex(index)}
                          >
                            <ListItemText className={`${styles.discussionBody}`}>
                              <Typography variant="h4" className={`${styles.messageSubject}`}>
                                {name}
                              </Typography>
                              <Typography variant="body" className={`${styles.messageSummary}`}>
                                {longDescription}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Card>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <Card className={`panelCard mt-3`}>
                    <Grid container spacing={2}>
                      {selectedProject && selectedProject.talents && selectedProject.talents.length > 0 ? (
                        selectedProject.talents.map((team, index) => {
                          const { userInfo } = team;
                          console.log("userInfo", userInfo);
                          return (
                            <Grid key={index} item sm={12} md={12} lg={6}>
                              <Link to={`/teams/${userInfo[0]?._id}`}>
                                <Card
                                  className={`panelCard mt-3 ${styles.overflowVisible} ${
                                    userInfo[0]?.proficiency === "Professional"
                                      ? styles.professionalBorder
                                      : userInfo[0]?.proficiency === "Genius"
                                      ? styles.geniusBorder
                                      : userInfo[0]?.proficiency === "Expert"
                                      ? styles.expertBorder
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`${styles.badge} ${styles[team.badge]} ${
                                      userInfo[0]?.proficiency === "Professional"
                                        ? styles.professional
                                        : userInfo[0]?.proficiency === "Genius"
                                        ? styles.genius
                                        : userInfo[0]?.proficiency === "Expert"
                                        ? styles.expert
                                        : ""
                                    }`}
                                  >
                                    {userInfo[0]?.proficiency}
                                  </div>
                                  <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                                    <Box className={`${styles.teamCard}`}>
                                      <CardMedia className={`${styles.teamMemberImg}`}>
                                        {userInfo[0]?.profilePicture === "null" || userInfo[0]?.profilePicture === "" ? (
                                          <span>{userInfo[0]?.name.charAt(0)}</span>
                                        ) : (
                                          <img src={userInfo[0]?.profilePicture} alt="TeamMember" />
                                        )}
                                      </CardMedia>
                                      <CardContent className={`${styles.teamMemberDetail}`}>
                                        <Typography variant="h4">
                                          {userInfo[0]?.name} - {userInfo[0]?.ratingData?.rating}
                                        </Typography>
                                        <Typography variant="body">
                                          Project(s): <b>{userInfo[0]?.totalProjectsCount}</b>
                                        </Typography>
                                        {/* {team.currentDeliverable === "" || team.currentDeliverable === "null" ? (
                                          <Typography variant="body">
                                            Current Deliverable: <b>{team.currentDeliverable} </b>
                                          </Typography>
                                        ) : (
                                          ""
                                        )} */}
                                        <Box className={`${styles.flexCard}`}>
                                          <div className={`${styles.ratingStar} flexCard`}>
                                            {/* <span> {userInfo[0]?.ratingData[0]?.rating}</span> */}
                                            {starArray.map((index) =>
                                              userInfo[0]?.ratingData[0]?.rating >= index ? (
                                                <StarIcon key={index} className={`cursor-pointer ${styles.ratingIcon} ${styles.rated}`} />
                                              ) : (
                                                <StarBorderIcon key={index} className={`cursor-pointer ${styles.ratingIcon}`} />
                                              ),
                                            )}
                                          </div>
                                        </Box>
                                      </CardContent>
                                    </Box>
                                    <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                                      <img src={messageIcon} alt="" />
                                    </Link>
                                  </div>
                                </Card>
                              </Link>
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item sm={12} md={12} lg={6}>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="body">No teams found for the selected project.</Typography>
                            </ListItemText>
                          </ListItem>
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ) : (
        <Box className={`${styles.emptyStateBox}`}>
          <Box className="emptyStateContainer">
            <DataNotFound icon={"team"} title={""} subtitle={"No Talent has been assigned yet!."} />
          </Box>
        </Box>
      )}
    </>
  );
}

/* {teams.map((team, index) => (
                  <Grid key={index} item sm={12} md={12} lg={6}>
                    <Card className={`panelCard mt-3 ${styles.overflowVisible}`} style={{ border: "1px solid #cacaca" }}>
                      <div className={`${styles.badge} ${styles[team.badge]}`}>{team.level}</div>
                      <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                        <Box className={`${styles.teamCard}`}>
                          <CardMedia className={`${styles.teamMemberImg}`}>
                            <img src={team.thumbnail} alt="TeamMember" />
                          </CardMedia>
                          <CardContent className={`${styles.teamMemberDetail}`}>
                            <Typography variant="h4">{team.name}</Typography>
                            <Typography variant="body">
                              Project(s): <b>{team.projects}</b>
                            </Typography>
                            <Typography variant="body">
                              Current Deliverable: <b>{team.currentDeliverable} </b>
                            </Typography>
                          </CardContent>
                        </Box>
                        <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                          <img src={messageIcon} alt="" />
                        </Link>
                      </div>
                    </Card>
                  </Grid>
                ))} */

// const teams = [
//   {
//     thumbnail: team,
//     name: "Zaire Korsgaard",
//     projects: "4",
//     currentDeliverable: "Designing on Figma",
//     level: "Expert",
//     badge: "expert",
//   },
//   {
//     thumbnail: team,
//     name: "Kadin Septimus",
//     projects: "24",
//     currentDeliverable: "Figma to Devlopment",
//     level: "Genius",
//     badge: "genius",
//   },
//   {
//     thumbnail: team,
//     name: "Angel Siphron",
//     projects: "5",
//     currentDeliverable: "Design Figma UI",
//     level: "Professional",
//     badge: "professional",
//   },
// ];
// const messageList = [
//   {
//     subject: "Recruitment & Talent Acquisition",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
//   {
//     subject: "Want to Create a Logo",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
//   {
//     subject: "Recruitment & Talent Acquisition",
//     summary: `I have repeatedly tried to get an environment to render for Open AI gym,
//       specifically the cartpole. Heres a picture showing exception in visual `,
//   },
// ];
