/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./tabs.module.css";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { ChatIcon, DescIcon, DocumentIcon, TeamImgIcon, TimelineIcon } from "../../../assets/yingoIcons";
// import { Link } from "react-router-dom";
// import messageIcon from "../../../assets/images/messageIcon.svg";
import { useDispatch, useSelector } from "react-redux";
// import teamImg from "../../../assets/images/team1.png";
import DownloadIcon from "@mui/icons-material/Download";
// Importing the ChatBox component from the relative path "../chatBox"
import ChatBox from "../ChatBox";
import { deliverableDocUploadAPI, deliverableTimelineAPI } from "../../../reducers/deliverablesSlice";
// import ChatBox from "../ChatBox";
import DataNotFound from "../PageNotFound/dataNotFound";
import Loader from "../Loader";
import AddIcon from "@mui/icons-material/Add";
import FileIcon from "../../../assets/icons/file-filled.svg";
import btnStyles from "../../../assets/button.module.css";
import UploadTalentDocument from "../../../pages/talent/deliverables/deliverableTabs/uploadDocument";
// import { useTheme } from "@mui/material/styles";
import DocPreviewModal from "../../../pages/talent/projects/components/DocPreviewModal";
import { Link } from "react-router-dom";
// import { talentPreviewAPI } from "../../../reducers/userSlice";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { TimesIcon, FileDarkIcon } from "../../../assets/yingoIcons";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function TabsListing(props) {
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [fileError, setFileError] = useState("");
  const [openDocPreviewModal, setOpenDocPreviewModal] = useState(false);
  const [selectedDocPathPreview, setSelectedDocPathPreview] = useState(null);

  const { infoLoading } = props;
  //const talentProjectState = useSelector((state) => state.deliverables);

  const { deliverableInfo, deliverableDocuments, docUploadLoading } = useSelector((state) => state.deliverables);
  console.log("deliverableInfo", deliverableInfo);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const theme = useTheme();

  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLGScreen = useMediaQuery("(max-width:767px)");

  useEffect(() => {
    if (deliverableInfo && deliverableInfo.length > 0) {
      const startDateObject = new Date(deliverableInfo[0]?.startDate); //endDate
      const endDateObject = new Date(deliverableInfo[0]?.endDate); //endDate
      setStartDate(startDateObject);
      setEndDate(endDateObject);
    }
  }, [deliverableInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleDateSelect = (type, date) => {
  //   if (type === "startDate") {
  //     setStartDate(date);
  //     const originalStartDate = new Date(date);

  //     const request = {
  //       startDate: originalStartDate,
  //       deliverableId: deliverableInfo[0]?._id,
  //       endDate: endDate,
  //     };

  //     dispatch(deliverableTimelineAPI(request));
  //   } else {
  //     setEndDate(date);
  //     const originalEndDate = new Date(date);

  //     const request = {
  //       startDate: startDate,
  //       deliverableId: deliverableInfo[0]?._id,
  //       endDate: originalEndDate,
  //     };

  //     dispatch(deliverableTimelineAPI(request));
  //   }
  // };

  const handleDateSelect = (type, date) => {
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (type === "startDate") {
      newStartDate = date;
    } else {
      newEndDate = date;
    }

    const request = {
      startDate: new Date(newStartDate),
      deliverableId: deliverableInfo[0]?._id,
      endDate: new Date(newEndDate),
    };

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    dispatch(deliverableTimelineAPI(request));
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setFile(null);
    setFileError("");
    setTitleError("");
  };

  const groupedDeliverablesDocuments = deliverableDocuments?.reduce((acc, doc) => {
    const dateKey = new Date(doc.created_at).toDateString();
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(doc);
    return acc;
  }, {});

  const handleTitleChange = (event) => {
    setTitleError("");
    setTitle(event.target.value);
  };

  const handleRemoveDoc = (event) => {
    setTitleError("");
    setTitle("");
    setFile(null);
    setFileError("");
    setTitleError("");
  };
  const handleFileChange = (acceptedFiles) => {
    setFileError("");
    // Assuming you only want to handle the first file
    const selectedFile = acceptedFiles[0];

    // Check if the file type is either PNG or JPG
    // const allowedFileTypes = ["image/png", "image/jpeg"];
    // if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
    if (selectedFile) {
      setFile(selectedFile);

      // Set the title as the file name
      setTitle(selectedFile.name);
    } else {
      // Display an error message or handle the case where the file type is not allowed
      console.error("Invalid file type. Please select a PNG or JPG image.");
    }
  };

  const handleFileUpload = () => {
    if (file === null) {
      setFileError("File is required !");
      return;
    }

    const extenionArray = ["jpeg", "jpg", "png", "pdf", "txt", "doc", "docx", "xls", "ppt", "zip"];
    const fileExtension = file.path.split(".").pop();

    if (!extenionArray.includes(fileExtension.toLowerCase())) {
      setFileError("Invalid File Type");
      return;
    }
    if (title.trim() === "") {
      // Display an error message or handle the case where title is empty or file is not selected
      setTitleError("Title is required !");
      return;
    }
    setFileError("");
    setTitleError("");
    const request = {
      doc: file,
      deliverableId: deliverableInfo[0]?._id,
      title: title,
    };
    dispatch(deliverableDocUploadAPI(request, handleClose));

    // handleClose();
  };

  // const handleOpenDocPreviewModal = (path) => {
  //   setSelectedDocPathPreview(path);
  //   setOpenDocPreviewModal(true);
  // };

  const handleCloseDocPreviewModal = () => {
    setSelectedDocPathPreview(null);
    setOpenDocPreviewModal(false);
  };
  // const handleTalentPreview = (talentId) => {

  //   navigate(`/talent-detail/${talentId}`);
  //   dispatch(talentPreviewAPI(talentId));
  // };

  const handleDownload = (path) => {
    fetch(path)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", path); // Empty string for the attribute will use the default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };
  const starArray = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <>
      {" "}
      {/* <Card className={`${styles.deliverableCard} mt-3`} style={{ boxShadow: "none" }}>
        <Grid container>
          <Grid item sm={12} className={`${styles.widthFull}`}> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className={`deliverableTabs ${styles.customTabs}`}
            value={value}
            onChange={handleChange}
            variant={isLGScreen ? "fullWidth" : "scrollable"}
            scrollButtons={isLGScreen ? "" : "auto"}
            aria-label=""
          >
            <Tab
              wrapped
              label={
                isLGScreen ? (
                  <Tooltip title="Comments">
                    <Box className={`${styles.tabIcon}`}>
                      <ChatIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  "Comments"
                )
              }
              {...a11yProps(0)}
            />
            <Tab
              wrapped
              label={
                isLGScreen ? (
                  <Tooltip title="Description">
                    <Box className={`${styles.tabIcon}`}>
                      <DescIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  "Description"
                )
              }
              {...a11yProps(1)}
            />
            <Tab
              wrapped
              label={
                isLGScreen ? (
                  <Tooltip title="Documents">
                    <Box className={`${styles.tabIcon}`}>
                      <DocumentIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  "Documents"
                )
              }
              {...a11yProps(2)}
            />
            <Tab
              wrapped
              label={
                isLGScreen ? (
                  <Tooltip title="Team">
                    <Box className={`${styles.tabIcon}`}>
                      <TeamImgIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  "Team"
                )
              }
              {...a11yProps(3)}
            />
            <Tab
              wrapped
              label={
                isLGScreen ? (
                  <Tooltip title="Timeline">
                    <Box className={`${styles.tabIcon}`}>
                      <TimelineIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  "Timeline"
                )
              }
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} className="">
          {infoLoading ? (
            <Box className="emptyStateContainer">
              <Loader />
            </Box>
          ) : (
            <ChatBox />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} className="">
          <Typography variant="body">
            {infoLoading ? (
              <Box className="emptyStateContainer">
                <Loader />
              </Box>
            ) : deliverableInfo[0] && deliverableInfo[0].description?.length < 0 ? (
              <Box>
                <Typography className={`${styles.tabParagraph}`}>{deliverableInfo[0].description}</Typography>
              </Box>
            ) : (
              <Box className={`${styles.emptyStateBox}`}>
                <Box className="emptyStateContainer">
                  <DataNotFound icon={"record"} title={""} subtitle={"No Description Added yet!"} />
                </Box>
              </Box>
            )}
          </Typography>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Box className={`${styles.flexCard} justified-end`}>
            <IconButton
              onClick={() => setOpen(true)}
              className={` ${styles.positionBtn}  ${styles.iconXLBtn}   ${btnStyles.iconBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <div>
            <Box className={`${styles.deliverableTabContainer}`}>
              <Grid container spacing={2}>
                {infoLoading ? (
                  <Loader />
                ) : groupedDeliverablesDocuments && Object.entries(groupedDeliverablesDocuments).length > 0 ? (
                  Object.entries(groupedDeliverablesDocuments).map(([date, documents]) => (
                    <React.Fragment key={date}>
                      <Grid item sm={12} md={12} className={`mt-2`}>
                        <Typography className={`${styles.docCardTitle}`}>{date}</Typography>
                      </Grid>
                      {documents.map((doc) => {
                        const { _id, title, path } = doc;
                        return (
                          <Grid key={_id} item sm={12} md={6}>
                            <Box className={`${styles.docCardBox} `}>
                              <Box className={`${styles.flexCard} justified-between `}>
                                <Box className={`${styles.flexCard} ${styles.gap10}`}>
                                  <Box className={`${styles.docCardIcon}`}>
                                    <img src={FileIcon} alt="" />
                                  </Box>
                                  <Typography className={`${styles.docCardTitle}`}>{title}</Typography>
                                </Box>
                                <Box className={`${styles.flexCard}`}>
                                  <IconButton onClick={() => handleDownload(path)}>
                                    <DownloadIcon className={`${styles.colorPrimary}`} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  ))
                ) : (
                  <Grid item sm={12} md={12} className={``}>
                    <Box className={`${styles.emptyStateBox}`}>
                      <Box className="emptyStateContainer">
                        <DataNotFound icon={"record"} title={""} subtitle={"No Document Uploaded yet!"} />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3} className="">
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              {infoLoading ? (
                <Box className="emptyStateContainer">
                  <Loader />
                </Box>
              ) : deliverableInfo[0] && deliverableInfo[0].talents?.length > 0 ? (
                deliverableInfo[0].talents.map((team, index) => {
                  const { userInfo } = team;
                  console.log("userInfo", userInfo);
                  return (
                    <Grid key={index} item sm={12} md={12} lg={5}>
                      <Link to={`/deliverables/${userInfo[0]?._id}`}>
                        {/* <Link to="/userInfo[0]?._id" className="cursor-pointer"> */}
                        <Card
                          className={`panelCard mt-3 ${styles.overflowVisible}  ${
                            userInfo[0]?.proficiency === "Professional"
                              ? styles.professionalBorder
                              : userInfo[0]?.proficiency === "Genius"
                              ? styles.geniusBorder
                              : userInfo[0]?.proficiency === "Expert"
                              ? styles.expertBorder
                              : ""
                          }`}
                        >
                          <div
                            className={`${styles.badge} ${styles[team.badge]} ${
                              userInfo[0]?.proficiency === "Professional"
                                ? styles.professional
                                : userInfo[0]?.proficiency === "Genius"
                                ? styles.genius
                                : userInfo[0]?.proficiency === "Expert"
                                ? styles.expert
                                : ""
                            }`}
                          >
                            {userInfo[0]?.proficiency}
                          </div>
                          <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                            <Box className={`${styles.teamCard}`}>
                              <CardMedia className={`${styles.teamMemberImg}`}>
                                {userInfo[0]?.profilePicture === "null" || userInfo[0]?.profilePicture === "" ? (
                                  <span>{userInfo[0]?.name.charAt(0)}</span>
                                ) : (
                                  <img src={userInfo[0]?.profilePicture} alt="TeamMember" />
                                )}
                              </CardMedia>
                              <CardContent className={`${styles.teamMemberDetail}`}>
                                <Typography variant="h4">{userInfo[0]?.name}</Typography>
                                <Typography variant="body">
                                  <b>{userInfo[0]?.totalProjectsCount}</b> Project(s)
                                </Typography>
                                <Box className={`${styles.flexCard}`}>
                                  <div className={`${styles.ratingStar} flexCard`}>
                                    {starArray.map((index) =>
                                      userInfo[0]?.ratingData[0]?.rating >= index ? (
                                        <StarIcon key={index} className={`cursor-pointer ${styles.ratingIcon} ${styles.rated}`} />
                                      ) : (
                                        <StarBorderIcon key={index} className={`cursor-pointer ${styles.ratingIcon}`} />
                                      ),
                                    )}
                                  </div>
                                </Box>
                              </CardContent>
                            </Box>
                            {/* <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                                  <img src={messageIcon} alt="" />
                                </Link> */}
                          </div>
                        </Card>
                      </Link>
                    </Grid>
                  );
                })
              ) : (
                <Box className={`${styles.emptyStateBox}`}>
                  <Box className="emptyStateContainer">
                    <DataNotFound icon={"team"} title={""} subtitle={"No Talent has been assigned yet!."} />
                  </Box>
                </Box>
              )}
            </Grid>

            {/* {teams.map((team, index) => (
                    <Grid key={index} item sm={12} md={4}>
                      <Card className={`panelCard mt-3 ${styles.overflowVisible} ${styles.border}`}>
                        <div className={`${styles.badge} ${styles[team.badge]}`}>{team.level}</div>
                        <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                          <Box className={`${styles.teamCard}`}>
                            <CardMedia className={`${styles.teamMemberImg}`}>
                              <img src={team.thumbnail} alt="TeamMember" />
                            </CardMedia>
                            <CardContent className={`${styles.teamMemberDetail}`}>
                              <Typography variant="h4">{team.name}</Typography>
                              <Typography variant="body">
                                Project(s): <b>{team.projects}</b>
                              </Typography>
                              <Typography variant="body">
                                Current Deliverable: <b>{team.currentDeliverable} </b>
                              </Typography>
                            </CardContent>
                          </Box>
                          <Link to="/create-ticket" className={`${styles.messageIcon}`}>
                            <img src={messageIcon} alt="" />
                          </Link>
                        </div>
                      </Card>
                    </Grid>
                  ))} */}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4} className="">
          {infoLoading ? (
            <Box className="emptyStateContainer">
              <Loader />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={6} lg={4}>
                <Typography variant="h4" className={`${styles.subtile}`}>
                  Start Date
                </Typography>
                <div className={`${styles.datePickerBox}`}>
                  <DatePicker
                    className={`${styles.datePicker}`}
                    selected={startDate}
                    onChange={(date) => handleDateSelect("startDate", date)} // when day is clicked
                    dateFormat="yyyy/MM/dd"
                    minDate={new Date()}
                  />
                </div>
              </Grid>
              <Grid item sm={6} lg={4}>
                <Typography variant="h4" className={`${styles.subtile}`}>
                  End Date
                </Typography>
                <div className={`${styles.datePickerBox}`}>
                  <DatePicker
                    className={`${styles.datePicker}`}
                    selected={endDate}
                    onChange={(date) => handleDateSelect("endDate", date)} // when day is clicked
                    dateFormat="yyyy/MM/dd"
                    minDate={new Date(startDate)}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </CustomTabPanel>
      </Box>
      {/* </Grid>
        </Grid>
      </Card> */}
      {/* /* File Upload Modal */}
      <Dialog className={`docDialogCard`} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          <Typography className={`${styles.docCardTitle}`}>Upload Document</Typography>
        </DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <InputLabel className={`${styles.inputLabel} `}>Document File</InputLabel>

          {file != null ? (
            <>
              <Box className={`${styles.doclistitem}`}>
                <Box class={`${styles.flexCard}`}>
                  <Box className={`${styles.docImgs}`}>
                    <FileDarkIcon />
                  </Box>
                  <span className={`${styles.docTitle}`}>{file.name} </span>
                </Box>
                <p className={`${styles.closeUploadBtn}`} onClick={handleRemoveDoc}>
                  <TimesIcon />
                </p>
              </Box>
            </>
          ) : (
            <UploadTalentDocument onDropChange={handleFileChange} />
          )}

          <p style={{ margin: 0, color: "red", fontSize: "13px" }}>{fileError}</p>

          <div className={`${styles.inputGroup} mt-2 mb-2`}>
            <InputLabel className={`${styles.inputLabel} `}>Document Title</InputLabel>
            <div className={`${styles.inputBox}`}>
              <TextField required className={`${styles.inputBoxInput}`} value={title} onChange={handleTitleChange} />
            </div>
            <p style={{ margin: 0, color: "red", fontSize: "13px" }}>{titleError}</p>
          </div>

          <Box className={`${styles.flexCard} justified-center mt-3`}>
            <Button
              onClick={handleClose}
              className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
              style={{ marginRight: "10px" }}
              disabled={docUploadLoading}
            >
              Cancel
            </Button>
            <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleFileUpload}>
              Upload {docUploadLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openDocPreviewModal && (
        <DocPreviewModal
          openDocPreviewModal={openDocPreviewModal}
          selectedDocPathPreview={selectedDocPathPreview}
          handleCloseDocPreviewModal={handleCloseDocPreviewModal}
        />
      )}
    </>
  );
}
