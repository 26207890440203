export const instructions = {
  defaultCategories: [
    {
      categoryId: 1,
      name: "Rya Assistant",
      actionId: "asst_PZvXtLOL923aZTKcDrz1p595",
      description: "How can i help you",
    },
  ],
  categories: [
    {
      categoryId: 1,
      name: "Translation",
      subcategories: [
        {
          name: "English to Spanish",
          actionId: "asst_SrmNT9k2AWboUrWhM6FnHbF7",
          description: "Translate text from English to Spanish",
        },
        {
          name: "Spanish to English",
          actionId: "asst_95EFI1hDnIDzfVtVzHt6v5ZX",
          description: "Translate text from Spanish to English",
        },
      ],
    },
    {
      categoryId: 2,
      name: "Marketing",
      subcategories: [
        {
          name: "Generate Cover Letter",
          actionId: "asst_1MF01INRkFovbNJm0jmKpLnl",
          description: "Example: Executive Assistant, Software Developer",
        },
        {
          name: "Create Job Posting",
          actionId: "asst_Xf0MwLmmPN4E2kgGlr450i2z",
          description: "Example: Executive Assistant, Software Developer",
        },
      ],
    },
    {
      categoryId: 3,
      name: "Employee Survey Questionnaire",
      subcategories: [
        {
          name: "Employee Engagement Survey",
          actionId: "asst_oMzMN4dDDdMVaQ4PsNY9PJz2",
          description: "Example: Executive Assistant, Software Developer",
        },
        {
          name: "Workplace Diversity Survey",
          actionId: "asst_7aVBlqIcK6aPCqR7oXrArYIL",
          description: "Example: Executive Assistant, Software Developer",
        },
      ],
    },
  ],
};
