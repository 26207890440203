// User Auth Methods
export const registerAPIMethods = "/user/register";
export const customerLoginAPIMethods = "/user/v1/login";
export const verifyOTPAPIMethods = "/user/verify";
export const resendOTPAPIMethods = "/user/resendOtp";

// User Profile Methods
export const getProfileAPIMethods = "/user/profile";
export const updateProfileAPIMethods = "/user/profile-update";
export const getTalentPreviewAPIMethods = "/user/talent/profile";
export const getRatingTalentAPIMethods = "/user/ratingToTalent/admin";
export const talentSubmitVideoAPIMethods = "/user/updateVideoUrl/admin";

/* Customer API's methods */

// Customer Projects Methods
export const projectCreateAPIMethods = "/project/create";
export const projectDetailAPIMethods = "/project/projectInfo";
export const projectListAPIMethods = "/project/list";
export const projectListByStatusAPIMethods = "/project/by/status";
export const projectTitleAPIMethods = "/project/update";
export const projectDocAPIMethods = "/project/document/upload";
export const projectStatusAPIMethods = "/project/update/status/user";
export const customerRemoveProjectAPIMethods = "/project/projectDelete";

//Customer Deliverables Methods
export const deliverablesListAPIMethods = "/project/deliverable";
export const deliverableInfoAPIMethods = "/project/deliverable/info";
export const deliverableCommentListAPIMethods = "/comment/list";
export const deliverableDocumentListAPIMethods = "/project/deliverable/documents/get";
export const deliverableDocumentUploadAPIMethods = "/project/deliverable/document/upload";
export const deliverableCommentAPIMethods = "/comment/create";
export const deliverableProjectListAPIMethods = "/project/deliverable/project/list";
export const deliverableTimelineAPIMethods = "/project/deliverable/update";

//Customer Status Update Methods GD
export const customerDeliverableForReviewAPIMethods = "/project/deliverable/status/update";
export const getDeliverableStatusProjectListAPIMethods = "/project/getDeliverableStatusNotification";

//Customer Support Methods
export const supportListAPIMethods = "/ticket/list/user";
export const supportTicketComposeAPIMethods = "/ticket/compose";
export const supportTicketCommentAPIMethods = "/ticket/comment";
export const supportTicketCommentListAPIMethods = "/ticket/comment/list";
export const supportTicketInfoAPIMethods = "/ticket/info";
export const supportTicketStatusAPIMethods = "/ticket/status/update";

/* Admin API's methods */

//Admin Projects Methods
export const projectStatusAdminAPIMethods = "/project/update/status/admin";
export const projectDeleteAdminAPIMethods = "/project/delete/admin";

//Admin Deliverables Methods
export const deliverablesListAdminAPIMethods = "/project/deliverable/list/admin";

//Admin Talents Methods
export const registerAdminTalentAPIMethods = "/user/addTalent/admin";
export const talentsListAPIMethods = "/talent/list";
export const talentAssignAPIMethods = "/talent/assign";
export const getAdmintTalentsListAPIMethods = "/user/GetTalentList/admin";

//Admin Support Methods
export const supportListAdminAPIMethods = "/ticket/list/admin";
export const supportTicketCommentAdminAPIMethods = "/ticket/comment/admin";
export const supportTicketCommentListAdminAPIMethods = "/ticket/comment/list/admin";
export const supportTicketInfoAdminAPIMethods = "/ticket/info/admin";
// export const supportTicketComposeAdminAPIMethods = "/ticket/compose";

/* Talent API's methods */

//Talent Auth
export const talentLoginAPIMethods = "/talent/talentLogin";
export const talentRegisterAPIMethods = "/talent/talentRegister";

//Talent Profile
export const talentProfileAPIMethods = "/talent/getTalentProfile";
export const talentUpdateProfileAPIMethods = "/talent/updateTalentProfile";
export const talentAddEducationAPIMethods = "/talent/addEducationToTalent";
export const talentEditEducationAPIMethods = "/talent/educationEdit";
export const talentDeleteEducationAPIMethods = "/talent/deleteEducation";
export const talentAddExperienceAPIMethods = "/talent/addExperinceToTalent";
export const talentEditExperienceAPIMethods = "/talent/experienceEdit";
export const talentDeleteExperienceAPIMethods = "/talent/deleteExperience";
export const talentAddSkillAPIMethods = "/talent/addSkillToTalent";
export const talentAddAiToolsAPIMethods = "/talent/addAiToTalent";
export const talentSkillListAPIMethods = "/talent/getSkillList";
export const talentAiListAPIMethods = "/talent/getAiList";

//Talent New Request
export const talentNewRequestListAPIMethods = "/talent/getNewRequest";
export const talentAcceptRequestAPIMethods = "/talent/acceptRequest";
export const talentRejectRequestAPIMethods = "/talent/rejectRequest";
export const talentRequestDetailAPIMethods = "/talent/requestDetail";

//Talent Projects
export const talentProjectListByStatusAPIMethods = "/talent/getProjectListByStatus";
export const talentProjectDetailAPIMethods = "/talent/projectDetail";
export const talentProjectListAPIMethods = "/talent/getProjectListByTalent";

//Talent Deliverables
export const talentDeliverableChatListAPIMethods = "/talent/deliverableChatList";
export const talentDeliverableChatAPIMethods = "/talent/deliverableChat";
export const talentDeliverableDocUploadAPIMethods = "/talent/document/upload";
export const talentDeliverableDocAPIMethods = "/talent/getDeliverableDocuments";
export const talentDeliverableForReviewAPIMethods = "/talent/submitForReview";
export const talentSupportDeliverablesAPIMethods = "/project/deliverable/list";

//RYA BOT API
export const getRyaTalentProjectsMethods = "/talent/getProjectListForTalent";
export const getRyaChatAPIMethods = "/talent/riaBotChatApi";
