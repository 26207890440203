import { callAPI } from "../config/api-core";
import {
  deliverableCommentAPIMethods,
  deliverableCommentListAPIMethods,
  deliverableDocumentListAPIMethods,
  deliverableDocumentUploadAPIMethods,
  deliverableInfoAPIMethods,
  deliverableProjectListAPIMethods,
  deliverableTimelineAPIMethods,
  deliverablesListAPIMethods,
  getDeliverableStatusProjectListAPIMethods,
  talentSupportDeliverablesAPIMethods,
} from "../methods";

const deliverablesListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let storedValue = localStorage.getItem("selectedProjectId");

  let header = {
    Authorization: userToken && userToken,
  };

  if (storedValue === "" || storedValue === "Select" || storedValue === null || storedValue === undefined) {
    let params = request ? `?projectID=${request}` : "";
    const response = await callAPI(deliverablesListAPIMethods + params, true, "", header, false, false);
    console.log("CCCCfirst", response);
    return response;
  } else {
    let params = request ? `?projectID=${storedValue}` : "";
    const response = await callAPI(deliverablesListAPIMethods + params, true, "", header, false, false);

    return response;
  }
};

const deliverableInfoAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = `?deliverableId=${request}`;

  const response = await callAPI(deliverableInfoAPIMethods + params, true, "", header, false, false);
  return response;
};

const deliverableCommentListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = `?deliverableId=${request}`;

  const response = await callAPI(deliverableCommentListAPIMethods + params, true, "", header, false, false);
  return response;
};

const deliverableDocumentListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = `?deliverableId=${request}`;

  const response = await callAPI(deliverableDocumentListAPIMethods + params, true, "", header, false, false);
  return response;
};

const deliverableDocUploadAPI = async (request) => {
  const { doc, deliverableId, title } = request;

  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  if (doc && doc instanceof File) {
    formData.append("file", doc);
  }
  formData.append("deliverableId", deliverableId);
  formData.append("title", title);

  const response = await callAPI(deliverableDocumentUploadAPIMethods, false, formData, header, false, false);
  return response;
};

const deliverableCommentAPI = async (request) => {
  const response = await callAPI(deliverableCommentAPIMethods, false, request, {}, false, false);
  return response;
};

const deliverableTimelineAPI = async (request) => {
  const response = await callAPI(deliverableTimelineAPIMethods, false, request, {}, false, false);
  return response;
};

/******* talentNewRequestListAPI  GD ******* */

const deliverableProjectListAPI = async () => {
  let userToken = localStorage.getItem("token");
  let userRole = localStorage.getItem("role");
  let header = {
    Authorization: userToken && userToken,
  };
  let params = `?role=${userRole}`;
  console.log("ddSS", params);
  const response = await callAPI(deliverableProjectListAPIMethods + params, true, "", header, false, false);
  console.log("SS", response);
  return response;
};

const getDeliverableStatusProjectListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(getDeliverableStatusProjectListAPIMethods, true, "", header, false, false);
  return response;
};

const getTalentSupportDeliverablesAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };

  let params = request ? `?projectID=${request}` : "";
  const response = await callAPI(talentSupportDeliverablesAPIMethods + params, true, "", header, false, false);
  console.log("ttttfirst", response);
  return response;
};

export const getDeliverablesListAPI = (request) => deliverablesListAPI(request);
export const getDeliverableInfoAPI = (request) => deliverableInfoAPI(request);
export const getDeliverableCommentListAPI = (request) => deliverableCommentListAPI(request);
export const getDeliverableDocumentListAPI = (request) => deliverableDocumentListAPI(request);
export const postDeliverableCommentAPI = (request) => deliverableCommentAPI(request);
export const getDeliverableProjectListAPI = () => deliverableProjectListAPI();
export const postDeliverableTimelineAPI = (request) => deliverableTimelineAPI(request);
export const postDeliverableDocumentUploadAPI = (request) => deliverableDocUploadAPI(request);

export const getDeliverableStatusProjectList = (request) => getDeliverableStatusProjectListAPI(request);
export const talentSupportDeliverablesAPI = (request) => getTalentSupportDeliverablesAPI(request);

export default {
  getDeliverablesListAPI,
  getDeliverableInfoAPI,
  getDeliverableCommentListAPI,
  getDeliverableDocumentListAPI,
  postDeliverableCommentAPI,
  getDeliverableProjectListAPI,
  postDeliverableTimelineAPI,
  postDeliverableDocumentUploadAPI,
  getDeliverableStatusProjectList,
};

// let params = `?refer_patient_id=${request}`
// const response = await callAPI(
//   activityAPIMethods+params,
