import { DEFAULT_API_CONFIG } from "./api-config";
import axios from "axios";

const CreateAPI = async () => {
  let userToken = localStorage.getItem("token");

  return axios.create({
    baseURL: DEFAULT_API_CONFIG.url,
    timeout: DEFAULT_API_CONFIG.timeout,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userToken && userToken,
    },
  });
};

export async function callAPI(url, isGet, request = {}, header = {}, isPut, isDelete) {
  const api = await CreateAPI();

  let response = {};
  if (!isPut && !isDelete) {
    response = isGet ? await api.get(url, request) : await api.post(url, request, { headers: header });
    return response;
  } else if (isDelete) {
    response = api.delete(url, request, { headers: header });
  } else {
    response = api.put(url, request, { headers: header });
  }
  return response;
}
