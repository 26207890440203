import { callAPI } from "../config/api-core";
import { getRyaChatAPIMethods, getRyaTalentProjectsMethods } from "../methods";

const getRyaTalentProjectsListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(getRyaTalentProjectsMethods, true, "", header, false, false);
  return response;
};
const getRyaChatAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(getRyaChatAPIMethods, false, request, header, false, false);
  return response;
};

export const getRyaTalentProjects = (request) => getRyaTalentProjectsListAPI(request);
export const getRyaChat = (request) => getRyaChatAPI(request);

export default {
  getRyaTalentProjectsListAPI,
};
