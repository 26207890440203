/* eslint-disable react/prop-types */
import { Box, Button, CircularProgress, Drawer, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import styles from "./newTalentDrawer.module.css";
import btnStyles from "../../../assets/button.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { adminRegisterTalentAPI } from "../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";

function NewTalentDrawer({ isDrawerOpen, handleDrawerClose }) {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [levelInput, setLevelInput] = useState("Select");
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLevel, setIsValidLevel] = useState(true);
  const handleNameChange = (event) => {
    setNameInput(event.target.value);
    setIsValidName(true);
  };
  const handlePhoneChange = (event) => {
    setPhoneInput(event.target.value);
    setIsValidPhone(true);
  };
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmailInput(email);
    setIsValidEmail(validateEmail(email));
  };

  const handleLevelChange = (event) => {
    const level = event.target.value;
    setLevelInput(level);
    setIsValidLevel(true);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const handleButtonClick = async () => {
    const data = {
      name: nameInput,
      email: emailInput,
      phone: phoneInput,
      proficiency: levelInput,
    };

    if (!nameInput.trim()) {
      setIsValidName(false);
      return;
    }
    if (!validateEmail(emailInput)) {
      setIsValidEmail(false);
      return;
    }
    if (!phoneInput.trim()) {
      setIsValidPhone(false);
      return;
    }
    if (!levelInput || levelInput === "Select") {
      setIsValidLevel(false);
      return;
    }
    console.log("levelInput", levelInput);
    dispatch(adminRegisterTalentAPI(data, handleDrawerClose));
    setLevelInput("");
    setNameInput("");
    setEmailInput("");
    setPhoneInput("");
    setIsValidName(true);
    setIsValidEmail(true);
    setIsValidPhone(true);
    setIsValidLevel(true);
    handleDrawerClose();
  };
  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose} hideBackdrop>
      {/* Drawer content goes here */}
      <Box className={`${styles.drawerWidth} `}>
        <div className={`${styles.drawerHeader} flexCard justified-between`}>
          <Typography variant="h4">New Talent</Typography>
          <IconButton onClick={handleDrawerClose}>
            {" "}
            <CloseIcon />
          </IconButton>
        </div>
        <Box component="form" className={`${styles.drawerInputBox} mt-3`}>
          <Box className={`${styles.formDrawerBox}`}>
            <div className={`${styles.inputGroup} mb-2 mt-3`}>
              <InputLabel className={`${styles.inputLabel} `}>Name</InputLabel>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  className={`${styles.inputBoxInput}`}
                  name="name"
                  value={nameInput}
                  onChange={handleNameChange}
                  error={!isValidName}
                />
              </div>
              {!isValidName && <span className={`${styles.errorMessage}`}>Please enter name.</span>}
            </div>
            <div className={`${styles.inputGroup} mb-2 mt-3`}>
              <InputLabel className={`${styles.inputLabel} `}>Email Address</InputLabel>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  className={`${styles.inputBoxInput}`}
                  name="name"
                  value={emailInput}
                  onChange={handleEmailChange}
                  error={!validateEmail}
                />
              </div>
              {!isValidEmail && <span className={`${styles.errorMessage}`}>Please enter email address.</span>}
            </div>
            <div className={`${styles.inputGroup} mb-2 mt-3`}>
              <InputLabel className={`${styles.inputLabel} `}>Phone</InputLabel>
              <div className={`${styles.inputBox}`}>
                <TextField
                  required
                  className={`${styles.inputBoxInput}`}
                  name="name"
                  value={phoneInput}
                  onChange={handlePhoneChange}
                  error={!isValidPhone}
                  type="number"
                />
              </div>
              {!isValidPhone && <span className={`${styles.errorMessage}`}>Please enter phone.</span>}
            </div>
            <div className={`${styles.inputGroup} mb-2 mt-3`}>
              <InputLabel className={`${styles.inputLabel} `}>Level</InputLabel>
              <div className={`${styles.inputBox} proficiency-select`}>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={levelInput} label="Age" onChange={handleLevelChange}>
                  <MenuItem value={"Professional"}>Professional</MenuItem>
                  <MenuItem value={"Expert"}>Expert</MenuItem>
                  <MenuItem value={"Genius"}>Genius</MenuItem>
                </Select>
              </div>
              {!isValidLevel && <span className={`${styles.errorMessage}`}>Please select proficiency level.</span>}
            </div>
          </Box>

          <div className={`${styles.buttonBox} fullWidth`}>
            <Button onClick={handleButtonClick} className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}>
              Submit {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
            </Button>
          </div>
        </Box>
      </Box>
    </Drawer>
  );
}

export default NewTalentDrawer;
