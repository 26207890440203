import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Dashboard from "../pages/dashboard/index";
import Result from "../pages/dashboard/result";
import Preview from "../pages/dashboard/preview";
import Pricing from "../pages/dashboard/pricing";
import AuthLogin from "../pages/auth/login";
import CustomerLogin from "../pages/auth/customerLogin";
import AuthOtp from "../pages/auth/otp";
import AuthRegister from "../pages/auth/register";
import Projects from "../pages/customer/projects";
import ProjectDetails from "../pages/customer/projects/projectDetails";
import Deliverables from "../pages/customer/deliverables";
import Invoices from "../pages/customer/invoices";
import Teams from "../pages/customer/teams";
import Profile from "../pages/profile";
import Messages from "../pages/customer/messages";
import NewTicket from "../pages/customer/messages/createTicket";
import GlobalSnackbar from "../components/common/snackbar/GlobalSnackbar";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import NewRequests from "../pages/admin/new-requests";

import TalentOTPVerify from "../pages/auth/talentOTPVerify";
import TalentRegisterName from "../pages/auth/talentRegisterName";
import CustomerTalentProfile from "../pages/customer/talentDetails/talentProfile";

/**** Talent ****/
import TalentProfile from "../pages/talent/profile";
import TalentRequests from "../pages/talent/requests";
import TalentProjects from "../pages/talent/projects";
import TalentProjectDetail from "../pages/talent/projects/projectDetail";
import TalentRequestDetail from "../pages/talent/requests/requestDetails";
import TalentInvoices from "../pages/talent/invoices";

/**** ADMIN ****/
import AdminProjects from "../pages/admin/projects";
import AdminProjectDetail from "../pages/admin/projects/projectDetails";
import PageNotFound from "../components/common/PageNotFound";
import TalentAuth from "../pages/auth/talentAuth";
import { ROLES } from "../utils/constant";
import AdminTalentProfile from "../pages/admin/projects/talentProfile";
import Talents from "../pages/admin/talents";
import TalentDetails from "../pages/admin/talents/talentDetails";
import RyaDashboard from "../pages/talent/ryabot";
import Terms from "../pages/dashboard/terms";

const RouteIndex = () => {
  return (
    <>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <GlobalSnackbar />
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <AuthLogin />
                </PublicRoute>
              }
            />

            <Route path="/result" element={<Result />} />
            <Route path="/preview" element={<Preview />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/terms" element={<Terms />} />

            <Route path="/customerLogin" element={<CustomerLogin />} />
            <Route
              path="/otp"
              element={
                <PublicRoute>
                  <AuthOtp />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <AuthRegister />
                </PublicRoute>
              }
            />

            <Route
              path="/talent-auth"
              element={
                <PublicRoute>
                  <TalentAuth />
                </PublicRoute>
              }
            />
            <Route
              path="/talent-name"
              element={
                <PublicRoute>
                  <TalentRegisterName />
                </PublicRoute>
              }
            />
            <Route
              path="/talent-otp"
              element={
                <PublicRoute>
                  <TalentOTPVerify />
                </PublicRoute>
              }
            />

            <Route element={<ProtectedRoute allowedRoles={ROLES.User} />}>
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetails />} />
              <Route path="/deliverables" element={<Deliverables />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/support" element={<Messages />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/create-ticket" element={<NewTicket />} />
              <Route path="/deliverables/:id" element={<CustomerTalentProfile />} />
              <Route path="/teams/:id" element={<CustomerTalentProfile />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={ROLES.Admin} />}>
              <Route path="/admin-dahbaord" element={<AdminProjects />} />
              <Route path="/preview/:id" element={<AdminProjectDetail />} />
              <Route path="/requests" element={<NewRequests />} />
              <Route path="/talent-preview" element={<AdminTalentProfile />} />
              <Route path="/admin-support" element={<Messages />} />
              <Route path="/talents" element={<Talents />} />
              <Route path="/talents/:id" element={<TalentDetails />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={ROLES.Talent} />}>
              <Route path="/talent-profile" element={<TalentProfile />} />
              <Route path="/talent-requests" element={<TalentRequests />} />
              <Route path="/talent-request-detail/:id" element={<TalentRequestDetail />} />
              <Route path="/talent-projects" element={<TalentProjects />} />
              <Route path="/talent-project-detail/:id" element={<TalentProjectDetail />} />
              <Route path="/talent-invoices" element={<TalentInvoices />} />
              <Route path="/talent-support" element={<Messages />} />
              <Route path="/talent-ticket" element={<NewTicket />} />
              <Route path="/rya-dashboard" element={<RyaDashboard />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </>
  );
};
export default RouteIndex;
