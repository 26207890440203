/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CssBaseline } from "@mui/material";
import Sidebar from "../../../components/common/sidebar";

export default function Invoices() {
  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        <Sidebar />
        <Box>
          <Card className={`panelCard panelCardFullHeight`}>No Data Found</Card>
        </Box>
      </Box>
    </>
  );
}
