/* eslint-disable react/prop-types */
import { Box, Card, CardContent, CardMedia, Drawer, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
import styles from "./navbar.module.css";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { formatDate } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { deliverablesListAPI } from "../../../reducers/deliverablesSlice";
import { useDispatch } from "react-redux";
import React from "react";
function NewCustomerProjectDrawer({ isDrawerOpen, handleDrawerClose, projectsListing }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestDetail = (requestId) => {
    localStorage.setItem("selectedProjectId", requestId);
    navigate(`deliverables/`);
    handleDrawerClose();
    const storedValue = localStorage.getItem("selectedProjectId");
    dispatch(deliverablesListAPI(storedValue));
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose} hideBackdrop>
      {/* Drawer content goes here */}
      <Box className={`${styles.drawerWidth} `}>
        <div className={`${styles.drawerHeader} flexCard justified-between`}>
          <Typography variant="h4">New Records</Typography>
          <IconButton onClick={handleDrawerClose}>
            {" "}
            <CloseIcon />
          </IconButton>
        </div>
        <Box className={`${styles.requestBody} mt-3`}>
          {projectsListing[0]?.projects && projectsListing[0]?.projects.length > 0 ? (
            projectsListing[0]?.projects.map((project, index) => {
              return project?.inReviewDeliverables && project?.inReviewDeliverables.length > 0 ? (
                project?.inReviewDeliverables.map((deliverable, index) => (
                  <React.Fragment key={index}>
                    <Card className={`${styles.requestCard} mb-3 cursor_pointer`} onClick={() => handleRequestDetail(deliverable.projectId)}>
                      <CardContent className={`${styles.requestContent}`}>
                        <Box className={`${styles.projecrequestCardBody}`}>
                          <Box className={`${styles.requestInfo} ${styles.flexBox} ${styles.flexStart}`}>
                            <CardMedia className={`${styles.requestThumb}`}>
                              <img src={DummyImg} alt="" />
                            </CardMedia>
                            <CardContent className={`${styles.requestDesc}`}>
                              <Typography variant="h4" className={`${styles.requestName}`}>
                                {deliverable.title}
                              </Typography>
                              <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start mb-1`} style={{ gap: "0" }}>
                                Project: <b>{deliverable.projectTitle}</b>
                              </div>
                              <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start mb-1`} style={{ gap: "0" }}>
                                <CalendarMonthIcon /> {formatDate(deliverable.updatedAt)}
                              </div>
                            </CardContent>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </React.Fragment>
                ))
              ) : (
                <React.Fragment>
                  <DataNotFound icon="record" title="No Record Found" dClass="centeredText" />
                </React.Fragment>
              );
            })
          ) : (
            <React.Fragment>
              <DataNotFound icon="record" title="No Record Found" dClass="centeredText" />
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default NewCustomerProjectDrawer;
