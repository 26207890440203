/* eslint-disable react/prop-types */
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Typography } from "@mui/material";
import styles from "../navbar/dialogs/reject-request-dialog.module.css";
// import WarningIcon from "../../../assets/icons/warningIcon.svg";
import btnStyles from "../../../assets/button.module.css";

import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useDispatch, useSelector } from "react-redux";

import { talentAcceptRequestAPI } from "../../../reducers/talentProjectSlice";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function AcceptRequest({ open, handleClose, selectedProjectId }) {
  const { t } = useTranslation();
  const { requestLoading } = useSelector((state) => state.talentProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitRequest = () => {
    const request = {
      status: "accepted",
      projectId: selectedProjectId,
    };
    dispatch(talentAcceptRequestAPI(request, handleClose, navigate));
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Dialog
      className="DelcineWarningModal"
      // fullScreen={fullScreen}
      open={open}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      hideBackdrop
    >
      <DialogTitle className={`${styles.dialogTitleBox}`}>
        <box className="popupIcon">
          <WarningAmberIcon />
        </box>
        <IconButton className={`popupCrossBtn`}>
          <CloseIcon onClick={handleClose} />
        </IconButton>
        {/* <Box className={`${styles.flexBox}`}>
          <div className={`${styles.flexBox}`}>
            <div className={`${styles.headThumb}`}>
              <img src={WarningIcon} alt=""></img>
            </div>
            <div className={`${styles.headContent}`}>Accept New Request</div>
          </div>
          <IconButton className={`${styles.crossBtn}`}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </Box> */}
      </DialogTitle>
      <DialogContent className={`${styles.dialogContentBox}`}>
        <Box component="form">
          <Typography className="popupTitle">{t("popupTextAcceptTitle")}</Typography>
          <Typography className="popupSubTitle">{t("popupTextAcceptSubTitle")} </Typography>
          <Box className={`${styles.textCenter} mt-3`}>
            <FormControlLabel
              className={`${styles.checkBoxLabel}`}
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label="I agree to"
            />{" "}
            <Link to="/terms" target="_blank" className={` ${styles.checkBoxLabel} ${styles.textUnderline}`}>
              Terms & Conditions
            </Link>
          </Box>
          <Box className="popupBtn mt-3">
            <Button
              className={`${btnStyles.yinBtnSm} ${!isChecked ? btnStyles.yinCylinBtn : btnStyles.yinPrimaryBtn}  ${btnStyles.yinRound}`}
              onClick={handleSubmitRequest}
              disabled={!isChecked}
            >
              {t("textAccept")} {requestLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AcceptRequest;
