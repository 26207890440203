// import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import styles from "./ryanavbar.module.css";
// import btnStyles from "../../../assets/button.module.css";
import Logo from "../../../assets/icons/yingoLogo.png";
// import CloseIcon from "@mui/icons-material/Close";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import TokenIcon from "../../../assets/icons/token.svg";
import CrossBtnIcon from "../../../assets/icons/cross-dotted.svg";
import { useEffect, useState } from "react";
import { LogoutIcon, ProfileIcon } from "../../../assets/yingoIcons";
import { useDispatch, useSelector } from "react-redux";
import { changeProjectClick, navbarProjectTitle } from "../../../reducers/talentProjectSlice";

function RyaNavbar() {
  const { projectCredit } = useSelector((state) => state.talentProject);
  console.log("projectCredit", projectCredit);
  const availableCredit = localStorage.getItem("ryaSelectProjectCredits");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showingCredit, setShowingCredit] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const body = document.body;
    body.classList.add("scrollBarLine");
  };

  const handleClose = () => {
    setAnchorEl(null);
    const body = document.body;
    body.classList.remove("scrollBarLine");
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuItems = [
    { text: "Profile", icon: <ProfileIcon />, link: "/talent-profile", onClick: handleClose },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      link: "/",
      onClick: () => {
        localStorage.clear();
        dispatch(navbarProjectTitle(""));
      },
    },
  ];
  const localProfileImg = localStorage.getItem("profile_picture");
  const loggedUserName = localStorage.getItem("name");
  const profileImgSrc = localProfileImg;

  const profileImg = profileImgSrc ? <img src={profileImgSrc} alt="..." /> : <span>{loggedUserName.charAt(0)}</span>;

  // const { talentProjectList } = useSelector((state) => state.talentProject);
  const handleBackHome = () => {
    localStorage.setItem("isRyaClick", "false");
    localStorage.setItem("isProjectSelect", "false");
    dispatch(changeProjectClick("false"));
    navigate("/talent-projects");
  };
  useEffect(() => {
    if (projectCredit > 0) {
      setShowingCredit(projectCredit);
      localStorage.setItem("ryaSelectProjectCredits", projectCredit);
    } else {
      setShowingCredit("0.00");
    }
    if (availableCredit > 0) {
      setShowingCredit(availableCredit);
    } else {
      setShowingCredit("0.00");
    }
  }, [projectCredit, availableCredit]);

  let formattedCredit = parseFloat(showingCredit).toFixed(2); // Convert to string with two decimal places
  let truncatedCredit = formattedCredit.split(".")[0] + "." + formattedCredit.split(".")[1];

  return (
    <>
      <AppBar position="fixed" className={`${styles.AppBar} appbar`}>
        <Toolbar
          className={`${styles.panelNavBox} 
        
        `}
          // ${
          //   talentProfile?.proficiency === "Professional"
          //     ? styles.professional
          //     : talentProfile?.proficiency === "Genius"
          //     ? styles.genius
          //     : talentProfile?.proficiency === "Expert"
          //     ? styles.expert
          //     : ""
          // }
        >
          <Box className={`${styles.panelLogoBox}`}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={`${styles.navHamBurgerIcon}`}
              sx={{ padding: "0", display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box className={`${styles.sibebarLogoBox}`} onClick={handleBackHome}>
              <img src={Logo} alt="Logo" />
            </Box>
          </Box>
          <Box className={`globalSeachBox ${styles.globalSeachContainer}`}>
            {/* <Box className={`${styles.titleBox}`}>
              <Typography variant="h4">
                {headerProjectTitle ? headerProjectTitle : ryaSelectProjectName ? ryaSelectProjectName : "Welcome Project"}
              </Typography>
            </Box> */}
            <div className={`${styles.flexCard} ${styles.flexAlignCenter}`}>
              <div className={`${styles.walletListItem}`}>
                <img src={TokenIcon} alt=""></img>
                <div className={`${styles.walletBox}`}>
                  <Typography variant="body" sx={{ display: { xs: "none", md: "block" } }}>
                    Available Credits
                  </Typography>
                  <Typography variant="h4"> {truncatedCredit}</Typography>
                </div>
              </div>
              <div className={`${styles.walletListItem}  ${styles.flexAlignCenter}`}>
                <Link to="/talent-projects" onClick={handleBackHome} style={{ marginTop: "8px" }}>
                  <img src={CrossBtnIcon} alt=""></img>
                </Link>
              </div>
              <div onClick={handleClick} className={`${styles.navProfileBox}`}>
                {profileImg}
              </div>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className={`navPopOver`}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem key={item.text} disablePadding>
                    <NavLink to={item.link} className={`${styles.menuItemLink} menuSideItem`} onClick={item.onClick}>
                      <ListItemButton className={`${styles.sidebarItemBox} `}>
                        <ListItemIcon className={`${styles.sidebarIconBox}`}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} className={`${styles.sidebarText}`} />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

RyaNavbar.propTypes = {
  window: PropTypes.func,
};
export default RyaNavbar;
