import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card, CircularProgress, Grid } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import LoginBanner from "../../assets/images/loginBanner.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { resendOtpAPI, verifyOtpAPI } from "../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/icons/yingoLogo.png";
export default function AuthOtp() {
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const localEmail = localStorage.getItem("email");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    inputRefs.current[0].focus();
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, "");
    const singleDigitValue = sanitizedValue.slice(0, 1);

    setInputValues((prevInputValues) => {
      const updatedValues = [...prevInputValues];
      updatedValues[index] = singleDigitValue;
      return updatedValues;
    });

    if (singleDigitValue !== "") {
      const nextInputIndex = (index + 1) % inputRefs.current.length;
      inputRefs.current[nextInputIndex].focus();
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    if (pastedText.length === 4) {
      // Distribute each digit to the input boxes
      for (let i = 0; i < 4; i++) {
        if (inputRefs.current[i]) {
          inputRefs.current[i].value = pastedText[i];
          handleInputChange(i, { target: { value: pastedText[i] } });
        }
      }
    }
  };
  const handleInputKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      const prevInputIndex = index - 1;
      inputRefs.current[prevInputIndex].focus();
    }
  };
  // const handleInputChange = (index, event) => {
  //   const { value } = event.target;
  //   const sanitizedValue = value.replace(/\D/g, "");
  //   const singleDigitValue = sanitizedValue.slice(0, 1);
  //   setInputValues((prevInputValues) => {
  //     const updatedValues = [...prevInputValues];
  //     updatedValues[index] = singleDigitValue;
  //     return updatedValues;
  //   });
  //   if (singleDigitValue !== "") {
  //     const nextInputIndex = (index + 1) % inputRefs.current.length;
  //     inputRefs.current[nextInputIndex].focus();
  //   }
  // };

  const handleButtonClick = async () => {
    if (inputValues.some((value) => value === "")) {
      setError("Please enter vaild otp.");
      return;
    }
    setError("");
    const otp = inputValues.join("");
    const usrEmail = localStorage.getItem("email");
    const data = {
      email: usrEmail,
      otp: otp,
    };
    dispatch(verifyOtpAPI(data, navigate));
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    const usrEmail = localStorage.getItem("email");
    const data = {
      email: usrEmail,
    };

    try {
      await dispatch(resendOtpAPI(data));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Link to="/">
            <div className={`${styles.logoCard}`}>
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div className={`${styles.contentBox} ${styles.textWhite}`}>
            <Box>
              <Typography variant="h1"> {t("otpTitleText")} </Typography>
              <Typography>
                {" "}
                {t("otpSubTitleText")} <b>{localEmail}</b>
              </Typography>
            </Box>
            <Box component="form" noValidate autoComplete="off">
              {/* <OtpInput value={otpValue} onChange={handleOtpChange} /> */}
              <Box className={`transparentInput ${styles.otpBox}`} onPaste={handlePaste}>
                {inputValues.map((value, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <div className={`${styles.inputBox}`} key={index}>
                    <input
                      key={index}
                      type="number"
                      value={value}
                      className={`${styles.inputBoxInput}`}
                      onChange={(event) => handleInputChange(index, event)}
                      ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                      onKeyDown={(event) => handleInputKeyDown(index, event)}
                    />
                  </div>
                ))}
              </Box>
              {error && <div className={`${styles.errorMessage}`}>{error}</div>}
              <div className={`${styles.yinDivider}`}></div>
              <Button
                className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinFullWidth}`}
                onClick={handleButtonClick}
              >
                <span className={`${btnStyles.btnText}`}>
                  <span className={`${btnStyles.btnTitle}`}> {t("textContinue")}</span>
                </span>
                {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
              </Button>
            </Box>
            <Typography className={`${styles.routeLinkBox}`}>
              <Link to="" className={`${styles.routeLink} ${styles.resendBtn}`} onClick={handleResendOtp}>
                {t("textResendOTP")} {isLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
              </Link>
            </Typography>
            <Typography className={`${styles.routeLinkBox}`}>
              {t("textDontAccount")}
              <Link to="/register" className={`${styles.routeLink}`}>
                {t("textRegisterNow")}
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Card className={`${styles.authCard}`}>
            <img src={LoginBanner} alt="" />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
