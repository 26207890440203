import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import styles from "./navbar.module.css";
import Logo from "../../../assets/icons/logo-light.png";
import { ListItemIcon, Popover, TextField } from "@mui/material";
import { LogoutIcon } from "../../../assets/yingoIcons";
import { Link, NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "../../../reducers/userSlice";
function PanelNavbar(props) {
  const dispatch = useDispatch();
  const { handleDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const menuItems = [
    // { text: "Profile", icon: <ProfileIcon />, link: "/profile", onClick: () => {} },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      link: "/",
      onClick: () => {
        localStorage.clear();
        dispatch(logoutSuccess());
      },
    },
  ];
  return (
    <>
      <AppBar position="fixed" className={`${styles.AppBar} appbar`}>
        <Toolbar className={`${styles.panelNavBox}`}>
          <Box className={`${styles.panelLogoBox}`}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={`${styles.navHamBurgerIcon}`}
              sx={{ display: { sm: "none" } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Box className={`${styles.sibebarLogoBox}`}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Box>
          <Box className={`globalSeachBox ${styles.globalSeachContainer}`}>
            <Box className={`${styles.searchBar}`}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search Projects"
                autoComplete="off"
                className={`${styles.searchInput}`}
              />
              <SearchIcon className={`${styles.inputIcon}`} />
            </Box>
            <Box>
              <div onClick={handleClick} className={`${styles.navProfileBox}`}>
                A
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={`navPopOver`}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem key={item.text} disablePadding>
                      <NavLink to={item.link} className={`${styles.menuItemLink} menuSideItem`} onClick={item.onClick}>
                        <ListItemButton className={`${styles.sidebarItemBox} `}>
                          <ListItemIcon className={`${styles.sidebarIconBox}`}>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.text} className={`${styles.sidebarText}`} />
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              </Popover>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

PanelNavbar.propTypes = {
  handleDrawerToggle: PropTypes.func,
};

export default PanelNavbar;
