import { createSlice } from "@reduxjs/toolkit";

// import { openSnackbar } from "./snackbarSlice";
import {
  getSupportListAPI,
  getSupportTicketCommentListAPI,
  getSupportTicketInfoAPI,
  postSupportTicketCommentAPI,
  postSupportTicketComposeAPI,
  updateSupportTicketStatusAPI,
} from "../services/api/support-api";
import { openSnackbar } from "./snackbarSlice";

const initialState = {
  loading: false,
  supportList: [],
  supportTicketInfo: [],
  supportTicketCommentList: [],
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    supportListSuccess: (state, action) => {
      state.supportList = action.payload;
    },
    supportTicketInfoSuccess: (state, action) => {
      state.supportTicketInfo = action.payload;
    },
    supportTicketCommentListSuccess: (state, action) => {
      state.supportTicketCommentList = action.payload;
    },
    supportTicketCommentSuccess: (state, action) => {
      state.supportTicketCommentList.result = [...state.supportTicketCommentList.result, action.payload];
    },
  },
});

export const {
  isLoading,
  finishLoading,
  supportListSuccess,
  supportTicketInfoSuccess,
  supportTicketCommentListSuccess,
  supportTicketCommentSuccess,
} = supportSlice.actions;

export const supportTicketComposeAPI = (request, handleBack) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await postSupportTicketComposeAPI(request);

    if (response.status === 201) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      handleBack();
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  } finally {
    dispatch(finishLoading());
  }
};

export const supportListAPI = (request, userRoleFromLocal) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getSupportListAPI(request, userRoleFromLocal);

    if (response.status === 201) {
      dispatch(supportListSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

export const supportTicketInfoAPI = (request, userRoleFromLocal) => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getSupportTicketInfoAPI(request, userRoleFromLocal);

    if (response.status === 200) {
      dispatch(supportTicketInfoSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const supportTicketCommentListAPI = (request, userRoleFromLocal) => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getSupportTicketCommentListAPI(request, userRoleFromLocal);

    if (response.status === 201) {
      dispatch(supportTicketCommentListSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const supportTicketCommentAPI = (request, handleDiscard, userRoleFromLocal) => async (dispatch) => {
  // dispatch(isLoading());
  try {
    const response = await postSupportTicketCommentAPI(request, userRoleFromLocal);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      dispatch(supportTicketCommentSuccess(response.data.result));
      handleDiscard();
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  } finally {
    // dispatch(finishLoading());
  }
};

export const supportTicketStatusUpdateAPI = (request) => async (dispatch) => {
  try {
    const response = await updateSupportTicketStatusAPI(request);

    if (response.status === 200) {
      dispatch(supportTicketInfoSuccess(response.data?.result));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  } finally {
    // dispatch(finishLoading());
  }
};

export default supportSlice.reducer;
