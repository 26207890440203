import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgress, CssBaseline, Grid, InputLabel, TextField } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { talentLoginAPI } from "../../reducers/userSlice";
import Navbar from "../../components/common/navbar";
import LineOverlay from "../../assets/icons/linelayer.png";
import { t } from "i18next";

export default function TalentAuth() {
  const navigate = useNavigate();
  const [emailInput, setEmailInput] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const { loading } = useSelector((state) => state.user);
  // navigate("/otp", { state: { nameInput, emailInput, fromPath: "register" } });

  const dispatch = useDispatch();
  // const { registrationStatus, userNotFoundStatus } = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (userNotFoundStatus) {
  //     navigate("/talent-name");
  //   }
  // }, [navigate, userNotFoundStatus]);

  // useEffect(() => {
  //   if (registrationStatus) {
  //     navigate("/otp");
  //   }
  // }, [registrationStatus, navigate]);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmailInput(email);
    setIsValidEmail(validateEmail(email));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleButtonClick = async () => {
    // navigate("/talent-name");
    const data = {
      email: emailInput,
      // fromPath: "register",
    };

    if (!validateEmail(emailInput)) {
      setIsValidEmail(false);
      return;
    }
    dispatch(talentLoginAPI(data, navigate));
  };

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  return (
    <>
      <Navbar />
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className={`${styles.authRegisterPage} custombg `}>
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <img src={LineOverlay} alt="" className={`${styles.lineLayerRight}`} />
        <Grid container>
          <Grid item sm={12}>
            <Box className={`${styles.talentAuthContainer}`}>
              <div>
                <Box className={`${styles.talentHeadingBox} ${styles.textWhite}`}>
                  <Typography variant="h2">{t("talentWelcomeText")}</Typography>
                  <Typography variant="h4">{t("talentSubTitleText")}</Typography>
                </Box>
                <Box className={`${styles.talentRegsiterBox} ${styles.textWhite} mt-5`}>
                  <InputLabel className={`${styles.inputLabel} `}>
                    {t("talentEmailLabel")}
                    <span>*</span>
                  </InputLabel>
                  <div className={`${styles.inputBox}`}>
                    <TextField
                      required
                      id="outlined-required-name"
                      placeholder="Ex: johndoe@yingo.com"
                      className={`${styles.inputBoxInput}`}
                      value={emailInput}
                      onChange={handleEmailChange}
                      error={!isValidEmail}
                    />
                    {!isValidEmail && <span className={`${styles.errorMessage}`}>{t("errorEmail")}</span>}
                  </div>
                  <div className={`${styles.cetnerAlignedBtn} fullWidth mt-5`}>
                    <Button className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} `} onClick={handleButtonClick}>
                      <span className={`${btnStyles.btnText}`}>
                        <span className={`${btnStyles.btnTitle}`}>{t("textContinue")}</span>
                      </span>
                      {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                      {/* <CircularProgress color="inherit" className={`${styles.${styles.loaderIcon}}`} /> */}
                    </Button>
                  </div>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
