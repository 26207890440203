import Box from "@mui/material/Box";
import { useEffect, useLayoutEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import styles from "./dashboard.module.css";
import AdminImg from "../../assets/images/admin.png";
import LineOverlay from "../../assets/icons/linelayer.png";
import Navbar from "../../components/common/navbar";
import HeroSlider from "../../components/dashboard/heroSlider";
import LogoSlider from "../../components/dashboard/logoSlider";
import Features from "../../components/dashboard/features";
import HowItWorks from "../../components/dashboard/howitworks";
import BookDemo from "../../components/dashboard/bookDemo";
import SubscribeBox from "../../components/dashboard/subscribeBox";
import Footer from "../../components/dashboard/footer";
import { ROLES } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

export default function DrawerAppBar(props) {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (role === ROLES.Talent) {
      navigate("/talent-profile", { replace: true });
    }
  }, [navigate, role]);

  useEffect(() => {
    document.body.classList.add("darkbg");

    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);
  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className="custombg">
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <Navbar />
        <HeroSlider />
        <Box className={`${styles.borderBottom}`}>
          <Grid container className={`${styles.container}`}>
            <Grid item sm={12}>
              <div className={`${styles.imgBannerBox} `}>
                <img src={AdminImg} alt="BannerImage" />
              </div>
            </Grid>
          </Grid>
        </Box>
        <LogoSlider />
        <Features />
        <HowItWorks />
        <BookDemo />
        <SubscribeBox />
        <Footer />
      </Box>
    </>
  );
}
