/* eslint-disable react/no-unescaped-entities */
import { Button, Grid, Typography } from "@mui/material";
import styles from "./bookDemo.module.css";
import btnStyles from "../../../assets/button.module.css";
import Girl from "../../../assets/images/girl.png";
import { useTranslation } from "react-i18next";
export default function BookDemo() {
  const { t } = useTranslation();
  return (
    <Grid container className={`container ${styles.textWhite} ${styles.contianerBg} `}>
      <Grid item sm={12} md={6}>
        <Typography variant="h5" className={`${styles.headingH5}`}>
          {t("bookDemoTitleStart")}
        </Typography>
        <Typography variant="h1" className={`${styles.headingH1}`}>
          {t("bookDemoSubTitle")}
        </Typography>
        <Typography className={`${styles.textP}`}>{t("bookDemoDescriptionText")}</Typography>
        <a href="https://calendly.com/doravaldez" target="_blank" rel="noopener noreferrer">
          <Button
            className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinBtnBlock} `}
            style={{ marginBottom: "30px" }}
          >
            {t("bookDemoBtnTxt")}
          </Button>
        </a>
      </Grid>

      <Grid item sm={12} md={6}>
        <div className={`${styles.bookDemoImg}`}>
          <img src={Girl} alt="" />
        </div>
      </Grid>
    </Grid>
  );
}
