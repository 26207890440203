/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import styles from "./talentchat.module.css";
import btnStyles from "../../../../../assets/button.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { talentDeliverableChatAPI } from "../../../../../reducers/talentProjectSlice";
import DataNotFound from "../../../../../components/common/PageNotFound/dataNotFound";
import { useTranslation } from "react-i18next";

export default function TalentChat({ deliverableId }) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const { talentDeliverableCommentList, talentProjectDetail } = useSelector((state) => state.talentProject);
  const dispatch = useDispatch();

  const { userData } = talentProjectDetail?.[0] || {};

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleComment = () => {
    if (inputValue) {
      const request = {
        toUser: userData[0]?._id,
        deliverableId: deliverableId,
        message: inputValue,
      };
      dispatch(talentDeliverableChatAPI(request, setInputValue));
    }
  };

  const loggedInUserId = localStorage.getItem("userId");
  const loggedUserName = localStorage.getItem("name");
  const loggedUserEmail = localStorage.getItem("email");
  const loggedUserPhoto = localStorage.getItem("profile_picture");
  let loggedTitle;
  if (loggedUserPhoto === "null" || loggedUserPhoto === "undefined" || loggedUserPhoto === "") {
    if (loggedUserName === "null" || loggedUserName === "undefined") {
      loggedTitle = <span>{loggedUserEmail.charAt(0)}</span>;
    } else {
      loggedTitle = <span>{loggedUserName.charAt(0)}</span>;
    }
  } else {
    loggedTitle = <img src={loggedUserPhoto} alt={loggedUserPhoto} />;
  }
  // const loggedTitle = <span>{loggedUserName.charAt(0)}</span>;
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [talentDeliverableCommentList]);

  return (
    <>
      <Card className={`${styles.accordianChatBox} p-0`}>
        <Box className={`${styles.accordianChatMsgs}`} ref={chatContainerRef}>
          {talentDeliverableCommentList && talentDeliverableCommentList.result?.length > 0 ? (
            talentDeliverableCommentList.result.map((comment) => {
              const { _id, byUser, message, toUserInfo } = comment;
              if (byUser === loggedInUserId) {
                return (
                  <div className={`${styles.userMsgsBox} ${styles.alignRight}`} key={_id}>
                    <Typography variant="body" className={`${styles.userText}`}>
                      {message}
                    </Typography>
                    <div className={`${styles.userImg}`}>{loggedTitle}</div>
                  </div>
                );
              } else {
                return (
                  <div className={`${styles.devsMsgsBox}`} key={_id}>
                    {toUserInfo[0]?.profilePicture === "null" || toUserInfo[0]?.profilePicture === "" ? (
                      <div className={`${styles.devsImg}`}>{toUserInfo[0]?.name.charAt(0)}</div>
                    ) : (
                      <div className={`${styles.devsImg}`}>
                        <img src={toUserInfo[0]?.profilePicture} alt="" />
                      </div>
                    )}
                    {/* <div className={`${styles.userImg}`}>{toUserInfo[0]?.name.charAt(0)}</div> */}

                    <Typography variant="body" className={`${styles.devsText}`}>
                      {message}
                    </Typography>
                  </div>
                );
              }
            })
          ) : (
            <Box className={`${styles.emptyStateBox}`}>
              <Box className="emptyStateContainer">
                <DataNotFound icon={"chat"} title={""} subtitle={t("textComentNotFound")} />
              </Box>
            </Box>
          )}
        </Box>
        <Box className={`${styles.accordianChatInput} chatBottomInput`}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleComment();
              }
            }}
          />
          <Button
            className={`${btnStyles.yinBtnSm} ${inputValue ? btnStyles.yinPrimaryBtn : btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
            disabled={!inputValue}
            onClick={handleComment}
          >
            {t("textSend")}
          </Button>
        </Box>
      </Card>
    </>
  );
}
