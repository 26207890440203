import { createSlice } from "@reduxjs/toolkit";
import {
  getDeliverableCommentListAPI,
  getDeliverableDocumentListAPI,
  getDeliverableInfoAPI,
  getDeliverableProjectListAPI,
  getDeliverableStatusProjectList,
  getDeliverablesListAPI,
  postDeliverableCommentAPI,
  postDeliverableDocumentUploadAPI,
  postDeliverableTimelineAPI,
  talentSupportDeliverablesAPI,
} from "../services/api/deliverables-api";
import { openSnackbar } from "./snackbarSlice";

const initialState = {
  loading: false,
  docUploadLoading: false,
  deliverables: [],
  deliverableInfo: [],
  deliverableComment: [],
  deliverableProjectListing: [],
  deliverableDocuments: [],
  // deliverableSendComment: [],
  deliverableStatusNotification: [],
};

const deliverablesSlice = createSlice({
  name: "deliverables",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    deliverablesListSuccess: (state, action) => {
      state.deliverables = action.payload;
    },
    deliverableInfoSuccess: (state, action) => {
      state.deliverableInfo = action.payload;
    },
    deliverableCommentListSuccess: (state, action) => {
      state.deliverableComment = action.payload;
    },
    deliverableDocumentListSuccess: (state, action) => {
      state.deliverableDocuments = action.payload;
    },
    deliverableProjectListingSuccess: (state, action) => {
      state.deliverableProjectListing = action.payload;
    },
    deliverableSendCommentSuccess: (state, action) => {
      state.deliverableComment.result = [...state.deliverableComment.result, action.payload];
      // state.deliverableSendComment = [...state.deliverableSendComment, action.payload];
    },
    isDocUploadLoading(state) {
      state.docUploadLoading = true;
    },
    finishDocUploadLoading(state) {
      state.docUploadLoading = false;
    },
    /******* GD Work***/
    deliverableStatusNotificationSuccess: (state, action) => {
      state.deliverableStatusNotification = action.payload;
    },
  },
});

export const {
  isLoading,
  finishLoading,
  deliverablesListSuccess,
  deliverableInfoSuccess,
  deliverableCommentListSuccess,
  deliverableProjectListingSuccess,
  deliverableSendCommentSuccess,
  deliverableDocumentListSuccess,
  isDocUploadLoading,
  finishDocUploadLoading,
  deliverableStatusNotificationSuccess,
} = deliverablesSlice.actions;

export const deliverablesListAPI = (request) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await getDeliverablesListAPI(request);

    if (response.status === 200) {
      dispatch(deliverablesListSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

export const deliverablesInfoAPI = (request) => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getDeliverableInfoAPI(request);

    if (response.status === 200) {
      dispatch(deliverableInfoSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const deliverableCommentAPI = (request, setInputValue) => async (dispatch) => {
  // dispatch(isLoading());
  try {
    const response = await postDeliverableCommentAPI(request);

    if (response.status === 201) {
      // deliverableSendCommentSuccess(response.data.result);
      dispatch(deliverableSendCommentSuccess(response.data.result));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      setInputValue("");
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  } finally {
    // dispatch(finishLoading());
  }
};

export const deliverableCommentListAPI = (request) => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getDeliverableCommentListAPI(request);

    if (response.status === 200) {
      dispatch(deliverableCommentListSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const deliverableDocumentListAPI = (request) => async (dispatch) => {
  try {
    const response = await getDeliverableDocumentListAPI(request);

    if (response.status === 200) {
      dispatch(deliverableDocumentListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  // dispatch(finishRequestDetailLoading());
};

export const deliverableDocUploadAPI = (request, handleClose) => async (dispatch) => {
  dispatch(isDocUploadLoading());

  try {
    const response = await postDeliverableDocumentUploadAPI(request);

    if (response.status === 201) {
      dispatch(deliverableDocumentListSuccess(response.data.result));
      handleClose();
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishDocUploadLoading());
};

export const deliverableProjectListAPI = () => async (dispatch) => {
  // dispatch(isLoading());

  try {
    const response = await getDeliverableProjectListAPI();

    if (response.status === 200) {
      dispatch(deliverableProjectListingSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    // dispatch(finishLoading());
  }
};

export const deliverableTimelineAPI = (request) => async (dispatch) => {
  // dispatch(isLoading());
  try {
    const response = await postDeliverableTimelineAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  } finally {
    // dispatch(finishLoading());
  }
};

/********* INVIEW STATUS PROJECT API GD ********* */

export const supportTalentDeliverablesAPI = (request) => async (dispatch) => {
  dispatch(isLoading());
  console.log("Rrr", request);
  try {
    const response = await talentSupportDeliverablesAPI(request);

    if (response.status === 200) {
      dispatch(deliverablesListSuccess(response.data?.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

export const deliverableStatusProjectAPI = (request) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await getDeliverableStatusProjectList(request);

    if (response.status === 200) {
      dispatch(deliverableStatusNotificationSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishLoading());
};

export default deliverablesSlice.reducer;
