import { useEffect, useState } from "react";
import { Box, Typography, Button, Card, Grid, TextField, CircularProgress } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import LoginBanner from "../../assets/images/loginBanner.jpg";
import { Link, useNavigate } from "react-router-dom";
import validateEmail from "../../utils/emailValidation";
import { userLoginAPI } from "../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/icons/yingoLogo.png";
import { useTranslation } from "react-i18next";
export default function AuthLogin() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { loading } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userNotFoundStatus } = useSelector((state) => state.user);

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  useEffect(() => {
    if (userNotFoundStatus) {
      navigate("/register");
    }
  }, [userNotFoundStatus, navigate]);

  const handleEmailChange = (event) => {
    setEmailError("");
    setEmail(event.target.value);
  };

  const handleContinueClick = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter your email address");
    } else {
      const data = {
        email: email,
        fromPath: "login",
        name: email,
      };
      dispatch(userLoginAPI(data, navigate));
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleContinueClick();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} className="fullWidth">
          <Box className={`${styles.formSection}`}>
            <Link to="/">
              <div className={`${styles.logoCard}`}>
                <img src={Logo} alt="" />
              </div>
            </Link>
            <div className={`${styles.contentBox} ${styles.textWhite}`}>
              <Box>
                <Typography variant="h1"> {t("loginWelcomeText")}</Typography>
                <Typography>{t("loginSubTitleText")}</Typography>
              </Box>

              <Box component="form" noValidate autoComplete="off" className={`transparentInput`}>
                <div className={`${styles.inputBox}`}>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Enter your Email Address"
                    className={`${styles.inputBoxInput}`}
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                    onKeyDown={handleEnterKeyPress}
                  />
                </div>
                <div className={`${styles.yinDivider}`}></div>
                <Button
                  className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} ${btnStyles.yinFullWidth}`}
                  onClick={handleContinueClick}
                >
                  <span className={`${btnStyles.btnText}`}>
                    <span className={`${btnStyles.btnTitle}`}>{t("textContinue")}</span>
                  </span>
                  {loading && <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />}
                </Button>
              </Box>
              <Typography className={`${styles.routeLinkBox}`}>
                {t("textDontAccount")}
                <Link to="/register" className={`${styles.routeLink}`}>
                  {t("textRegisterNow")}
                </Link>
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Card className={`${styles.authCard}`}>
            <img src={LoginBanner} alt="" />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
