/* eslint-disable react/prop-types */
import { Box, Card, CardContent, CardMedia, Drawer, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
// import DummyImg from "../../../assets/icons/dummy-project.webp";
import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styles from "./navbar.module.css";
// import btnStyles from "../../../assets/button.module.css";
import { formatDate } from "../../../utils/helper";
import DataNotFound from "../../../components/common/PageNotFound/dataNotFound";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewRequestDrawer({ isDrawerOpen, handleDrawerClose, talentNewRequest, handleClickOpen, handleAcceptRequestDialog }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleRequestDetail = (requestId) => {
    navigate(`/talent-request-detail/${requestId}`);
    handleDrawerClose();
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose} hideBackdrop>
      {/* Drawer content goes here */}
      <Box className={`${styles.drawerWidth} `}>
        <div className={`${styles.drawerHeader} flexCard justified-between`}>
          <Typography variant="h4">{t("textNewRequests")}</Typography>
          <IconButton onClick={handleDrawerClose}>
            {" "}
            <CloseIcon />
          </IconButton>
        </div>
        <Box component="form" className={`mt-3`}>
          {talentNewRequest && talentNewRequest.length > 0 ? (
            talentNewRequest.map((request, index) => {
              const { projectId, _id } = request;

              return (
                <Card className={`${styles.requestCard} mb-3 cursor_pointer`} key={index} onClick={() => handleRequestDetail(_id)}>
                  <CardContent className={`${styles.requestContent}`}>
                    <Box className={`${styles.projecrequestCardBody}`}>
                      <Box className={`${styles.requestInfo} ${styles.flexBox} ${styles.flexStart}`}>
                        <CardMedia className={`${styles.requestThumb}`}>
                          <img src={DummyImg} alt="" />
                        </CardMedia>
                        <CardContent className={`${styles.requestDesc}`}>
                          <Typography variant="h4" className={`${styles.requestName}`}>
                            {projectId[0]?.name}
                          </Typography>
                          <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start mb-1`} style={{ gap: "0" }}>
                            Start date: {projectId[0].startDate ? formatDate(projectId[0].startDate) : "N/A"}
                          </div>
                          <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start `} style={{ gap: "0" }}>
                            Deadline: {projectId[0].endDate ? formatDate(projectId[0].endDate) : "N/A"}
                          </div>
                          {/* <div className={`${styles.flexCard} mt-2`}>
                            <Button
                              className={`${btnStyles.yinBtnXs} ${btnStyles.yinSuccessBtnOutline}`}
                              onClick={() => handleAcceptRequestDialog(projectId[0]._id)}
                            >
                              Accept
                            </Button>
                            <Button
                              className={`${btnStyles.yinBtnXs} ${btnStyles.yinDangerBtnOutline}`}
                              onClick={() => handleClickOpen(projectId[0]._id)}
                            >
                              Decline
                            </Button>
                            <Button className={`${btnStyles.yinBtnXs} ${btnStyles.yinTeritaryBtnOutline}`}>View</Button>
                          </div> */}
                        </CardContent>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <DataNotFound icon="record" title="No Request Found" dClass="centeredText" />
          )}

          {/* <Card className={`${styles.requestCard} mb-3`}>
            <CardContent className={`${styles.requestContent}`}>
              <Box className={`${styles.projecrequestCardBody}`}>
                <Box className={`${styles.requestInfo} ${styles.flexBox} ${styles.flexStart}`}>
                  <CardMedia className={`${styles.requestThumb}`}>
                    <img src={DummyImg} alt="" />
                  </CardMedia>
                  <CardContent className={`${styles.requestDesc}`}>
                    <Typography variant="h4" className={`${styles.requestName}`}>
                      Build a website for Pharmacy
                    </Typography>
                    <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start mb-1`} style={{ gap: "0" }}>
                      <CalendarMonthIcon />1 Nov, 2023
                    </div>
                    <div className={`${styles.flexBox} ${styles.dateOfProject} justified-start mb-2`} style={{ gap: "0" }}>
                      Deadline: 1 Nov, 2023
                    </div>
                    <div className={`${styles.flexCard} mt-2`}>
                      <Button className={`${btnStyles.yinBtnXs} ${btnStyles.yinSuccessBtnOutline}`}>Accept</Button>
                      <Button className={`${btnStyles.yinBtnXs} ${btnStyles.yinDangerBtnOutline}`}>Decline</Button>
                      <Button className={`${btnStyles.yinBtnXs} ${btnStyles.yinTeritaryBtnOutline}`}>View</Button>
                    </div>
                  </CardContent>
                </Box>
              </Box>
            </CardContent>
          </Card> */}
        </Box>
      </Box>
    </Drawer>
  );
}

export default NewRequestDrawer;
