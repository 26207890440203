import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import styles from "./navbar.module.css";
import btnStyles from "../../../assets/button.module.css";
import Logo from "../../../assets/icons/yingoLogo.png";
import { useState } from "react";
import { Card, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ROLES } from "../../../utils/constant";
// import UsFlag from "../../../assets/icons/us.png";
// import SpFlag from "../../../assets/icons/spain.png";

const drawerWidth = 240;

function Navbar(props) {
  const { t } = useTranslation();
  const navItems = [t("mainMenuItemHome"), t("mainMenuItemAbout"), t("mainMenuItemStories"), t("mainMenuItemContact")];
  const [mobileOpen, setMobileOpen] = useState(false);
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLoginClick = async () => {
    navigate("/login");
  };
  const handleTalentClick = async () => {
    navigate("/talent-auth");
  };
  // handle redirection
  // const handleDashboardClick = () => {
  //   if (role === ROLES.Talent) {
  //     navigate("/talent-profile");
  //   } else {
  //     navigate("/projects");
  //   }
  // };

  const handleDashboardClick = () => {
    if (role === ROLES.Talent) {
      navigate("/talent-profile");
    } else {
      navigate("/projects");
    }
  };

  // handle redirection   END //

  // let activeLang;
  // let langName;
  // const currentLang = localStorage.getItem("currentLang");
  // if (currentLang === "en") {
  //   activeLang = <img src={UsFlag} alt="" />;
  //   langName = "English";
  // } else {
  //   activeLang = <img src={SpFlag} alt="" />;
  //   langName = "Spanish";
  // }

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <div className={`${styles.logoCard}`}>
        <img src={Logo} alt="" />
      </div>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        {token ? (
          <>
            <ListItem>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm}`} onClick={handleDashboardClick}>
                {t("mainMenuItemDashboard")}
              </Button>
            </ListItem>
          </>
        ) : location.pathname === "/pricing" ? null : (
          <>
            <ListItem>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleLoginClick}>
                {t("mainMenuLoginCustomer")}
              </Button>
            </ListItem>
            <ListItem>
              <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleTalentClick}>
                {t("mainMenuLoginTalent")}
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const [anchorEl, setAnchorEl] = useState(null);
  // const [langValue, setLangValue] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleLangClick = (event) => {
  //   setLangValue(event.currentTarget);
  // };
  // const handleLangClose = (event) => {
  //   setLangValue(null);
  // };

  const open = Boolean(anchorEl);

  // const id = open ? "simple-popover" : undefined;
  // const openlangValue = Boolean(langValue);
  // const idlangValue = openlangValue ? "simple-popover" : undefined;

  // const handleEsLangChange = (e) => {
  //   localStorage.setItem("currentLang", "es");
  //   handleLangClose();
  //   navigate(0);
  // };

  // const handleEnLangChange = (e) => {
  //   localStorage.setItem("currentLang", "en");
  //   handleLangClose();
  //   navigate(0);
  // };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        <Box className="container">
          <AppBar component="nav" className={`${styles.noFixed} ${styles.transparentHeader}`}>
            <Toolbar>
              <IconButton
                className={`${styles.hamBurgerIcon}`}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <div className={`${styles.container} ${styles.headerDiviion}`}>
                <Link to="/" className={`${styles.logoCard}`}>
                  <img src={Logo} alt="" />
                </Link>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {navItems.map((item) => (
                    <Button className={`${styles.menuItems}`} key={item} sx={{ color: "#fff" }}>
                      {item}
                    </Button>
                  ))}

                  {token ? (
                    <>
                      <Button
                        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm}`}
                        style={{ marginRight: "15px", marginLeft: "15px" }}
                        onClick={handleDashboardClick}
                      >
                        {t("mainMenuItemDashboard")}
                      </Button>
                      {/* <Button
                        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm}`}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/login");
                        }}
                      >
                        Logout
                      </Button> */}
                    </>
                  ) : location.pathname === "/pricing" ? null : (
                    <>
                      <IconButton aria-describedby={"id"} variant="contained" onClick={handleClick}>
                        {/* <MenuIcon style={{ color: "white" }} /> */}
                        <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn}  ${btnStyles.yinSqCornerSm} `}>
                          {t("mainMenuItemLoginSignup")}
                        </Button>
                      </IconButton>
                      <Popover
                        id={"id"}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Card className="panelCard p-0">
                          <List className={`${styles.popoverLinking}`}>
                            <ListItem>
                              <Link to="/login"> {t("mainMenuLoginCustomer")}</Link>
                            </ListItem>
                            <ListItem>
                              <Link to="/talent-auth"> {t("mainMenuLoginTalent")} </Link>
                            </ListItem>
                          </List>
                        </Card>
                      </Popover>
                    </>
                  )}

                  {/* <IconButton className={`${styles.menuItems}`} onClick={handleLangClick}>
                    <Box className={`${styles.languageBox}`}>
                      {activeLang}
                      {langName}
                    </Box>
                  </IconButton>
                  <Popover
                    id={idlangValue}
                    open={openlangValue}
                    anchorEl={langValue}
                    onClose={handleLangClose}
                    style={{ marginTop: "40px", marginLeft: "-15px" }}
                  >
                    <Card className="panelCard p-0">
                      <List className={`${styles.popoverLinking}`}>
                        <ListItem>
                          <Box className={`${styles.flexCard} ${styles.gap10} cursor-pointer`} onClick={handleEnLangChange}>
                            <img src={UsFlag} alt="" style={{ width: "20px" }} />
                            <Typography>English</Typography>
                          </Box>
                        </ListItem>
                        <ListItem>
                          <Box className={`${styles.flexCard} ${styles.gap10} cursor-pointer`} onClick={handleEsLangChange}>
                            <img src={SpFlag} alt="" style={{ width: "20px" }} />
                            <Typography> Spanish</Typography>
                          </Box>
                        </ListItem>
                      </List>
                    </Card>
                  </Popover> */}
                </Box>
              </div>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      </Box>
    </>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
