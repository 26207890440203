/* eslint-disable react/prop-types */
import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileIcon from "../../../../assets/icons/file-filled.svg";
import styles from "../talent-profile.module.css";
import { useState } from "react";

function EducationListItem({ education, onDelete, onUpdate }) {
  const [isHovered, setIsHovered] = useState(false);

  const { _id, degree, fieldOfStudy, school, graduationDate } = education;
  const graduationYear = new Date(graduationDate).getFullYear();
  return (
    <ListItem
      className="flexCard aignItemCenter justified-between"
      disablePadding
      key={_id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flexCard aignItemCenter">
        <ListItemIcon className={`${styles.listCardIcon}`}>
          <img src={FileIcon} alt="" />
        </ListItemIcon>
        <Box className={`flexCard flexColumn aignItemStart ${styles.listCardContent}`}>
          <div className="fullWidth flexCard  justified-between">
            <ListItemText className={`${styles.listaiToolTitle}`} primary={school} />
            {isHovered && (
              <div className="cardActionIcon">
                <div className="flexCard gap10">
                  <IconButton className={`${styles.editIcon}`} onClick={() => onUpdate("education", "Edit Education", education)}>
                    <CreateIcon className={`${styles.innerActionIcon}`} />
                  </IconButton>
                  <IconButton className={`${styles.delIcon}`} onClick={() => onDelete(_id)}>
                    <DeleteOutlineIcon className={`${styles.innerActionIcon}`} />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <div className={`${styles.listaiToolSubtitle} flexCard`} style={{ marginTop: "-5px" }}>
            <ListItemText className={`${styles.listaiToolSubtitle}`}>
              {degree} in {fieldOfStudy} in {graduationYear}{" "}
            </ListItemText>
          </div>
        </Box>
      </div>
      {/* <div className="flexCard cardActionIcon">
        <IconButton>
          <CreateIcon className={`${styles.innerActionIcon}`} />
        </IconButton>
        <IconButton>
          <DeleteOutlineIcon className={`${styles.innerActionIcon}`} />
        </IconButton>
      </div> */}
    </ListItem>
  );
}

export default EducationListItem;
