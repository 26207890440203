import { Box, Button, Typography } from "@mui/material";
import styles from "./emptystate.module.css";
import btnStyles from "../../../assets/button.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export default function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleBack = () => {
    navigate(-1);
  };
  const [seconds, setSeconds] = useState(5);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(intervalId);
        // Redirect to the login page
        navigate("/login");
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, navigate]);
  return (
    <>
      <Box className={`${styles.emptyStateBox}`} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        {/* <Typography variant="h1">404</Typography> */}
        <Typography variant="h1">{seconds}</Typography>
        {/* <Typography variant="h4">{t("textPageNotFound")}</Typography> */}
        <Typography variant="body">{t("textPageNotFoundSubTitle")}</Typography>
        {/* {seconds === 0 && ( */}
        <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `} onClick={handleBack}>
          {t("textPageNotFoundBtnTitle")}
        </Button>
        {/* )} */}
      </Box>
    </>
  );
}
