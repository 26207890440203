import * as React from "react";
import Box from "@mui/material/Box";
import styles from "./heroSlider.module.css";
import btnStyles from "../../../assets/button.module.css";
import DottedCircle from "../../../assets/icons/roundoverlay.png";
import { Button, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function HeroSlider() {
  const { t } = useTranslation();
  const [inputData, setInputData] = React.useState("");
  const [currentTagInputSystemContent, setCurrentTagInputSystemContent] = React.useState("");
  const [isValidInput, setIsValidInput] = React.useState(true); // Track input validity
  const navigate = useNavigate();
  const recentResults = [
    {
      tagName: t("heroTagName1"),
      tagSystemContent: t("heroTagSystemContent1"),
    },
    {
      tagName: t("heroTagName2"),
      tagSystemContent: t("heroTagSystemContent2"),
    },
    {
      tagName: t("heroTagName3"),
      tagSystemContent: t("heroTagSystemContent3"),
    },
  ];
  const handleInputChange = (event) => {
    setInputData(event.target.value);
    setIsValidInput(true);
  };

  const handleButtonClick = async () => {
    if (!inputData.trim()) {
      setIsValidInput(false);
      return;
    }
    navigate("/result", { state: { inputData, currentTagInputSystemContent, pageType: "home" } });
  };

  const handleItemClick = (inputData, tagInputSystemContent) => {
    setInputData(inputData); // Set the inputData state to the clicked item's text
    setCurrentTagInputSystemContent(tagInputSystemContent); // Update the currentTagInputSystemContent state
    // navigate("/result", {
    //   state: { inputData, tagInputSystemContent, pageType: "tag" },
    // });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (inputData.trim() !== "") {
      handleButtonClick();
    }
  };

  return (
    <Box className={styles.sliderBox}>
      <Grid container className={`container ${styles.textWhite}`}>
        <Grid item md={8} lg={8} xl={7}>
          <Typography variant="h5" className={styles.headingH5}>
            {t("heroSubTitle")}
          </Typography>
          <Typography variant="h1" className={styles.headingH1}>
            {t("heroTitleStart")}
            {/* <span className={`${styles.highlightKeyword}`}> {t("heroTitleSpan")}</span> {t("heroTitleEnd")} */}
          </Typography>
          <Box component="form" noValidate autoComplete="off" className={`transparentInput ${styles.searchQueryForm}`} onSubmit={handleFormSubmit}>
            <TextField
              required
              id="outlined-required"
              placeholder={t("heroInputPlacholder")}
              className={`${styles.searchQueryInput}`}
              value={inputData}
              onChange={handleInputChange}
              error={!isValidInput}
            />
            <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCornerSm}`} onClick={handleButtonClick}>
              <span className={`${btnStyles.btnText}`}>
                <span className={`${btnStyles.btnTitle}`}>{t("heroBtnTxt")}</span>
                <span className={`${btnStyles.btnIcon}`}>
                  <SendIcon />
                </span>
              </span>
            </Button>
          </Box>
          {!isValidInput && <span className={`${styles.errorMessage}`}>Please enter something !</span>}
          <Box className={`${styles.recentListBox} ${styles.flexCard} ${styles.textWhite}`}>
            <Typography variant="p" className={styles.textP}>
              {t("heroTagLabel")}:
            </Typography>
            <List className={`${styles.flexCard}`}>
              {recentResults.map((item, index) => (
                <ListItem key={index} className={`${styles.yinListItem}`}>
                  <ListItemText
                    primary={item.tagName}
                    className={`${styles.listBladge}`}
                    onClick={() => handleItemClick(item.tagName, item.tagSystemContent)}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
      <img src={DottedCircle} alt="" className={`${styles.dottedCircle}`} />
    </Box>
  );
}
