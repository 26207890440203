import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./howitworks.module.css";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import Step1 from "../../../assets/images/h1.png";
import Step2 from "../../../assets/images/h2.png";
import Step3 from "../../../assets/images/h3.png";
import Step4 from "../../../assets/images/h4.png";
import Step5 from "../../../assets/images/h5.png";
import { useTranslation } from "react-i18next";
export default function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div>
      <Grid container className={`container ${styles.textWhite} ${styles.py5}`}>
        <Grid item sm={12} md={4}>
          <div className={`${styles.sectionBox}`}>
            <div className={`${styles.sectionCard}`}>
              <Typography variant="h5"> {t("yingoWorkTitle")}</Typography>
              <Typography variant="h1">
                {/* How <span className={`${styles.highlightKeyword}`}>Yingo </span> Works */}
                {t("yingoWorkSubTitle")}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={8}>
          <Grid container>
            <Grid item xs={12} md={12} className={`${styles.stepInitial} ${styles.stepOneDottedLine} ${styles.overlayHalfCircle}`}>
              <Card className={`${styles.stepCard} ${styles.textWhite}`}>
                <CardMedia>
                  <img src={Step1} alt="" />
                </CardMedia>
                <CardContent className={`${styles.stepCardContent}`}>
                  <Typography> {t("yingoWorkTagTitle1")}</Typography>
                  <Typography variant="h4"> {t("yingoWorkTagName1")}</Typography>
                </CardContent>
              </Card>
              <div className={`${styles.counterCircle} ${styles.circleTopBottomSpace}`}>1</div>
            </Grid>
            <Grid item xs={12} md={12} className={`${styles.stepCenter} ${styles.stepTwoDottedLine}`}>
              <div className={`${styles.counterCircle}`}>2</div>
              <Card className={`${styles.stepCard} ${styles.textWhite}`}>
                <CardMedia>
                  <img src={Step2} alt="" />
                </CardMedia>
                <CardContent className={`${styles.stepCardContent}`}>
                  <Typography> {t("yingoWorkTagTitle2")}</Typography>
                  <Typography variant="h4"> {t("yingoWorkTagName2")}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} className={`${styles.stepEnd} ${styles.stepThreeAlign} ${styles.stepThreeDottedLine}`}>
              <div className={`${styles.counterCircle} `}>3</div>
              <Card className={`${styles.stepCard} ${styles.textWhite} ${styles.middleCardSpace}`}>
                <CardMedia>
                  <img src={Step3} alt="" />
                </CardMedia>
                <CardContent className={`${styles.stepCardContent}`}>
                  <Typography> {t("yingoWorkTagTitle3")}</Typography>
                  <Typography variant="h4"> {t("yingoWorkTagName3")}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} className={`${styles.stepCenter} ${styles.stepFourDottedLine}`}>
              <div className={`${styles.counterCircle}`}>4</div>
              <Card className={`${styles.stepCard} ${styles.textWhite}`}>
                <CardMedia>
                  <img src={Step4} alt="" />
                </CardMedia>
                <CardContent className={`${styles.stepCardContent}`}>
                  <Typography> {t("yingoWorkTagTitle4")}</Typography>
                  <Typography variant="h4"> {t("yingoWorkTagName4")}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} className={`${styles.stepInitial} ${styles.reverse} ${styles.stepFiveDottedLine}`}>
              <div className={`${styles.counterCircle} ${styles.circleTopBottomSpace}`}>5</div>
              <Card className={`${styles.stepCard} ${styles.textWhite}`}>
                <CardMedia>
                  <img src={Step5} alt="" />
                </CardMedia>
                <CardContent className={`${styles.stepCardContent}`}>
                  <Typography> {t("yingoWorkTagTitle5")}</Typography>
                  <Typography variant="h4"> {t("yingoWorkTagName5")}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
