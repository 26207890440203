/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./talent.module.css";
import btnStyles from "../../../assets/button.module.css";
// import team from "../../../assets/images/team1.png";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { talentsAssignAPI } from "../../../reducers/talentSlice";

const AssignPopUp = (props) => {
  const {
    deleteAssignedTalent,
    isHiddenValue,
    removeAssignedTalent,
    open,
    handleTalentOpen,
    handleClose,
    selectedPriority1Talent,
    selectedProjectId,
  } = props;

  const { talentsList } = useSelector((state) => state.talents);
  const dispatch = useDispatch();
  // const [deleteAssignedTalent, setDeleteAssignedTalent] = useState("true");
  console.log("talentsList", talentsList?.ratingData);
  const priority1Talent = useMemo(() => {
    return talentsList.find((talent) => talent._id === selectedPriority1Talent?._id);
  }, [selectedPriority1Talent, talentsList]);

  // const priority2Talent = useMemo(() => {
  //   return talentsList.find((talent) => talent._id === selectedPriority2Talent?._id);
  // }, [selectedPriority2Talent, talentsList]);
  //console.log("dgd", talent?.ratingData);
  //const removeAssignedTalent()
  // const starArray = Array.from({ length: 5 }, (_, index) => index + 1);

  const TalentListItem = ({ talent, handleTalentOpen }) => (
    <ListItem className={`${styles.flexCard}`} style={{ padding: 0 }}>
      {deleteAssignedTalent === "true" ? (
        <>
          <Card className={`panelCard`}>
            <div className={`${styles.flexCard} ${styles.justifyBW}`}>
              <Box className={`${styles.teamCard}`}>
                <CardMedia className={`${styles.teamMemberImg}`}>
                  {talent?.profilePicture ? <img src={talent?.profilePicture} alt="TeamMember" /> : <span>{talent?.name.charAt(0)}</span>}
                </CardMedia>
                <CardContent className={`${styles.teamMemberDetail}`}>
                  <Typography variant="h4">
                    {talent?.name} ({talent?.proficiency})
                  </Typography>
                  {/* <Typography variant="body">
                    <div className={`${styles.ratingStar} flexCard`}>
                      <span> {talent?.ratingData[0]?.rating}</span>
                      {starArray.map((index) => (
                        <StarBorderPurple500Icon
                          key={index}
                          className={`cursor-pointer ${styles.ratingIcon} ${index <= talent?.ratingData[0]?.rating ? styles.rated : ""}`}
                        />
                      ))}
                    </div>
                    Rating(s): <b> {talent?.ratingData[0]?.rating}</b>
                  </Typography> */}
                </CardContent>
              </Box>
            </div>
          </Card>
          <Box>
            <IconButton className={`${btnStyles.iconBtnPrimary}`} onClick={() => removeAssignedTalent()}>
              {/* Pass the function reference, not calling the function immediately */}
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      ) : (
        <>c</>
      )}
    </ListItem>
  );

  // function for 2 talent submit
  // const handleTalentAssign = () => {
  //   if (selectedPriority1Talent && selectedPriority2Talent) {
  //     const request = {
  //       projectID: selectedProjectId,
  //       talents: [selectedPriority1Talent._id, selectedPriority2Talent._id],
  //     };
  //     dispatch(talentsAssignAPI(request));
  //   }
  //   handleClose();
  // };

  const handleTalentAssign = () => {
    if (selectedPriority1Talent) {
      const request = {
        projectID: selectedProjectId,
        talents: [selectedPriority1Talent._id],
      };
      dispatch(talentsAssignAPI(request));
    }
    handleClose();
    localStorage.setItem("isHidden", "false");
  };
  return (
    <Dialog
      className={`assignTalentPoup`}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" className={`${styles.modalTitle}`}>
          Assign Talent to Project
        </Typography>
        <span className={`${styles.modalSubTitle}`}>Select talent from below list</span>
      </DialogTitle>
      <DialogContent className={`${styles.talentList}`}>
        <Grid container spacing={2}>
          <Grid item sm={12} className="mb-3">
            <Typography variant="h6" className={`${styles.modalHeadingLabel}`}>
              Priority
            </Typography>
            <List>
              <ListItem>
                {isHiddenValue === "true" ? (
                  deleteAssignedTalent === "true" ? (
                    ""
                  ) : (
                    <Typography onClick={() => handleTalentOpen("priority-1")} className={`${styles.addAnchor}`}>
                      +Add Talent
                    </Typography>
                  )
                ) : (
                  <Typography onClick={() => handleTalentOpen("priority-1")} className={`${styles.addAnchor}`}>
                    +Add Talent
                  </Typography>
                )}
              </ListItem>
              {priority1Talent ? <TalentListItem talent={priority1Talent} /> : null}
              {/* {priority1Talent ? (
                <ListItem className={`${styles.flexCard}`} style={{ padding: 0 }}>
                  <Card className={`panelCard`}>
                    <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                      <Box className={`${styles.teamCard}`}>
                        <CardMedia className={`${styles.teamMemberImg}`}>
                          <img src={team} alt="TeamMember" />
                        </CardMedia>
                        <CardContent className={`${styles.teamMemberDetail}`}>
                          <Typography variant="h4">{priority1Talent?.name}</Typography>
                          <Typography variant="body">
                            Rating(s): <b>89%</b>
                          </Typography>
                        </CardContent>
                      </Box>
                    </div>
                  </Card>
                  <Box>
                    <IconButton className={`${btnStyles.iconBtnPrimary}`}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </ListItem>
              ) : null} */}
            </List>
          </Grid>
          {/* // Ui for 2 talent select */}
          {/* <Grid item sm={12}>
            <Typography variant="h6" className={`${styles.modalHeadingLabel}`}>
              Priority 2
            </Typography>
            <List>
              <ListItem>
                <Typography className={`${styles.addAnchor}`} onClick={() => handleTalentOpen("priority-2")}>
                  +Add Talent
                </Typography>
              </ListItem>
              {priority2Talent ? <TalentListItem talent={priority2Talent} /> : null}
            </List>
          </Grid> */}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}>
          Cancel
        </Button>
        <Button
          disabled={selectedPriority1Talent === null || selectedPriority1Talent._id === ""}
          onClick={handleTalentAssign}
          className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
          // autoFocus
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AssignPopUp;
