import { Box, Button, Card, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./priceplan.module.css";

import btnStyles from "../../../assets/button.module.css";
import User1 from "../../../assets/images/user1.png";
import User2 from "../../../assets/images/user2.png";
import User3 from "../../../assets/images/user3.png";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../reducers/snackbarSlice";
import { initialQueryAPI } from "../../../reducers/intialQuerySlice";
import { getPlanDetails } from "../../../utils/plan";
import { useState } from "react";
import { TickIcon } from "../../../assets/yingoIcons";
import { useTranslation } from "react-i18next";
export default function PricingPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  function createData(feature, proffessional, expert, genius) {
    return { feature, proffessional, expert, genius };
  }

  const handleSelctedPlan = (type) => {
    const token = localStorage.getItem("token");
    const queryPresent = localStorage.getItem("assistantMsgParam");
    const role = localStorage.getItem("role");
    const selectedPlan = getPlanDetails(type);

    localStorage.setItem("plan", JSON.stringify(selectedPlan));

    if (token && queryPresent && role !== "admin") {
      const assistantMsgParam = localStorage.getItem("assistantMsgParam");
      const localSkillResponseList = localStorage.getItem("skillResponseList");
      const localDeliverableResponseList = localStorage.getItem("deliverableResponseList");
      const localAiTools = localStorage.getItem("aiTools");
      const projectTitle = localStorage.getItem("getTitle");
      const parsedLocalDeliverableResponseList = JSON.parse(localDeliverableResponseList);
      const data = {
        name: projectTitle,
        smallDescription: "string",
        longDescription: assistantMsgParam,
        deliverable: parsedLocalDeliverableResponseList,
        skillsRequired: localSkillResponseList,
        status: "not-submitted",
        tags: [localAiTools],
        plan: selectedPlan,
      };

      const projectIdPromise = dispatch(initialQueryAPI(data));

      projectIdPromise
        .then((projectId) => {
          navigate(`/projects/${projectId}`);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      navigate("/customerLogin");
    }

    dispatch(openSnackbar({ message: `You selected the ${type} plan`, severity: "success" }));
  };

  const rows = [
    createData(
      <span className={`${styles.light}`}>{t("textCoreFeatures")}</span>,
      <span className={`${styles.amount}`}>
        <span className={`${styles.small}`}>$</span>99
      </span>,
      <span className={`${styles.amount}`}>
        <span className={`${styles.small}`}>$</span>199
      </span>,
      <span className={`${styles.amount}`}>
        <span className={`${styles.small}`}>$</span>249
      </span>,
    ),
    createData(
      <span className={`${styles.titleWithIcon}`}>
        <TickIcon />
        <span>{t("textRow1Col1")} </span>
      </span>,
      <span>{t("textRow1Col2")} </span>,
      t("textRow1Col2"),
      t("textRow1Col3"),
      t("textRow1Col4"),
    ),
    createData(
      <span className={`${styles.titleWithIcon}`}>
        <TickIcon />
        <span>{t("textRow2Col1")} </span>
      </span>,
      t("textRow2Col2"),
      t("textRow2Col3"),
      t("textRow2Col4"),
    ),
    createData(
      <span className={`${styles.titleWithIcon}`}>
        <TickIcon />
        <span>{t("textRow3Col1")} </span>
      </span>,
      <span className={`${styles.flexCard}`}>
        <img src={User1} alt="" /> 25
      </span>,
      <span className={`${styles.flexCard}`}>
        <img src={User2} alt="" /> 10
      </span>,
      <span className={`${styles.flexCard}`}>
        <img src={User3} alt="" /> 5
      </span>,
    ),
    createData(
      <span className={`${styles.titleWithIcon}`}>
        <TickIcon />
        <span>{t("textRow4Col1")} </span>
      </span>,
      "1",
      "3",
      "5",
    ),
    createData(
      <span className={`${styles.titleWithIcon}`}>
        <TickIcon />
        <span>{t("textRow5Col1")} </span>
      </span>,
      <span> {t("textRow5Col2")} </span>,
      <span>{t("textRow5Col3")}</span>,
      <span>{t("textRow5Col4")}</span>,
    ),
    createData(
      "",
      <Button
        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtnOutline} ${btnStyles.yinSqCornerSm}`}
        onClick={() => handleSelctedPlan("Professionals")}
      >
        {t("textSelect")}
      </Button>,
      <Button
        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtnOutline} ${btnStyles.yinSqCornerSm}`}
        onClick={() => handleSelctedPlan("Expert")}
      >
        {t("textSelect")}
      </Button>,
      <Button
        className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtnOutline} ${btnStyles.yinSqCornerSm}`}
        onClick={() => handleSelctedPlan("Genius")}
      >
        {t("textSelect")}
      </Button>,
    ),
  ];

  const [activePlan, setActivePlan] = useState("Professional");
  const handleTabClick = (tab) => {
    setActivePlan(tab);
  };
  return (
    <Box className={`container ${styles.pricePlanBox} ${styles.textWhite} `}>
      <Grid container>
        <Grid item sm={12} className={`${styles.textCenter}`}>
          <Typography variant="h1" className={styles.headingH1}>
            {t("textTalentProficiency")}
          </Typography>
          <Typography style={{ marginBottom: "30px" }}>{t("pricingSubTitle")}</Typography>
        </Grid>

        <Grid item sm={12} className={`${styles.textCenter}`}>
          <Box className={`${styles.mobilePlanTab}`}>
            <Box
              className={`${activePlan === "Professional" ? styles.activeTabPad : ""} ${styles.mobileTabbox} ${styles.mobileTabOne}`}
              onClick={() => handleTabClick("Professional")}
            >
              <Typography> {t("pricingPlanProfessional")} </Typography>
            </Box>
            <Box
              className={`${activePlan === "Expert" ? styles.activeTabPad : ""} ${styles.mobileTabbox} ${styles.mobileTabTwo}`}
              onClick={() => handleTabClick("Expert")}
            >
              <Typography>{t("pricingPlanExpert")} </Typography>
            </Box>
            <Box
              className={`${activePlan === "Genius" ? styles.activeTabPad : ""} ${styles.mobileTabbox} ${styles.mobileTabThree}`}
              onClick={() => handleTabClick("Genius")}
            >
              <Typography>{t("pricingPlanGenius")} </Typography>
            </Box>
          </Box>
        </Grid>
        <Card className={`${styles.priceTable} `}>
          <Table aria-label="simple table" class="priceTableBox">
            <TableHead className={`${styles.priceTableHead}`}>
              <TableRow className={`${styles.priceTableRow}`}>
                <TableCell className={`${styles.noBorder} ${styles.firtRowWidth}`}></TableCell>
                <TableCell className={`${styles.noBorder} ${styles.selectedbg} ${styles.centerText}`}>{t("pricingPlanProfessional")} </TableCell>
                <TableCell className={`${styles.noBorder} ${styles.centerText}`}>{t("pricingPlanExpert")}</TableCell>
                <TableCell className={`${styles.noBorder} ${styles.selectedbg} ${styles.centerText}`}>{t("pricingPlanGenius")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={`${styles.priceTableHead}`}>
              {rows.map((row) => (
                <TableRow key={row.feature} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className={`${styles.rows}`}>
                  <TableCell className={`${styles.noBorder} ${styles.priceHeading} ${styles.bottomLine}  ${styles.bottomLineLeft}`}>
                    {row.feature}
                  </TableCell>

                  <TableCell className={`${styles.noBorder} ${styles.centerText}  ${styles.selectedbg} ${styles.bottomLine}`}>
                    {row.proffessional}
                  </TableCell>

                  <TableCell className={`${styles.noBorder} ${styles.centerText} ${styles.bottomLine}`}>{row.expert}</TableCell>

                  <TableCell className={`${styles.noBorder} ${styles.selectedbg} ${styles.centerText} ${styles.bottomLine}`}>{row.genius}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>

        <Grid container className={`${styles.mobilePlansContainer}`}>
          <Grid item xs={12} className={` ${styles.hiddenPlanBox} ${activePlan === "Professional" ? styles.viewPlanBox : ""}`}>
            <Card className={`${styles.planBoxCard}`}>
              <Box className={`${styles.planBoxCardHead}`}>
                <Typography variant="h5">Professional</Typography>
              </Box>
              <Box className={`${styles.planBoxCardBody}`}>
                {rows.map((row) => (
                  <Typography key={row.feature} variant="h5">
                    {row.proffessional}
                  </Typography>
                ))}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} className={`${styles.hiddenPlanBox}  ${activePlan === "Expert" ? styles.viewPlanBox : ""}`}>
            <Card className={`${styles.planBoxCard}`}>
              <Box className={`${styles.planBoxCardHead}`}>
                <Typography variant="h5">Expert</Typography>
              </Box>
              <Box className={`${styles.planBoxCardBody}`}>
                {rows.map((row) => (
                  <Typography key={row.feature} variant="h5">
                    {row.expert}
                  </Typography>
                ))}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} className={` ${styles.hiddenPlanBox} ${activePlan === "Genius" ? styles.viewPlanBox : ""}`}>
            <Card className={`${styles.planBoxCard}`}>
              <Box className={`${styles.planBoxCardHead}`}>
                <Typography variant="h5">Genius</Typography>
              </Box>
              <Box className={` ${styles.planBoxCardBody}`}>
                {rows.map((row) => (
                  <Typography key={row.feature} variant="h5">
                    {row.genius}
                  </Typography>
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
