import { Box, CircularProgress } from "@mui/material";
import styles from "./loader.module.css";
export default function Loader() {
  return (
    <>
      <Box className={`${styles.globalLoader}`}>
        <CircularProgress color="inherit" className={`${styles.globalloaderIcon}`} />
      </Box>
    </>
  );
}
