/* eslint-disable react/prop-types */
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from "@mui/material";
import styles from "./talent.module.css";
import btnStyles from "../../../assets/button.module.css";
import { useSelector } from "react-redux";
// import talentImg from "../../../assets/images/team1.png";
// import SearchIcon from "@mui/icons-material/Search";
// import { useEffect, useState } from "react";
// import { talentsListAPI } from "../../../reducers/talentSlice";
// import Loader from "../../common/loader";

const TeamListPopUp = (props) => {
  const { openTalent, handleTalentClose, handleChange, selectedTeam, handleAssignTalent, defaultSelectOption } = props;
  const { talentsList } = useSelector((state) => state.talents);

  // const dispatch = useDispatch();

  // const [searchInput, setSearchInput] = useState("");

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     dispatch(talentsListAPI(searchInput));
  //   }, 3000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchInput]);

  // const handleSearchInputChange = (event) => {
  //   const input = event.target.value;
  //   setSearchInput(input);
  // };

  // const defaultSelectOption = { _id: "", name: "Select" };
  return (
    <Dialog
      className={`assignTalentPoup`}
      open={openTalent}
      onClose={handleTalentClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" className={`${styles.modalTitle}`}>
          Select Talent for Project
        </Typography>
        {/* <span className={`${styles.modalSubTitle}`}>Choose talent form list</span> */}
      </DialogTitle>
      <DialogContent className={`${styles.talentList}`}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <FormControl style={{ width: "100%" }} id={`talentAutocompleteBox`}>
              <Autocomplete
                id={`talent-autocomplete`}
                options={[defaultSelectOption, ...talentsList]}
                getOptionLabel={(option) => (option ? (option.name === "Select" ? option.name : `${option.name} (${option.proficiency})`) : "")}
                // getOptionSelected={(option, value) => option._id === value._id}
                // defaultValue={defaultSelectOption}
                value={selectedTeam}
                onChange={(event, newValue) => handleChange(event, newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
          {talentsList.length > 0 ? (
            talentsList.map((team) => {
              const { _id, name } = team;
              return (
                <Grid key={_id} item sm={12}>
                  <FormControl style={{ width: "100%" }}>
                    <RadioGroup
                      aria-labelledby={`demo-radio-buttons-group-label-${_id}`}
                      defaultValue=""
                      name={`talent-radio-buttons-group-${_id}`}
                      value={selectedTeam}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={_id} // Set the value to the team name
                        className={`talentListCheck ${styles.talentListItem}`}
                        control={<Radio />}
                        label={
                          <Card className={`panelCard`}>
                            <div className={`${styles.flexCard} ${styles.justifyBW}`}>
                              <Box className={`${styles.teamCard}`}>
                                <CardMedia className={`${styles.teamMemberImg}`}>
                                  <img src={talentImg} alt="TeamMember" />
                                </CardMedia>
                                <CardContent className={`${styles.teamMemberDetail}`}>
                                  <Typography variant="h4">{name}</Typography>
                                  <Typography variant="body">
                                    Rating(s): <b>{team.rating}</b>
                                  </Typography>
                                </CardContent>
                              </Box>
                            </div>
                          </Card>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <p>No Talent found...</p>
            </Grid>
          )}
        </Grid> */}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleTalentClose} className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}>
          Cancel
        </Button>
        <Button
          disabled={selectedTeam._id === ""}
          onClick={handleAssignTalent}
          className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
          autoFocus
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TeamListPopUp;

// const TeamListPopUp = (props) => {
//   const { openTalent, handleTalentClose, handleChange, selectedTeam, handleAssignTalent } = props;
//   const { talentsList } = useSelector((state) => state.talents);

//   const dispatch = useDispatch();

//   const [searchInput, setSearchInput] = useState("");

//   useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       dispatch(talentsListAPI(searchInput));
//     }, 3000);

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchInput]);

//   const handleSearchInputChange = (event) => {
//     const input = event.target.value;
//     setSearchInput(input);
//   };

//   return (
//     <Dialog
//       className={`assignTalentPoup`}
//       open={openTalent}
//       onClose={handleTalentClose}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//     >
//       <DialogTitle id="alert-dialog-title">
//         <Typography variant="h4" className={`${styles.modalTitle}`}>
//           Select Talent for Project
//         </Typography>
//         {/* <span className={`${styles.modalSubTitle}`}>Choose talent form list</span> */}
//       </DialogTitle>
//       <DialogContent className={`${styles.talentList}`}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={12} md={12} className={`${styles.buttonAlignment}`}>
//             <Box className={`${styles.searchBar}`}>
//               <TextField
//                 id="outlined-basic"
//                 variant="outlined"
//                 placeholder="Search"
//                 autoComplete="off"
//                 className={`${styles.searchInput}`}
//                 value={searchInput}
//                 onChange={handleSearchInputChange}
//               />
//               <SearchIcon className={`${styles.inputIcon}`} />
//             </Box>
//           </Grid>
//           {talentsList.length > 0 ? (
//             talentsList.map((team) => {
//               const { _id, name } = team;
//               return (
//                 <Grid key={_id} item sm={12}>
//                   <FormControl style={{ width: "100%" }}>
//                     <RadioGroup
//                       aria-labelledby={`demo-radio-buttons-group-label-${_id}`}
//                       defaultValue=""
//                       name={`talent-radio-buttons-group-${_id}`}
//                       value={selectedTeam}
//                       // value={selectedPriority === "priority-1" ? selectedPriority1Talent : selectedPriority2Talent}
//                       onChange={handleChange}
//                     >
//                       <FormControlLabel
//                         value={_id} // Set the value to the team name
//                         className={`talentListCheck ${styles.talentListItem}`}
//                         control={<Radio />}
//                         label={
//                           <Card className={`panelCard`}>
//                             <div className={`${styles.flexCard} ${styles.justifyBW}`}>
//                               <Box className={`${styles.teamCard}`}>
//                                 <CardMedia className={`${styles.teamMemberImg}`}>
//                                   <img src={talentImg} alt="TeamMember" />
//                                 </CardMedia>
//                                 <CardContent className={`${styles.teamMemberDetail}`}>
//                                   <Typography variant="h4">{name}</Typography>
//                                   <Typography variant="body">
//                                     Rating(s): <b>{team.rating}</b>
//                                   </Typography>
//                                 </CardContent>
//                               </Box>
//                             </div>
//                           </Card>
//                         }
//                       />
//                     </RadioGroup>
//                   </FormControl>
//                 </Grid>
//               );
//             })
//           ) : (
//             <Grid item xs={12} sm={12} md={12}>
//               <p>No Talent found...</p>
//             </Grid>
//           )}
//         </Grid>
//       </DialogContent>

//       <DialogActions>
//         <Button onClick={handleTalentClose} className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}>
//           Cancel
//         </Button>
//         <Button onClick={handleAssignTalent} className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} autoFocus>
//           Assign
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
