import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Grid,
  List,
  ListItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import styles from "./ryaBot.module.css";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SendIcon from "@mui/icons-material/Send";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import Loader from "../../../components/common/loader";
import CloseIcon from "@mui/icons-material/Close";
import Send from "../../../assets/icons/send.svg";
import SendLight from "../../../assets/icons/send-light.svg";
import botIcon from "../../../assets/icons/rya-xl.png";
// import DummyImg from "../../../assets/icons/dummy.png";
import btnStyles from "../../../assets/button.module.css";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import PropTypes from "prop-types";
// import CloseIcon from "@mui/icons-material/Close";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { navbarProjectTitle, projectCreditFunc, ryaTalentProjectsAPI } from "../../../reducers/talentProjectSlice";
import axios from "axios";
import RyaCategories from "./categories";
import { ThreeDots } from "react-loader-spinner";
import { instructions } from "../../../utils/ryaInstructions";

export default function RyaDashboard() {
  // console.log("instructions", instructions.categories[0]?.subcategories[0]?.actionId);
  const baseUrl = process.env.REACT_APP_BASE_URL_LOCAL || process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isListPopupOpen, setListPopupOpen] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [handleDropdownBox, setHandleDropdownBox] = useState(false);
  const [selectProject, setSelectProject] = useState("Select");
  const [openPop, setOpenPop] = useState(false);
  const [choosedCat, setChoosedCat] = useState(false);
  const [errors, setError] = useState(false);
  const [showingCredit, setShowingCredit] = useState("");
  const availableCredit = localStorage.getItem("ryaSelectProjectCredits");
  const { loading, talentProjectList, headerProjectTitle, projectCredit } = useSelector((state) => state.talentProject);
  const ryaSelectProjectName = localStorage.getItem("ryaSelectProjectName");

  const userName = localStorage.getItem("name");
  const profile_picture = localStorage.getItem("profile_picture");
  const chatContainerRef = useRef(null);
  const handleInputChange = (e) => {
    setHandleDropdownBox(false);
    setError(false);
    const value = e.target.value;
    setInputValue(value);
    if (value.trim() === "/") {
      setListPopupOpen(true);
      dispatch(ryaTalentProjectsAPI());
    } else {
      setListPopupOpen(false);
    }
  };

  const handleProjectDropdownOpen = () => {
    dispatch(ryaTalentProjectsAPI());
    setHandleDropdownBox(!handleDropdownBox);
  };

  //********** CHAT API  Start*************//

  const url = `${baseUrl}/talent/riaBotChatApi`;
  const urlChatHistory = `${baseUrl}/talent/riaChatBotHistory`;
  const userToken = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: userToken,
  };
  const handleUserQuery = async () => {
    const currentActionId = localStorage.getItem("currentActionId");
    const assistantName = localStorage.getItem("currentCatName");
    const ryaSelectProjectId = localStorage.getItem("ryaSelectProjectId");
    if (!currentActionId) {
      setError("Please select category");
      return;
    } else {
      setError("");
      setIsLoader(true);
      setChoosedCat("");
      const userMessage = {
        _id: "",
        projectId: ryaSelectProjectId,
        userId: "",
        actionId: currentActionId,
        assistantName: assistantName,
        messageBody: inputValue,
        threadId: "",
        openAiResponse: "",
      };

      console.log("usermsss", userMessage);
      if (inputValue) {
        setUserMessages((prevUserMessages) => [...prevUserMessages, userMessage]);
        const request = {
          actionId: currentActionId,
          messageBody: inputValue,
          projectId: ryaSelectProjectId,
          assistantName: assistantName,
        };
        try {
          const Newresponse = await axios.post(url, request, { headers });
          if (Newresponse.status === 200) {
            setInputValue("");
            setUserMessages((prevBotMessages) => [...prevBotMessages, Newresponse.data.result]);

            //console.log("selectedProjectCredits = usedCredits", availableCredit);
            const deducatedCredtis = availableCredit - Newresponse.data.result.usedCredits;
            dispatch(projectCreditFunc(availableCredit - Newresponse.data.result.usedCredits));
            localStorage.setItem("ryaSelectProjectCredits", deducatedCredtis);
            //console.log(" Newresponse.data.result ", Newresponse.data.result);
          } else {
            console.log(" Cancelled API Proceesed ");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    setIsLoader(false);
  };

  useEffect(() => {
    // Scroll to bottom after rendering new messages
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
    if (projectCredit > 0) {
      setShowingCredit(projectCredit);
    } else {
      setShowingCredit("0.00");
    }
    if (availableCredit > 0) {
      setShowingCredit(availableCredit);
    } else {
      setShowingCredit("0.00");
    }
  }, [userMessages, projectCredit, availableCredit]);
  //********** CHAT API  END *************//
  //console.log("showingCredit", showingCredit);
  //********** CANCEL CHAT QUERY  API *************//

  const urlCancelQuery = `${baseUrl}/talent/cancelRiaChatRequest`;
  const handleCancelQueryBtn = async () => {
    setIsLoader(true);
    const currentActionId = localStorage.getItem("currentActionId");
    const ryaSelectProjectId = localStorage.getItem("ryaSelectProjectId");
    const userMessage = {
      projectId: ryaSelectProjectId,
      actionId: currentActionId,
    };
    const response = await axios.post(urlCancelQuery, userMessage, { headers });
    console.log("Cancel API", response.data?.result);
    setInputValue("");
    // setUserMessages(response.data.result);
  };

  //********** CANCEL CHAT QUERY  API  END *************//

  const handleSwitchCat = (actionId, catName) => {
    localStorage.setItem("currentActionId", actionId);
    localStorage.setItem("currentCatName", catName);
    setChoosedCat(catName);
    setInputValue("");
    setListPopupOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      document.body.classList.add("bgWhite");
      localStorage.setItem("isProjectSelect", "true");
      const pid = localStorage.getItem("ryaSelectProjectId");

      const response = await axios.get(`${urlChatHistory}?projectId=${pid}`, { headers });
      setUserMessages(response.data.result);
      setIsLoader(false);
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    };
    fetchData();

    return () => {
      document.body.classList.remove("bgWhite");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoader]);

  let selectedProjectCredits = "";
  const handleChangeProject = async (id, name) => {
    console.log("Current Id:", id);
    console.log("Currentname:", name);
    setSelectProject(id);
    localStorage.setItem("currentActionId", instructions.defaultCategories[0]?.actionId);
    localStorage.setItem("currentCatName", instructions.defaultCategories[0]?.name);

    console.log("updated State :", selectProject, "<===>", id);
    const selectedProject = talentProjectList.find((project) => project._id === id);
    selectedProjectCredits = selectedProject?.projectCredits;
    console.log("compared id", selectedProject._id);
    if (id === selectedProject._id) {
      localStorage.setItem("ryaSelectProjectName", selectedProject.name);
      localStorage.setItem("ryaSelectProjectId", id);
      localStorage.setItem("ryaSelectProjectCredits", selectedProjectCredits);
      localStorage.setItem("isProjectSelect", "true");
      dispatch(projectCreditFunc(selectedProjectCredits));
      dispatch(navbarProjectTitle(selectedProject.name));
      const response = await axios.get(`${urlChatHistory}?projectId=${id}`, { headers });
      setUserMessages(response.data.result);

      setInputValue("");
      handlePopupClose(false);
      setHandleDropdownBox(false);
    }
  };

  const ProjectList = ({ projects, onProjectClick, isSelected }) => {
    return (
      <List className={`${styles.projectList}`}>
        {projects?.map((project) => (
          <ListItem
            key={project.projectId}
            className={`${styles.projectListItem} ${isSelected === project.projectId ? styles.activeLi : ""}`}
            onClick={() => {
              onProjectClick(project.projectId);
              handleProjectClick(project.name, project._id, project?.projectCredits);
            }}
          >
            {project.name}
          </ListItem>
        ))}
      </List>
    );
  };

  //********** CHAT HISTORY  API *************//
  const handleProjectClick = async (p_name, p_id, p_credits) => {
    localStorage.setItem("isProjectSelect", "true");
    localStorage.setItem("ryaSelectProjectName", p_name);
    localStorage.setItem("ryaSelectProjectId", p_id);
    localStorage.setItem("ryaSelectProjectCredits", p_credits);
    dispatch(navbarProjectTitle(p_name, p_id));
    dispatch(projectCreditFunc(p_credits));
    setInputValue("");
    setListPopupOpen(false);
    const response = await axios.get(`${urlChatHistory}?projectId=${p_id}`, { headers });
    setUserMessages(response.data.result);
  };
  function handleBackButtonClick() {
    localStorage.setItem("isRyaClick", "false");
  }
  window.addEventListener("popstate", handleBackButtonClick);

  const TypingEffect = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
      let index = 0;
      const typingInterval = setInterval(() => {
        setDisplayedText((prevText) => prevText + text.charAt(index));
        index++;

        if (index === text.length) {
          clearInterval(typingInterval);
        }
      }, speed);

      return () => clearInterval(typingInterval);
    }, [text, speed]);

    return <div>{displayedText}</div>;
  };

  const isProjectSelect = localStorage.getItem("isProjectSelect");

  // POP UP for Change Project //

  // const changeProjectHandle = (e) => {
  //   setSelectProject(e.target.value);
  // };
  // const handlePopupOpen = () => {
  //   dispatch(ryaTalentProjectsAPI());
  //   setOpenPop(true);
  // };
  const handlePopupClose = () => {
    setOpenPop(false);
  };

  // POP UP for Change Project END //

  let lastDisplayedCategory = null;
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (showingCredit === "0.00") {
        handleBalancerAlert();
      } else {
        handleUserQuery();
      }

      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
      }
    }
  };

  const handleBalancerAlert = () => {
    setOpenPop(true);
  };
  const closePoup = () => {
    setOpenPop(false);
  };

  return (
    <>
      <Box>
        <Box className={`${styles.titleBox}`}>
          <Box className={`${styles.titleCard}`} onClick={handleProjectDropdownOpen}>
            <Typography variant="h4">
              {headerProjectTitle ? headerProjectTitle : ryaSelectProjectName ? ryaSelectProjectName : "Welcome Project"}
            </Typography>
            <IconButton className={`${styles.arrowButton}`}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
          <Box className={`${styles.projectListDropdown} ${handleDropdownBox ? styles.dropdownShow : ""}`}>
            {loading ? (
              <>
                <Box className={`${styles.loaderBox}`}>
                  <CircularProgress color="inherit" className={`${styles.loaderIcon}`} />{" "}
                </Box>
              </>
            ) : (
              <List>
                {talentProjectList?.map((project) => {
                  const { _id, name } = project;
                  return (
                    <ListItem key={_id} value={_id} onClick={() => handleChangeProject(_id, name)}>
                      {name}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        </Box>

        <Card className={`panelCard ${styles.bgGray} ${styles.ryaChatCard} ${styles.fullPanelHeight}`}>
          <Box className={`${styles.innerChatBox}`} ref={chatContainerRef}>
            {/* Empty State Area*/}
            {userMessages.length > 0 ? (
              <>
                {/* Chat Message Area*/}
                {userMessages.map((userMessage, index) => {
                  const isNewCategory = lastDisplayedCategory !== userMessage.actionId;

                  if (isNewCategory) {
                    lastDisplayedCategory = userMessage.actionId;
                    return (
                      <>
                        <Box className={`${styles.categoryHeader}`}>{userMessage.assistantName} </Box>
                        {userMessage?.messageBody ? (
                          <Box key={index} className={`${styles.msgContainer} `} data-id={index}>
                            <Box className={`${styles.msgThumb} ${styles.talentThumb}`}>
                              {profile_picture ? <img src={profile_picture} alt="" /> : <span>{userName.charAt(0)}</span>}
                            </Box>
                            <Box className={`${styles.userMsgBox}  ${styles.flexCard} ${styles.flexColumn}`}>
                              <Box className={`${styles.useMsgCard} ${styles.catSpace} `}>{userMessage?.messageBody}</Box>
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                        {userMessage?.openAiResponse ? (
                          <Box key={index + "~"} className={`${styles.msgContainer} ${styles.userMsgContainer}`}>
                            <Box className={`${styles.msgCard} ${styles.ryaCard}`} style={{ whiteSpace: "pre-line" }}>
                              {userMessage?.openAiResponse}
                            </Box>
                            <Box className={`${styles.msgThumb} ${styles.ryaThumb}`}>
                              <img src={botIcon} alt="" />
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      {userMessage?.messageBody ? (
                        <Box key={index} className={`${styles.msgContainer} `} data-id={index}>
                          <Box className={`${styles.msgThumb} ${styles.talentThumb}`}>
                            {profile_picture ? <img src={profile_picture} alt="" /> : <span>{userName.charAt(0)}</span>}
                          </Box>
                          <Box className={`${styles.userMsgBox} ${styles.flexCard} ${styles.flexColumn}`}>
                            <Box className={`${styles.useMsgCard}`}>{userMessage?.messageBody}</Box>
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                      {userMessage?.openAiResponse ? (
                        <Box key={index + "~"} className={`${styles.msgContainer} ${styles.userMsgContainer}`}>
                          <Box className={`${styles.msgCard} ${styles.ryaCard}`} style={{ whiteSpace: "pre-line" }}>
                            {userMessage?.openAiResponse}
                          </Box>
                          <Box className={`${styles.msgThumb} ${styles.ryaThumb}`}>
                            <img src={botIcon} alt="" />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
                {isLoader ? (
                  <Box className={`${styles.msgContainer} ${styles.userMsgContainer}`}>
                    <Box className={`${styles.msgCard} ${styles.ryaCard}`} style={{ whiteSpace: "pre-line" }}>
                      <div className={`${styles.assistanTpingLoader}`}>
                        Processing your input
                        <ThreeDots
                          height="40"
                          width="40"
                          radius="9"
                          color="#fa6400"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </Box>
                    <Box className={`${styles.msgThumb} ${styles.ryaThumb}`}>
                      <img src={botIcon} alt="" />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <Box className={`${styles.emptyStateBox}`}>
                  <Box className={`${styles.emptyStateContentBox}`}>
                    <img src={botIcon} alt="" className={`${styles.botIcon}`} />
                    <Typography variant="h3">Hi! I am Rya </Typography>
                    <Typography variant="">How may I assist you? I am here and ready to help.</Typography>
                    <Box className={`${styles.tipsBox} mt-4`}>
                      <Typography variant="body">Tips</Typography>
                      <Typography variant="h4">Press &apos;/&apos; For Inline Prompts</Typography>
                      <Typography>
                        Press &apos;/&apos; in the chat bar to use custom prompts and built-in notebook prompts like Deep Summaries and more.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {choosedCat ? (
            <Box className={`${styles.choosedCategoryBox}`}>
              <Box className={`${styles.choosedCategoryBadge}`}>
                <Typography variant="h4">{choosedCat}</Typography>
                {/* <IconButton className={`${styles.removeChoosedCat}`}>
                  <CloseIcon />
                </IconButton> */}
              </Box>
            </Box>
          ) : (
            ""
          )}

          {errors && <Box className={`${styles.errorMessage} `}>{errors}</Box>}
          {/* Popup*/}

          <Box className={`${styles.listPopupBox} ${isListPopupOpen ? styles.popupOpen : ""}`}>
            {isProjectSelect === "true" ? (
              <>
                <Grid container spacing={2}>
                  <RyaCategories
                    handleSwitchCat={handleSwitchCat}
                    inputValue={inputValue}
                    // setListPopupOpen={setListPopupOpen}
                    setInputValue={setInputValue}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item md={4} lg={3}>
                    <Box className={`${styles.flexCard} ${styles.listPopupHead} mb-1`}>
                      <Typography className={`${styles.textLightGray} mb-0`}>Projects</Typography>
                    </Box>
                    <ProjectList projects={talentProjectList} isSelected={selectedProjectId} onProjectClick={setSelectedProjectId} />
                  </Grid>
                  <Grid item md={8} lg={9}>
                    <Box className={`${styles.flexCard} ${styles.listPopupHead} pl-3`}></Box>
                    <Grid container spacing={2} className={`${styles.innerDataList} ${styles.mt20px} pl-4`}>
                      <Box className={`${styles.emptyStateBox} ${styles.height180px}`}>
                        <Box className={`${styles.flexCardEmpty}`}>
                          <Typography variant="h4">{t("textWelcomeProject")}</Typography>
                          <Typography variant="body">{t("textWelcomeProjectDescritipn")}</Typography>
                          <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}>
                            <AddIcon /> {t("textCreateProject")}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Card>
        <div className={`${styles.flexCard} ${styles.flexCol} ${styles.bottomBarDiv}`}>
          <Box component="form" noValidate autoComplete="off" className={` transparentInput ${styles.searchQueryForm} `}>
            <TextField
              value={inputValue}
              onChange={handleInputChange}
              id="outlined-required"
              placeholder="Type / to select category "
              className={`${styles.searchQueryInput}`}
              disabled={isLoader}
              onKeyDown={handleKeyDown}
            />
            {isLoader ? (
              <IconButton className={`${styles.roundedBtn} ${styles.stopBtn}`} onClick={handleCancelQueryBtn}>
                <StopCircleIcon />
              </IconButton>
            ) : (
              <Button className={`${styles.roundedBtn}`} onClick={showingCredit === "0.00" ? handleBalancerAlert : handleUserQuery}>
                <img src={Send} alt="" className={`${styles.darkImg}`} />
                <img src={SendLight} alt="" className={`${styles.lightImg}`} />
              </Button>
            )}
          </Box>
        </div>
      </Box>
      <Dialog className={`DelcineWarningModal modal-xxl`} open={openPop} onClose={handlePopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handlePopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContentText>
          <Typography className="popupTitle">Your Credit Balance is Zero</Typography>
          <Typography className="popupSubTitle">You {"don't"} have enough credits for conversation.</Typography>
        </DialogContentText>
        <Box className="popupBtn mt-3">
          <Button autoFocus className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={closePoup}>
            Submit
          </Button>
        </Box>
      </Dialog>
    </>
  );
  // eslint-disable-next-line no-unreachable
  ProjectList.propTypes = {
    projects: PropTypes.array.isRequired,
    onProjectClick: PropTypes.func.isRequired,
    isSelected: PropTypes.string.isRequired,
  };

  TypingEffect.propTypes = {
    text: PropTypes.string.isRequired,
    speed: PropTypes.number.isRequired,
  };
}
