/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./tabs.module.css";
import btnStyles from "../../../../assets/button.module.css";
import { Button, CircularProgress, Grid, IconButton, InputLabel, TextField, Tooltip } from "@mui/material";
import { TimesIcon, FileDarkIcon } from "../../../../assets/yingoIcons";
import { ChatIcon, DescIcon, DocumentIcon, TimelineIcon } from "../../../../assets/yingoIcons";
import TalentChat from "./chat";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileIcon from "../../../../assets/icons/file-filled.svg";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import UploadTalentDocument from "./uploadDocument";
import React, { useState } from "react";
import { formatDate } from "../../../../utils/helper";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import DataNotFound from "../../../../components/common/PageNotFound/dataNotFound";
import { talentDeliverableDocUploadAPI } from "../../../../reducers/talentProjectSlice";
import DocPreviewModal from "../../projects/components/DocPreviewModal";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TalentDeliverableTabs({ deliverabletitle, startDate, endDate, deliverableId, infoLoading, updateMessage }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [fileError, setFileError] = useState("");
  const [openDocPreviewModal, setOpenDocPreviewModal] = useState(false);
  const [selectedDocPathPreview, setSelectedDocPathPreview] = useState(null);

  // const theme = useTheme();
  const dispatch = useDispatch();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLGScreen = useMediaQuery("(max-width:767px)");
  //const talentProjectState = useSelector((state) => state.talentProject);

  const { talentProjectDetail, talentDeliverableDocumentList, docUploadLoading } = useSelector((state) => state.talentProject);
  //console.log("updateMessage", updateMessage);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const groupedDeliverablesDocuments = talentDeliverableDocumentList?.reduce((acc, doc) => {
    const dateKey = new Date(doc.created_at).toDateString();
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(doc);
    return acc;
  }, {});

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setFile(null);
    setFileError("");
    setTitleError("");
  };

  const handleTitleChange = (event) => {
    setTitleError("");
    setTitle(event.target.value);
  };

  const handleRemoveDoc = (event) => {
    setTitleError("");
    setTitle("");
    setFile(null);
    setFileError("");
    setTitleError("");
  };

  const handleFileChange = (acceptedFiles) => {
    setFileError("");
    // Assuming you only want to handle the first file
    const selectedFile = acceptedFiles[0];

    // Check if the file type is either PNG or JPG
    // const allowedFileTypes = ["image/png", "image/jpeg"];
    // if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
    if (selectedFile) {
      setFile(selectedFile);

      // Set the title as the file name
      setTitle(selectedFile.name);
    } else {
      // Display an error message or handle the case where the file type is not allowed
      console.error("Invalid file type. Please select a PNG or JPG image.");
    }
  };

  const handleFileUpload = () => {
    if (file === null) {
      setFileError("File is required !");
      return;
    }

    const extenionArray = ["jpeg", "jpg", "png", "pdf", "txt", "doc", "docx", "xls", "ppt", "zip"];
    const fileExtension = file.path.split(".").pop();

    if (!extenionArray.includes(fileExtension.toLowerCase())) {
      setFileError("Invalid File Type");
      return;
    }
    if (title.trim() === "") {
      // Display an error message or handle the case where title is empty or file is not selected
      setTitleError("Title is required !");
      return;
    }
    setFileError("");
    setTitleError("");
    const request = {
      doc: file,
      deliverableId: deliverableId,
      title: title,
    };
    dispatch(talentDeliverableDocUploadAPI(request, handleClose));

    // handleClose();
  };

  // const handleOpenDocPreviewModal = (path) => {
  //   setSelectedDocPathPreview(path);
  //   setOpenDocPreviewModal(true);
  // };

  const handleDownload = (path) => {
    fetch(path)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", path); // Empty string for the attribute will use the default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  // Usage in the component
  // <IconButton onClick={() => handleDownload(path, 'desired_filename.ext')}>
  //   <DownloadIcon className={`${styles.colorSecondary}`} />
  // </IconButton>

  const handleCloseDocPreviewModal = () => {
    setSelectedDocPathPreview(null);
    setOpenDocPreviewModal(false);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className={`basicTabList ${styles.delvierableTabList}`}
            value={value}
            onChange={handleChange}
            variant={isLGScreen ? "fullWidth" : "scrollable"}
            scrollButtons={isLGScreen ? "" : "auto"}
            aria-label=""
          >
            <Tab
              className={`basicTabListItem`}
              label={
                isLGScreen ? (
                  <Tooltip title="Comments">
                    <Box className={`${styles.tabIcon}`}>
                      <ChatIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  t("textComment")
                )
              }
              {...a11yProps(0)}
            />
            <Tab
              className={`basicTabListItem`}
              label={
                isLGScreen ? (
                  <Tooltip title="Description">
                    <Box className={`${styles.tabIcon}`}>
                      <DescIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  t("textDescription")
                )
              }
              {...a11yProps(1)}
            />
            <Tab
              className={`basicTabListItem`}
              label={
                isLGScreen ? (
                  <Tooltip title="Documents">
                    <Box className={`${styles.tabIcon}`}>
                      <DocumentIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  t("textDocuments")
                )
              }
              {...a11yProps(2)}
            />
            <Tab
              className={`basicTabListItem`}
              label={
                isLGScreen ? (
                  <Tooltip title="Timeline">
                    <Box className={`${styles.tabIcon}`}>
                      <TimelineIcon />
                    </Box>
                  </Tooltip>
                ) : (
                  t("textTimeline")
                )
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {infoLoading ? (
            <Box className="emptyStateContainer">
              <Loader />
            </Box>
          ) : (
            <TalentChat deliverableId={deliverableId} />
          )}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Typography variant="body">
            {infoLoading ? (
              <Box className="emptyStateContainer">
                <Loader />
              </Box>
            ) : talentProjectDetail[0] && talentProjectDetail[0].deliverable.length < 0 ? (
              <>
                <Box className={`${styles.emptyStateBox}`}>
                  <Box className="emptyStateContainer">
                    <DataNotFound icon={"record"} title={""} subtitle={t("textDescriptionNotFound")} />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className={`${styles.emptyStateBox}`}>
                <Box className="emptyStateContainer">
                  <DataNotFound icon={"record"} title={""} subtitle={t("textDescriptionNotFound")} />
                </Box>
              </Box>
            )}
          </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className={`${styles.flexCard}  justified-end`}>
            <IconButton
              onClick={handleClickOpen}
              className={` ${styles.positionBtn} ${styles.iconXLBtn}  ${btnStyles.iconBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
            >
              <AddIcon />
            </IconButton>

            {/* File Upload Modal */}
            <Dialog className={`docDialogCard`} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Typography className={`${styles.docCardTitle}`}>{t("textUploadDocument")}</Typography>
              </DialogTitle>
              <DialogContent style={{ width: "400px" }}>
                <InputLabel className={`${styles.inputLabel} `}>{t("textDocumentFile")} </InputLabel>
                {file != null ? (
                  <>
                    <Box className={`${styles.doclistitem}`}>
                      <Box class={`${styles.flexCard}`}>
                        <Box className={`${styles.docImgs}`}>
                          <FileDarkIcon />
                        </Box>
                        <span className={`${styles.docTitle}`}>{file.name} </span>
                      </Box>
                      <p className={`${styles.closeUploadBtn}`} onClick={handleRemoveDoc}>
                        <TimesIcon />
                      </p>
                    </Box>
                  </>
                ) : (
                  <UploadTalentDocument onDropChange={handleFileChange} />
                )}
                <p style={{ margin: 0, color: "red", fontSize: "13px" }}>{fileError}</p>
                <div className={`${styles.inputGroup} mt-2 mb-2`}>
                  <InputLabel className={`${styles.inputLabel} `}>{t("textDocumentTitle")}</InputLabel>
                  <div className={`${styles.inputBox}`}>
                    <TextField required className={`${styles.inputBoxInput}`} value={title} onChange={handleTitleChange} />
                  </div>
                  <p style={{ margin: 0, color: "red", fontSize: "13px" }}>{titleError}</p>
                </div>
                <Box className={`${styles.flexCard} justified-center mt-3`}>
                  <Button
                    onClick={handleClose}
                    className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                    style={{ marginRight: "10px" }}
                    disabled={docUploadLoading}
                  >
                    {t("textCancel")}
                  </Button>
                  <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`} onClick={handleFileUpload}>
                    {t("textUpload")} {docUploadLoading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          <div>
            <Box className={`${styles.deliverableTabContainer}`}>
              <Grid container spacing={2}>
                {infoLoading ? (
                  <Loader />
                ) : groupedDeliverablesDocuments && Object.entries(groupedDeliverablesDocuments).length > 0 ? (
                  Object.entries(groupedDeliverablesDocuments).map(([date, documents]) => (
                    <React.Fragment key={date}>
                      <Grid item sm={12} md={12} className={`mt-2`}>
                        <Typography className={`${styles.docCardTitle}`}>{date}</Typography>
                      </Grid>
                      {documents.map((doc) => {
                        const { _id, title, path } = doc;
                        return (
                          <Grid key={_id} item sm={12} md={6}>
                            <Box className={`${styles.docCardBox}`}>
                              <Box className={`${styles.flexCard} justified-between `}>
                                <Box className={`${styles.flexCard} ${styles.gap10}`}>
                                  <Box className={`${styles.docCardIcon}`}>
                                    <img src={FileIcon} alt="" />
                                  </Box>
                                  <Typography className={`${styles.docCardTitle}`}>{title}</Typography>
                                </Box>
                                <Box className={`${styles.flexCard}`}>
                                  {/* <IconButton onClick={() => handleOpenDocPreviewModal(path)}>
                                    <RemoveRedEyeIcon className={`${styles.colorPrimary}`} />
                                  </IconButton> */}

                                  <IconButton onClick={() => handleDownload(path)}>
                                    <DownloadIcon className={`${styles.colorPrimary}`} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  ))
                ) : (
                  <Grid item sm={12} md={12} className={``}>
                    <Box className={`${styles.emptyStateBox}`}>
                      <Box className="emptyStateContainer">
                        <DataNotFound icon={"record"} title={""} subtitle={t("textDocumentNotFound")} />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4}>
              <Typography className={`${styles.subtile}`}>{t("textStartDate")}:</Typography>
              <Typography className={`${styles.tabParagraph} ${styles.timeLineView}`}>
                <b>{formatDate(startDate)}</b>
              </Typography>
            </Grid>
            <Grid item sm={6} lg={4}>
              <Typography className={`${styles.subtile}`}>{t("textEndDate")}:</Typography>
              <Typography className={`${styles.tabParagraph} ${styles.timeLineView}`}>
                <b>{formatDate(endDate)}</b>
              </Typography>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
      {openDocPreviewModal && (
        <DocPreviewModal
          openDocPreviewModal={openDocPreviewModal}
          selectedDocPathPreview={selectedDocPathPreview}
          handleCloseDocPreviewModal={handleCloseDocPreviewModal}
        />
      )}
    </>
  );
}
