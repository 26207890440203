/* eslint-disable react/prop-types */
import { Box, Button, Card, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputLabel, Typography } from "@mui/material";
import styles from "./admincard.module.css";
import btnStyles from "../../../assets/button.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import DataNotFound from "../../common/PageNotFound/dataNotFound";
import { capitalizeFirstLetter, formatDate } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { projectDeleteAdminAPI } from "../../../reducers/intialQuerySlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
export default function AdminProjectCard(props) {
  const dispatch = useDispatch();
  const { projectsList, currentTab } = props;
  const { projects } = projectsList;
  const [openCompleted, setOpenCompleted] = useState(false);
  const [panelId, setPanelId] = useState();
  //setPanelId(_id);
  const handleCompletedPopupOpen = (_id) => {
    setPanelId(_id);
    setOpenCompleted(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompleted(false);
  };
  const handleDeleteProject = (id) => {
    const request = {
      projectID: id,
    };
    dispatch(projectDeleteAdminAPI(request, currentTab));
  };

  return (
    <>
      {projects && projects.length > 0 ? (
        projects.map((project) => {
          const { _id, status, name, userInfo, plan, endDate } = project;

          return (
            <Card className={`${styles.adminCard}`} key={_id}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={12} md={12} lg={3} xl={2} xxl={2}>
                  <span
                    className={`${styles.badge} 
                  ${
                    capitalizeFirstLetter(status) === "In-progress"
                      ? styles.badgeInProgress
                      : capitalizeFirstLetter(status) === "Overdue"
                      ? styles.badgeOverdue
                      : styles.badgeCompleted
                  }`}
                  >
                    {capitalizeFirstLetter(status)}
                  </span>
                  <Typography variant="h4" className={`${styles.projectCols} ${styles.pr10}`}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2} xl={2} xxl={2}>
                  <InputLabel className={`${styles.inputLabel}`}>Customer</InputLabel>
                  <Typography variant="h4" className={`${styles.projectCols}`}>
                    {userInfo[0]?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2} xl={2} xxl={2}>
                  <InputLabel className={`${styles.inputLabel}`}>Talent</InputLabel>
                  <Typography variant="h4" className={`${styles.projectCols}`}>
                    {project?.talents[0]?.userInfo[0]?.name}
                    {project?.tentative === "true" ? <div className={`${styles.badge} ${styles.badgetentative}`}>Tentative</div> : ""}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3} lg={2} xl={2} xxl={2}>
                  <InputLabel className={`${styles.inputLabel}`}>Deadline</InputLabel>
                  <Typography variant="h4" className={`${styles.projectCols}`}>
                    {formatDate(endDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3} lg={2} xl={2} xxl={2}>
                  <InputLabel className={`${styles.inputLabel}`}>Pricing Plan</InputLabel>
                  <Typography variant="h4" className={`${styles.projectCols}`}>
                    {plan.type ? `${plan.type} $${plan.price}.00` : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} lg={1} xl={1} xxl={1}>
                  <Box className={`${styles.actionBtns}`}>
                    <Link to={`/preview/${_id}`}>
                      <IconButton className={`${styles.eyeIcon}`}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Link>
                    {/* <IconButton className={`${styles.deleteIcon}`} onClick={() => handleDeleteProject(_id)}> */}
                    <IconButton className={`${styles.deleteIcon}`} onClick={() => handleCompletedPopupOpen(_id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          );
        })
      ) : (
        <DataNotFound />
      )}
      {/* Dialog for Compelted for Deliverable */}
      <Dialog className="DelcineWarningModal" open={openCompleted} onClose={handleCompletedPopupClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle className={`${styles.dialogTitle}`} id="responsive-dialog-title">
          <box className="popupIcon">
            <WarningAmberIcon />
          </box>
          <IconButton className={`popupCrossBtn`}>
            <CloseIcon onClick={handleCompletedPopupClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className="popupTitle">Are you Sure to Delete!</Typography>
            <Typography className="popupSubTitle">Do you want to delete this project? If deleted, this project cannot be recovered.</Typography>

            <Box className="popupBtn mt-3 flexCard justified-center gap-sm">
              <Button
                onClick={() => handleCompletedPopupClose()}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
              >
                {" "}
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteProject(panelId)}
                autoFocus
                className={` ${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
              >
                {" "}
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
