import { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import styles from "./result.module.css";
import LineOverlay from "../../../assets/icons/linelayer.png";
import ChatRoom from "../../../components/dashboard/chatRoom";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/common/navbar";

function Result(props) {
  const location = useLocation();
  const resultText = new URLSearchParams(location.search).get("text");

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  return (
    <>
      <Navbar />
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className={`${styles.resultPage} custombg `}>
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <img src={LineOverlay} alt="" className={`${styles.lineLayerRight}`} />

        <div className={`${styles.chatArea}`}>
          <ChatRoom resultText={resultText} />
        </div>
      </Box>
    </>
  );
}

export default Result;
