/* eslint-disable react/prop-types */
// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ROLES } from "../utils/constant";

const PublicRoute = ({ children }) => {
  // const { loginStatus } = useSelector((state) => state.user);

  // if (userLoggedIn) {
  //   if(lastRoute){
  //     return <Navigate to={`${lastRoute}`}  />
  //   }
  //   return <Navigate to="/dashboard" replace />;
  // }

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  if (token) {
    if (role === ROLES.User) {
      return <Navigate to="/projects" replace />;
    } else if (role === ROLES.Admin) {
      return <Navigate to="/admin-dahbaord" replace />;
    } else {
      return <Navigate to="/talent-profile" replace />;
    }
  }

  return children;
};
export default PublicRoute;
