import { callAPI } from "../config/api-core";
import {
  customerRemoveProjectAPIMethods,
  projectCreateAPIMethods,
  projectDeleteAdminAPIMethods,
  projectDetailAPIMethods,
  projectDocAPIMethods,
  projectListAPIMethods,
  projectListByStatusAPIMethods,
  projectStatusAPIMethods,
  projectTitleAPIMethods,
} from "../methods";

const initialQueryAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  //   let token = JSON.parse(userToken);

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectCreateAPIMethods, false, request, header, false, false);
  return response;
};

const projectDetailAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  //   let token = JSON.parse(userToken);
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectDetailAPIMethods, false, request, header, false, false);
  return response;
};

const projectListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  //   let token = JSON.parse(userToken);
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectListAPIMethods, true, "", header, false, false);
  return response;
};

const projectListByStatusAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectListByStatusAPIMethods, false, request, header, false, false);
  return response;
};

const projectTitleAPI = async (request) => {
  const response = await callAPI(projectTitleAPIMethods, false, request, {}, false, false);
  return response;
};

const projectDocAPI = async (request) => {
  const { file, projectID } = request;
  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };

  const formData = new FormData();
  formData.append("file", file);
  formData.append("projectID", projectID);

  const response = await callAPI(projectDocAPIMethods, false, formData, header, false, false);
  return response;
};

const projectStatusAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectStatusAPIMethods, false, request, header, false, false);
  return response;
};

const projectDeleteAdminAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(projectDeleteAdminAPIMethods, false, request, header, false, false);
  return response;
};

/********* DELETE NOT SUBMITTED API GD ********** */

const customerRemoveProjectAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(customerRemoveProjectAPIMethods, false, request, header, false, false);
  return response;
};

export const postInitialQueryAPI = (request) => initialQueryAPI(request);
export const postProjectDetailAPI = (request) => projectDetailAPI(request);
export const getProjectDetailAPI = (request) => projectListAPI(request);
export const postProjectListByStatusAPI = (request) => projectListByStatusAPI(request);
export const updateProjectTitleAPI = (request) => projectTitleAPI(request);
export const updateProjectDocAPI = (request) => projectDocAPI(request);
export const updateProjectStatusAPI = (request) => projectStatusAPI(request);
export const deleteProjectAdminAPI = (request) => projectDeleteAdminAPI(request);
export const customerProjectRemoveAPI = (request) => customerRemoveProjectAPI(request);

export default {
  postInitialQueryAPI,
  postProjectDetailAPI,
  getProjectDetailAPI,
  postProjectListByStatusAPI,
  updateProjectTitleAPI,
  updateProjectDocAPI,
  updateProjectStatusAPI,
  deleteProjectAdminAPI,
  customerProjectRemoveAPI,
};
