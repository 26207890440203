/* eslint-disable max-len */
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./features.module.css";
import { Grid, List, ListItem, Typography } from "@mui/material";
import Feature1 from "../../../assets/images/image53.png";
import Feature2 from "../../../assets/images/image54.png";
import LogoLight from "../../../assets/icons/logo-opacity.png";
import { useTranslation } from "react-i18next";

export default function Features() {
  const { t } = useTranslation();
  const data = [
    {
      logo: Feature1,
      title: t("featureTitle1"),
      description: t("featureDescription1"),
    },
    {
      logo: Feature2,
      title: t("featureTitle2"),
      description: t("featureDescription2"),
    },
    {
      logo: Feature1,
      title: t("featureTitle3"),
      description: t("featureDescription3"),
    },
  ];
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    beforeChange: (currentSlide, nextSlide) => {
      setActiveSlide(nextSlide);
    },
  };

  return (
    <div className={`${styles.feaatureSection} featuresliders`}>
      <Grid container className={`container ${styles.py5} ${styles.featureCard}`}>
        <Grid item xs={12} md={6} style={{ margin: "0 auto" }}>
          <Typography variant="h1" className={`${styles.headingH1}`}>
            {/* YINGO: Unleashing <span className={`${styles.highlightKeyword}`}>the Power of</span> AI-Driven Features */}
            {t("featureHeading")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ position: "relative" }}>
          <List className={`${styles.sliderDots}`}>
            {data.map((item, index) => (
              <ListItem key={index} className={`${styles.slideDotItem} ${activeSlide === index ? styles.activeDot : ""}`}>
                {index < 9 ? `0${index + 1}` : index + 1}
              </ListItem>
            ))}
          </List>
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index}>
                <Grid container className={`${styles.sliderContianer}`}>
                  <Grid item xs={12} md={5}>
                    <img src={LogoLight} alt="" className={`${styles.notXs}`} />
                    <div className={`${styles.featureItem}`}>
                      <Typography variant="h4">{item.title}</Typography>
                      <Typography>{item.description}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <div className={`${styles.featureMedia}`}>
                      <img src={item.logo} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
}
