import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./messages.module.css";
import btnStyles from "../../../assets/button.module.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deliverableProjectListAPI, deliverablesListAPI, supportTalentDeliverablesAPI } from "../../../reducers/deliverablesSlice";
import { supportTicketComposeAPI } from "../../../reducers/supportSlice";
import { useTranslation } from "react-i18next";

export default function CreateTicket(props) {
  // const [previewImage, setPreviewImage] = useState("");
  // const [subject, setSubject] = useState("");
  const { t } = useTranslation();
  const [selectProject, setSelectProject] = useState("Select");
  const [selectDeliverable, setSelectDeliverable] = useState("Select");
  const [editorContent, setEditorContent] = useState(""); /// supportTicketComposeAPI
  const [attachedDoc, setAttachedDoc] = useState([]);
  const [attachedDocPreview, setAttachedDocPreview] = useState([]);
  const subjectRef = useRef("");
  // const editorContentRef = useRef("");
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deliverableProjectListing, deliverables } = useSelector((state) => state.deliverables);
  const { loading } = useSelector((state) => state.support);

  useEffect(() => {
    if (isMounted.current === false) {
      dispatch(deliverableProjectListAPI());
      isMounted.current = true;
    }
  }, [dispatch]);
  const userRole = localStorage.getItem("role");
  const changeProjectHandle = (e) => {
    if (e.target.value !== "Select") {
      if (userRole === "user") {
        dispatch(deliverablesListAPI(e.target.value));
      }
      if (userRole === "talent") {
        dispatch(supportTalentDeliverablesAPI(e.target.value));
      }
    }
    setSelectProject(e.target.value);
  };

  const changeDeliverableHandle = (e) => {
    setSelectDeliverable(e.target.value);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const previewImages = [];
      const images = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onloadend = () => {
          // Push the reader result (image data URL) to the previewImages array
          previewImages.push(reader.result);
          images.push(file);

          // If we have processed all files, update the state with the array of preview images
          if (previewImages.length === files.length) {
            setAttachedDocPreview([...attachedDocPreview, ...previewImages]);
            setAttachedDoc([...attachedDoc, ...images]);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleImageDelete = (index) => {
    const newAttachedDoc = [...attachedDoc];
    const newAttachedDocPreview = [...attachedDocPreview];

    newAttachedDoc.splice(index, 1);
    newAttachedDocPreview.splice(index, 1);

    setAttachedDoc(newAttachedDoc);
    setAttachedDocPreview(newAttachedDocPreview);
  };

  // const handleImageDelete = (index) => {
  //   const updatedAttachedDoc = attachedDocPreview.slice();
  //   updatedAttachedDoc.splice(index, 1);
  //   setAttachedDocPreview(updatedAttachedDoc);
  // };

  const handleDiscard = () => {
    subjectRef.current.value = "";
    // editorContentRef.current = "";
    setEditorContent("");
    setSelectProject("Select");
    setSelectDeliverable("Select");
    setAttachedDoc([]);
    setAttachedDocPreview([]);
  };

  const handleBack = () => {
    subjectRef.current.value = "";
    // editorContentRef.current = "";
    setEditorContent("");
    setSelectProject("Select");
    setSelectDeliverable("Select");
    setAttachedDoc([]);
    setAttachedDocPreview([]);
    if (userRole === "user") {
      navigate("/support");
    } else {
      navigate("/talent-support");
    }
  };

  const handleSubmitTicket = () => {
    const request = {
      file: attachedDoc,
      subject: subjectRef.current.value,
      projectId: selectProject,
      message: editorContent,
    };

    if (selectDeliverable !== "Select") {
      request.deliverableId = selectDeliverable;
    }

    // const request = {
    //   file: attachedDoc,
    //   subject: subjectRef.current.value,
    //   projectId: selectProject,
    //   deliverableId: selectDeliverable,
    //   message: editorContent,
    // };
    dispatch(supportTicketComposeAPI(request, handleBack));
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item sm={12} md={6}>
            <Box className={`${styles.flexTitleInput} ${styles.innerBtnGap}`}>
              <Typography variant="h4" className={`panelHeading`}>
                {t("textSupport")}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} className={`${styles.btnAligns}`} style={{ marginLeft: "auto" }}>
            <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`} onClick={handleBack}>
              {t("textBacktoMessages")}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Card className={`mt-3 ${styles.editorCard}`}>
              <CardContent>
                <Grid container spacing={2}>
                  {/* <Grid item sm={12} md={12}>
                    <InputLabel id="label" className={`${styles.formLabel}`}>
                      To
                    </InputLabel>

                    <Select className={`formInputSelect ${styles.formInput} `} value={value} onChange={changeHandler}>
                      <MenuItem value="admin@yingo.com">admin@yingo.com</MenuItem>
                    </Select>
                  </Grid> */}
                  <Grid item sm={12} md={12}>
                    <InputLabel id="label" className={`${styles.formLabel}`}>
                      {t("textSubject")}
                    </InputLabel>
                    <TextField
                      className={`${styles.formInput}`}
                      id="outlined-basic"
                      variant="outlined"
                      autoComplete="off"
                      // value={subject}
                      // onChange={(e) => setSubject(e.target.value)}
                      inputRef={subjectRef}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <InputLabel id="label" className={`${styles.formLabel}`}>
                      {t("textSelectProject")}
                    </InputLabel>
                    <Select className={`formInputSelect ${styles.formInput} `} value={selectProject} onChange={changeProjectHandle}>
                      <MenuItem value="Select">Select</MenuItem>
                      {deliverableProjectListing?.map((project) => {
                        const { _id, name } = project;
                        return (
                          <MenuItem key={_id} value={_id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <InputLabel id="label" className={`${styles.formLabel}`}>
                      {t("textSelectDeliverable")}
                    </InputLabel>
                    <Select
                      className={`formInputSelect ${styles.formInput} `}
                      value={selectDeliverable}
                      onChange={changeDeliverableHandle}
                      disabled={selectProject === "Select" || !deliverables[0]}
                    >
                      <MenuItem value="Select">Select</MenuItem>
                      {userRole === "user" &&
                        deliverables[0]?.deliverable?.map((item) => {
                          const { _id, title } = item;
                          return (
                            <MenuItem key={_id} value={_id}>
                              {title}
                            </MenuItem>
                          );
                        })}

                      {userRole === "talent" &&
                        deliverables?.map((item) => {
                          const { _id, title } = item;
                          return (
                            <MenuItem key={_id} value={_id}>
                              {title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <InputLabel id="label" className={`${styles.formLabel}`}>
                      {t("textMessage")}
                    </InputLabel>
                    <div className={`${styles.editorBox}`}>
                      {/* <CKEditor style={{ minHeight: "300px" }} editor={ClassicEditor} data="<p>Hello from CKEditor&nbsp;5!</p>" 
                      onReady={() => {}} /> */}
                      <CKEditor
                        style={{ minHeight: "300px" }}
                        editor={ClassicEditor}
                        onReady={() => {}}
                        data={editorContent}
                        onChange={(event, editor) => {
                          const content = editor.getData();
                          setEditorContent(content);
                        }}
                        // data={editorContentRef.current}
                        // onChange={(event, editor) => {
                        //   const content = editor.getData();

                        //   editorContentRef.current = content;
                        // }}
                      />
                    </div>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    {attachedDocPreview && attachedDocPreview.length > 0 && (
                      <List>
                        <ListItem className={`${styles.attachmentBox}`}>
                          {attachedDocPreview.map((image, index) => (
                            <Box className={`${styles.attachmentImgs}`} key={index}>
                              <img src={image} alt={`Document ${index + 1}`} />
                              <Box className={`${styles.RemoveAttachIcon}`} onClick={() => handleImageDelete(index)}>
                                <DeleteOutlineIcon />
                              </Box>
                            </Box>
                          ))}
                        </ListItem>
                      </List>
                    )}
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <div className={`${styles.flexCard} ${styles.justifiedBetween}`}>
                      <div className={`${styles.flexCard}`}>
                        <Button
                          className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound}`}
                          onClick={handleSubmitTicket}
                          // disabled={!subject || selectProject === "Select" || !editorContent}
                          // disabled={!subjectRef.current || selectProject === "Select" || !editorContentRef.current}
                          disabled={!subjectRef.current || selectProject === "Select" || !editorContent}
                        >
                          {t("textSubmit")} {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                        </Button>
                        <Tooltip title="Attchments">
                          <IconButton className={`${styles.attachmentBtnBox}`}>
                            <AttachFileIcon />
                            <input
                              type="file"
                              multiple
                              id="uploadimage"
                              onChange={handleImageUpload}
                              className={`${styles.attachmentInput}`}
                              accept="image/*"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Tooltip title="Discard">
                        <IconButton onClick={handleDiscard}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
