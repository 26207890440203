import { Grid } from "@mui/material";
import styles from "./pricing.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/common/navbar";
import PricingPlan from "../../../components/dashboard/pricePlans";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function Preview(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);
  return (
    <>
      <div className={`${styles.roundedbkg} custombg`}>
        <div className="container">
          <div className={`${styles.navbarBox}`}>
            <Navbar />
          </div>
          <Grid container className={`${styles.textWhite} ${styles.spaceT}`}>
            <Grid item sm={12} md={12}>
              <Link
                to="/preview"
                className={`${styles.flexCard} ${styles.anchorLink} `}
                style={{ justifyContent: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.clear();
                  const lastAssistantMessage = location.state?.lastAssistantMessage || "";
                  navigate("/preview", {
                    state: { lastAssistantMessage, pageType: "preview" },
                  });
                }}
              >
                <ArrowBackIcon />
                <span> {t("textBacktoProject")}</span>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <PricingPlan />
    </>
  );
}

export default Preview;
