/* eslint-disable react/prop-types */
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import styles from "./snackbar.module.css";
// function TransitionLeft(props) {
//   return <Slide {...props} direction="bottom" />;
// }

const SnackbarComponent = ({ open, message, severity, handleClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} className={`${styles.globalSnackBox}`}>
      <MuiAlert className={`${styles.globalSnackBar}`} elevation={6} variant="filled" severity={severity} onClose={handleClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;
