import { callAPI } from "../config/api-core";
import {
  talentAddAiToolsAPIMethods,
  talentAddEducationAPIMethods,
  talentAddExperienceAPIMethods,
  talentAddSkillAPIMethods,
  talentAiListAPIMethods,
  talentDeleteEducationAPIMethods,
  talentDeleteExperienceAPIMethods,
  talentEditEducationAPIMethods,
  talentEditExperienceAPIMethods,
  talentProfileAPIMethods,
  talentSkillListAPIMethods,
  talentUpdateProfileAPIMethods,
} from "../methods";

const talentProfileAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(talentProfileAPIMethods, true, "", header, false, false);
  return response;
};

const talentUpdateProfileAPI = async (request) => {
  const { countryCode, phone, profileImg, name, about } = request;
  let userToken = localStorage.getItem("token");
  let header = {
    "Content-Type": "multipart/form-data",
    Authorization: userToken && userToken,
  };
  const formData = new FormData();
  if (profileImg && profileImg instanceof File) {
    formData.append("file", profileImg);
  }
  formData.append("phone", phone);
  formData.append("name", name);
  if (countryCode) {
    formData.append("countryCode", countryCode);
  }
  if (about) {
    formData.append("about", about);
  }
  // formData.append("address", address);
  // formData.append("dob", dob);

  const response = await callAPI(talentUpdateProfileAPIMethods, false, formData, header, false, false);
  return response;
};

const talentEducationAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAddEducationAPIMethods, false, request, header, false, false);
  return response;
};

const talentEducationEditAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentEditEducationAPIMethods, false, request, header, false, false);
  return response;
};

const talentEducationDeleteAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentDeleteEducationAPIMethods, false, request, header, false, false);
  return response;
};

const talentExperienceAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAddExperienceAPIMethods, false, request, header, false, false);
  return response;
};

const talentExperienceEditAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentEditExperienceAPIMethods, false, request, header, false, false);
  return response;
};

const talentExperienceDeleteAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentDeleteExperienceAPIMethods, false, request, header, false, false);
  return response;
};

const talentSkillsAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAddSkillAPIMethods, false, request, header, false, false);
  return response;
};

const talentAiToolsAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAddAiToolsAPIMethods, false, request, header, false, false);
  return response;
};

const skillListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(talentSkillListAPIMethods, true, "", header, false, false);
  return response;
};

const aiListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  // const param = `?search=${request}`;
  const response = await callAPI(talentAiListAPIMethods, true, "", header, false, false);
  return response;
};

export const getTalentProfileAPI = (request) => talentProfileAPI(request);
export const postTalentProfileAPI = (request) => talentUpdateProfileAPI(request);
export const postTalentEducationAPI = (request) => talentEducationAPI(request);
export const postTalentEditEducationAPI = (request) => talentEducationEditAPI(request);
export const postTalentDeleteEducationAPI = (request) => talentEducationDeleteAPI(request);
export const postTalentExperienceAPI = (request) => talentExperienceAPI(request);
export const postTalentEditExperienceAPI = (request) => talentExperienceEditAPI(request);
export const postTalentDeleteExperienceAPI = (request) => talentExperienceDeleteAPI(request);
export const postTalentSkillsAPI = (request) => talentSkillsAPI(request);
export const postTalentAiToolsAPI = (request) => talentAiToolsAPI(request);
export const getSkillListAPI = (request) => skillListAPI(request);
export const getAiListAPI = (request) => aiListAPI(request);

export default {
  getTalentProfileAPI,
  postTalentProfileAPI,
  postTalentEducationAPI,
  postTalentExperienceAPI,
  postTalentSkillsAPI,
  getSkillListAPI,
  getAiListAPI,
  postTalentAiToolsAPI,
  postTalentEditExperienceAPI,
  postTalentEditEducationAPI,
  postTalentDeleteExperienceAPI,
  postTalentDeleteEducationAPI,
};
