/* eslint-disable react/prop-types */
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

import styles from "./projectCard.module.css";

import DummyImg from "../../../assets/images/dummy-image-square.jpeg";
import NoTalent from "../../../assets/icons/notalent.svg";
import ListIcon from "@mui/icons-material/List";
import { formatDate } from "../../../utils/helper";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";

export default function ProjectCard(props) {
  // eslint-disable-next-line react/prop-types
  const { status, projectInfo } = props;

  // const deliverable = projectInfo?.deliverables;
  // const deliverablesCount = projectInfo?.deliverable.length;
  const timestamp = new Date(projectInfo?.endDate);
  const year = timestamp.getFullYear();
  const month = (timestamp.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1.
  const day = timestamp.getDate().toString().padStart(2, "0");
  const endDate = `${year}-${month}-${day}`;
  return (
    <Box>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Card
            className={`${
              status === "Not-submitted"
                ? styles.notSubmitCard
                : status === "Pending"
                ? styles.pendingCard
                : status === "In-progress"
                ? styles.progressCard
                : status === "Overdue"
                ? styles.overdueCard
                : status === "Completed"
                ? styles.completedCard
                : ""
            } ${styles.projectCard}`}
          >
            <CardContent className={`${styles.projectContent}`}>
              <Box className={`${styles.projectCardHead} ${styles.flexBox}`}>
                <div className={`${styles.flexBox} `}>
                  <span className={`${styles.badge} ${styles[status]}`}>{status}</span>
                  {/* <p className={`${styles.lastStatusTime}`}>Updated 3hrs ago</p> */}
                </div>
              </Box>
              <Box className={`${styles.projectCardBody}`}>
                <Link to={`/projects/${projectInfo._id}`}>
                  <Box className={`${styles.projectInfo} ${styles.flexBox} ${styles.flexStart}`}>
                    <CardMedia className={`${styles.projectThumb}`}>
                      {/* <img src={ProjectImg} alt="" /> */}
                      <img src={DummyImg} alt="" />
                    </CardMedia>
                    <CardContent className={`${styles.projectDesc}`}>
                      <Typography variant="h4" className={`${styles.projectName}`}>
                        {projectInfo?.name}
                      </Typography>
                      <Typography variant="p" className={`${styles.projectDetail}`}>
                        {projectInfo?.longDescription}
                      </Typography>
                    </CardContent>
                  </Box>
                  <Box className={`${styles.tasksCard}`}>
                    <div className={`${styles.flexBox} ${styles.tasksBox}`}>
                      <span>Deliverables</span>
                      <span className={`${styles.flexBox}`} style={{ gap: "0" }}>
                        <ListIcon />
                        {projectInfo?.completedDeliverable}/{projectInfo?.inProgressDeliverable}
                      </span>
                    </div>
                  </Box>
                </Link>
                <Box>
                  <div className={`${styles.flexBox} ${styles.userBox}`}>
                    <div className={`${styles.flexBox}`}>
                      {projectInfo.talents?.length > 0 ? (
                        <>
                          <Link to={`/teams/${projectInfo.talents[0].userInfo[0]?._id}`}>
                            <CardMedia className={`${styles.userBoxImg}`}>
                              {projectInfo.talents[0].userInfo[0].profilePicture === "null" ||
                              projectInfo.talents[0].userInfo[0].profilePicture === "" ||
                              projectInfo.talents[0].userInfo[0]?.profilePicture?.includes("example") ? (
                                <>
                                  <CardMedia className={`${styles.userBoxImg}`}>{projectInfo.talents[0].userInfo[0].name.charAt(0)}</CardMedia>
                                </>
                              ) : (
                                <img src={projectInfo.talents[0].userInfo[0].profilePicture} alt="" />
                              )}
                            </CardMedia>
                          </Link>
                        </>
                      ) : (
                        <>
                          {" "}
                          <CardMedia className={`${styles.userBoxImg}`}>
                            <img src={NoTalent} alt="" />
                          </CardMedia>
                        </>
                      )}
                    </div>

                    <div className={`${styles.flexBox} ${styles.dateOfProject}  ${styles.alignCenter}`} style={{ gap: "2px" }}>
                      <CalendarMonthIcon />
                      {formatDate(endDate)}
                    </div>
                  </div>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
