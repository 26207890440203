/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgress, CssBaseline, Grid, InputLabel, TextField } from "@mui/material";
import styles from "./auth.module.css";
import btnStyles from "../../assets/button.module.css";
import { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar";
import LineOverlay from "../../assets/icons/linelayer.png";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { talentRegistrationAPI } from "../../reducers/userSlice";
import { useTranslation } from "react-i18next";
export default function TalentRegisterName() {
  const { t } = useTranslation();
  const [nameInput, setNameInput] = useState("");
  const { loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("darkbg");
    return () => {
      document.body.classList.remove("darkbg");
    };
  }, []);

  const handleContinue = () => {
    const email = localStorage.getItem("newUserEmail");
    const request = {
      fromPath: "register",
      name: nameInput,
      email: email,
    };
    dispatch(talentRegistrationAPI(request, navigate));
    // navigate("/talent-otp");
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (/^[a-zA-Z ]*$/.test(inputValue)) {
      setNameInput(inputValue);
    }
  };

  // **** Modal ***

  return (
    <>
      <Navbar />
      <Box sx={{ width: "100%" }} style={{ position: "relative" }} className={`${styles.authRegisterPage} custombg `}>
        <CssBaseline />
        <img src={LineOverlay} alt="" className={`${styles.lineLayer}`} />
        <img src={LineOverlay} alt="" className={`${styles.lineLayerRight}`} />
        <Grid container>
          <Grid item sm={12}>
            <Box className={`${styles.talentAuthContainer}`}>
              <div>
                <Box className={`${styles.talentHeadingBox} ${styles.textWhite}`}>
                  <Typography variant="h2">{t("talentRegisterTitle")}</Typography>
                  <Typography variant="h4"> {t("talentRegisterSubTitle")}</Typography>
                </Box>
                <Box className={`${styles.talentRegsiterBox} ${styles.textWhite} mt-5`}>
                  <InputLabel className={`${styles.inputLabel} `}>
                    {t("talentRegisterLabel")}
                    <span>*</span>
                  </InputLabel>
                  <div className={`${styles.inputBox}`}>
                    <TextField
                      required
                      id="outlined-required-name"
                      placeholder="Ex: John Doe"
                      className={`${styles.inputBoxInput} ${styles.alertText}`}
                      value={nameInput}
                      onChange={handleInputChange}
                      error={!!nameInput}
                      helperText={nameInput.trim().length < 5 ? "Full name should be atlest 5 character" : null}
                    />
                  </div>

                  <div className={`${styles.cetnerAlignedBtn} fullWidth mt-5`}>
                    <Button
                      className={`${btnStyles.yinBtn} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner} `}
                      onClick={handleContinue}
                      disabled={!nameInput.trim() || nameInput.trim().length < 5}
                    >
                      <span className={`${btnStyles.btnText}`}>
                        <span className={`${btnStyles.btnTitle}`}> {t("regsiterTextContinue")}</span>
                      </span>
                      {loading && <CircularProgress color="inherit" className={`loaderIcon`} />}
                    </Button>
                  </div>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
