import { createSlice } from "@reduxjs/toolkit";
import {
  getProjectDetailAPI,
  getRequestDetailAPI,
  getTalentDeliverableChatListAPI,
  getTalentDeliverableDocumentListAPI,
  getTalentNewRequestList,
  getTalentProjectListAPI,
  postAcceptRequestAPI,
  postRejectRequestAPI,
  postTalentDeliverableChatAPI,
  postTalentDeliverableDocUploadAPI,
  postTalentDeliverableForReviewAPI,
  postTalentProjectListByStatusAPI,
  postCustomerDeliverableForReviewAPI,
} from "../services/api/talent-projects-api";
import { openSnackbar } from "./snackbarSlice";
import { getRyaChat, getRyaTalentProjects } from "../services/api/rya-bot-api";

const initialState = {
  loading: false,
  requestLoading: false,
  requestDetailLoading: false,
  docUploadLoading: false,
  talentNewRequest: [],
  talentRequestDetail: [],
  talentProjectListByStatus: [],
  talentProjectDetail: [],
  talentProjectList: [],
  talentDeliverableCommentList: {},
  talentDeliverableDocumentList: [],
  deliverableCurrentStatus: {},
  headerProjectTitle: "",
  headerProjectId: "",
  ryaBotResponse: "",
  ryaChangeProjectClick: "",
  ryaPopupChangeProject: [],
  projectCredit: "",
  currentCat: "",
  sentReviewMessage: {},
};

const TalentProjectSlice = createSlice({
  name: "talentProject",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    isRequestLoading(state) {
      state.requestLoading = true;
    },
    finishRequestLoading(state) {
      state.requestLoading = false;
    },
    isRequestDetailLoading(state) {
      state.requestDetailLoading = true;
    },
    finishRequestDetailLoading(state) {
      state.requestDetailLoading = false;
    },
    isDocUploadLoading(state) {
      state.docUploadLoading = true;
    },
    finishDocUploadLoading(state) {
      state.docUploadLoading = false;
    },
    talentNewRequestListSuccess(state, action) {
      state.talentNewRequest = action.payload;
    },
    talentRequestDetailSuccess(state, action) {
      state.talentRequestDetail = action.payload;
    },
    talentProjectListByStatusSuccess(state, action) {
      state.talentProjectListByStatus = action.payload;
    },
    talentProjectDetailSuccess(state, action) {
      state.talentProjectDetail = action.payload;
    },
    talentProjectListSuccess(state, action) {
      state.talentProjectList = action.payload;
    },
    talentDeliverableCommentListSuccess(state, action) {
      state.talentDeliverableCommentList = action.payload;
    },
    talentDeliverableDocumentListSuccess(state, action) {
      state.talentDeliverableDocumentList = action.payload;
    },
    deliverableSendCommentSuccess: (state, action) => {
      state.talentDeliverableCommentList.result = [...state.talentDeliverableCommentList.result, action.payload];
      // state.deliverableSendComment = [...state.deliverableSendComment, action.payload];
    },
    // GD STATE

    currentDeliverableStatus(state, action) {
      state.deliverableCurrentStatus = action.payload;
    },

    headerProjectTitleSuccess(state, action) {
      state.headerProjectTitle = action.payload;
    },
    headerProjectIdSuccess(state, action) {
      state.headerProjectId = action.payload;
    },

    ryaBotResponseSuccess(state, action) {
      state.ryaBotResponse = action.payload;
    },

    ryaChangeProjectClickSuccess(state, action) {
      state.ryaChangeProjectClick = action.payload;
    },

    headerPopUpProjectSelectionSuccess(state, action) {
      state.ryaPopupChangeProject = action.payload;
    },

    projectCreditsSuccess(state, action) {
      state.projectCredit = action.payload;
    },

    currentCatSuccess(state, action) {
      state.currentCat = action.payload;
    },

    sentReviewMessageSuccess(state, action) {
      state.sentReviewMessage = action.payload;
    },
  },
});

export const {
  isLoading,
  finishLoading,
  isRequestLoading,
  finishRequestLoading,
  isDocUploadLoading,
  finishDocUploadLoading,
  talentNewRequestListSuccess,
  talentRequestDetailSuccess,
  isRequestDetailLoading,
  finishRequestDetailLoading,
  talentProjectListByStatusSuccess,
  talentProjectDetailSuccess,
  talentProjectListSuccess,
  talentDeliverableCommentListSuccess,
  talentDeliverableDocumentListSuccess,
  deliverableSendCommentSuccess,
  currentDeliverableStatus,
  headerProjectTitleSuccess,
  headerProjectIdSuccess,
  ryaBotResponseSuccess,
  ryaChangeProjectClickSuccess,
  headerPopUpProjectSelectionSuccess,
  projectCreditsSuccess,
  currentCatSuccess,
  sentReviewMessageSuccess,
} = TalentProjectSlice.actions;

export const talentNewRequestAPI = (request) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isLoading());

  try {
    const response = await getTalentNewRequestList(request);

    if (response.status === 200) {
      dispatch(talentNewRequestListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishLoading());
};

/************ *********/

export const projectCreditFunc = (request) => async (dispatch) => {
  //console.log("Credit State", request);
  dispatch(projectCreditsSuccess(request));
};

export const currentCatFunc = (request) => async (dispatch) => {
  //console.log("Category State", request);
  dispatch(currentCatSuccess(request));
};

export const popUpResponse = (request) => async (dispatch) => {
  //console.log("navbar Geee", request);
  dispatch(headerPopUpProjectSelectionSuccess(request));
};

export const navbarProjectTitle = (request) => async (dispatch) => {
  // console.log("navbar Geee", request);
  dispatch(headerProjectTitleSuccess(request));
};

export const navbarProjectId = (request) => async (dispatch) => {
  // console.log("navbar Geee", request);
  dispatch(headerProjectIdSuccess(request));
};

export const changeProjectClick = (request) => async (dispatch) => {
  //console.log("navbar Geee", request);
  dispatch(ryaChangeProjectClickSuccess(request));
};

export const talentAcceptRequestAPI = (request, handleClose, navigate) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isRequestLoading());

  try {
    const response = await postAcceptRequestAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      // dispatch(talentNewRequestAPI());
      handleClose();
      navigate("/talent-requests");
      // dispatch(talentNewRequestListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishRequestLoading());
};

export const talentRejectRequestAPI = (request, handleClose, navigate) => async (dispatch) => {
  // const email = request?.email;

  dispatch(isRequestLoading());

  try {
    const response = await postRejectRequestAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      // dispatch(talentNewRequestAPI());
      handleClose();
      navigate("/talent-requests");
      // dispatch(talentNewRequestListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishRequestLoading());
};

export const talentRequestDetailAPI = (request) => async (dispatch) => {
  dispatch(isRequestDetailLoading());

  try {
    const response = await getRequestDetailAPI(request);

    if (response.status === 200) {
      dispatch(talentRequestDetailSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishRequestDetailLoading());
};

export const talentProjectListByStatusAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postTalentProjectListByStatusAPI(request);

    if (response.status === 200) {
      dispatch(talentProjectListByStatusSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(finishLoading());
  }
};

export const talentProjectDetailAPI = (request) => async (dispatch) => {
  dispatch(isRequestDetailLoading());

  try {
    const response = await getProjectDetailAPI(request);

    if (response.status === 200) {
      dispatch(talentProjectDetailSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishRequestDetailLoading());
};

export const talentProjectListAPI = (request) => async (dispatch) => {
  // dispatch(isRequestDetailLoading());

  try {
    const response = await getTalentProjectListAPI(request);

    if (response.status === 200) {
      dispatch(talentProjectListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  // dispatch(finishRequestDetailLoading());
};

export const talentDeliverableChatListAPI = (request) => async (dispatch) => {
  // dispatch(isRequestDetailLoading());

  try {
    const response = await getTalentDeliverableChatListAPI(request);

    if (response.status === 200) {
      dispatch(talentDeliverableCommentListSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
  // dispatch(finishRequestDetailLoading());
};

export const talentDeliverableChatAPI = (request, setInputValue) => async (dispatch) => {
  // dispatch(isTalentProfileLoading());

  try {
    const response = await postTalentDeliverableChatAPI(request);

    if (response.status === 201) {
      dispatch(deliverableSendCommentSuccess(response.data.data));
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      setInputValue("");
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  // dispatch(finishTalentProfileLoading());
};

export const talentDeliverableDocUploadAPI = (request, handleClose) => async (dispatch) => {
  dispatch(isDocUploadLoading());

  try {
    const response = await postTalentDeliverableDocUploadAPI(request);

    if (response.status === 201) {
      dispatch(talentDeliverableDocumentListSuccess(response.data.result));
      handleClose();
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishDocUploadLoading());
};

export const talentDeliverableDocumentListAPI = (request) => async (dispatch) => {
  // dispatch(isRequestDetailLoading());

  try {
    const response = await getTalentDeliverableDocumentListAPI(request);
    if (response.status === 200) {
      dispatch(talentDeliverableDocumentListSuccess(response.data.result));
    }
  } catch (error) {
    console.log(error);
  }
  // dispatch(finishRequestDetailLoading());
};

export const talentDeliverableForReviewAPI = (request, handlePopupClose) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postTalentDeliverableForReviewAPI(request);

    if (response.status === 200) {
      dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      const responseResult = response.data.result;
      console.log("SFR", response);
      dispatch(currentDeliverableStatus(responseResult));
      dispatch(sentReviewMessageSuccess(response.data.messageData));
      handlePopupClose();
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

/// CUSTOMER REVIEW API  GD///

export const customerDeliverableForReviewAPI = (request, handlePopupClose) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await postCustomerDeliverableForReviewAPI(request);
    console.log("responsess", response);
    if (response.status === 200) {
      // dispatch(deliverableSendCommentSuccess(response.data.data));
      dispatch(openSnackbar({ message: response.data?.message, severity: "success" }));
      const responseResult = response.data.result;
      console.log("responseResult", responseResult);
      dispatch(currentDeliverableStatus(responseResult));
      handlePopupClose();
    }
  } catch (error) {
    console.log(error);
    dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }

  dispatch(finishLoading());
};

/// RYA   GD///

export const ryaTalentProjectsAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getRyaTalentProjects(request);
    if (response.status === 200) {
      dispatch(talentProjectListSuccess(response.data?.result));
      console.log("gurdee", response.data?.result[0]?.projectCredits);

      // dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      // const responseData = response.data?.result;
    }
  } catch (error) {
    console.log(error);
    //dispatch(openSnackbar({ message: error.response?.data?.message, severity: "error" }));
  }
  dispatch(finishLoading());
};

export const ryaChatAPI = (request) => async (dispatch) => {
  dispatch(isLoading());

  try {
    const response = await getRyaChat(request);
    if (response.status === 200) {
      dispatch(ryaBotResponseSuccess(response.data));
      //const responseData = response.data?.result;
      console.log("chatBot", response.data);
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(finishLoading());
};

export default TalentProjectSlice.reducer;
