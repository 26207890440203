import { callAPI } from "../config/api-core";
import { getAdmintTalentsListAPIMethods, talentAssignAPIMethods, talentsListAPIMethods } from "../methods";

const talentsListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const param = `?search=${request}`;
  const response = await callAPI(talentsListAPIMethods + param, true, "", header, false, false);
  return response;
};

const assignTalentAPI = async (request) => {
  let userToken = localStorage.getItem("token");

  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(talentAssignAPIMethods, false, request, header, false, false);
  return response;
};

/******** GD API ****** */

const getTalentsListAdminAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let header = {
    Authorization: userToken && userToken,
  };
  const response = await callAPI(getAdmintTalentsListAPIMethods, false, request, header, false, false);
  return response;
};

export const getTalentsListAPI = (request) => talentsListAPI(request);
export const postAssignTalentAPI = (request) => assignTalentAPI(request);
export const getAdminTalentsListAPI = (request) => getTalentsListAdminAPI(request);

export default {
  getTalentsListAPI,
  postAssignTalentAPI,
  getAdminTalentsListAPI,
};
