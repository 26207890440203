import projects from "./icons/projects.svg";
import deliverable from "./icons/deliverable.svg";
import payment from "./icons/payment.svg";
import logout from "./icons/logout.svg";
import uploadDoc from "./icons/upload.svg";
import trash from "./icons/trash.svg";
import filedarkicon from "./icons/darkfile.svg";
import teamicon from "./icons/teams.svg";
import fileicon from "./icons/fileIcon.svg";
import profile from "./icons/profile.svg";
import duscussionicon from "./icons/duscussionicon.svg";
import comment from "./icons/comments.svg";
import calender from "./icons/calender.svg";

import chat from "./icons/chat.svg";
import document from "./icons/document.svg";
import description from "./icons/description.svg";
import timeline from "./icons/timeline.svg";
import teamico from "./icons/teamico.svg";
import tick from "./icons/tickIcon.svg";
export const TickIcon = () => {
  return <img src={tick} alt="TickIcon" />;
};
export const TeamImgIcon = () => {
  return <img src={teamico} alt="TeamImgIcon" />;
};
export const TimelineIcon = () => {
  return <img src={timeline} alt="TimelineIcon" />;
};
export const DescIcon = () => {
  return <img src={description} alt="DescIcon" />;
};

export const DocumentIcon = () => {
  return <img src={document} alt="DocumentIcon" />;
};
export const ChatIcon = () => {
  return <img src={chat} alt="ChatIcon" />;
};

export const CalenderIcon = () => {
  return <img src={calender} alt="CalenderIcon" />;
};
export const CommentIcon = () => {
  return <img src={comment} alt="CommentIcon" />;
};
export const ProjectIcon = () => {
  return <img src={projects} alt="ProjectIcon" />;
};
export const DiscussionIcon = () => {
  return <img src={duscussionicon} alt="DiscussionIcon" />;
};
export const ProfileIcon = () => {
  return <img src={profile} alt="ProfileIcon" />;
};
export const FileIcon = () => {
  return <img src={fileicon} alt="FileIcon" />;
};
export const DeliverableIcon = () => {
  return <img src={deliverable} alt="DeliverableIcon" />;
};

export const PaymentIcon = () => {
  return <img src={payment} alt="PaymentIcon" />;
};

export const LogoutIcon = () => {
  return <img src={logout} alt="LogoutIcon" />;
};

export const UploadIcon = () => {
  return <img src={uploadDoc} alt="UploadIcon" />;
};

export const TimesIcon = () => {
  return <img src={trash} alt="TimesIcon" />;
};

export const FileDarkIcon = () => {
  return <img src={filedarkicon} alt="FileDarkIcon" />;
};
export const TeamsIcon = () => {
  return <img src={teamicon} alt="TeamsIcon" />;
};
