import { combineReducers } from "@reduxjs/toolkit";
import snackbarReducer from "./snackbarSlice";
import userReducer from "./userSlice";
import intialQueryReducer from "./intialQuerySlice";
import deliverablesReducer from "./deliverablesSlice";
import talentsReducer from "./talentSlice";
import supportReducer from "./supportSlice";
import talentProfileReducer from "./talentProfileSlice";
import talentProjectReducer from "./talentProjectSlice";

const rootReducer = combineReducers({
  snackbar: snackbarReducer,
  user: userReducer,
  intialQuery: intialQueryReducer,
  deliverables: deliverablesReducer,
  talents: talentsReducer,
  support: supportReducer,
  talentProfile: talentProfileReducer,
  talentProject: talentProjectReducer,
});

export default rootReducer;
