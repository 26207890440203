import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import styles from "./subscribe.module.css";
import btnStyles from "../../../assets/button.module.css";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
export default function SubscribeBox() {
  const { t } = useTranslation();
  return (
    <div className={`${styles.contianerBg} `}>
      <Grid container className={`${styles.container} ${styles.textWhite}`} style={{ alignItems: "center" }}>
        <Grid item sm={12} md={5}>
          <Typography variant="h1" className={`${styles.headingH1}`}>
            {t("subscriptionTitleStart")}
          </Typography>
          <Typography className={`${styles.textP}`}> {t("subscriptionSubTitle")}</Typography>
        </Grid>

        <Grid item sm={12} md={7} className={`${styles.fullWidth}`}>
          <Box component="form" noValidate autoComplete="off" className={` transparentInput ${styles.searchQueryForm}`}>
            <TextField required id="outlined-required" placeholder={t("subscriptionInputPlacholder")} className={`${styles.searchQueryInput}`} />
            <Button className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinSqCorner}`}>
              <span className={`${btnStyles.btnText}`}>
                <span className={`${btnStyles.btnTitle}`}> {t("subscriptionBtnTxt")}</span>
                <span className={`${btnStyles.btnIcon}`}>
                  <SendIcon />
                </span>
              </span>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
