import Box from "@mui/material/Box";

import styles from "./requestDetails.module.css";
import btnStyles from "../../../assets/button.module.css";

import FileIcon from "../../../assets/icons/darkfile.svg";

import { Button, Card, CardContent, CardMedia, CssBaseline, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

import { FileDarkIcon } from "../../../assets/yingoIcons";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { talentRequestDetailAPI } from "../../../reducers/talentProjectSlice";
import { formatDate } from "../../../utils/helper";
import DefaultImg from "../../../assets/icons/defaultState.png";
import Loader from "../../../components/common/Loader";
import RejectRequestDialog from "../navbar/dialogs/RejectRequestDialog";
import AcceptRequest from "../navbar/AcceptRequest";
import { useTranslation } from "react-i18next";

function TalentRequestDetail() {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [acceptRequestDialog, setAcceptRequestDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  // const isMounted = useRef(false);
  const { requestDetailLoading, talentRequestDetail } = useSelector((state) => state.talentProject);

  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!isMounted.current) {
    const request = {
      projectID: param.id,
    };
    // dispatch(setTemporaryDocEmpty([]));
    dispatch(talentRequestDetailAPI(request));
    // isMounted.current = true;
    // }
  }, [dispatch, param.id]);

  const requestProjectDetail = useMemo(() => {
    if (talentRequestDetail && talentRequestDetail.length > 0) {
      const project = talentRequestDetail[0];

      // const deliverables = project.deliverables ? JSON.parse(project.deliverables) : {};
      const skillsRequired = project.projectInfo[0].skillsRequired ? JSON.parse(project.projectInfo[0].skillsRequired) : {};
      const tags = project.projectInfo[0].tags ? JSON.parse(project.projectInfo[0].tags) : {};

      return {
        requestProjectId: project.projectInfo[0]._id || "",
        requestProjectTitle: project.projectInfo[0].name || "",
        requestProjectDescription: project.projectInfo[0].longDescription || "",
        requestProjectDeliverable: project.deliverable || [],
        requestProjectSkills: skillsRequired || {},
        requestProjectAi: tags || [],
        requestProjectDoc: project.projectDocuments || [],
        requestProjectStartDate: project.projectInfo[0].startDate || "",
        requestProjectCustomer: project.projectInfo[0].userData || {},
      };
    }
    return {};
  }, [talentRequestDetail]);

  const handleClickOpen = (projectId) => {
    setSelectedProjectId(projectId);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAcceptRequestDialog = (projectId) => {
    setSelectedProjectId(projectId);
    setAcceptRequestDialog(true);
  };

  const handleAcceptRequestDialogClose = () => {
    setAcceptRequestDialog(false);
  };

  return (
    <>
      <Box sx={{ width: "100%" }} style={{ position: "relative" }}>
        <CssBaseline />
        {requestDetailLoading ? (
          <Loader />
        ) : (
          <Box>
            <Grid container className={`${styles.justifySpace}`} style={{ marginBottom: "25px", alignItems: "center" }}>
              <Grid item sm={12} md={6} lg={6}>
                <Box className={`${styles.projectEditableInputBox}`}>
                  <label>
                    <b>
                      {t("textProjectTitle")} : {requestProjectDetail.requestProjectTitle}
                    </b>
                  </label>
                </Box>
                {/* {error && <Typography style={{ color: "red" }}>{error}</Typography>} */}
              </Grid>
              <Grid item sm={12} md={6} lg={6} className={`${styles.btnAligns}`}>
                <Button
                  className={`${btnStyles.yinBtnSm} ${btnStyles.yinSuccessBtn} ${btnStyles.yinRound}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handleAcceptRequestDialog(requestProjectDetail.requestProjectId)}
                >
                  {t("textAccept")}
                </Button>
                <Button
                  className={`${btnStyles.yinBtnSm} ${btnStyles.yinDangerBtn} ${btnStyles.yinRound}`}
                  style={{ marginRight: "5px" }}
                  onClick={() => handleClickOpen(requestProjectDetail.requestProjectId)}
                >
                  {" "}
                  {t("textDecline")}
                </Button>
                {/* <Button
                // onClick={handleClickOpenDetail}
                className={`${btnStyles.yinBtnSm} ${btnStyles.yinCylinBtn} ${btnStyles.yinRound}`}
                style={{ marginRight: "0px" }}
              >
                Back
              </Button> */}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent className={`${styles.scrollableContent}`}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textProjectDescription")}
                    </Typography>
                    <Typography variant="p" className={`${styles.panelCardP} `}>
                      {requestProjectDetail.requestProjectDescription}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent className={`${styles.listDeliverables} ${styles.scrollableContent}`}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textDeliverables")}
                    </Typography>
                    <List className={`${styles.dablList}`}>
                      {requestProjectDetail.requestProjectDeliverable && requestProjectDetail.requestProjectDeliverable.length > 0
                        ? requestProjectDetail.requestProjectDeliverable.map((deliverable, index) => {
                            const { _id, title } = deliverable;
                            return (
                              <ListItem disablePadding key={_id}>
                                <ListItemIcon>
                                  <img src={FileIcon} alt="" />
                                </ListItemIcon>
                                <ListItemText className={`${styles.listDeliverableTitle}`} primary={title} />
                              </ListItem>
                            );
                          })
                        : null}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent className={`${styles.listAiTools}`}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textAITools")}
                    </Typography>
                    <List>
                      {requestProjectDetail.requestProjectAi && requestProjectDetail.requestProjectAi.length > 0
                        ? requestProjectDetail.requestProjectAi.map((tool) => {
                            const { id, title, icon } = tool;
                            return (
                              <ListItem disablePadding key={id}>
                                <ListItemIcon>
                                  <img src={icon} alt="" />
                                </ListItemIcon>
                                <ListItemText className={`${styles.listaiToolTitle}`} primary={title} />
                              </ListItem>
                            );
                          })
                        : null}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent className={`${styles.skills}`}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textSkills")}
                    </Typography>
                    <List>
                      {requestProjectDetail.requestProjectSkills
                        ? Object.keys(requestProjectDetail?.requestProjectSkills).map((key, index) => {
                            return (
                              <ListItem disablePadding key={index}>
                                <ListItemText className={`${styles.listSkillTitle}`} primary={requestProjectDetail.requestProjectSkills[key]} />
                              </ListItem>
                            );
                          })
                        : null}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={`${styles.xsFull} mb-3`}>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent className={`${styles.listAiTools}`}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textStartDate")}
                    </Typography>
                    <div className={`${styles.datePickerBox}`}>
                      <Typography className={`${styles.panelCardP}`}>
                        {requestProjectDetail.requestProjectStartDate ? formatDate(requestProjectDetail.requestProjectStartDate) : "N/A"}
                      </Typography>
                    </div>
                    <div className={`mt-3`}>
                      <label className={`${styles.panelCardH4}`}> {t("textCustomerInfo")}</label>
                      <Box className={`${styles.teamCard}`}>
                        <CardMedia className={`${styles.teamMemberImg}`}>
                          <img src={requestProjectDetail?.requestProjectCustomer?.profilePicture || DefaultImg} alt="TeamMember" />
                        </CardMedia>
                        <CardContent className={`${styles.teamMemberDetail}`}>
                          <Typography variant="h4">{requestProjectDetail?.requestProjectCustomer?.name} </Typography>
                          <Typography variant="body">Email : {requestProjectDetail?.requestProjectCustomer?.email}</Typography>
                        </CardContent>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card className={`panelCard heightFull`}>
                  <CardContent style={{ position: "relative" }}>
                    <Typography variant="h4" className={`${styles.panelCardH4}`}>
                      {t("textDocuments")}
                    </Typography>
                    {requestProjectDetail.requestProjectDoc && requestProjectDetail.requestProjectDoc.length > 0 ? (
                      requestProjectDetail.requestProjectDoc.map((doc) => {
                        const { _id, filename, path } = doc;

                        return (
                          <a href={path} target="_blank" rel="noopener noreferrer" key={_id}>
                            <div className={`${styles.uploadedList}`}>
                              <div className={`${styles.doclistitem}`}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <div className={`${styles.docImgs}`}>
                                    <FileDarkIcon />
                                  </div>
                                  <span className={`${styles.docTitle}`}>{filename}</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    ) : (
                      <h4>{t("noDocument")}</h4>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <RejectRequestDialog open={openDialog} handleClose={handleDialogClose} selectedProjectId={selectedProjectId} />
      <AcceptRequest open={acceptRequestDialog} handleClose={handleAcceptRequestDialogClose} selectedProjectId={selectedProjectId} />
    </>
  );
}

export default TalentRequestDetail;
