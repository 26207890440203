/* eslint-disable react/prop-types */
import { Box, Button, Dialog, DialogContent, DialogContentText, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "../../../pages/auth/auth.module.css";
import btnStyles from "../../../assets/button.module.css";
import SucessIcon from "../../../assets/images/successIcon.svg";

const WelcomeModal = ({ open, handleClose, handleClickOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog className={`modalBoxCard`} fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <DialogContentText>
          <div className={`${styles.iconCard}`}>
            <img src={SucessIcon} alt="" />
          </div>
          <Box className={`${styles.modalContentBox}`}>
            <Typography variant="h3">Welcome! Glad to Have You</Typography>

            <Typography variant="body">Let complete your profile.</Typography>
          </Box>
          <div className={`${styles.cetnerAlignedBtn} fullWidth mt-3`}>
            <Button
              style={{ minWidth: "185px" }}
              className={`${btnStyles.yinBtnSm} ${btnStyles.yinPrimaryBtn} ${btnStyles.yinRound} `}
              onClick={handleClose}
            >
              Complete Profile
            </Button>
          </div>
          {/* <div className={`${styles.cetnerAlignedBtn} fullWidth mt-2`}>OR</div> */}
          {/* <div className={`${styles.cetnerAlignedBtn} fullWidth mt-2`}>
            <Button disabled className={`${btnStyles.yinBtnSm} ${btnStyles.yinLinkedinBtn} ${btnStyles.yinRound} `} onClick={handleClose}>
              Import from LinkedIn
            </Button>
          </div> */}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeModal;
